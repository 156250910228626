import "regenerator-runtime/runtime";
import React from "react";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import OwlCarousel from "react-owl-carousel";
import { LoginCover, logo, NetIcon2 } from "../../imagepath";
import FeatherIcon from "feather-icons-react";
import { GoogleLogin } from "@react-oauth/google";
import FacebookLogin from "react-facebook-login";
import configurations from "../../../../config";
import { useRef, useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import "../../../assets/js/jquery-3.6.0.min.js"
import { LoginFormValidator } from "../../../validators/login.validator"
import { useActions, useAppState } from "../../../presenter";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

const Login = () => {
  const { loginWithGoogle, loginWithFacebook } = useActions();
  const { loadingBtn } = useAppState();
  const { t: translate } = useTranslation();
  const { t } = useTranslation();
  const [passwordType, setPasswordType] = useState("password");
  // const [passwordInput, setPasswordInput] = useState("");
  const { login } = useActions();
  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(LoginFormValidator),
  });

  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";
  const notLogged = new URLSearchParams(location.search).get("notLogged");

  useEffect(() => {
    if (notLogged) {
      toast.error("Please login first", {
        position: "top-left",
      });
    }
  }, [notLogged]);

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text")
      return;
    }
    setPasswordType("password");
  };

  var settings = {
    //autoWidth: true,
    items: 1,
    margin: 25,
    dots: true,
    nav: true,
    navText: [
      '<i className="fas fa-arrow-left"></i>',
      '<i className="fas fa-arrow-right"></i>',
    ],

    loop: false,
    responsiveClass: true,
    responsive: {
      0: {
        items: 1,
      },
      768: {
        items: 1,
      },
      1170: {
        items: 1,
      },
    },
  };

  const responseFacebook = async (response) => {
    const resp = await loginWithFacebook(response);
  };

  const onSubmit = (data) => {
    login(data)
      .then(res => {
        if (["Super_Admin", "Admin"].includes(res.user.role)) navigate("/admin/dashboard");
        else navigate("/student-dashboard");
      });
  }

  return (
    <>
      <div className="main-wrapper log-wrap">
        <div className="row">
          {/* Login Banner */}
          <div className="col-md-6 login-bg">
            <OwlCarousel
              {...settings}
              className="owl-carousel login-slide owl-theme"
            >
              <div className="welcome-login">
                <div className="login-banner">
                  <img src={LoginCover} className="img-fluid" alt="Logo" />
                </div>
                <div className="mentor-course text-center">
                  <h2>
                  {t("form.welcomeTitle")}
                  </h2>
                  <p>
                  {t("form.loginSubtitle")}
                  </p>
                </div>
              </div>
            </OwlCarousel>
          </div>
          {/* /Login Banner */}
          <div className="col-md-6 login-wrap-bg">
            {/* Login */}
            <div className="login-wrapper">
              <div className="loginbox">
                <div className="w-100">
                  <div className="img-logo">
                    <img src={logo} className="img-fluid" alt="Logo" />
                    <div className="back-home">
                      <Link to="/">{t('form.backHome')}</Link>
                    </div>
                  </div>
                  <h1>{t('form.signIn')}</h1>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-group">
                      <label className="form-control-label">{t('form.email')}</label>
                      <input
                        {...register("email")}
                        type="email"
                        className="form-control"
                        placeholder={t('form.enterEmail')}
                      />
                    </div>
                    <p className="invalid-feedback d-block">
                      {translate(errors.email?.message)}
                    </p>

                    <div className="form-group">
                      <label className="form-control-label">{t('form.password')}</label>
                      <div className="pass-group">
                        <input
                          {...register("password")}
                          type={passwordType} name="password" className="form-control" placeholder={t('form.password')}
                        />
                        <span className="toggle-password feather-eye" onClick={togglePassword}>
                          {passwordType === "password" ? <FeatherIcon icon="eye" /> : <FeatherIcon icon="eye-off" />}
                        </span>
                      </div>
                    </div>
                    <p className="invalid-feedback d-block">
                      {translate(errors.password?.message)}
                    </p>

                    <div className="forgot">
                      <span>
                        <Link className="forgot-link" to="/forgot-password">
                          {t('form.forgotPassword')}
                        </Link>
                      </span>
                    </div>
                    <div className="remember-me">
                      <label className="custom_check mr-2 mb-0 d-inline-flex remember-me">
                        {" "}
                        {t('form.rememberMe')}
                        <input type="checkbox" name="radio" />
                        <span className="checkmark" />
                      </label>
                    </div>
                    <div className="d-grid">
                      <button className="btn btn-start" type="submit" disabled={loadingBtn}>
                        {loadingBtn && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>}
                        {t('form.signIn')}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <div className="google-bg text-center">
                <span>
                  <Link to="#">{t('form.orSignInWith')}</Link>
                </span>
                <div className="sign-google">
                  <ul>
                    {/* <li>
          <Link to="#">
            <img src={NetIcon1} className="img-fluid" alt="Logo" />
            {t('form.signInWithGoogle')}
          </Link>
        </li> */}
                    <li>
                      {/* <img src={NetIcon1} className="img-fluid" alt="Logo" /> */}
                      <GoogleLogin
                        onSuccess={async (credentialResponse) => {
                          const result = await loginWithGoogle(
                            credentialResponse.credential
                          );
                          const data = result.data;
                          if (data) {
                            data.user.role == "Super_Admin" ? navigate("/admin-dashboard") : navigate("/student-dashboard");
                          }
                        }}
                        onError={() => {
                          console.log("Login Failed");
                        }}
                      />
                    </li>
                    <li>
                      <Link to="#">
                        <img src={NetIcon2} className="img-fluid" alt="Logo" />
                        <FacebookLogin
                          appId={`${configurations.facebookClientID}`}
                          fields="name,first_name,last_name,email,picture"
                          callback={async (response) => {
                            const result = await loginWithFacebook(response);
                            const data = result.data;
                            if (data) {
                              data.user.role == "Super_Admin" ? navigate("/admin-dashboard") : navigate("/student-dashboard")
                            }
                          }}
                          cssClass="my-facebook-button-class"
                        />
                      </Link>
                    </li>
                  </ul>
                </div>
                <p className="mb-0">
                  {t('form.newUser')} <Link to="/register">{t('form.createAnAcc')}</Link>
                </p>
              </div>
            </div>
            {/* /Login */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
