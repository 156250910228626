import React, { useState } from "react";
import Header from "../../header";
import Footer from "../../footer";
import StudentSideBar from "../sidebar";
import { useActions, useAppState } from "../../../presenter";
import { AccountSettingValidator } from "../../../validators/account-setting.validator";
import { useTranslation } from "react-i18next";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

export default function StudentSecurity() {
  const { user, loadingBtn } = useAppState();
  const [error, setError] = useState(null);
  const [eye, seteye] = useState(true);
  const [eye2, seteye2] = useState(true);
  const [eye3, seteye3] = useState(true);
  const [userData, setUserData] = useState({
    phoneNumber: user.phoneNumber || '',
    email: user.email || '',
  });
  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(AccountSettingValidator),
  });
  // const { t: translate } = useTranslation();
  const { t } = useTranslation();
  const { updateAccountSettings } = useActions();

  const handleChange = (event) => {
    const { name, value } = event.target;
    setUserData((prevUserData) => ({
      ...prevUserData,
      [name]: value,
    }));
  };

  const onEyeClick = () => {
    seteye(!eye)
  }

  const onEyeClick2 = () => {
    seteye2(!eye2)
  }

  const onEyeClick3 = () => {
    seteye3(!eye3)
  }

  const onSubmit = async (data) => {
    if (data.newPassword !== data.confirmPassword) {
      setError("Passwords do not match");
      return;
    }
    let settings = {
      email: user.email,
      phoneNumber: user.phoneNumber
    };
    if (data.email) settings.email = data.email;
    if (data.phoneNumber) settings.phoneNumber = data.phoneNumber;
    if (data.currentPassword) settings.currentPassword = data.currentPassword;
    if (data.confirmPassword && data.newPassword) settings.newPassword = data.newPassword;
    if (data.email || data.phoneNumber || data.currentPassword || data.newPassword) {
      const result = await updateAccountSettings(settings);
    }
  };

  return (
    <div className="main-wrapper">
      <Header activeMenu={"Security"} />
      {/* Student Dashboard */}
      <div className="page-content">
        <div className="container" style={{ marginTop: '70px' }}>
          <div className="row">
            {/* Sidebar */}
            <StudentSideBar activeMenu={"Security"} />
            {/* Sidebar */}

            {/* Student Security */}
            <div className="col-xl-9 col-md-8">
              <div className="settings-widget profile-details">
                <div className="settings-menu p-0">
                  <div className="profile-heading">
                    <h3>{t('settings.account')}</h3>
                    {/* <p>
                      Edit your account settings and change your password here.
                    </p> */}
                  </div>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="checkout-form personal-address border-line">
                      <div className="personal-info-head">
                        <h4>{t('form.changeEmail')}</h4>
                        <p>
                          {t('form.currentEmail')}{" "}
                          <span>{user.email}</span>
                        </p>
                      </div>

                      <div className="new-address">
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="form-group">
                              {/* <label className="form-control-label">
                                New email address
                              </label> */}
                              <input
                                {...register("email")}
                                type="email"
                                className="form-control"
                                placeholder={t('form.enterNewEmail')}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="checkout-form personal-address border-line">
                      <div className="personal-info-head">
                        <h4>{t('form.changePhone')}</h4>
                        <p>
                          {t('form.currentPhone')}{" "}
                          <span>{user.phoneNumber}</span>
                        </p>
                      </div>

                      <div className="new-address">
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="form-group">
                              <input
                                {...register("phoneNumber")}
                                type="phone"
                                className="form-control"
                                value={userData.phoneNumber}
                                onChange={handleChange}
                                placeholder={t('form.enterNewPhone')}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="checkout-form personal-address">
                      <div className="personal-info-head">
                        <h4>{t('form.changePassword')}</h4>
                        {/* <p>
                          We will email you a confirmation when changing your
                          password, so please expect that email after submitting.
                        </p> */}
                      </div>
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label className="form-control-label">
                              {t('form.currentPassword')}
                            </label>
                            <div className="pass-group" id="passwordInput">
                              <input
                                {...register("currentPassword")}
                                type={eye ? "password" : "text"} className="form-control"
                                placeholder={t('form.enterPassword')} />
                              <span onClick={onEyeClick} className={`fa toggle-password feather-eye" ${eye ? "fa-eye" : "fa-eye-slash"}`} />
                              <span className="toggle-password feather-eye"></span>
                              <span className="pass-checked"><i className="feather-check"></i></span>
                            </div>
                          </div>
                          <div className="form-group">
                            <label className="form-control-label">
                              {t('form.newPassword')}
                            </label>
                            <div className="pass-group" id="passwordInput">
                              <input
                                {...register("newPassword")}
                                type={eye2 ? "password" : "text"}
                                className="form-control pass-input"
                                placeholder={t('form.enterNewPassword')}
                              />
                              <span onClick={onEyeClick2} className={`fa toggle-password feather-eye" ${eye2 ? "fa-eye" : "fa-eye-slash"}`} />
                              <span className="pass-checked">
                                <i className="feather-check" />
                              </span>
                            </div>
                            <span className="note">{t('form.passwordNote')}</span>
                            {/* <div
                              className="password-strength"
                              id="passwordStrength"
                            >
                              <span id="poor"></span>
                              <span id="weak"></span>
                              <span id="strong"></span>
                              <span id="heavy"></span>
                            </div>
                            <div id="passwordInfo"></div> */}
                          </div>
                          <div className="form-group">
                            <label className="form-control-label">
                              {t('form.confirmNewPassword')}
                            </label>
                            <div className="pass-group" id="passwordInputs">
                              <input
                                {...register("confirmPassword")}
                                type={eye3 ? "password" : "text"} className="form-control"
                                placeholder={t('form.enterNewPassword')} />
                              <span onClick={onEyeClick3} className={`fa toggle-password feather-eye" ${eye3 ? "fa-eye" : "fa-eye-slash"}`} />
                              <span className="pass-checked">
                                <i className="feather-check" />
                              </span>
                            </div>
                          </div>
                          {error && <p className="invalid-feedback d-block">
                            {error}
                          </p>}
                          <div className="update-profile save-password">
                            <button type="submit" className="btn btn-primary" disabled={loadingBtn}>
                              {loadingBtn && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>}
                              {t('settings.save')}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            {/* Student Security */}
          </div>
        </div>
      </div>
      {/* Student Dashboard */}
      <Footer />
    </div>
  );
}
