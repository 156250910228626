export const state = {
  loading: false,
  loadingBtn: false,
  loadingFavorites: false,
  isVerified: false,
  user: JSON.parse(localStorage.getItem("user")) || null,
  accessToken: localStorage.getItem("accessToken") || null,
  deviceToken: localStorage.getItem("deviceToken") || null,
  notifications: [],
  questions: [],
  result: null,
  topResults: null,
  fieldIdResult: [],
  suggestedFields: null,
  selectedSubfields: null,
  suggestionType: null,
  projectPositionAnswers: null,
  businessModelQuestions: null,
  businessModelResult: null,
  surveyResult: null,
  certificateDate: localStorage.getItem("certificateDate") || null,
  screenshots: JSON.parse(localStorage.getItem("screenshots")) || {
    chart: null,
    suggestedFields: null,
    selectedSuggestions: null,
    projectPosition: null,
    businessModel: null,
  },
};
