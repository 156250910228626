import React from "react";
import { Link } from "react-router-dom";
import { Icon22 } from "../imagepath";
import { formatDate } from "../../utils/date";
import podcastDefaultImage from "../../assets/img/podcastDefaultImage.png";
import blogDefaultImage from "../../assets/img/blogDefaultImage.png";

const SideBlog = ({ blogs, title, type }) => {
  console.log(type);
  return (
    <>
      <div className="card post-widget blog-widget">
        <div className="card-header">
          <h4 className="card-title">{title}</h4>
        </div>
        <div className="card-body">
          <ul className="latest-posts">
            {blogs &&
              blogs.map((blog) => (
                <li key={blog.id}>
                  <div className="post-thumb">
                    <Link
                      to={
                        type === "blog"
                          ? `/${type}-details/${blog.id}?language=${blog.language}`
                          : `/${type}-details/${blog.id}`
                      }>
                      <img
                        className="img-fluid side-img"
                        src={
                          type === "podcast"
                            ? blog.image?.url ?? podcastDefaultImage
                            : blog.image?.url ?? blogDefaultImage
                        }
                        alt=""
                      />
                    </Link>
                  </div>
                  <div className="post-info">
                    <h4>
                      <Link
                        to={
                          type === "blog"
                            ? `/${type}-details/${blog.id}?language=${blog.language}`
                            : `/${type}-details/${blog.id}`
                        }>
                        {blog.title}
                      </Link>
                    </h4>
                    <p>
                      <img className="img-fluid" src={Icon22} alt="" />
                      {formatDate(blog.createdAt)}
                    </p>
                  </div>
                </li>
              ))}
          </ul>
        </div>
      </div>
    </>
  );
};

export default SideBlog;
