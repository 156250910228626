import React, { useEffect } from "react";
import { useState } from "react";
import { Chapter, Play, Timer2, Users, Video2 } from "../../../imagepath";
import { Link, useParams } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import { useActions, useAppState } from "../../../../presenter";
import { useTranslation } from "react-i18next";
import defaultCourseImg from "../../../../assets/img/defaultCourseImg.png";
import { useNavigate } from "react-router-dom";
import { translateTime } from "../../../../utils/time";
import { GoSell } from "@tap-payments/gosell";
import PaymentComponent from "../../../payment";

const DetailsContent = ({ course, totalEnrolledStudent }) => {
  const { t } = useTranslation();
  const { id, language: lang } = useParams();
  const [open, setOpen] = useState(false);
  const [favorites, setFavorites] = useState([]);
  const [isEnrolledButton, setIsEnrolledButton] = useState(false);
  const {
    listFavorites,
    addFavorite,
    deleteFavorite,
    isEnrolled,
    payment,
    enrollCourse,
  } = useActions();

  const { user } = useAppState();
  const navigate = useNavigate();
  const isEnrolledHandler = async () => {
    const result = await isEnrolled({ id, language: lang });
    setIsEnrolledButton(result.isEnrolled);
  };
  useEffect(() => {
    if (user) {
      isEnrolledHandler();

      fetchFavorites();

      // GoSell.showResult({
      //   callback: async (response) => {
      //     const result = await payment({
      //       productType: "Course",
      //       productId: id,
      //       chargeId: response.callback.id,
      //     });

      //     if (result) {
      //       setIsEnrolledButton(true);
      //     }
      //   },
      // });
    }
  }, [isEnrolledButton]);

  const fetchFavorites = () => {
    listFavorites({ currentPage: 1, limit: 1000 }).then((res) => {
      setFavorites(res.favorites);
    });
  };

  const handleEnrollCourse = async () => {
    if (!user) {
      navigate("/login?notLogged=true");
      return;
    }

    if (isEnrolledButton) {
      navigate(
        `/course-lesson/${course.id}?language=${
          course?.isMultiLanguage ? user.language : course?.language
        }`
      );
      return;
    }

    const result = await enrollCourse({ id, language: lang });

    if (result) {
      setIsEnrolledButton(true);
    }
  };

  const addToFavorite = async () => {
    const result = await addFavorite({ id, language: lang });
    fetchFavorites();
  };

  const removeFromFavorite = async () => {
    await deleteFavorite({ id, language: lang });
    fetchFavorites();
  };

  const computeTotals = () => {
    let totalLessons = 0;
    let totalDuration = 0;

    course.sections.forEach((section) => {
      if (section.lessons && Array.isArray(section.lessons)) {
        section.lessons.forEach((lesson) => {
          if (lesson.duration) {
            totalLessons++;
            totalDuration += +lesson.duration;
          }
        });
      }
    });

    const hours = Math.floor(totalDuration / 60);
    const minutes = totalDuration % 60;

    const formattedDuration =
      (hours > 0 ? hours + "h " : "") + (minutes > 0 ? minutes + "min" : "");

    return { totalLessons, totalDuration: formattedDuration };
  };

  const formatTime = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;

    const formattedHours = String(hours).padStart(2, "0");
    const formattedMinutes = String(remainingMinutes).padStart(2, "0");
    const formattedSeconds = "00";

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  };

  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = course.manual?.url;
    link.download = "Course Manual";
    link.click();
  };

  const replaceStr = (str) => {
    const modifiedString = str.replace(new RegExp("oembed", "g"), "iframe");
    const description = modifiedString.replace(
      new RegExp("<iframe url", "g"),
      "<iframe src"
    );
    return description;
  };

  return (
    <>
      <section className="page-content course-sec">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="row">
                <div className="card overview-sec" style={{ padding: "30px" }}>
                  <h6>{t("courseDetails.courseSummary").toUpperCase()}</h6>
                  <iframe
                    className="video-thumbnail"
                    src={course.videoUrl}
                    title="External Link Preview"
                    width="100%"
                    height="500px"
                  />
                </div>
              </div>
              {/* Overview */}
              <div className="card overview-sec">
                <div
                  className="card-body"
                  dangerouslySetInnerHTML={{
                    __html: replaceStr(course?.description),
                  }}></div>
              </div>
              {/* /Overview */}
              {/* Course Content */}
              <div className="card content-sec">
                <div className="card-body">
                  <div className="row">
                    <div className="col-sm-6">
                      <h5 className="subs-title">
                        {t("courseDetails.courseContent")}
                      </h5>
                    </div>
                    <div className="col-sm-6 text-sm-end">
                      <h6>
                        {t(
                          computeTotals().totalLessons > 1
                            ? "courseInfo.totalLessons.plural"
                            : "courseInfo.totalLessons.singular",
                          {
                            count: computeTotals().totalLessons,
                          }
                        )}
                        <span
                          style={{
                            color: "black",
                            fontSize: "12px",
                            padding: "0px 5px",
                          }}>
                          •
                        </span>

                        {translateTime(
                          computeTotals(
                            course.sections
                          ).totalDuration.toString(),
                          user?.language.toLowerCase() ?? "ar"
                        )}
                      </h6>
                    </div>
                  </div>
                  {course.sections.map((section, i) => (
                    <div className="course-card">
                      <h6 className="cou-title">
                        <Link
                          className="collapsed"
                          data-bs-toggle="collapse"
                          to={"#collapseOne" + section.id}
                          aria-expanded={open}
                          onClick={() => setOpen(!open)}
                          aria-controls="example-collapse-text">
                          {t("section", {
                            order: section.order,
                          })}
                        </Link>
                      </h6>

                      <div
                        id={"collapseOne" + section.id}
                        className="card-collapse collapse">
                        <ul>
                          {section.lessons.map((lesson, j) => (
                            <div>
                              <li>
                                <p>
                                  <img src={Play} alt="" className="me-2" />
                                  {t("courseDetails.lesson", {
                                    sectionOrder: section.order,
                                    lessonOrder: lesson.order,
                                    lessonTitle: lesson.title,
                                  })}
                                </p>
                                <div>
                                  {/* <Link to="#">Preview</Link> */}
                                  <span>{formatTime(lesson.duration)}</span>
                                </div>
                              </li>

                              {/* SURVEY */}
                              {course.hasSurvey &&
                                i == course.sections.length - 1 &&
                                j == section.lessons.length - 1 && (
                                  <li>
                                    <p>
                                      <img src="" alt="" className="me-2" />
                                      {t("courseDetails.survey")}
                                    </p>
                                    <div>
                                      {/* <span>{formatTime(lesson.duration)}</span> */}
                                    </div>
                                  </li>
                                )}
                            </div>
                          ))}
                        </ul>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              {/* /Course Content */}
            </div>
            <div className="col-lg-4">
              <div className="sidebar-sec">
                {/* Video */}
                <div className="video-sec vid-bg">
                  <div className="card">
                    <div className="card-body">
                      <img
                        className=""
                        src={course.image?.url ?? defaultCourseImg}
                        alt=""
                      />
                      {/* </Link> */}
                      <div className="video-details">
                        <div className="course-fee">
                          <h2 className="price-h3">
                            {course.price} {t("currency")}
                          </h2>
                        </div>

                        <div className="row gx-2">
                          {user && (
                            <div className="col-md-6 addHeart">
                              <button
                                type="button"
                                onClick={
                                  favorites.find((item) => item.id == id)
                                    ? removeFromFavorite
                                    : addToFavorite
                                }
                                className=" btn btn-wish w-100">
                                {/* <i className="feather-heart" />  */}
                                <FeatherIcon
                                  icon="heart"
                                  style={{
                                    fill: favorites.find(
                                      (item) => item.id == id
                                    )
                                      ? "#1b75bb"
                                      : "",
                                  }}
                                />
                                {favorites.find((item) => item.id == id)
                                  ? t("courseDetails.removeFromWishlist")
                                  : t("courseDetails.addToWishlist")}
                              </button>
                            </div>
                          )}
                          <div className="col-md-6 addHeart">
                            <button
                              type="button"
                              onClick={handleDownload}
                              className="btn btn-wish w-100">
                              {/* <i className="feather-share-2" />  */}
                              <FeatherIcon icon="download-cloud" />
                              {t("courseDetails.courseManual")}
                            </button>
                          </div>
                        </div>

                        <div
                          onClick={handleEnrollCourse}
                          className="btn btn-enroll w-100">
                          {user && isEnrolledButton
                            ? t("courseDetails.continueCourseLearning")
                            : t("courseDetails.enrollNow")}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {user && (
                  <PaymentComponent
                    order={{
                      itemId: course.id,
                      name: `${t("Course")}: ${course.title}`,
                      description: course.title,
                      quantity: "1",
                      amount: parseFloat(course.price),
                    }}
                    redirectUrl={window.location.href}
                  />
                )}

                {/* /Video */}
                {/* Include */}

                {/* /Include */}
                {/* Features */}
                <div className="card feature-sec">
                  <div className="card feature-sec">
                    <div className="card-body">
                      <div className="cat-title">
                        <h4>{t("courseDetails.includes")}</h4>
                      </div>
                      <ul>
                        <li>
                          <img src={Users} className="me-2" alt="" />{" "}
                          {t("courseDetails.enrolled")}:{" "}
                          <span>
                            {totalEnrolledStudent} {t("courseDetails.students")}
                          </span>
                        </li>
                        <li>
                          <img src={Timer2} className="me-2" alt="" />{" "}
                          {t("courseDetails.duration")}:{" "}
                          <span>
                            {translateTime(
                              computeTotals().totalDuration.toString(),
                              user?.language?.toLowerCase() ?? "ar"
                            )}
                          </span>
                        </li>
                        <li>
                          <img src={Chapter} className="me-2" alt="" />{" "}
                          {t("courseDetails.sections")}:{" "}
                          <span>{course.sections.length}</span>
                        </li>
                        <li>
                          <img src={Video2} className="me-2" alt="" />{" "}
                          {t("courseDetails.lessons")}:
                          <span> {computeTotals().totalLessons}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                {/* /Features */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default DetailsContent;
