import "regenerator-runtime/runtime"; 

import { handleError } from "../errors";

export const getStudentDashboard = async (context) => {
  try {
    const result = await context.effects.getStudentDashboard();
    return result;
  } catch (err) {
    handleError(err);
  }
};
