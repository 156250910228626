import React, { useEffect, useState } from "react";
import Header from "../../../header";
import GridInnerPage from "./gridInnerPage";
import FeatherIcon from "feather-icons-react";
import { Blog1, Blog2, Blog3 } from "../../../imagepath";
import Select from "react-select";
import { Link } from "react-router-dom";
import Footer from "../../../footer";
import { useActions, useAppState } from "../../../../presenter";
import { useTranslation } from "react-i18next";
import PageChange from "../../../page-change";

const SurveyGrid = () => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const [survey, setSurvey] = useState(null);
  const { loading, loadingBtn } = useAppState();
  const { listSurvey } = useActions();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [pagesNum, setPagesNum] = useState([]);
  const [query, setQuery] = useState("");
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const q = searchParams.get("q");
    if (q) {
      setSearchQuery(decodeURIComponent(q));
      setQuery(decodeURIComponent(q));
    }
  }, []);

  useEffect(() => {
    getSurvey();
  }, []);

  const getSurvey = () => {
    listSurvey().then((data) => {
      setSurvey(data);
    });
  };

  // const handlePageChange = (page) => {
  //   setCurrentPage(page);
  // };
  // const handleSearch = (e) => {
  //   e.preventDefault();
  //   setQuery(searchQuery);
  //   // getBlogs();
  // };
  if (!survey) return <PageChange />;

  return (
    <>
      <div className="main-wrapper">
        <Header activeMenu={"SurveyGrid"} />
        <div className="breadcrumb-bar">
          <div className="container" style={{ marginTop: "70px" }}>
            <div className="row">
              <div className="col-md-12 col-12">
                <div className="breadcrumb-list">
                  <nav aria-label="breadcrumb" className="page-breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/">{t("header.home")}</Link>
                      </li>
                      <li className="breadcrumb-item" aria-current="page">
                        {t("surveys")}
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page">
                        {t("allSurveys")}
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>

        <section className="course-content">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                {/* Filter */}
                <div className="showing-list">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="d-flex align-items-center">
                        {/* <div className="view-icons">
                          <Link to="/course-grid" className="grid-view active">
                            <FeatherIcon icon="grid" />
                          </Link>
                          <Link to="/course-list" className="list-view">
                            <FeatherIcon icon="list" />
                          </Link>
                        </div> */}
                        <div className="show-result">
                          {/* <h4>
                            Showing 1-{pagesNum.length} of {courses.length}{" "}
                            results
                          </h4> */}
                        </div>
                      </div>
                    </div>
                    <br />
                    <div style={{ display: "flex" }}>
                      <div className="col-lg-3 col-md-12 sidebar-right theiaStickySidebar">
                        <div className="stickysidebar">
                          {/* Search */}
                          {/* <div className="card search-widget blog-search blog-widget">
                            <div className="card-body">
                              <form
                                className="search-form"
                                onSubmit={handleSearch}>
                                <div className="input-group">
                                  <input
                                    type="text"
                                    placeholder="Search..."
                                    className="form-control"
                                    value={searchQuery}
                                    onChange={(e) =>
                                      setSearchQuery(e.target.value)
                                    }
                                  />
                                  <button
                                    type="submit"
                                    className="btn btn-primary">
                                    <i className="fa fa-search" />
                                  </button>
                                </div>
                              </form>
                            </div>
                          </div> */}
                          {/* /Search */}
                          {/* Latest Posts */}
                        </div>
                        {/* /Blog Sidebar */}
                      </div>
                    </div>
                  </div>
                </div>
                {/* /Filter */}

                <GridInnerPage survey={survey} />
                {/* course pagination */}
                {/* <div className="row">
                  <div className="col-md-12">
                    <ul
                      className="pagination lms-page"
                      style={{ marginBottom: "20px" }}>
                      <li
                        className={`"page-item prev" ${
                          currentPage == 1 ? "disable-btn" : ""
                        }`}>
                        <Link
                          className="page-link"
                          to="#"
                          tabIndex={-1}
                          onClick={() => setCurrentPage((prev) => prev - 1)}>
                          <i className="fas fa-angle-left" />
                        </Link>
                      </li>

                      {pagesNum.map((number) => (
                        <li
                          key={number}
                          className={`"page-item ${
                            currentPage == 1 ? "first-page" : ""
                          } ${currentPage == number ? "active" : ""}`}>
                          <Link
                            onClick={() => handlePageChange(number)}
                            className="page-link"
                            to="#">
                            {number}
                          </Link>
                        </li>
                      ))}

                      <li
                        className={`"page-item next" ${
                          currentPage == pagesNum[pagesNum.length - 1]
                            ? "disable-btn"
                            : ""
                        }`}>
                        <Link
                          onClick={() => setCurrentPage((prev) => prev + 1)}
                          className="page-link"
                          to="#">
                          <i className="fas fa-angle-right" />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div> */}
                {/* /pagination */}

                {/* /pagination */}
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </>
  );
};

export default SurveyGrid;
