import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import BlogDetails from "./components/blog/blogDetails";
import BlogGrid from "./components/blog/blogGrid";
import BlogList from "./components/blog/bloglist";
import PodcastDetails from "./components/podcast/podcastDetails";
import Cart from "./components/pages/cart";
import JobCategory from "./components/pages/category";
import Checkout from "./components/pages/checkout";
import AddCourse from "./components/pages/course/addCourse";
import CourseDetails from "./components/pages/course/courseDetails";
import CourseGrid from "./components/pages/course/courseGrid";
import CourseLesson from "./components/pages/course/courseLesson";
import CourseList from "./components/pages/course/courseList";
import ComingSoon from "./components/pages/error/comingSoon";
import Error404 from "./components/pages/error/error404";
import Error500 from "./components/pages/error/error500";
import UnderConstruction from "./components/pages/error/underConstruction";
import Faq from "./components/pages/faq";
import ForgotPassword from "./components/pages/forgotPassword";
import Login from "./components/pages/login";
import Notification from "./components/pages/notification";
import PrivacyPolicy from "./components/pages/policy";
import Register from "./components/pages/register";
import Support from "./components/pages/support";
import TermsCondition from "./components/pages/termsCondition";

//harish
import { Home } from "./components/home";
import { Dashboard } from "./components/instructor/dashboard";
import InstructorCourse from "./components/instructor/course";
import InstructorPodcast from "./components/instructor/podcast";
import InstructorEditProfile from "./components/instructor/editProfile";
import InstructorGeneralSettings from "./components/instructor/generalSettings";
import StudentEditProfile from "./components/student/editProfile";
import StudentAccounts from "./components/student/accounts";
import StudentBilling from "./components/student/billing";
import StudentDeleteProfile from "./components/student/deleteProfile";
import StudentInvoice from "./components/student/invoice";
import StudentNotification from "./components/student/notification";
import StudentPayment from "./components/student/payment";
import StudentPrivacy from "./components/student/privacy";
import StudentReferral from "./components/student/referral";
import StudentSecurity from "./components/student/security";
import StudentSocialProfile from "./components/student/socialProfile";
import StudentSubscription from "./components/student/subscription";
import StudentProfile from "./components/student/profile";
import StudentsGrid from "./components/student/grid";
import StudentsGrid2 from "./components/student/grid2";
import StudentsList from "./components/student/list";
import StudentDashboard from "./components/student/dashboard";
import DepositStudent from "./components/student/dashboard";
import TransactionStudent from "./components/student/transactionStudent";

import CourseMessage from "./components/student/courseMessage";
import CourseWishlist from "./components/student/courseWishlist";
import CourseStudent from "./components/student/courseStudent";
import PurchaseHistory from "./components/student/purchaseHistory";
import ViewInvoice from "./components/student/invoice/viewInvoice";
import NewPassword from "./components/pages/newPassword";
import config from "config";

import RequireAuth from "./components/RequireAuth";
import NotLoggedIn from "./components/NotLoggedIn";
import Unathorized from "./components/pages/error/unauthorized";
import AddPodcast from "./components/pages/podcast/addPodcast";
import InstructorVideo from "./components/instructor/video";
import AddVideo from "./components/pages/video/addVideo";
import AddBlog from "./components/pages/blog/addBlog";
import InstructorBlog from "./components/instructor/blog";
import AddField from "./components/pages/fields/addField";
import InstructorField from "./components/instructor/field";
import SurveyQuestion from "./components/pages/survey/surveyQuestion";
import AddBusinessModel from "./components/pages/businessModel/addBusinessModel";
import InstructorBusinessModel from "./components/pages/businessModel/instructorBusinessModel";
import ManageAdmins from "./components/instructor/superAdmin";
import AddAdmin from "./components/instructor/superAdmin/addAdmin";
import PDFDocument from "./components/pages/survey/pdfDocument";
import SurveyGrid from "./components/pages/surveyList/surveyGrid";
import SurveyDetails from "./components/pages/surveyList/surveyDetails";
import Certificate from "./components/pages/course/certificate";

const ROLES = {
  Student: "Student",
  Admin: "Admin",
  Super_Admin: "Super_Admin",
};

const AppRouter = () => {
  return (
    <BrowserRouter basename={`${config.publicPath}`} className={`App ${"ltr"}`}>
      <Routes>
        <Route path="/" element={<Home />} />

        <Route
          path="/admin"
          element={
            <RequireAuth allowedRole={[ROLES.Super_Admin, ROLES.Admin]} />
          }>
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="courses" element={<InstructorCourse />} />
          <Route path="podcasts" element={<InstructorPodcast />} />
          <Route path="videos" element={<InstructorVideo />} />
          <Route path="blogs" element={<InstructorBlog />} />
          <Route path="business-model" element={<InstructorBusinessModel />} />
          <Route path="fields" element={<InstructorField />} />
          <Route path="add-field" element={<AddField />} />
          <Route path="add-blog" element={<AddBlog />} />
          <Route path="add-course" element={<AddCourse />} />
          <Route path="add-podcast" element={<AddPodcast />} />
          <Route path="add-video" element={<AddVideo />} />
          <Route path="add-business-model" element={<AddBusinessModel />} />

          {/* Manage Admins */}
          <Route path="manage-admins" element={<ManageAdmins />} />
          <Route path="add-admin" element={<AddAdmin />} />

          {/* Instructor Routes */}
          <Route path="courses" element={<InstructorCourse />} />
          <Route path="profile" element={<InstructorEditProfile />} />
          <Route
            path="general-settings"
            element={<InstructorGeneralSettings />}
          />
        </Route>

        {/* Blog */}
        <Route path="/blog-list" element={<BlogList />} />
        <Route path="/blog-grid" element={<BlogGrid />} />

        {/* Pages */}
        <Route path="/page-notification" element={<Notification />} />

        <Route path="/course-list" element={<CourseList />} />
        <Route path="/come-soon" element={<ComingSoon />} />
        <Route path="/error-404" element={<Error404 />} />
        <Route path="/error-500" element={<Error500 />} />
        <Route path="/unauthorized" element={<Unathorized />} />
        <Route path="/under-construction" element={<UnderConstruction />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/support" element={<Support />} />
        <Route path="/job-category" element={<JobCategory />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/checkout" element={<Checkout />} />

        <Route element={<NotLoggedIn />}>
          <Route path="/login" element={<Login />} />
          <Route path="/reset-password" element={<NewPassword />} />
          <Route path="/register" element={<Register />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
        </Route>

        <Route path="/term-condition" element={<TermsCondition />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />

        {/*Student Routes  */}
        <Route element={<RequireAuth allowedRole={[ROLES.Student]} />}>
          <Route
            path="/setting-edit-profile"
            element={<StudentEditProfile />}
          />
          <Route
            path="/setting-student-accounts"
            element={<StudentAccounts />}
          />
          <Route path="/setting-student-billing" element={<StudentBilling />} />
          <Route
            path="/setting-student-delete-profile"
            element={<StudentDeleteProfile />}
          />
          <Route path="/setting-student-invoice" element={<StudentInvoice />} />
          <Route path="/view-invoice" element={<ViewInvoice />} />
          <Route
            path="/setting-student-notification"
            element={<StudentNotification />}
          />
          <Route path="/setting-student-payment" element={<StudentPayment />} />
          <Route path="/setting-student-privacy" element={<StudentPrivacy />} />
          <Route
            path="/setting-student-referral"
            element={<StudentReferral />}
          />
          <Route
            path="/setting-student-security"
            element={<StudentSecurity />}
          />
          <Route
            path="/setting-student-social-profile"
            element={<StudentSocialProfile />}
          />
          <Route
            path="/setting-student-subscription"
            element={<StudentSubscription />}
          />
          <Route path="/student-dashboard" element={<StudentDashboard />} />
          <Route path="/students-profile" element={<StudentProfile />} />
          <Route path="/students-grid" element={<StudentsGrid />} />
          <Route path="/students-grid2" element={<StudentsGrid2 />} />
          <Route path="/students-list" element={<StudentsList />} />
          <Route path="/course-student" element={<CourseStudent />} />
          <Route path="/course-wishlist" element={<CourseWishlist />} />
          <Route path="/course-message" element={<CourseMessage />} />
          <Route path="/purchase-history" element={<PurchaseHistory />} />
          <Route path="/deposit-student" element={<DepositStudent />} />
          <Route
            path="/transactions-student"
            element={<TransactionStudent />}
          />
          <Route path="/survey/:id" element={<SurveyQuestion />} />
          <Route path="/PDFDocument" element={<PDFDocument />} />
          <Route path="/course-lesson/:id" element={<CourseLesson />} />
          <Route path="/certificate" element={<Certificate />} />
        </Route>

        <Route path="/add-field" element={<AddField />} />
        <Route path="/survey-grid" element={<SurveyGrid />} />
        <Route path="/survey-details/:id" element={<SurveyDetails />} />

        <Route path="/course-grid" element={<CourseGrid />} />
        <Route
          path="/course-details/:language/:id"
          element={<CourseDetails />}
        />
        <Route path="/blog-details/:id" element={<BlogDetails />} />
        <Route path="/podcast-details/:id" element={<PodcastDetails />} />

        <Route path="*" element={<Error404 />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;
