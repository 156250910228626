import React, { useEffect, useRef, useState } from "react";
import { LogOut, Moon, Star, User } from "react-feather";
import { Link, useNavigate } from "react-router-dom";

import useOnClickOutside from "../../../hooks/useOnClickOutside";
import { logo, Notification, User11 } from "../../imagepath";
import { useActions, useAppState } from "../../../presenter";
import { formatTime } from "../../../utils/time";
import { useTranslation } from "react-i18next";
import { notificationNavigation } from "../../../utils/notification-navigation";

export default function StudentHeader({ activeMenu }) {
  const [navbar, setNavbar] = useState(false);
  const { t: translate } = useTranslation();
  const navigate = useNavigate();

  const [showNotification, setShowNotification] = useState(false);
  const [showProfile, setShowProfile] = useState(false);
  const {
    listNotifications,
    markAllNotificationsAsRead,
    markNotificationAsRead,
  } = useActions();
  const { notifications, user } = useAppState();
  const isRtl = user ? user.language == "AR" : i18n.language === "ar";
  const [page, setPage] = useState(1);
  const [isStatusChanged, setIsStatusChanged] = useState(false);
  const [isNewNotification, setIsNewNotification] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  // Mobile Menu toggle
  const [mobileMenu, setMobileMenu] = useState(false);
  const [mobileSubMenu, setMobileSubMenu] = useState(false);
  const [mobileSubMenu2, setMobileSubMenu2] = useState(false);
  const [mobileSubMenu22, setMobileSubMenu22] = useState(false);
  const [mobileSubMenu3, setMobileSubMenu3] = useState(false);
  const [mobileSubMenu32, setMobileSubMenu32] = useState(false);
  const [mobileSubMenu4, setMobileSubMenu4] = useState(false);
  const [mobileSubMenu42, setMobileSubMenu42] = useState(false);
  const [mobileSubMenu43, setMobileSubMenu43] = useState(false);
  const [mobileSubMenu5, setMobileSubMenu5] = useState(false);
  const [query, setQuery] = useState("");

  function handleQueryChange(event) {
    setQuery(event.target.value);
  }
  const unreadNotifications = notifications.filter(
    (notification) => notification.status === "Active"
  );
  useEffect(() => {
    if (unreadNotifications.length > 0) setIsNewNotification(true);
    else setIsNewNotification(false);
  }, [unreadNotifications, isStatusChanged]);
  useEffect(() => {
    getNotifications();
  }, [query, page, isStatusChanged]);

  useEffect(() => {
    setPage(1);
  }, [query]);
  const getNotifications = () => {
    listNotifications({ currentPage: page, limit: 5, q: query }).then(
      (pages) => {
        setTotalPages(pages);
      }
    );
  };
  const markAllAsRead = () => {
    setPage(1);
    markAllNotificationsAsRead().then(getNotifications());
  };

  const notificationNav = (courseId, sectionId, language, lessonId, action) => {
    const result = notificationNavigation(
      courseId,
      language,
      sectionId,
      lessonId,
      action
    );
    navigate(result);
  };
  const openMobileMenu = (event) => {
    document.body.classList.add("menu-opened");
    setMobileMenu(true);
  };
  const hideMobileMenu = (event) => {
    document.body.classList.remove("menu-opened");
    setMobileMenu(false);
  };

  const openMobileSubMenu = (e) => {
    e.preventDefault();
    setMobileSubMenu(!mobileSubMenu);
  };
  const openMobileSubMenu2 = (e) => {
    e.preventDefault();
    setMobileSubMenu2(!mobileSubMenu2);
  };
  const openMobileSubMenu22 = (e) => {
    e.preventDefault();
    setMobileSubMenu22(!mobileSubMenu22);
  };
  const openMobileSubMenu3 = (e) => {
    e.preventDefault();
    setMobileSubMenu3(!mobileSubMenu3);
  };
  const openMobileSubMenu32 = (e) => {
    e.preventDefault();
    setMobileSubMenu32(!mobileSubMenu32);
  };
  const openMobileSubMenu4 = (e) => {
    e.preventDefault();
    setMobileSubMenu4(!mobileSubMenu4);
  };
  const openMobileSubMenu42 = (e) => {
    e.preventDefault();
    setMobileSubMenu42(!mobileSubMenu42);
  };
  const openMobileSubMenu43 = (e) => {
    e.preventDefault();
    setMobileSubMenu43(!mobileSubMenu43);
  };
  const openMobileSubMenu5 = (e) => {
    e.preventDefault();
    setMobileSubMenu5(!mobileSubMenu5);
  };

  const notification = useRef();
  useOnClickOutside(notification, () => setShowNotification(false));

  const profile = useRef();
  useOnClickOutside(profile, () => setShowProfile(false));

  const notificationClick = (e) => {
    e.preventDefault();
    setShowNotification(!showNotification);
  };
  const profileClick = (e) => {
    e.preventDefault();
    setShowProfile(!showProfile);
  };

  const changeHeaderBackground = () => {
    if (window.scrollY >= 60) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };
  window.addEventListener("scroll", changeHeaderBackground);

  return (
    <header className="header header-page">
      <div className="header-fixed">
        <nav
          className={
            navbar
              ? "navbar navbar-expand-lg header-nav scroll-sticky add-header-bg"
              : "navbar navbar-expand-lg header-nav scroll-sticky"
          }
        >
          <div className="container ">
            <div className="navbar-header">
              <Link id="mobile_btn" to="#;" onClick={openMobileMenu}>
                <span className="bar-icon">
                  <span></span>
                  <span></span>
                  <span></span>
                </span>
              </Link>
              <Link to="/" className="navbar-brand logo">
                <img src={logo} className="img-fluid" alt="Logo" />
              </Link>
            </div>
            <div className="main-menu-wrapper">
              <div className="menu-header">
                <Link to="/" className="menu-logo">
                  <img src={logo} className="img-fluid" alt="Logo" />
                </Link>
                <Link
                  id="menu_close"
                  className="menu-close"
                  to="#;"
                  onClick={hideMobileMenu}
                >
                  <i className="fas fa-times"></i>
                </Link>
              </div>
              <ul className="main-nav">
                <li className="has-submenu">
                  <Link to="/" className={mobileSubMenu ? "submenu" : ""}>
                    Home{" "}
                    <i
                      className="fas fa-chevron-down"
                      onClick={openMobileSubMenu}
                    ></i>
                  </Link>
                  <ul
                    className={
                      mobileSubMenu ? "submenu submenuShow" : "submenu"
                    }
                  >
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <Link to="/home2">Home Two</Link>
                    </li>
                    <li>
                      <Link to="/home3">Home Three</Link>
                    </li>
                    <li>
                      <Link to="/home4">Home Four</Link>
                    </li>
                  </ul>
                </li>
                <li className="has-submenu">
                  <Link to="/admin-dashboard">
                    Instructor{" "}
                    <i
                      className="fas fa-chevron-down "
                      onClick={openMobileSubMenu2}
                    ></i>
                  </Link>
                  <ul
                    className={
                      mobileSubMenu2 ? "submenu submenuShow" : "submenu"
                    }
                  >
                    <li className="active">
                      <Link to="/admin-dashboard">Dashboard</Link>
                    </li>
                    <li className="has-submenu">
                      <Link to="/instructor-list">
                        Instructor
                        <i
                          className="fas fa-chevron-right listMob"
                          onClick={openMobileSubMenu22}
                        ></i>
                      </Link>
                      <ul
                        className={
                          mobileSubMenu22 ? "submenu submenuShow" : "submenu"
                        }
                      >
                        <li>
                          <Link to="/instructor-list">List</Link>
                        </li>
                        <li>
                          <Link to="/instructor-grid">Grid</Link>
                        </li>
                      </ul>
                    </li>
                    <li className={activeMenu === "Courses" ? "active" : ""}>
                      <Link to="/instructor-course">My Course</Link>
                    </li>
                    <li>
                      <Link to="/instructor-reviews">Reviews</Link>
                    </li>
                    <li>
                      <Link to="/instructor-earnings">Earnings</Link>
                    </li>
                    <li>
                      <Link to="/instructor-orders">Orders</Link>
                    </li>
                    <li>
                      <Link to="/instructor-payouts">Payouts</Link>
                    </li>
                    <li>
                      <Link to="/instructor-tickets">Support Ticket</Link>
                    </li>
                    <li>
                      <Link to="/instructor-profile">Instructor Profile</Link>
                    </li>
                    <li>
                      <Link to="/instructor-security">Security</Link>
                    </li>
                    <li>
                      <Link to="/instructor-social-profile">
                        Social Profiles
                      </Link>
                    </li>
                    <li>
                      <Link to="/instructor-notifications">Notifications</Link>
                    </li>
                    <li>
                      <Link to="/instructor-profile-privacy">
                        Profile Privacy
                      </Link>
                    </li>
                    <li>
                      <Link to="/">Delete Profile</Link>
                    </li>
                    <li>
                      <Link to="/instructor-linked-account">
                        Linked Accounts
                      </Link>
                    </li>
                  </ul>
                </li>
                <li className="has-submenu active">
                  <Link to="/students-list">
                    Student{" "}
                    <i
                      className="fas fa-chevron-down"
                      onClick={openMobileSubMenu3}
                    ></i>
                  </Link>
                  <ul
                    className={
                      mobileSubMenu3
                        ? "submenu first-submenu submenuShow"
                        : "submenu first-submenu"
                    }
                  >
                    <li
                      className={
                        activeMenu === "List" || activeMenu === "Grid1"
                          ? "has-submenu active"
                          : "has-submenu"
                      }
                    >
                      <Link to="/students-list">
                        Student
                        <i
                          className="fas fa-chevron-right listMob"
                          onClick={openMobileSubMenu32}
                        ></i>
                      </Link>
                      <ul
                        className={
                          mobileSubMenu32 ? "submenu submenuShow" : "submenu"
                        }
                      >
                        <li className={activeMenu === "List" ? "active" : ""}>
                          <Link to="/students-list" onClick={hideMobileMenu}>
                            List
                          </Link>
                        </li>
                        <li className={activeMenu === "Grid1" ? "active" : ""}>
                          <Link to="/students-grid" onClick={hideMobileMenu}>
                            Grid
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li className={activeMenu === "Profile" ? "active" : ""}>
                      <Link to="/setting-edit-profile" onClick={hideMobileMenu}>
                        Student Profile
                      </Link>
                    </li>
                    <li className={activeMenu === "Security" ? "active" : ""}>
                      <Link
                        to="/setting-student-security"
                        onClick={hideMobileMenu}
                      >
                        Security
                      </Link>
                    </li>
                    <li
                      className={activeMenu === "SocialProfile" ? "active" : ""}
                    >
                      <Link
                        to="/setting-student-social-profile"
                        onClick={hideMobileMenu}
                      >
                        Social profile
                      </Link>
                    </li>
                    <li
                      className={activeMenu === "Notification" ? "active" : ""}
                    >
                      <Link
                        to="/setting-student-notification"
                        onClick={hideMobileMenu}
                      >
                        Notification
                      </Link>
                    </li>
                    <li className={activeMenu === "Privacy" ? "active" : ""}>
                      <Link
                        to="/setting-student-privacy"
                        onClick={hideMobileMenu}
                      >
                        Profile Privacy
                      </Link>
                    </li>

                    <li className={activeMenu === "Accounts" ? "active" : ""}>
                      <Link
                        to="/setting-student-accounts"
                        onClick={hideMobileMenu}
                      >
                        Link Accounts
                      </Link>
                    </li>
                    <li className={activeMenu === "Referral" ? "active" : ""}>
                      <Link
                        to="/setting-student-referral"
                        onClick={hideMobileMenu}
                      >
                        Referal
                      </Link>
                    </li>
                    <li
                      className={activeMenu === "Subscription" ? "active" : ""}
                    >
                      <Link
                        to="/setting-student-subscription"
                        onClick={hideMobileMenu}
                      >
                        Subscription
                      </Link>
                    </li>
                    <li className={activeMenu === "Billing" ? "active" : ""}>
                      <Link
                        to="/setting-student-billing"
                        onClick={hideMobileMenu}
                      >
                        Billing
                      </Link>
                    </li>
                    <li className={activeMenu === "Payment" ? "active" : ""}>
                      <Link
                        to="/setting-student-payment"
                        onClick={hideMobileMenu}
                      >
                        Payment
                      </Link>
                    </li>
                    <li className={activeMenu === "Invoice" ? "active" : ""}>
                      <Link
                        to="/setting-student-invoice"
                        onClick={hideMobileMenu}
                      >
                        Invoice
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/setting-support-tickets"
                        onClick={hideMobileMenu}
                      >
                        Support Tickets
                      </Link>
                    </li>
                  </ul>
                </li>
                <li className="has-submenu">
                  <Link to="">
                    Pages
                    <i
                      className="fas fa-chevron-down"
                      onClick={openMobileSubMenu4}
                    ></i>
                  </Link>
                  <ul
                    className={
                      mobileSubMenu4 ? "submenu submenuShow" : "submenu"
                    }
                  >
                    <li>
                      <Link to="/page-notification">Notification</Link>
                    </li>
                    <li>
                      <Link to="/pricing-plan">Pricing Plan</Link>
                    </li>
                    <li>
                      <Link to="/wishlist">Wishlist</Link>
                    </li>
                    <li className="has-submenu">
                      <Link to="/course-list">
                        Course
                        <i
                          className="fas fa-chevron-right listMob"
                          onClick={openMobileSubMenu42}
                        ></i>
                      </Link>
                      <ul
                        className={
                          mobileSubMenu42 ? "submenu submenuShow" : "submenu"
                        }
                      >
                        <li
                          className={activeMenu === "AddCourse" ? "active" : ""}
                        >
                          <Link to="/add-course" onClick={hideMobileMenu}>
                            Add Course
                          </Link>
                        </li>
                        <li
                          className={
                            activeMenu === "CourseList" ? "active" : ""
                          }
                        >
                          <Link to="/course-list" onClick={hideMobileMenu}>
                            Course List
                          </Link>
                        </li>
                        <li
                          className={
                            activeMenu === "CourseGrid" ? "active" : ""
                          }
                        >
                          <Link to="/course-grid" onClick={hideMobileMenu}>
                            Course Grid
                          </Link>
                        </li>
                        <li
                          className={
                            activeMenu === "CourseDetails" ? "active" : ""
                          }
                        >
                          <Link to="/course-details" onClick={hideMobileMenu}>
                            Course Details
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li className="has-submenu">
                      <Link to="/come-soon">
                        Error
                        <i
                          className="fas fa-chevron-right listMob"
                          onClick={openMobileSubMenu43}
                        ></i>
                      </Link>
                      <ul
                        className={
                          mobileSubMenu43 ? "submenu submenuShow" : "submenu"
                        }
                      >
                        <li>
                          <Link to="/come-soon" onClick={hideMobileMenu}>
                            Coming soon
                          </Link>
                        </li>
                        <li>
                          <Link to="/error-404" onClick={hideMobileMenu}>
                            404
                          </Link>
                        </li>
                        <li>
                          <Link to="/error-500" onClick={hideMobileMenu}>
                            500
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/under-construction"
                            onClick={hideMobileMenu}
                          >
                            Under Construction
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <Link to="/faq">FAQ</Link>
                    </li>
                    <li>
                      <Link to="/support">Support</Link>
                    </li>
                    <li>
                      <Link to="/job-category">Category</Link>
                    </li>
                    <li>
                      <Link to="/cart">Cart</Link>
                    </li>
                    <li>
                      <Link to="/checkout">Checkout</Link>
                    </li>
                    <li>
                      <Link to="/login">Login</Link>
                    </li>
                    <li>
                      <Link to="/register">Register</Link>
                    </li>
                    <li>
                      <Link to="/forgot-password">Forgot Password</Link>
                    </li>
                  </ul>
                </li>
                <li className="has-submenu">
                  <Link to="#">
                    Blog{" "}
                    <i
                      className="fas fa-chevron-down"
                      onClick={openMobileSubMenu5}
                    ></i>
                  </Link>
                  <ul
                    className={
                      mobileSubMenu5 ? "submenu submenuShow" : "submenu"
                    }
                  >
                    <li>
                      <Link to="/blog-list">Blog List</Link>
                    </li>
                    <li>
                      <Link to="/blog-grid">Blog Grid</Link>
                    </li>
                    <li>
                      <Link to="/blog-masonry">Blog Masonry</Link>
                    </li>
                    <li>
                      <Link to="/blog-modern">Blog Modern</Link>
                    </li>
                    <li>
                      <Link to="/blog-details">Blog Details</Link>
                    </li>
                  </ul>
                </li>
                <li className="login-link">
                  <Link to="/login">Login / Signup</Link>
                </li>
              </ul>
            </div>
            <ul className="nav header-navbar-rht">
              <li className="nav-item noti-nav">
                <Link
                  to="#"
                  className={
                    showNotification
                      ? "dropdown-toggle show"
                      : "dropdown-toggle"
                  }
                  data-bs-toggle="dropdown"
                >
                  {isNewNotification && (
                    <span
                      className={`badge rounded-pill badge-notification bg-primary  ${
                        isRtl ? "right dot" : "right dot_l"
                      }`}
                    >
                      &nbsp;
                    </span>
                  )}

                  <img
                    onClick={notificationClick}
                    src={Notification}
                    alt="img"
                  />
                </Link>
                <div
                  ref={notification}
                  className={
                    showNotification
                      ? `notifications dropdown-menu dropdown-menu-${
                          isRtl ? "left" : "right"
                        } show `
                      : `notifications dropdown-menu dropdown-menu-${
                          isRtl ? "right" : "left"
                        }`
                  }
                >
                  <div className="topnav-dropdown-header notify">
                    <span className="notification-title">
                      Notifications{" "}
                      <select value={query} onChange={handleQueryChange}>
                        <option value="">All</option>
                        <option value="Active">Unread</option>
                      </select>
                    </span>
                    <Link
                      to="#"
                      className="clear-noti"
                      onClick={() => {
                        markAllAsRead();
                      }}
                    >
                      Mark all as read{" "}
                      <span className="fa-solid fa-circle-check"></span>
                    </Link>
                  </div>
                  <div className={`noti-content `}>
                    <ul
                      className={`notification-list ${
                        isRtl ? "total-item" : ""
                      }`}
                    >
                      {notifications.map((notification) => (
                        <li
                          key={notification.id}
                          className="notification-message"
                          onClick={() => {
                            markNotificationAsRead(notification.id);
                            setIsStatusChanged((prev) => !prev);
                            notificationNav({
                              courseId: notification?.metaData?.courseId,
                              language: notification?.metaData?.language,
                              action: notification?.metaData?.action,
                            });
                          }}
                        >
                          <div
                            className="media d-block"
                            style={{
                              backgroundColor: `${
                                notification.status === "Active"
                                  ? "#ccc"
                                  : "#fff"
                              }`,
                            }}
                          >
                            <div className="media-body">
                              <h6>
                                <Link to="/page-notification">
                                  {notification.title}
                                </Link>
                              </h6>

                              <p>{notification.body}</p>
                              <p>
                                {formatTime(
                                  notification.createdAt,
                                  user.language
                                )}
                              </p>
                            </div>
                          </div>
                        </li>
                      ))}

                      {totalPages > page && (
                        <p
                          style={{
                            textAlign: "center",
                            marginTop: "20px",
                            fontSize: "18px",
                          }}
                          onClick={(e) => {
                            setPage((prev) => prev + 1);
                            e.stopPropagation();
                          }}
                        >
                          show more
                        </p>
                      )}
                    </ul>
                  </div>
                </div>
              </li>
              <li className="nav-item user-nav">
                <Link
                  to="#"
                  className={
                    showProfile ? "dropdown-toggle show" : "dropdown-toggle"
                  }
                  data-bs-toggle="dropdown"
                  onClick={profileClick}
                >
                  <span className="user-img">
                    <img src={User11} alt="" />
                    <span className="status online"></span>
                  </span>
                </Link>
                <div
                  ref={profile}
                  className={
                    showProfile
                      ? `users dropdown-menu dropdown-menu-${
                          isRtl
                            ? "left show modalPosition"
                            : "right show modalPosition"
                        } show `
                      : `users dropdown-menu dropdown-menu-${
                          isRtl ? "left " : "right"
                        }`
                  }
                  data-popper-placement="bottom-end"
                >
                  <div className="user-header">
                    <div className="avatar avatar-sm">
                      <img
                        src={User11}
                        alt="User Image"
                        className="avatar-img rounded-circle"
                      />
                    </div>
                    <div className="user-text">
                      <h6>Jenny Wilson</h6>
                      <p className="text-muted text mb-0">Student</p>
                    </div>
                  </div>
                  <Link
                    className="dropdown-item text"
                    to="/setting-edit-profile"
                  >
                    <User size={14} color={"#2B3A8E"} className="headerIcon" />{" "}
                    Profile
                  </Link>
                  <Link
                    className="dropdown-item text"
                    to="/setting-student-subscription"
                  >
                    <Star size={14} color={"#2B3A8E"} className="headerIcon" />{" "}
                    Subscription
                  </Link>
                  <div className="dropdown-item text night-mode">
                    <span>
                      <Moon size={14} className="headerIcon" /> Night Mode{" "}
                    </span>
                    <div className="form-check form-switch check-on m-0">
                      <input
                        className="form-check-input focusShadow"
                        type="checkbox"
                        id="night-mode"
                      />
                    </div>
                  </div>
                  <Link className="dropdown-item text" to="/">
                    <LogOut
                      size={14}
                      color={"#2B3A8E"}
                      className="headerIcon"
                    />{" "}
                    Logout
                  </Link>
                </div>
              </li>
            </ul>
          </div>
        </nav>
        <div
          className={mobileMenu ? "sidebar-overlay opened" : "sidebar-overlay"}
        ></div>
      </div>
    </header>
  );
}
