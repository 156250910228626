import "regenerator-runtime/runtime";

import i18n from "../../locales/i18n";
import { toast } from "react-toastify";
import { handleError } from "../errors";
import { changePositionByLanguage } from "../../utils/toastify";

export const getCountries = async (context, data) => {
    try {
        const result = await context.effects.getCountries();
        return result;
    } catch (err) {
        handleError(err);
    }
};

export const updateGeneralSettings = async (context, data) => {
    try {
        context.state.loadingBtn = true;
        const user = await context.effects.updateGeneralSettings(data);
        context.state.user = user;
        localStorage.setItem('user', JSON.stringify(user));

        toast.success(i18n.t("form.success.settingsUpdate"), {
            position: changePositionByLanguage(i18n.language),
        });
        context.state.loadingBtn = false;
        return user;
    } catch (err) {
        handleError(err);
        context.state.loadingBtn = false;
    }
};

export const updateAvatar = async (context, formData) => {
    try {
        context.state.loadingBtn = true;
        const result = await context.effects.updateAvatar(formData);
        console.log(result)
        const user = JSON.parse(localStorage.getItem('user'));
        console.log(result.url)
        // if (user.image) user.image.url = result.url;
        //  user.image.url = result.url
        user.image = result.url
        localStorage.setItem('user', JSON.stringify(user));
        context.state.user = user;
        
        console.log(context.state.user)
        console.log(user)

        toast.success(i18n.t("form.success.settingsUpdate"), {
            position: changePositionByLanguage(i18n.language),
        });
        context.state.loadingBtn = false;
        return result;
    } catch (err) {
        handleError(err);
        context.state.loadingBtn = false;
    }
};

export const updateAccountSettings = async (context, data) => {
    try {
        context.state.loadingBtn = true;
        const user = await context.effects.updateAccountSettings(data);
        context.state.user = user;
        localStorage.setItem('user', JSON.stringify(user));

        toast.success(i18n.t("form.success.settingsUpdate"), {
            position: changePositionByLanguage(i18n.language),
        });
        context.state.loadingBtn = false;
        return user;
    } catch (err) {
        handleError(err);
        context.state.loadingBtn = false;
    }
};

export const updateNotificationsSettings = async (context, data) => {
    try {
        const result = await context.effects.updateNotificationsSettings(data);
        const user = JSON.parse(localStorage.getItem('user'));
        user.isSubscribed = result.isSubscribed;
        user.instantNotifications = result.instantNotifications;
        user.emailNotifications = result.emailNotifications;
        user.discountNotifications = result.discountNotifications;
        localStorage.setItem('user', JSON.stringify(user));
        context.state.user = user;
        return result;
    } catch (err) {
        handleError(err);
        context.state.loadingBtn = false;
    }
};

export const getCards = async (context, data) => {
    try {
        context.state.loading = true;
        const result = await context.effects.getCards(data);
        context.state.loading = false;
        return result;
    } catch (err) {
        handleError(err);
        context.state.loadingBtn = false;
    }
};

export const addCard = async (context, data) => {
    try {
        context.state.loadingBtn = true;
        const result = await context.effects.addCard(data);
        context.state.loadingBtn = false;

        toast.success(i18n.t("form.success.cardAdded"), {
            position: changePositionByLanguage(i18n.language),
        });
        return result;
    } catch (err) {
        handleError(err);
        context.state.loadingBtn = false;
    }
};

export const deleteCard = async (context, data) => {
    try {
        context.state.loadingBtn = true;
        const result = await context.effects.deleteCard(data);
        context.state.loadingBtn = false;

        toast.success(i18n.t("form.success.cardDeleted"), {
            position: changePositionByLanguage(i18n.language),
        });
        return result;
    } catch (err) {
        handleError(err);
        context.state.loadingBtn = false;
    }
};
