import "regenerator-runtime/runtime";

import axios from "axios";
import {
  BadRequestError,
  ForbiddenError,
  InternalServerError,
  UnauthenticatedError,
  UnexpectedError,
} from "../errors";
import { apiClient } from "./constants";

const BASE_ROUTE = "/courses";

export const listCourses = async (currentPage, limit, q) => {
  try {
    let queryParams = currentPage ? `?page=${currentPage}&limit=${limit}` : "";
    if (q) queryParams += `&q=${q}`;
    const result = await apiClient.get(`${BASE_ROUTE}${queryParams}`);
    return result.data;
  } catch (err) {
    console.log("err", err);
    if (axios.isAxiosError(err)) {
      const responseData = err.response.data;

      if (err.response?.status == 400)
        throw new BadRequestError(responseData.message);
      if (err.response?.status == 401)
        throw new UnauthenticatedError(responseData.message);
      if (err.response?.status == 403)
        throw new ForbiddenError(responseData.message);
      if (err.response?.status == 404)
        throw new UnexpectedError(responseData.message);
      if (err.response?.status == 500)
        throw new InternalServerError(responseData.message);
    }
    throw new UnexpectedError();
  }
};

export const listEnrolledCourses = async (currentPage, limit, q) => {
  try {
    let queryParams = currentPage ? `?page=${currentPage}&limit=${limit}` : "";
    if (q) queryParams += `&q=${q}`;
    const result = await apiClient.get(`${BASE_ROUTE}/enrolled${queryParams}`);
    return result.data;
  } catch (err) {
    console.log("err", err);
    if (axios.isAxiosError(err)) {
      const responseData = err.response.data;

      if (err.response?.status == 400)
        throw new BadRequestError(responseData.message);
      if (err.response?.status == 401)
        throw new UnauthenticatedError(responseData.message);
      if (err.response?.status == 403)
        throw new ForbiddenError(responseData.message);
      if (err.response?.status == 404)
        throw new UnexpectedError(responseData.message);
      if (err.response?.status == 500)
        throw new InternalServerError(responseData.message);
    }
    throw new UnexpectedError();
  }
};

export const getCourse = async (data) => {
  try {
    const result = await apiClient.get(
      `${BASE_ROUTE}/${data.id}/language/${data.language}`
    );
    return result.data;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      const responseData = err.response.data;

      if (err.response?.status == 400)
        throw new BadRequestError(responseData.message);
      if (err.response?.status == 401)
        throw new UnauthenticatedError(responseData.message);
      if (err.response?.status == 403)
        throw new ForbiddenError(responseData.message);
      if (err.response?.status == 404)
        throw new UnexpectedError(responseData.message);
      if (err.response?.status == 500)
        throw new InternalServerError(responseData.message);
    }
    throw new UnexpectedError();
  }
};

export const getEnrolledCourseData = async (data) => {
  try {
    const result = await apiClient.get(
      `${BASE_ROUTE}/course/${data.id}/language/${data.language}/enrolled`
    );
    return result.data;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      const responseData = err.response.data;

      if (err.response?.status == 400)
        throw new BadRequestError(responseData.message);
      if (err.response?.status == 401)
        throw new UnauthenticatedError(responseData.message);
      if (err.response?.status == 403)
        throw new ForbiddenError(responseData.message);
      if (err.response?.status == 404)
        throw new UnexpectedError(responseData.message);
      if (err.response?.status == 500)
        throw new InternalServerError(responseData.message);
    }
    throw new UnexpectedError();
  }
};

export const getEnrolledLessonData = async (data) => {
  try {
    const result = await apiClient.get(
      `${BASE_ROUTE}/course/${data.id}/language/${data.language}/section/${data.sectionId}/lesson/${data.lessonId}/enrolled/lesson`
    );
    return result.data;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      const responseData = err.response.data;

      if (err.response?.status == 400)
        throw new BadRequestError(responseData.message);
      if (err.response?.status == 401)
        throw new UnauthenticatedError(responseData.message);
      if (err.response?.status == 403)
        throw new ForbiddenError(responseData.message);
      if (err.response?.status == 404)
        throw new UnexpectedError(responseData.message);
      if (err.response?.status == 500)
        throw new InternalServerError(responseData.message);
    }
    throw new UnexpectedError();
  }
};

export const createCourse = async (data) => {
  try {
    const result = await apiClient.post(`${BASE_ROUTE}/create`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return result.data;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      const responseData = err.response.data;

      if (err.response?.status == 400)
        throw new BadRequestError(responseData.message);
      if (err.response?.status == 401)
        throw new UnauthenticatedError(responseData.message);
      if (err.response?.status == 403)
        throw new ForbiddenError(responseData.message);
      if (err.response?.status == 404)
        throw new UnexpectedError(responseData.message);
      if (err.response?.status == 500)
        throw new InternalServerError(responseData.message);
    }
    throw new UnexpectedError();
  }
};

export const addCourse = async (data) => {
  try {
    const result = await apiClient.post(
      `${BASE_ROUTE}/create/${data.id}/language/${data.language}`,
      data.data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return result.data;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      const responseData = err.response.data;

      if (err.response?.status == 400)
        throw new BadRequestError(responseData.message);
      if (err.response?.status == 401)
        throw new UnauthenticatedError(responseData.message);
      if (err.response?.status == 403)
        throw new ForbiddenError(responseData.message);
      if (err.response?.status == 404)
        throw new UnexpectedError(responseData.message);
      if (err.response?.status == 500)
        throw new InternalServerError(responseData.message);
    }
    throw new UnexpectedError();
  }
};

export const addMaterial = async (data) => {
  try {
    const result = await apiClient.post(
      `${BASE_ROUTE}/course/${data.courseId}/language/${data.language}/section/${data.sectionId}/lesson/${data.lessonId}/material`,
      data.data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return result.data;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      const responseData = err.response.data;

      if (err.response?.status == 400)
        throw new BadRequestError(responseData.message);
      if (err.response?.status == 401)
        throw new UnauthenticatedError(responseData.message);
      if (err.response?.status == 403)
        throw new ForbiddenError(responseData.message);
      if (err.response?.status == 404)
        throw new UnexpectedError(responseData.message);
      if (err.response?.status == 500)
        throw new InternalServerError(responseData.message);
    }
    throw new UnexpectedError();
  }
};

export const updateMaterial = async (data) => {
  try {
    const result = await apiClient.patch(
      `${BASE_ROUTE}/course/${data.courseId}/language/${data.language}/section/${data.sectionId}/lesson/${data.lessonId}/material/${data.materialId}`,
      data.data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return result.data;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      const responseData = err.response.data;

      if (err.response?.status == 400)
        throw new BadRequestError(responseData.message);
      if (err.response?.status == 401)
        throw new UnauthenticatedError(responseData.message);
      if (err.response?.status == 403)
        throw new ForbiddenError(responseData.message);
      if (err.response?.status == 404)
        throw new UnexpectedError(responseData.message);
      if (err.response?.status == 500)
        throw new InternalServerError(responseData.message);
    }
    throw new UnexpectedError();
  }
};

export const deleteMaterial = async (data) => {
  try {
    const result = await apiClient.delete(
      `${BASE_ROUTE}/course/${data.courseId}/language/${data.language}/section/${data.sectionId}/lesson/${data.lessonId}/material/${data.materialId}`
    );
    return result.data;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      const responseData = err.response.data;

      if (err.response?.status == 400)
        throw new BadRequestError(responseData.message);
      if (err.response?.status == 401)
        throw new UnauthenticatedError(responseData.message);
      if (err.response?.status == 403)
        throw new ForbiddenError(responseData.message);
      if (err.response?.status == 404)
        throw new UnexpectedError(responseData.message);
      if (err.response?.status == 500)
        throw new InternalServerError(responseData.message);
    }
    throw new UnexpectedError();
  }
};

export const updateCourse = async (data) => {
  try {
    const result = await apiClient.patch(
      `${BASE_ROUTE}/course/${data.id}/language/${data.language}`,
      data.data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return result.data;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      const responseData = err.response.data;

      if (err.response?.status == 400)
        throw new BadRequestError(responseData.message);
      if (err.response?.status == 401)
        throw new UnauthenticatedError(responseData.message);
      if (err.response?.status == 403)
        throw new ForbiddenError(responseData.message);
      if (err.response?.status == 404)
        throw new UnexpectedError(responseData.message);
      if (err.response?.status == 500)
        throw new InternalServerError(responseData.message);
    }
    throw new UnexpectedError();
  }
};

export const updateProgress = async (data) => {
  try {
    const result = await apiClient.patch(
      `${BASE_ROUTE}/course/${data.id}/language/${data.language}/section/${data.sectionId}/lesson/${data.lessonId}/progress`
    );
    return result.data;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      const responseData = err.response.data;

      if (err.response?.status == 400)
        throw new BadRequestError(responseData.message);
      if (err.response?.status == 401)
        throw new UnauthenticatedError(responseData.message);
      if (err.response?.status == 403)
        throw new ForbiddenError(responseData.message);
      if (err.response?.status == 404)
        throw new UnexpectedError(responseData.message);
      if (err.response?.status == 500)
        throw new InternalServerError(responseData.message);
    }
    throw new UnexpectedError();
  }
};

export const deleteCourse = async (data) => {
  try {
    const result = await apiClient.delete(
      `${BASE_ROUTE}/course/${data.id}/language/${data.language}`
    );
    return result.data;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      const responseData = err.response.data;

      if (err.response?.status == 400)
        throw new BadRequestError(responseData.message);
      if (err.response?.status == 401)
        throw new UnauthenticatedError(responseData.message);
      if (err.response?.status == 403)
        throw new ForbiddenError(responseData.message);
      if (err.response?.status == 404)
        throw new UnexpectedError(responseData.message);
      if (err.response?.status == 500)
        throw new InternalServerError(responseData.message);
    }
    throw new UnexpectedError();
  }
};

export const listTags = async (data) => {
  try {
    const result = await apiClient.get(`tags`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return result.data;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      const responseData = err.response.data;

      if (err.response?.status == 400)
        throw new BadRequestError(responseData.message);
      if (err.response?.status == 401)
        throw new UnauthenticatedError(responseData.message);
      if (err.response?.status == 403)
        throw new ForbiddenError(responseData.message);
      if (err.response?.status == 404)
        throw new UnexpectedError(responseData.message);
      if (err.response?.status == 500)
        throw new InternalServerError(responseData.message);
    }
    throw new UnexpectedError();
  }
};

export const listFavorites = async (currentPage, limit) => {
  try {
    let queryParams = currentPage ? `?page=${currentPage}&limit=${limit}` : "";
    const result = await apiClient.get(`${BASE_ROUTE}/favorite${queryParams}`);
    return result.data;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      const responseData = err.response.data;

      if (err.response?.status == 400)
        throw new BadRequestError(responseData.message);
      if (err.response?.status == 401)
        throw new UnauthenticatedError(responseData.message);
      if (err.response?.status == 403)
        throw new ForbiddenError(responseData.message);
      if (err.response?.status == 404)
        throw new UnexpectedError(responseData.message);
      if (err.response?.status == 500)
        throw new InternalServerError(responseData.message);
    }
    throw new UnexpectedError();
  }
};

export const addFavorite = async (data) => {
  try {
    const result = await apiClient.post(
      `${BASE_ROUTE}/${data.id}/language/${data.language}/favorite`,
      data
    );
    return result.data;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      const responseData = err.response.data;

      if (err.response?.status == 400)
        throw new BadRequestError(responseData.message);
      if (err.response?.status == 401)
        throw new UnauthenticatedError(responseData.message);
      if (err.response?.status == 403)
        throw new ForbiddenError(responseData.message);
      if (err.response?.status == 404)
        throw new UnexpectedError(responseData.message);
      if (err.response?.status == 500)
        throw new InternalServerError(responseData.message);
    }
    throw new UnexpectedError();
  }
};

export const deleteFavorite = async (data) => {
  try {
    const result = await apiClient.delete(
      `${BASE_ROUTE}/course/${data.id}/language/${data.language}/remove/favorite`,
      data
    );
    return result.data;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      const responseData = err.response.data;

      if (err.response?.status == 400)
        throw new BadRequestError(responseData.message);
      if (err.response?.status == 401)
        throw new UnauthenticatedError(responseData.message);
      if (err.response?.status == 403)
        throw new ForbiddenError(responseData.message);
      if (err.response?.status == 404)
        throw new UnexpectedError(responseData.message);
      if (err.response?.status == 500)
        throw new InternalServerError(responseData.message);
    }
    throw new UnexpectedError();
  }
};

export const listFields = async (currentPage, limit, q) => {
  try {
    const result = await apiClient.get("fields");
    return result.data;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      const responseData = err.response.data;

      if (err.response?.status == 400)
        throw new BadRequestError(responseData.message);
      if (err.response?.status == 401)
        throw new UnauthenticatedError(responseData.message);
      if (err.response?.status == 403)
        throw new ForbiddenError(responseData.message);
      if (err.response?.status == 404)
        throw new UnexpectedError(responseData.message);
      if (err.response?.status == 500)
        throw new InternalServerError(responseData.message);
    }
    throw new UnexpectedError();
  }
};

export const getField = async (id) => {
  try {
    const result = await apiClient.get(`fields/${id}`);
    return result.data;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      const responseData = err.response.data;

      if (err.response?.status == 400)
        throw new BadRequestError(responseData.message);
      if (err.response?.status == 401)
        throw new UnauthenticatedError(responseData.message);
      if (err.response?.status == 403)
        throw new ForbiddenError(responseData.message);
      if (err.response?.status == 404)
        throw new UnexpectedError(responseData.message);
      if (err.response?.status == 500)
        throw new InternalServerError(responseData.message);
    }
    throw new UnexpectedError();
  }
};

export const addField = async (data) => {
  try {
    const result = await apiClient.post("fields", data);
    return result.data;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      const responseData = err.response.data;

      if (err.response?.status == 400)
        throw new BadRequestError(responseData.message);
      if (err.response?.status == 401)
        throw new UnauthenticatedError(responseData.message);
      if (err.response?.status == 403)
        throw new ForbiddenError(responseData.message);
      if (err.response?.status == 404)
        throw new UnexpectedError(responseData.message);
      if (err.response?.status == 500)
        throw new InternalServerError(responseData.message);
    }
    throw new UnexpectedError();
  }
};

export const updateField = async (data) => {
  try {
    const result = await apiClient.patch(`fields/${data.id}`, data.data);
    return result.data;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      const responseData = err.response.data;

      if (err.response?.status == 400)
        throw new BadRequestError(responseData.message);
      if (err.response?.status == 401)
        throw new UnauthenticatedError(responseData.message);
      if (err.response?.status == 403)
        throw new ForbiddenError(responseData.message);
      if (err.response?.status == 404)
        throw new UnexpectedError(responseData.message);
      if (err.response?.status == 500)
        throw new InternalServerError(responseData.message);
    }
    throw new UnexpectedError();
  }
};

export const deleteField = async (id) => {
  try {
    const result = await apiClient.delete(`fields/${id}`);
    return result.data;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      const responseData = err.response.data;

      if (err.response?.status == 400)
        throw new BadRequestError(responseData.message);
      if (err.response?.status == 401)
        throw new UnauthenticatedError(responseData.message);
      if (err.response?.status == 403)
        throw new ForbiddenError(responseData.message);
      if (err.response?.status == 404)
        throw new UnexpectedError(responseData.message);
      if (err.response?.status == 500)
        throw new InternalServerError(responseData.message);
    }
    throw new UnexpectedError();
  }
};

export const addSubfield = async (data) => {
  try {
    const result = await apiClient.post(
      `sub-fields/field/${data.id}`,
      data.data
    );
    return result.data;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      const responseData = err.response.data;

      if (err.response?.status == 400)
        throw new BadRequestError(responseData.message);
      if (err.response?.status == 401)
        throw new UnauthenticatedError(responseData.message);
      if (err.response?.status == 403)
        throw new ForbiddenError(responseData.message);
      if (err.response?.status == 404)
        throw new UnexpectedError(responseData.message);
      if (err.response?.status == 500)
        throw new InternalServerError(responseData.message);
    }
    throw new UnexpectedError();
  }
};

export const updateSubfield = async (data) => {
  try {
    const result = await apiClient.patch(`sub-fields/${data.id}`, data.data);
    return result.data;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      const responseData = err.response.data;

      if (err.response?.status == 400)
        throw new BadRequestError(responseData.message);
      if (err.response?.status == 401)
        throw new UnauthenticatedError(responseData.message);
      if (err.response?.status == 403)
        throw new ForbiddenError(responseData.message);
      if (err.response?.status == 404)
        throw new UnexpectedError(responseData.message);
      if (err.response?.status == 500)
        throw new InternalServerError(responseData.message);
    }
    throw new UnexpectedError();
  }
};

export const deleteSubfield = async (id) => {
  try {
    const result = await apiClient.delete(`sub-fields/${id}`);
    return result.data;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      const responseData = err.response.data;

      if (err.response?.status == 400)
        throw new BadRequestError(responseData.message);
      if (err.response?.status == 401)
        throw new UnauthenticatedError(responseData.message);
      if (err.response?.status == 403)
        throw new ForbiddenError(responseData.message);
      if (err.response?.status == 404)
        throw new UnexpectedError(responseData.message);
      if (err.response?.status == 500)
        throw new InternalServerError(responseData.message);
    }
    throw new UnexpectedError();
  }
};

export const addQuestion = async (data) => {
  try {
    const result = await apiClient.post(
      `questions/field/${data.id}`,
      data.data
    );
    return result.data;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      const responseData = err.response.data;

      if (err.response?.status == 400)
        throw new BadRequestError(responseData.message);
      if (err.response?.status == 401)
        throw new UnauthenticatedError(responseData.message);
      if (err.response?.status == 403)
        throw new ForbiddenError(responseData.message);
      if (err.response?.status == 404)
        throw new UnexpectedError(responseData.message);
      if (err.response?.status == 500)
        throw new InternalServerError(responseData.message);
    }
    throw new UnexpectedError();
  }
};

export const updateQuestion = async (data) => {
  try {
    const result = await apiClient.patch(`questions/${data.id}`, data.data);
    return result.data;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      const responseData = err.response.data;

      if (err.response?.status == 400)
        throw new BadRequestError(responseData.message);
      if (err.response?.status == 401)
        throw new UnauthenticatedError(responseData.message);
      if (err.response?.status == 403)
        throw new ForbiddenError(responseData.message);
      if (err.response?.status == 404)
        throw new UnexpectedError(responseData.message);
      if (err.response?.status == 500)
        throw new InternalServerError(responseData.message);
    }
    throw new UnexpectedError();
  }
};

export const deleteQuestion = async (id) => {
  try {
    const result = await apiClient.delete(`questions/${id}`);
    return result.data;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      const responseData = err.response.data;

      if (err.response?.status == 400)
        throw new BadRequestError(responseData.message);
      if (err.response?.status == 401)
        throw new UnauthenticatedError(responseData.message);
      if (err.response?.status == 403)
        throw new ForbiddenError(responseData.message);
      if (err.response?.status == 404)
        throw new UnexpectedError(responseData.message);
      if (err.response?.status == 500)
        throw new InternalServerError(responseData.message);
    }
    throw new UnexpectedError();
  }
};

export const listBusinessModelQuestions = async (data) => {
  try {
    const result = await apiClient.get("business-model");
    return result.data;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      const responseData = err.response.data;

      if (err.response?.status == 400)
        throw new BadRequestError(responseData.message);
      if (err.response?.status == 401)
        throw new UnauthenticatedError(responseData.message);
      if (err.response?.status == 403)
        throw new ForbiddenError(responseData.message);
      if (err.response?.status == 404)
        throw new UnexpectedError(responseData.message);
      if (err.response?.status == 500)
        throw new InternalServerError(responseData.message);
    }
    throw new UnexpectedError();
  }
};

export const getBusinessModelQuestion = async (id) => {
  try {
    const result = await apiClient.get(`business-model/${id}`);
    return result.data;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      const responseData = err.response.data;

      if (err.response?.status == 400)
        throw new BadRequestError(responseData.message);
      if (err.response?.status == 401)
        throw new UnauthenticatedError(responseData.message);
      if (err.response?.status == 403)
        throw new ForbiddenError(responseData.message);
      if (err.response?.status == 404)
        throw new UnexpectedError(responseData.message);
      if (err.response?.status == 500)
        throw new InternalServerError(responseData.message);
    }
    throw new UnexpectedError();
  }
};

export const addBusinessModelQuestion = async (data) => {
  try {
    const result = await apiClient.post("business-model", data);
    return result.data;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      const responseData = err.response.data;

      if (err.response?.status == 400)
        throw new BadRequestError(responseData.message);
      if (err.response?.status == 401)
        throw new UnauthenticatedError(responseData.message);
      if (err.response?.status == 403)
        throw new ForbiddenError(responseData.message);
      if (err.response?.status == 404)
        throw new UnexpectedError(responseData.message);
      if (err.response?.status == 500)
        throw new InternalServerError(responseData.message);
    }
    throw new UnexpectedError();
  }
};

export const updateBusinessModelQuestion = async (data) => {
  try {
    const result = await apiClient.patch(
      `business-model/${data.id}`,
      data.data
    );
    return result.data;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      const responseData = err.response.data;

      if (err.response?.status == 400)
        throw new BadRequestError(responseData.message);
      if (err.response?.status == 401)
        throw new UnauthenticatedError(responseData.message);
      if (err.response?.status == 403)
        throw new ForbiddenError(responseData.message);
      if (err.response?.status == 404)
        throw new UnexpectedError(responseData.message);
      if (err.response?.status == 500)
        throw new InternalServerError(responseData.message);
    }
    throw new UnexpectedError();
  }
};

export const deleteBusinessModelQuestion = async (id) => {
  try {
    const result = await apiClient.delete(`business-model/${id}`);
    return result.data;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      const responseData = err.response.data;

      if (err.response?.status == 400)
        throw new BadRequestError(responseData.message);
      if (err.response?.status == 401)
        throw new UnauthenticatedError(responseData.message);
      if (err.response?.status == 403)
        throw new ForbiddenError(responseData.message);
      if (err.response?.status == 404)
        throw new UnexpectedError(responseData.message);
      if (err.response?.status == 500)
        throw new InternalServerError(responseData.message);
    }
    throw new UnexpectedError();
  }
};
export const enrollCourse = async (data) => {
  try {
    const result = await apiClient.post(
      `${BASE_ROUTE}/course/${data.id}/language/${data.language}/enroll`
    );
    return result.data;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      const responseData = err.response.data;

      if (err.response?.status == 400)
        throw new BadRequestError(responseData.message);
      if (err.response?.status == 401)
        throw new UnauthenticatedError(responseData.message);
      if (err.response?.status == 403)
        throw new ForbiddenError(responseData.message);
      if (err.response?.status == 404)
        throw new UnexpectedError(responseData.message);
      if (err.response?.status == 500)
        throw new InternalServerError(responseData.message);
    }
    throw new UnexpectedError();
  }
};

export const isEnrolled = async (data) => {
  try {
    console.log(data.id);
    const result = await apiClient.get(
      `${BASE_ROUTE}/course/${data.id}/language/${data.language}/isEnrolled`
    );
    return result.data;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      const responseData = err.response.data;

      if (err.response?.status == 400)
        throw new BadRequestError(responseData.message);
      if (err.response?.status == 401)
        throw new UnauthenticatedError(responseData.message);
      if (err.response?.status == 403)
        throw new ForbiddenError(responseData.message);
      if (err.response?.status == 404)
        throw new UnexpectedError(responseData.message);
      if (err.response?.status == 500)
        throw new InternalServerError(responseData.message);
    }
    throw new UnexpectedError();
  }
};

export const certificate = async (data) => {
  try {
    const result = await apiClient.get(
      `${BASE_ROUTE}/certificate/course/${data.courseId}/language/${data.language}/date`
    );
    return result.data;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      const responseData = err.response.data;

      if (err.response?.status == 400)
        throw new BadRequestError(responseData.message);
      if (err.response?.status == 401)
        throw new UnauthenticatedError(responseData.message);
      if (err.response?.status == 403)
        throw new ForbiddenError(responseData.message);
      if (err.response?.status == 404)
        throw new UnexpectedError(responseData.message);
      if (err.response?.status == 500)
        throw new InternalServerError(responseData.message);
    }
    throw new UnexpectedError();
  }
};
