import React from 'react';
import { useLocation, Navigate, Outlet } from "react-router-dom";
import { overmind } from "../../src/index"

const RequireAuth = ({ allowedRole }) => {
    const location = useLocation();

    return (
        overmind.state.user && allowedRole.includes(overmind.state.user.role)
            ? <Outlet />
            : overmind.state.user
                ? <Navigate to="/unauthorized" state={{ from: location }} replace />
                : <Navigate to="/login?notLogged=true" state={{ from: location }} replace />
    );
}

export default RequireAuth;