import "regenerator-runtime/runtime";

import { handleError } from "../errors";
import { toast } from "react-toastify";
import i18n from "../../locales/i18n";
import { changePositionByLanguage } from "../../utils/toastify";

export const listQuestions = async (context, { page, limit }) => {
  try {
    context.state.loading = true;
    const result = await context.effects.listQuestions(page, limit);
    context.state.loading = false;
    context.state.questions = result;
    return result;
  } catch (err) {
    context.state.loading = false;
    handleError(err);
  }
};

export const calculateResults = async (context, { answers }) => {
  const upperScores = {};
  // Iterate over the fields
  console.log(answers);
  for (const fieldId in answers) {
    const fieldData = answers[fieldId];
    const questionIds = Object.keys(fieldData);
    const numQuestions = questionIds.length;
    const maxScore = 4;
    const totalUpperScores = numQuestions * maxScore;

    upperScores[fieldId] = totalUpperScores;
  }

  const result = {};
  const fieldIdResult = [];
  Object.keys(answers).map((fieldId) => {
    const fieldAnswers = answers[fieldId];
    const questionIds = Object.keys(fieldAnswers);
    let totalUserScore = 0;

    questionIds.forEach((questionId) => {
      const score = parseInt(fieldAnswers[questionId]);
      console.log(score);
      totalUserScore += score;
    });
    const totalUpperScores = upperScores[fieldId];
    console.log(totalUpperScores);
    const inclinationPercentage = (totalUserScore / totalUpperScores) * 100;
    const question = context.state.questions.find((q) => q.fieldId == fieldId);
    console.log(inclinationPercentage);
    result[question.Field.name] = inclinationPercentage.toFixed(2);
    fieldIdResult.push({
      fieldId: question.fieldId,
      rating: Number(inclinationPercentage.toFixed(2)),
    });
  });
  const fieldNames = Object.keys(result);

  // Sort the fieldNames based on their corresponding inclination percentages in descending order
  const sortedFieldNames = fieldNames.sort((a, b) => result[b] - result[a]);

  // Get the top 3 fieldNames with the largest inclination percentages
  const top3FieldNames = sortedFieldNames.slice(0, 3);

  // Get the corresponding inclination percentages for the top 3 fieldNames
  const topResults = top3FieldNames.map((fieldName) => {
    const question = context.state.questions.find(
      (q) => q.Field.name == fieldName
    );
    return {
      fieldName,
      fieldId: question.fieldId,
      percentage: result[question.Field.name],
    };
  });

  console.log("result", result);
  console.log("topResults", topResults);
  context.state.result = result;
  context.state.topResults = topResults;
  context.state.fieldIdResult = fieldIdResult;
};

export const setSuggestedFields = async (context, fields) => {
  console.log("setSuggestedFields", fields);
  context.state.suggestedFields = fields;
};

export const setFinalSubfields = async (context, arr) => {
  console.log("setFinalSubfields", arr);
  context.state.selectedSubfields = arr;
};

export const setSuggestionType = async (context, data) => {
  context.state.suggestionType = data;
};

export const setProjectPositionAnswers = async (context, arr) => {
  console.log("setProjectPositionAnswers", arr);
  context.state.projectPositionAnswers = arr;
};

export const setBusinessModelQuestions = async (context, arr) => {
  context.state.businessModelQuestions = arr;
  context.state.businessModelResult = arr.map((item) => {
    return {
      id: item.id,
      result: item.answer,
    };
  });
};

export const setScreenshots = async (context, { key, screenshot }) => {
  // console.log('setScreenshots', screenshot);
  context.state.screenshots[key] = screenshot;
};

export const setHeaderScreenshots = async (context, screenshot) => {
  // console.log('setScreenshots', screenshot);
  context.state.surveyHeader1 = screenshot;
};

export const submitReport = async (context, surveyId) => {
  try {
    context.state.loadingBtn = true;
    const obj = {};
    obj.fieldRatings = context.state.fieldIdResult.map((item) => {
      return {
        fieldId: item.fieldId,
        rating: item.rating,
      };
    });
    obj.suggestions = {
      suggestedFields: context.state.suggestedFields,
    };
    obj.suggestions[context.state.suggestionType] =
      context.state.selectedSubfields;

    obj.projectPosition = context.state.projectPositionAnswers;
    const businessModel = context.state.businessModelQuestions.map(
      (question) => {
        return {
          business_Model_QuestionId: question.id,
          result: question.answer,
        };
      }
    );
    obj.businessModel = businessModel;
    context.state.loading = true;
    const result = await context.effects.submitReport(obj, surveyId);
    context.state.loading = false;
    context.state.loadingBtn = false;
    toast.success(i18n.t("form.success.updated"), {
      position: changePositionByLanguage(i18n.language),
    });
    setTimeout(() => {
      toast.dismiss();
    }, 1000);
    return result;
  } catch (err) {
    context.state.loading = false;
    handleError(err);
  }
};

export const getSurveyReport = async (context) => {
  context.state.loading = true;
  const result = await context.effects.getSurveyReport();
  context.state.surveyResult = result;
  if (result) {
    const transformedData = result.fieldsRatings.reduce((result, item) => {
      const fieldName = item.field.name;
      const rating = item.rating;

      result[fieldName] = rating;

      return result;
    }, {});
    const fieldNames = Object.keys(transformedData);

    // Sort the fieldNames based on their corresponding inclination percentages in descending order
    const sortedFieldNames = fieldNames.sort(
      (a, b) => transformedData[b] - transformedData[a]
    );

    // Get the top 3 fieldNames with the largest inclination percentages
    const top3FieldNames = sortedFieldNames.slice(0, 3);
    console.log("top3FieldNames", top3FieldNames);

    // Get the corresponding inclination percentages for the top 3 fieldNames
    const topResults = top3FieldNames.map((fieldName) => {
      const field = result.fieldsRatings.find((q) => q.field.name == fieldName);
      return {
        fieldName,
        fieldId: field.fieldId,
        percentage: field.rating,
      };
    });
    context.state.fieldIdResult = result.fieldsRatings;
    context.state.result = transformedData;
    context.state.topResults = topResults;
    context.state.suggestedFields = result.suggestions.suggestedFields;
    if (result.suggestions.merge) {
      context.state.selectedSubfields = result.suggestions.merge;
      context.state.suggestionType = "merge";
    } else if (result.suggestions.select) {
      context.state.selectedSubfields = result.suggestions.select;
      context.state.suggestionType = "select";
    } else if (result.suggestions.innovation) {
      context.state.selectedSubfields = result.suggestions.innovation;
      context.state.suggestionType = "innovation";
    }
    context.state.projectPositionAnswers = result.projectPosition.map(
      (item) => {
        return item.values;
      }
    );
    context.state.businessModelResult = result.businessModelResult;
  }
  context.state.loading = false;
  return result;
};

export const resetSurvey = async (context) => {
  context.state.surveyResult = null;
  context.state.fieldIdResult = null;
  context.state.result = null;
  context.state.topResults = null;
  context.state.suggestedFields = null;
  context.state.selectedSubfields = null;
  context.state.suggestionType = null;
  context.state.businessModelResult = null;
};
export const listSavedQuestions = async (context, { page, limit }) => {
  try {
    context.state.loading = true;
    const result = await context.effects.getSavedSurveyQuestions(page, limit);
    context.state.questions = result.shuffledQuestions;
    return result;
  } catch (err) {
    context.state.loading = false;
    handleError(err);
  }
};

export const saveQuestions = async (context, data) => {
  try {
    context.state.loading = true;
    const result = await context.effects.saveSurveyQuestions(data);
    toast.success(i18n.t("form.success.saved"), {
      position: changePositionByLanguage(i18n.language),
    });
    return result;
  } catch (err) {
    context.state.loading = false;
    handleError(err);
  }
};

export const deleteSavedSurveyQuestions = async (context) => {
  try {
    context.state.loading = true;
    const result = await context.effects.deleteSavedSurveyQuestions();

    return result;
  } catch (err) {
    context.state.loading = false;
    handleError(err);
  }
};

export const listSurvey = async (context) => {
  try {
    context.state.loading = true;
    const result = await context.effects.listSurvey();

    return result;
  } catch (err) {
    context.state.loading = false;
    handleError(err);
  }
};

export const getSurveyDetails = async (context, data) => {
  try {
    context.state.loading = true;
    const result = await context.effects.getSurveyDetails(data);

    return result;
  } catch (err) {
    context.state.loading = false;
    handleError(err);
  }
};

export const isUserEnrolledSurvey = async (context, data) => {
  try {
    context.state.loading = true;
    const result = await context.effects.isUserEnrolledSurvey(data);

    return result;
  } catch (err) {
    context.state.loading = false;
    handleError(err);
  }
};

export const updateSurvey = async (context, data) => {
  try {
    console.log(data);
    context.state.loading = true;
    const result = await context.effects.updateSurvey(data);
    console.log(result);
    toast.success(i18n.t("form.success.updated"), {
      position: changePositionByLanguage(i18n.language),
    });
    context.state.loading = false;
    return result;
  } catch (err) {
    context.state.loading = false;
    handleError(err);
  }
};

export const updateLifeProjectSurveyDetails = async (context, data) => {
  try {
    context.state.loading = true;
    const result = await context.effects.updateLifeProjectSurveyDetails(data);
    console.log(result);
    toast.success(i18n.t("form.success.updated"), {
      position: changePositionByLanguage(i18n.language),
    });
    context.state.loading = false;
    return result;
  } catch (err) {
    context.state.loading = false;
    handleError(err);
  }
};

export const enrollSurvey = async (context, data) => {
  try {
    context.state.loading = true;
    const result = await context.effects.enrollSurvey(data);
    toast.success(i18n.t("form.success.enrolled"), {
      position: changePositionByLanguage(i18n.language),
    });
    context.state.loading = false;
    return result;
  } catch (err) {
    context.state.loading = false;
    handleError(err);
  }
};

export const lifeProjectSurveyDetails = async (context) => {
  try {
    context.state.loading = true;
    const result = await context.effects.lifeProjectSurveyDetails();

    return result;
  } catch (err) {
    context.state.loading = false;
    handleError(err);
  }
};
