import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Course10,
  Course11,
  Course12,
  Course13,
  Course14,
  Course15,
  Course16,
  Course17,
  Icon1,
  Icon2,
  User,
  User1,
  User2,
  User3,
  User4,
  User5,
  User6,
} from "../../../imagepath";
import { useActions } from "../../../../presenter";
import defaultCourseImg from "../../../../assets/img/defaultCourseImg.png";
import { useTranslation } from "react-i18next";

const GridInnerPage = ({ survey }) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="row">
        {survey &&
          survey.map((survey) => (
            <div key={survey.id} className="col-lg-4 col-md-6 d-flex">
              <div className="course-box course-design d-flex ">
                <div className="product">
                  <div className="product-img">
                    <Link to={`/survey-details/${survey.id}`}>
                      <img
                        className="img-fluid"
                        alt=""
                        style={{
                          width: "100%",
                          height: "200px",
                          objectFit: "cover",
                        }}
                        src={survey?.image?.url ?? defaultCourseImg}
                      />
                    </Link>
                    <div className="price">
                      <h3 className="price-h3">
                        {survey.price} {t("currency")}
                      </h3>
                    </div>
                  </div>
                  <div className="product-content">
                    <div className="course-group d-flex">
                      <div className="course-share d-flex align-items-center justify-content-center"></div>
                    </div>
                    <h3 className="title">
                      {survey.title}
                    </h3>

                    <div className="all-btn all-category d-flex align-items-center">
                      <Link
                        style={{ width: "100%" }}
                        to={`/survey-details/${survey?.id}`}
                        className="btn btn-primary">
                        {t("moveSurvey")}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
    </>
  );
};

export default GridInnerPage;
