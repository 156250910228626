import * as Yup from "yup";

export const VideoValidator = Yup.object().shape({
    title: Yup.string().required("form.validation.required"),
    duration: Yup.string()
      .required("form.validation.required")
      .matches(/^[0-9][0-9]:[0-5][0-9]$/, "form.validation.durationFormat"),
    videoLink: Yup.string().url("form.validation.videoLinkURL"),
    fileAttachment: Yup.mixed().notRequired(),
    isPinned: Yup.boolean(),
  });
  