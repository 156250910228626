import React, { useEffect, useState } from "react";

import { useActions, useAppState } from "../../../../presenter";

import { useTranslation } from "react-i18next";

function SelectedSuggestionSection() {
  const { t } = useTranslation();

  const [subfields, setSubfields] = useState([]);
  const [selectedSelectSubfields, setSelectedSelectSubfields] = useState([]);
  const [selectedMergeSubfields, setSelectedMergeSubfields] = useState([]);
  const [selectedInnovationSubfields, setSelectedInnovationSubfields] =
    useState([]);
  const [mergeSubfield, setMergeSubfield] = useState("");
  const { suggestedFields, topResults, selectedSubfields, suggestionType } =
    useAppState();
  const { setFinalSubfields, setSuggestionType, setScreenshots } = useActions();

  useEffect(() => {
    const selectedSubfieldsArr = suggestedFields?.flatMap((field) => {
      if (field?.selectedSubfields) {
        return field.selectedSubfields.map((subfield) => ({
          fieldId: field.id,
          fieldName: field.name,
          subfieldId: subfield.subfieldId,
          subfieldName: subfield.subfieldName,
        }));
      }
      return [];
    });
    setSubfields(selectedSubfieldsArr);
    if (suggestionType && suggestionType == "select") {
      setSelectedSelectSubfields(selectedSubfields);
    } else if (suggestionType && suggestionType == "merge") {
      setSelectedMergeSubfields(selectedSubfields);
      const mergeValue = selectedSubfields?.find((item) => !item.subfieldId);
      if (mergeValue) setMergeSubfield(mergeValue.subfieldName);
    } else if (suggestionType && suggestionType == "innovation") {
      setSelectedInnovationSubfields(selectedSubfields);
    }
  }, [suggestedFields, selectedSubfields, suggestionType, setSubfields]);
  return (
    <>
      {subfields && subfields.length > 0 && (
        <div className="">
          <div
            id="selectedSuggestions"
            className="row g-4">
            {/* SELECT */}
            <div className="col col-print-4">
              <div className="card suggested-field-card-color p-3 h-100">
                <div className="suggestion-text d-flex align-items-center">
                  <svg
                    width="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M9 15H11V9H9V15ZM10 7C10.2833 7 10.521 6.904 10.713 6.712C10.9043 6.52067 11 6.28333 11 6C11 5.71667 10.9043 5.479 10.713 5.287C10.521 5.09567 10.2833 5 10 5C9.71667 5 9.47933 5.09567 9.288 5.287C9.096 5.479 9 5.71667 9 6C9 6.28333 9.096 6.52067 9.288 6.712C9.47933 6.904 9.71667 7 10 7ZM10 20C8.61667 20 7.31667 19.7373 6.1 19.212C4.88333 18.6873 3.825 17.975 2.925 17.075C2.025 16.175 1.31267 15.1167 0.788 13.9C0.262667 12.6833 0 11.3833 0 10C0 8.61667 0.262667 7.31667 0.788 6.1C1.31267 4.88333 2.025 3.825 2.925 2.925C3.825 2.025 4.88333 1.31233 6.1 0.787C7.31667 0.262333 8.61667 0 10 0C11.3833 0 12.6833 0.262333 13.9 0.787C15.1167 1.31233 16.175 2.025 17.075 2.925C17.975 3.825 18.6873 4.88333 19.212 6.1C19.7373 7.31667 20 8.61667 20 10C20 11.3833 19.7373 12.6833 19.212 13.9C18.6873 15.1167 17.975 16.175 17.075 17.075C16.175 17.975 15.1167 18.6873 13.9 19.212C12.6833 19.7373 11.3833 20 10 20ZM10 18C12.2333 18 14.125 17.225 15.675 15.675C17.225 14.125 18 12.2333 18 10C18 7.76667 17.225 5.875 15.675 4.325C14.125 2.775 12.2333 2 10 2C7.76667 2 5.875 2.775 4.325 4.325C2.775 5.875 2 7.76667 2 10C2 12.2333 2.775 14.125 4.325 15.675C5.875 17.225 7.76667 18 10 18Z"
                      fill="#333333"
                    />{" "}
                  </svg>
                  <h3 className="ms-3 mb-0">{t("select")}</h3>
                </div>
                <div className="subfield-checkboxes">
                  {subfields?.map((subfield) => (
                    <div
                      className="subfield-checkbox mb-3"
                      key={"select" + subfield?.subfieldName}>
                      <div className="form-check">
                        <input
                          type="radio"
                          id={"radio-select-" + subfield?.subfieldName}
                          name="merge"
                          className="form-check-input"
                          checked={selectedSelectSubfields?.some(
                            (item) => item.subfieldId === subfield?.subfieldId
                          )}
                          disabled={true}
                        />
                        <label
                          className="form-check-label"
                          htmlFor={"radio-select-" + subfield?.subfieldName}>
                          {" "}
                          {subfield?.subfieldName.replace(
                            / /g,
                            "\u00A0"
                          )}{" "}
                        </label>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            {/* MERGE */}
            <div className="col col-print-4">
              <div className="card suggested-field-card-color p-3 h-100">
                <div className="suggestion-text d-flex align-items-center">
                  <svg
                    width="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M9 15H11V9H9V15ZM10 7C10.2833 7 10.521 6.904 10.713 6.712C10.9043 6.52067 11 6.28333 11 6C11 5.71667 10.9043 5.479 10.713 5.287C10.521 5.09567 10.2833 5 10 5C9.71667 5 9.47933 5.09567 9.288 5.287C9.096 5.479 9 5.71667 9 6C9 6.28333 9.096 6.52067 9.288 6.712C9.47933 6.904 9.71667 7 10 7ZM10 20C8.61667 20 7.31667 19.7373 6.1 19.212C4.88333 18.6873 3.825 17.975 2.925 17.075C2.025 16.175 1.31267 15.1167 0.788 13.9C0.262667 12.6833 0 11.3833 0 10C0 8.61667 0.262667 7.31667 0.788 6.1C1.31267 4.88333 2.025 3.825 2.925 2.925C3.825 2.025 4.88333 1.31233 6.1 0.787C7.31667 0.262333 8.61667 0 10 0C11.3833 0 12.6833 0.262333 13.9 0.787C15.1167 1.31233 16.175 2.025 17.075 2.925C17.975 3.825 18.6873 4.88333 19.212 6.1C19.7373 7.31667 20 8.61667 20 10C20 11.3833 19.7373 12.6833 19.212 13.9C18.6873 15.1167 17.975 16.175 17.075 17.075C16.175 17.975 15.1167 18.6873 13.9 19.212C12.6833 19.7373 11.3833 20 10 20ZM10 18C12.2333 18 14.125 17.225 15.675 15.675C17.225 14.125 18 12.2333 18 10C18 7.76667 17.225 5.875 15.675 4.325C14.125 2.775 12.2333 2 10 2C7.76667 2 5.875 2.775 4.325 4.325C2.775 5.875 2 7.76667 2 10C2 12.2333 2.775 14.125 4.325 15.675C5.875 17.225 7.76667 18 10 18Z"
                      fill="#333333"
                    />
                  </svg>
                  <h3 className="ms-3 mb-0">{t("merge")}</h3>
                </div>
                <div className="subfield-checkboxes">
                  {subfields?.map((subfield) => (
                    <div
                      className="subfield-checkbox mb-3"
                      key={"merge" + subfield?.subfieldName}>
                      <input
                        type="checkbox"
                        id={subfield?.subfieldName}
                        name={subfield?.subfieldName}
                        checked={selectedMergeSubfields?.some(
                          (item) => item.subfieldId === subfield?.subfieldId
                        )}
                        disabled={true}
                      />
                      <label htmlFor={subfield?.subfieldName}>
                        {" "}
                        {subfield?.subfieldName.replace(/ /g, "\u00A0")}{" "}
                      </label>
                    </div>
                  ))}
                </div>

                <div style={{ display: "flex", alignSelf: "center" }}>
                  <hr className="line" />
                  <div className="or-text">{t("or")}</div>
                  <hr className="line" />
                </div>

                <textarea
                  className="text-area form-control"
                  name="subfieldString"
                  id=""
                  cols="30"
                  rows="3"
                  placeholder={t("mergePlaceholder").replace(/ /g, "\u00A0")}
                  style={{ width: "100%" }}
                  value={mergeSubfield?.replace(/ /g, "\u00A0")}
                  disabled={true}
                />
              </div>
            </div>
            {/* INNOVATE */}
            <div className="col col-print-4">
              <div className="card suggested-field-card-color p-3 h-100">
                <div className="suggestion-text d-flex align-items-center">
                  <svg
                    width="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M9 15H11V9H9V15ZM10 7C10.2833 7 10.521 6.904 10.713 6.712C10.9043 6.52067 11 6.28333 11 6C11 5.71667 10.9043 5.479 10.713 5.287C10.521 5.09567 10.2833 5 10 5C9.71667 5 9.47933 5.09567 9.288 5.287C9.096 5.479 9 5.71667 9 6C9 6.28333 9.096 6.52067 9.288 6.712C9.47933 6.904 9.71667 7 10 7ZM10 20C8.61667 20 7.31667 19.7373 6.1 19.212C4.88333 18.6873 3.825 17.975 2.925 17.075C2.025 16.175 1.31267 15.1167 0.788 13.9C0.262667 12.6833 0 11.3833 0 10C0 8.61667 0.262667 7.31667 0.788 6.1C1.31267 4.88333 2.025 3.825 2.925 2.925C3.825 2.025 4.88333 1.31233 6.1 0.787C7.31667 0.262333 8.61667 0 10 0C11.3833 0 12.6833 0.262333 13.9 0.787C15.1167 1.31233 16.175 2.025 17.075 2.925C17.975 3.825 18.6873 4.88333 19.212 6.1C19.7373 7.31667 20 8.61667 20 10C20 11.3833 19.7373 12.6833 19.212 13.9C18.6873 15.1167 17.975 16.175 17.075 17.075C16.175 17.975 15.1167 18.6873 13.9 19.212C12.6833 19.7373 11.3833 20 10 20ZM10 18C12.2333 18 14.125 17.225 15.675 15.675C17.225 14.125 18 12.2333 18 10C18 7.76667 17.225 5.875 15.675 4.325C14.125 2.775 12.2333 2 10 2C7.76667 2 5.875 2.775 4.325 4.325C2.775 5.875 2 7.76667 2 10C2 12.2333 2.775 14.125 4.325 15.675C5.875 17.225 7.76667 18 10 18Z"
                      fill="#333333"
                    />
                  </svg>
                  <h3 className="ms-3 mb-0">{t("Innovation")}</h3>
                </div>
                <div className="subfield-checkboxes mx-auto">
                  <textarea
                    className="text-area form-control"
                    name="subfieldString"
                    id=""
                    cols="30"
                    rows="8"
                    placeholder={t("innovatePlaceholder")}
                    value={selectedInnovationSubfields?.[0]?.subfieldName?.replace(
                      / /g,
                      "\u00A0"
                    )}
                    disabled={true}></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default SelectedSuggestionSection;
