import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { Dispatch, SetStateAction } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useActions, useAppState } from "../../../presenter";
import PageChange from "../../page-change";

export default function Pagination({
  pagesCount,
  selectedPage,
  onClick: setCurrentPage,
  onSubmit,
  savedSurveyQuestions,
  setIsNextDisabled,
  survey,
  isNextDisabled,
}) {
  const {
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors,
  } = useFormContext();
  const { t } = useTranslation();
  const queryParams = new URLSearchParams(location.search);
  const courseId = queryParams.get("courseId");

  const { id } = useParams();

  const navigate = useNavigate();
  const { saveQuestions, isUserEnrolledSurvey } = useActions();
  const hasNextPages = selectedPage < pagesCount;
  const hasPrevPages = selectedPage > 1;

  useEffect(() => {
    isUserEnrolledSurvey({ id, courseId }).then((data) => {
      if (!data.isEnrolled) {
        navigate("/unauthorized");
      }
    });
  }, []);

  useEffect(() => {
    if (Object.keys(survey).length === selectedPage * 8)
      return setIsNextDisabled(false);
    else return setIsNextDisabled(true);
  }, [Object.keys(survey).length, selectedPage]);
  const handleNextClick = () => {
    if (isNextDisabled)
      return handleSubmit((data) => {
        onSubmit(data);
      })();

    if (hasNextPages) {
      localStorage.setItem(
        "surveyQuestionsPage",
        JSON.stringify(selectedPage + 1)
      );

      clearErrors("answers");
      setCurrentPage(selectedPage + 1);
    }
  };

  const handlePrevClick = () => {
    if (hasPrevPages) {
      console.log("selectedPage", selectedPage);
      localStorage.setItem(
        "surveyQuestionsPage",
        JSON.stringify(selectedPage - 1)
      );
      setCurrentPage(selectedPage - 1);
    }
    console.log("selectedPage", selectedPage);
  };

  const handleSave = async () => {
    const surveyQuestions = JSON.parse(localStorage.getItem("survey"));
    await saveQuestions({
      shuffledQuestion: JSON.parse(localStorage.getItem("shuffledQuestion")),
      surveyQuestions: surveyQuestions,
      surveyQuestionsNumber:
        JSON.parse(localStorage.getItem("surveyQuestionsPage")) ?? 1,
    });
  };

  const indicatorButtonStyle = (isDisabled) => {
    return classNames(
      "bg-white py-3 px-5 shadow-md rounded-full",
      { "cursor-pointer": !isDisabled },
      { "cursor-not-allowed": isDisabled }
    );
  };

  return (
    <>
      {errors?.answers && (
        <h5 className="text-center text-red-500 text-sm mb-2">
          {t("answerAll")}
        </h5>
      )}

      <div
        className="d-flex justify-content-between align-items-center flex-lg-row"
        style={{ flexDirection: "column" }}>
        <div className=" survey-btn">
          <button className="btn btn-black prev_btn" onClick={handlePrevClick}>
            {t("back")}
          </button>
        </div>
        <div
          className=" survey-btn flex-lg-row"
          style={{ flexDirection: "column" }}>
          <button onClick={handleSave} className="btn btn-outline-dark">
            {t("Save")}
          </button>
          {hasNextPages ? (
            <div className="survey-btn">
              <button
                onClick={handleNextClick}
                type="button"
                className="btn btn-info-light next_btn">
                {t("next")}
              </button>
            </div>
          ) : (
            <div className="survey-btn">
              <button
                onClick={handleSubmit((data) => onSubmit(data))}
                type="submit"
                className="btn btn-info-light next_btn">
                {t("finish")}
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
