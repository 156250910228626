import "regenerator-runtime/runtime";

import { handleError } from "../errors";

export const listBlogs = async (context, { currentPage, limit, q , language }) => {
  try {
    context.state.loading = true;
    const result = await context.effects.listBlogs(currentPage, limit, q ,language);
    context.state.loading = false;
    return result;
  } catch (err) {
    context.state.loading = false;
    handleError(err);
  }
};

export const getBlog = async (context, data) => {
  try {
    context.state.loading = true;
    const result = await context.effects.getBlog(data);
    context.state.loading = false;
    return result;
  } catch (err) {
    context.state.loading = false;
    handleError(err);
  }
};

export const createBlog = async (context, data) => {
  try {
    context.state.loadingBtn = true;
    const result = await context.effects.createBlog(
      data
    );
    context.state.loadingBtn = false;
    return result;
  } catch (err) {
    context.state.loadingBtn = false;
    handleError(err);
  }
};

export const addBlog = async (context, data) => {
  try {
    context.state.loadingBtn = true;
    const result = await context.effects.addBlog(
      data
    );
    context.state.loadingBtn = false;
    return result;
  } catch (err) {
    context.state.loadingBtn = false;
    handleError(err);
  }
};

export const updateBlog = async (context, data) => {
  try {
    context.state.loadingBtn = true;
    const result = await context.effects.updateBlog(
      data
    );
    context.state.loadingBtn = false;
    return result;
  } catch (err) {
    context.state.loadingBtn = false;
    handleError(err);
  }
};

export const deleteBlog = async (context, data) => {
  try {
    context.state.loadingBtn = true;
    const result = await context.effects.deleteBlog(
      data
    );
    context.state.loadingBtn = false;
    return result;
  } catch (err) {
    context.state.loadingBtn = false;
    handleError(err);
  }
};
