import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Spotify, SoundCloud, Podcast } from "../imagepath";
import { formatDate } from "../../utils/date";
import { useActions, useAppState } from "../../presenter";

import Footer from "../footer";
import Header from "../header";
import SideBlog from "../blog/sideBlog";
import config from "../../../config";
import PageChange from "../page-change";
import podcastDefaultImage from "../../assets/img/podcastDefaultImage.png";

const PodcastDetails = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [podcast, setPodcast] = useState({});
  const [randomPodcasts, setRandomPodcasts] = useState([]);

  const { loading } = useAppState();
  const { getPodcast } = useActions();

  useEffect(() => {
    getPodcast(id).then((data) => {
      console.log(data);
      setPodcast(data.podcast);
      setRandomPodcasts(data.randomPodcasts);
    });
  }, [id]);

  if (loading && !podcast) return <PageChange />;
  return (
    <>
      <div className="main-wrapper">
        <Header activeMenu={"Details"} />
        <div className="breadcrumb-bar">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-12">
                <div className="breadcrumb-list">
                  <nav aria-label="breadcrumb" className="page-breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/">{t("breadcrumb.home")}</Link>{" "}
                      </li>
                      <li className="breadcrumb-item" aria-current="page">
                        {t("breadcrumb.podcastDetails")}
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>

        <section className="course-content">
          <div className="container">
            <div className="row">
              <div className="col-lg-9 col-md-12">
                {/* Blog Post */}
                {podcast && podcast.image && id && (
                  <div className="blog">
                    <div className="blog-image podcast-container">
                      <div className="row">
                        <div className="col-5 d-flex justify-content-center">
                          <img
                            className="img-fluid podcast-img shadow"
                            style={{ height: "250px" }}
                            src={podcast.image?.url ?? podcastDefaultImage}
                            alt="Podcast Image"
                          />
                        </div>
                        <div className="col-7">
                          <div
                            className="clearfix"
                            style={{ fontSize: "14px", marginButton: "10px" }}>
                            <div className="post-left">
                              <ul>
                                <li>
                                  <div className="post-author">
                                    <span>
                                      {t("episode")} {podcast.episodeNumber}
                                    </span>
                                  </div>
                                </li>
                                <li>{formatDate(podcast.createdAt)}</li>
                                <li>
                                  {podcast.duration} {t("min")}
                                </li>
                              </ul>
                            </div>
                          </div>
                          <h3
                            className="blog-title"
                            style={{ fontSize: "71px" }}>
                            {podcast.title}
                          </h3>
                          <div className="blog-content">
                            <div className="row">
                              <div className="col-3">{t("listenOn")}</div>
                              <div className="col-1">
                                <Link
                                  to={podcast.googlePodcastLink}
                                  target="_blank"
                                  rel="noopener noreferrer">
                                  <img
                                    src={Podcast}
                                    className="podcast-icons"
                                  />
                                </Link>
                              </div>
                              <div className="col-1">
                                <Link
                                  to={podcast.applePodcastLink}
                                  target="_blank"
                                  rel="noopener noreferrer">
                                  <img
                                    src={Spotify}
                                    className="podcast-icons"
                                  />
                                </Link>
                              </div>
                              <div className="col-1">
                                <Link
                                  to={podcast.soundCloudLink}
                                  target="_blank"
                                  rel="noopener noreferrer">
                                  <img
                                    src={SoundCloud}
                                    className="podcast-icons"
                                  />
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="blog-content mx-5">
                      <div className="mb-3">
                        <i className="fa-solid fa-file-lines fa-lg mx-2"></i>{" "}
                        {t("transcript")}
                      </div>

                      {podcast.transcript}
                    </div>
                  </div>
                )}
                {/* /Blog Post */}
              </div>

              {/* Blog Sidebar */}
              <div className="col-lg-3 col-md-12 sidebar-right theiaStickySidebar">
                <div className="stickysidebar">
                  {/* Latest Posts */}
                  <SideBlog
                    blogs={randomPodcasts}
                    title={t("sugPodcasts")}
                    type="podcast"
                  />
                  {/* /Latest Posts */}
                </div>
              </div>
              {/* /Blog Sidebar */}
            </div>
          </div>
        </section>

        <Footer />
      </div>
    </>
  );
};

export default PodcastDetails;
