import { redirect } from "react-router-dom";
import { toast } from "react-toastify";

export class InternalServerError extends Error {
  constructor(message) {
    super();
    this.name = "InternalServerError";
  }

  handle() {
    if (this.message) {
      toast.error(this.message, { position: "top-left" });
    }

    redirect("/500");
  }
}

export class UnexpectedError extends Error {
  constructor(message) {
    super(message);
    this.name = "UnexpectedError";
  }

  handle() {
    if (this.message) {
      toast.error(this.message, { position: "top-left" });
    } else {
      toast.error("Unexpected error, please try again", {
        position: "top-left",
      });
    }
  }
}

export class UnauthenticatedError extends Error {
  message = "Unauthenticated";

  constructor(message) {
    super(message);
    this.name = "UnauthenticatedError";
  }

  handle() {
    if (this.message) {
      toast.error(this.message, { position: "top-left" });
    }

    redirect("/auth/login");
  }
}

export class ForbiddenError extends Error {
  constructor(message) {
    super(message);
    this.name = "ForbiddenError";
  }

  handle() {
    if (this.message) {
      toast.error(this.message, { position: "top-left" });
    } else {
      toast.error("You're not authorized to make access this action", {
        position: "top-left",
      });
    }

    redirect("/");
  }
}

export class BadRequestError extends Error {
  constructor(message) {
    super(message);
    this.name = "BadRequestError";
  }

  handle() {
    if (this.message) {
      toast.error(this.message, { position: "top-left" });
    } else {
      toast.error("Bad Request, please try again with correct data", {
        position: "top-left",
      });
    }
  }
}

export function handleError(error) {
  console.log(error);
  error.handle();
}
