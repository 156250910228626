import * as Yup from "yup";

export const CardValidator = Yup.object().shape({
    cardNumber: Yup
        .string()
        .required("form.validation.required")
        .matches(/^\d{4}\s\d{4}\s\d{4}\s\d{4}$/, "form.validation.invalidCardNumber"),
    expirationMonth: Yup
        .number("form.validation.number")
        .required("form.validation.required")
        .min(1, "form.validation.invalidMonth")
        .max(12, "form.validation.invalidMonth"),
    expirationYear: Yup
        .number("form.validation.number")
        .required("form.validation.required")
        .min(new Date().getFullYear(), "form.validation.invalidYear")
        .max(new Date().getFullYear() + 10, "form.validation.invalidYear"),
    holderName: Yup.string().required("form.validation.required"),
    country: Yup.string("form.validation.string")
        .required("form.validation.required"),
});
