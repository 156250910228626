import React, { useEffect, useState } from "react";
import Header from "../../header";
import Footer from "../../footer";
import {
    Course10,
    Course11,
    Course12,
    Course13,
    Course14,
    Icon1,
    InstructorProfileBg,
    TimerStart,
    User15,
    Icon22
} from "../../imagepath";
import { Search } from "react-feather";
import { Link } from "react-router-dom";
import InstructorSidebar from "../sidebar";
import Select from "react-select";
import PageChange from "../../page-change";
import { useTranslation } from 'react-i18next';
import { useActions, useAppState } from "../../../presenter";
import { formatDate } from "../../../utils/date";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

export default function InstructorPodcast() {
    const { t } = useTranslation();
    const { i18n } = useTranslation();
    const [podcasts, setPodcasts] = useState(null);
    const [value, setValue] = useState(null);
    const { loading, loadingBtn } = useAppState();
    const { listPodcasts, deletePodcast } = useActions();
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(6);
    const [pagesNum, setPagesNum] = useState([]);

    const [search, setSearch] = useState();

    useEffect(() => {
        getPodcasts();
    }, [currentPage, itemsPerPage , search]);

    const getPodcasts = () => {
        listPodcasts({ currentPage, limit: itemsPerPage, q: search }).then(data => {
            setPodcasts(data.podcasts);
            const arr = [];
            for (let i = 1; i <= data.meta.totalPages; i++) {
                arr.push(i);
            }
            setPagesNum(arr);
        });
    }

    const handleDeletePodcast = async (id) => {
        await deletePodcast(id);
        getPodcasts();
    }

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    if (!podcasts) return <PageChange />
    return (
        <div className="main-wrapper">
            <Header activeMenu={"Podcasts"} />
            {/* Page Wrapper */}
            <div className="page-content">
                <div className="container" style={{ marginTop: "70px" }}>
                    <div className="row">
                        {/* Sidebar */}
                        <InstructorSidebar activeMenu="Podcasts" />
                        {/* Sidebar */}

                        {/* Instructor Dashboard */}
                        <div className="col-xl-9 col-lg-8 col-md-12">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="settings-widget">
                                        <div className="settings-inner-blk p-0">
                                            <div className="sell-course-head comman-space">
                                                <h3>{t('podcasts')}</h3>
                                                {/* <p>
                                                    Manage your podcasts and its update like live, draft
                                                    and insight.
                                                </p> */}
                                            </div>
                                            <div className="comman-space pb-0">
                                                <div className="instruct-search-blk">
                                                    <div className="show-filter choose-search-blk">
                                                        <form action="#">
                                                            <div className="row gx-2 align-items-center">
                                                                <div className="col-md-6 col-item">
                                                                    <div className=" search-group">
                                                                        <Search
                                                                            size={16}
                                                                            style={{
                                                                                position: "absolute",
                                                                                marginInlineStart: "7px",
                                                                                color: "#1B75BB",
                                                                            }}
                                                                        />
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            placeholder={t('searchPodcasts')}
                                                                            onChange={()=> setSearch(event.target.value)}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6 col-lg-6 col-item">
                                                                    <div className="form-group select-form mb-0">
                                                                        <Link to="/admin/add-podcast" className="btn btn-primary" style={{ float: i18n.language == "en" ? "right" : "left", width: "150px" }}>
                                                                            {t('addPodcast')}
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                                <div className="settings-tickets-blk course-instruct-blk table-responsive">
                                                    {/* Referred Users */}
                                                    <table className="table table-nowrap mb-2">
                                                        <thead>
                                                            <tr>
                                                                <th>{t('podcasts')}</th>
                                                                {/* <th>STUDENTS</th> */}
                                                                <th style={{ textAlign: "center" }}>{t('actions')}</th>
                                                            </tr>
                                                        </thead>
                                                        {podcasts.length > 0 ? (
                                                            <tbody>
                                                                {podcasts && podcasts.map(podcast => (
                                                                    <tr key={podcast.id}>
                                                                        <td>
                                                                            <div className="sell-table-group d-flex align-items-center">
                                                                                <div className="sell-group-img">
                                                                                    <Link to={`/podcast-details/${podcast.id}`}>
                                                                                        <img
                                                                                            src={podcast.image?.url}
                                                                                            className="img-fluid "
                                                                                            alt=""
                                                                                            style={{ width: "271px", height: "100%" }}
                                                                                        />
                                                                                    </Link>
                                                                                </div>
                                                                                <div className="sell-tabel-info">
                                                                                    <p>
                                                                                        <Link to={`/podcast-details/${podcast.id}`}>
                                                                                            {podcast.title}
                                                                                        </Link>
                                                                                    </p>
                                                                                    <div className="course-info d-flex align-items-center border-bottom-0 pb-0">
                                                                                        <div className="rating-img d-flex align-items-center">
                                                                                            <img
                                                                                                className="img-fluid"
                                                                                                src={Icon22}
                                                                                                alt=""
                                                                                            />
                                                                                            {formatDate(podcast.createdAt)}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        {/* <td>3200</td> */}
                                                                        <td>
                                                                            <div style={{ display: "flex" }}>
                                                                                <Link to={`/admin/add-podcast?id=${podcast.id}`} className="badge info-inter" style={{ marginInlineEnd: "10px", color: "white" }}>{t('edit')}</Link>
                                                                                <Link className="badge info-high" style={{ color: "white" }}
                                                                                    onClick={() => {
                                                                                        Swal.fire({
                                                                                            title: t('deleteConfirmation'),
                                                                                            text: t('deleteWarning'),
                                                                                            icon: 'warning',
                                                                                            showCancelButton: true,
                                                                                            confirmButtonColor: '#3085d6',
                                                                                            cancelButtonColor: '#d33',
                                                                                            confirmButtonText: t('delete'),
                                                                                            cancelButtonText: t('cancel')
                                                                                        }).then((result) => {
                                                                                            if (result.isConfirmed) {
                                                                                                handleDeletePodcast(podcast.id);
                                                                                            }
                                                                                        });
                                                                                    }}>
                                                                                    {/* {loadingBtn && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>} */}
                                                                                    {t('delete')}
                                                                                </Link>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        ) : (
                                                            <tbody>
                                                                <tr>
                                                                    <td style={{ textAlign: "center" }} colSpan="2">{t("noPodcastsYet")}</td>
                                                                </tr>
                                                            </tbody>
                                                        )}
                                                    </table>
                                                    {/* Referred Users */}
                                                </div>

                                                {/* Podcast pagination */}
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <ul className="pagination lms-page" style={{ marginBottom: "20px" }}>
                                                            <li className={`"page-item prev" ${currentPage == 1 ? "disable-btn" : ""}`}>
                                                                <Link
                                                                    className="page-link"
                                                                    to="#"
                                                                    tabIndex={-1}
                                                                    onClick={() => setCurrentPage(prev => prev - 1)}
                                                                >
                                                                    <i className="fas fa-angle-left" />
                                                                </Link>
                                                            </li>

                                                            {pagesNum.map(number => (
                                                                <li key={number} className={`"page-item ${currentPage == 1 ? "first-page" : ""} ${currentPage == number ? "active" : ""}`}
                                                                >
                                                                    <Link onClick={() => handlePageChange(number)} className="page-link" to="#">
                                                                        {number}
                                                                    </Link>
                                                                </li>
                                                            ))}

                                                            <li className={`"page-item next" ${currentPage == pagesNum[pagesNum.length - 1] ? "disable-btn" : ""}`}>
                                                                <Link onClick={() => setCurrentPage(prev => prev + 1)} className="page-link" to="#">
                                                                    <i className="fas fa-angle-right" />
                                                                </Link>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                {/* /Podcast pagination */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Instructor Dashboard */}
                    </div>
                </div>
            </div>
            {/* Page Wrapper */}
            <Footer />
        </div>
    );
}
