import "regenerator-runtime/runtime";

import i18n from "../../locales/i18n";
import { toast } from "react-toastify";
import { handleError } from "../errors";
import { changePositionByLanguage } from "../../utils/toastify";

export const listVideos = async (context, { currentPage, limit, q }) => {
    try {
        context.state.loading = true;
        const result = await context.effects.listVideos(currentPage, limit, q);
        context.state.loading = false;
        return result;
    } catch (err) {
        context.state.loading = false;
        handleError(err);
    }
};

export const getVideo = async (context, id) => {
    try {
        context.state.loading = true;
        const result = await context.effects.getVideo(id);
        context.state.loading = false;
        return result;
    } catch (err) {
        context.state.loading = false;
        handleError(err);
    }
};

export const addVideo = async (context, data) => {
    try {
        context.state.loadingBtn = true;
        const result = await context.effects.addVideo(data);
        context.state.loadingBtn = false;

        toast.success(i18n.t("form.success.created"), {
            position: changePositionByLanguage(i18n.language),
        });
        return result;
    } catch (err) {
        context.state.loadingBtn = false;
        handleError(err);
    }
};

export const updateVideo = async (context, data) => {
    try {
        context.state.loadingBtn = true;
        const result = await context.effects.updateVideo(data);
        context.state.loadingBtn = false;

        toast.success(i18n.t("form.success.updated"), {
            position: changePositionByLanguage(i18n.language),
        });
        return result;
    } catch (err) {
        context.state.loadingBtn = false;
        handleError(err);
    }
};

export const deleteVideo = async (context, id) => {
    try {
        context.state.loadingBtn = true;
        const result = await context.effects.deleteVideo(id);
        context.state.loadingBtn = false;

        toast.success(i18n.t("form.success.deleted"), {
            position: changePositionByLanguage(i18n.language),
        });
        return result;
    } catch (err) {
        context.state.loadingBtn = false;
        handleError(err);
    }
};
