import { toHijri } from "hijri-converter";

export const formatDate = (isoDate) => {
  const date = new Date(isoDate);
  const month = date.toLocaleString("default", { month: "short" });
  const day = date.getDate();
  const year = date.getFullYear();

  return `${month} ${day}, ${year}`;
};

export function gregorianToHijri(gregorianDate) {
  const date = new Date(gregorianDate);
  const day = date.getUTCDate();
  const month = date.getUTCMonth() + 1;
  const year = date.getUTCFullYear();
  const hijri = toHijri(year, month, day);
  const hijriYear = hijri.hy;
  const hijriMonth = hijri.hm;
  const hijriDay = hijri.hd;

  return `${hijriYear}/${hijriMonth}/${hijriDay}`;
}
