import "regenerator-runtime/runtime";

import axios from "axios";
import {
  BadRequestError,
  ForbiddenError,
  InternalServerError,
  UnauthenticatedError,
  UnexpectedError,
} from "../errors";
import { apiClient } from "./constants";

const BASE_ROUTE = "/podcasts";

export const listPodcasts = async (currentPage, limit, q) => {
  try {

    let queryParams = currentPage ? `?page=${currentPage}&limit=${limit}` : "";
    if (q) queryParams += `&q=${q}`;
    const result = await apiClient.get(`${BASE_ROUTE}/listpodcasts${queryParams}`);
    return result.data;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      const responseData = err.response.data;

      if (err.response?.status == 400)
        throw new BadRequestError(responseData.message);
      if (err.response?.status == 401)
        throw new UnauthenticatedError(responseData.message);
      if (err.response?.status == 403)
        throw new ForbiddenError(responseData.message);
      if (err.response?.status == 404)
        throw new UnexpectedError(responseData.message);
      if (err.response?.status == 500)
        throw new InternalServerError(responseData.message);
    }
    throw new UnexpectedError();
  }
};

export const getPodcast = async (id) => {
  try {
    const result = await apiClient.get(`${BASE_ROUTE}/${id}`);
    return result.data;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      const responseData = err.response.data;

      if (err.response?.status == 400)
        throw new BadRequestError(responseData.message);
      if (err.response?.status == 401)
        throw new UnauthenticatedError(responseData.message);
      if (err.response?.status == 403)
        throw new ForbiddenError(responseData.message);
      if (err.response?.status == 404)
        throw new UnexpectedError(responseData.message);
      if (err.response?.status == 500)
        throw new InternalServerError(responseData.message);
    }
    throw new UnexpectedError();
  }
};

export const addPodcast = async (data) => {
  try {
    const result = await apiClient.post(`${BASE_ROUTE}/create`, data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return result.data;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      const responseData = err.response.data;

      if (err.response?.status == 400)
        throw new BadRequestError(responseData.message);
      if (err.response?.status == 401)
        throw new UnauthenticatedError(responseData.message);
      if (err.response?.status == 403)
        throw new ForbiddenError(responseData.message);
      if (err.response?.status == 404)
        throw new UnexpectedError(responseData.message);
      if (err.response?.status == 500)
        throw new InternalServerError(responseData.message);
    }
    throw new UnexpectedError();
  }
};

export const updatePodcast = async (data) => {
  try {
    const result = await apiClient.put(`${BASE_ROUTE}/${data.id}`, data.data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return result.data;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      const responseData = err.response.data;

      if (err.response?.status == 400)
        throw new BadRequestError(responseData.message);
      if (err.response?.status == 401)
        throw new UnauthenticatedError(responseData.message);
      if (err.response?.status == 403)
        throw new ForbiddenError(responseData.message);
      if (err.response?.status == 404)
        throw new UnexpectedError(responseData.message);
      if (err.response?.status == 500)
        throw new InternalServerError(responseData.message);
    }
    throw new UnexpectedError();
  }
};

export const deletePodcast = async (id) => {
  try {
    const result = await apiClient.delete(`${BASE_ROUTE}/${id}`);
    return result.data;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      const responseData = err.response.data;

      if (err.response?.status == 400)
        throw new BadRequestError(responseData.message);
      if (err.response?.status == 401)
        throw new UnauthenticatedError(responseData.message);
      if (err.response?.status == 403)
        throw new ForbiddenError(responseData.message);
      if (err.response?.status == 404)
        throw new UnexpectedError(responseData.message);
      if (err.response?.status == 500)
        throw new InternalServerError(responseData.message);
    }
    throw new UnexpectedError();
  }
};
