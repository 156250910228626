import React, { useEffect, useState } from "react";
import { logo, SaudiArabiaLogo, UnitedKingdomLogo } from "../../../imagepath";
import backgroundlogo from "../../../../assets/img/unistep-logo.png";
import vec from "../../../../assets/img/Layer 1.svg";
import { useParams } from "react-router-dom";
import { useAppState, useActions } from "../../../../presenter";
import vector from "../../../../assets/img/coverbackground.png";
import { gregorianToHijri } from "../../../../utils/date";
import PageChange from "../../../page-change";

function Certificate() {
  const queryParams = new URLSearchParams(location.search);
  const language = queryParams.get("language");
  const courseId = queryParams.get("courseId");
  const [date, setDate] = useState();
  const backgroundStyles = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: "-1",
    opacity: "0.75", // Adjust the opacity value as needed (0.5 means 50% opacity)
  };
  const a4PageStyles = {
    width: "794px", // Approximately 210mm converted to pixels
    height: "1123px", // Approximately 297mm converted to pixels
    margin: "0 auto", // Center the container horizontally
    position: "relative", // Add positioning for background image
    display: "flex",
    flexDirection: "column",
    justifyContent: "center", // Center vertically
  };
  const { getCertificateDate } = useActions();
  const fetchCertificateDate = async () => {
    const date = await getCertificateDate({ language, courseId });
    setDate(date);
  };
  useEffect(() => {
    fetchCertificateDate();
    setTimeout(() => {
      window.print();
    }, 1000);
  }, []);

  const centerStyle = {
    display: "flex",
    justifyContent: "center",
  };
  const { user } = useAppState();
  if (!date) <PageChange />;
  return (
    <div>
      <div style={a4PageStyles} className="shadow-lg mt-5">
        <style>
          {`
          @page {
            size: A4;
            margin: 0;
          }

          body {
            width: 230mm; /* Custom width */
            height: 297mm; /* A4 height */
            margin: 0 auto; /* Center horizontally */
          }
        `}
        </style>
        <div style={backgroundStyles}>
          <img src={backgroundlogo} className="opacity-25" />
        </div>
        <div
          className="main-wrapper"
          style={{ display: "flex", justifyContent: "end" }}>
          <div className="navbar-header py-5 px-10">
            <img src={logo} className="img-fluid" alt="Logo" width={"70%"} />
          </div>
        </div>
        <div>
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                gap: "30px",
                alignItems: "center",
              }}>
              <img width={40} src={vec} />
              <h6 className="certified-font py-3 " style={{ fontSize: "80px" }}>
                شهادة
              </h6>
              <img width={40} src={vec} />
            </div>
            <div style={centerStyle}>
              <h1>إتمام دورة تدريبية</h1>
            </div>

            <div className="px-5">
              <hr style={{ opacity: "1", borderTop: "2px solid black" }} />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                paddingTop: "40px",
              }}>
              <div>
                <h3>
                  تشهد أكاديميــــة{" "}
                  <span className="text-primary" style={{ fontWeight: "bold" }}>
                    مشــــروع العمـــر
                  </span>
                </h3>
              </div>
            </div>

            <div className="mt-3 px-4">
              <div style={centerStyle}>
                <h3>
                  بأن المشارك /{"\t" + user.firstName + " " + user.lastName}
                </h3>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}>
                <hr
                  style={{
                    opacity: "1",
                    borderTop: "2px solid black",
                    width: "50%",
                  }}
                />
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center ">
            <div>
              <h3>
                قد شارك في دورة تدريبية بعنوان{" "}
                <span className="text-primary" style={{ fontWeight: "bold" }}>
                  (مشروع العمر)
                </span>{" "}
                بنجاح
              </h3>
            </div>
          </div>
          <div style={centerStyle} className="py-2">
            <h3>
              التي قدمها الدكتور/{" "}
              <span style={{ fontWeight: "bold" }}>مشعل الفلاحي</span>
            </h3>
          </div>
          <div className="py-2" style={centerStyle}>
            <h4>والتي أتمها في تاريخ {gregorianToHijri(date)} هـ</h4>
          </div>
          <div className="pt-3" style={centerStyle}>
            <h4>
              تم تصميم هذه الدورة بغرض تزويـــــد المشــــاركين بالمعرفـــة
              والمهارات
            </h4>
          </div>
          <div style={centerStyle}>
            <h4>
              اللازمة لتطوير وإدارة مشروع حياتهم الشخصية بشكل فعال ومستدام
            </h4>
          </div>
        </div>
        <div style={centerStyle} className="pt-5">
          <h4>مع تمنياتنا له بمستقبل مشرق ومليئًا بالنجاح </h4>
        </div>
        <div style={{ marginTop: "auto" }} className="px-5 py-4">
          <div style={{ display: "flex", justifyContent: "end" }}>
            <hr
              style={{
                opacity: "1",
                borderTop: "2px solid black",
                width: "20%",
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "end",
            }}>
            <h4 style={{ justifyContent: "center" }}>د. مشعل الفلاحي</h4>
          </div>
        </div>
        <img src={vector} style={{ position: "absolute", bottom: "0" }} />
      </div>
    </div>
  );
}

export default Certificate;
