import React, { useEffect } from "react";
import { useState } from "react";
import { Chapter, Play, Timer2, Users, Video2 } from "../../../imagepath";
import { Link, useParams } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import { useActions, useAppState } from "../../../../presenter";
import { useTranslation } from "react-i18next";
import defaultCourseImg from "../../../../assets/img/defaultCourseImg.png";
import { useNavigate } from "react-router-dom";
import { translateTime } from "../../../../utils/time";
import { GoSell } from "@tap-payments/gosell";
import PaymentComponent from "../../../payment";

const DetailsContent = ({ survey }) => {
  const queryParams = new URLSearchParams(location.search);
  const { t } = useTranslation();
  const { id } = useParams();
  const [isEnrolledButton, setisEnrolledButton] = useState(false);
  const { enrollSurvey, isUserEnrolledSurvey, payment } = useActions();

  const { user } = useAppState();
  const navigate = useNavigate();
  const isEnrolledHandler = async () => {
    const result = await isUserEnrolledSurvey({ id });
    setisEnrolledButton(result.isEnrolled);
  };
  useEffect(() => {
    if (user) {
      isEnrolledHandler();
      // GoSell.showResult({
      //   callback: async (response) => {
      //     const result = await payment({
      //       productType: "Survey",
      //       productId: id,
      //       chargeId: response.callback.id,
      //     });

      //     if (result) {
      //       setIsEnrolledButton(true);
      //     }
      //   },
      // });
    }
  }, [isEnrolledButton]);

  const handlEnrollSurvey = async () => {
    if (!user) {
      navigate("/login?notLogged=true");
      return;
    }
    if (isEnrolledButton) {
      navigate(`/survey/${id}`);
      return;
    }
    // GoSell.openLightBox();
    await enrollSurvey({ id });
    setIsEnrolledButton(true);
  };

  return (
    <>
      <section className="page-content course-sec">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              {/* Overview */}
              <div className="card overview-sec">
                <div className="card-body">{survey.description}</div>
              </div>

              {/* /Course Content */}
            </div>
            <div className="col-lg-4">
              <div className="sidebar-sec">
                {/* Video */}
                <div className="video-sec vid-bg">
                  <div className="card">
                    <div className="card-body">
                      <img
                        className=""
                        src={survey?.image?.url ?? defaultCourseImg}
                        alt=""
                      />
                      {/* </Link> */}
                      <div className="video-details">
                        <div className="course-fee">
                          <h2 className="price-h3">
                            {survey?.price} {t("currency")}
                          </h2>
                        </div>

                        <div className="row gx-2">
                          {user && <div className="col-md-6 addHeart"></div>}
                        </div>

                        <div
                          onClick={() => {
                            {
                              handlEnrollSurvey();
                            }
                          }}
                          className="btn btn-enroll w-100">
                          {user && isEnrolledButton
                            ? t("courseDetails.continueSurveyLearning")
                            : t("courseDetails.enrollNow")}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* {user && (
              <PaymentComponent
                order={{
                  itemId: survey.id,
                  name: `${t("survey")}: ${survey.title}`,
                  description: survey.title,
                  quantity: "1",
                  amount: parseFloat(survey.price),
                }}
                redirectUrl={window.location.href}
              />
            )} */}
          </div>
        </div>
      </section>
    </>
  );
};

export default DetailsContent;
