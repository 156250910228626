import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Footer from "../../../footer";
import Header from "../../../header";
import { InstructorProfileBg } from "../../../imagepath";
import { VideoValidator } from "../../../../validators/video.validator";
import { useForm } from "react-hook-form";
import { useActions, useAppState } from "../../../../presenter";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { avatarValidator } from "./../../../../utils/avatar-validation";

const AddVideo = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");
  const { loadingBtn } = useAppState();
  const [imgFile, setImgFile] = useState(null);
  const [file, setFile] = useState(null);
  const navigate = useNavigate();
  const [error, setError] = useState(null);

  const { getVideo, addVideo, updateVideo } = useActions();
  console.log(error);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(VideoValidator),
    defaultValues: {
      title: "",
      duration: "",
      isPinned: false,
      videoLink: "",
      fileAttachment: null,
    },
  });

  const goBack = () => navigate("/admin/videos");

  useEffect(() => {
    if (id) {
      getVideo(id).then((data) => {
        setValue("title", data.title);
        setValue("duration", data.duration);
        setValue("videoLink", data.videoLink);
        setValue("isPinned", data.isPinned);

        setImgFile(data.image.url);
      });
    }
  }, [id, setValue]);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    setFile(file);
    const reader = new FileReader();
    const errorMessege = avatarValidator(file);
    if (errorMessege) {
      setError(errorMessege);
      return;
    }
    setError("");

    reader.onloadend = async () => {
      setImgFile(reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const onSubmit = async (data) => {
    if (!imgFile) {
      return;
    }
    const formData = new FormData();

    formData.append("title", data.title);
    formData.append("duration", data.duration);
    formData.append("videoLink", data.videoLink);
    formData.append("isPinned", data.isPinned);
    if (data.fileAttachment) formData.append("file", file);

    const result = id
      ? await updateVideo({ id, data: formData })
      : await addVideo(formData);
    if (result) goBack();
  };

  return (
    <>
      <div className="main-wrapper">
        <Header />
        <div className="page-content">
          <div className="container" style={{ marginTop: "70px" }}>
            <div className="row">
              {/* Profile Details */}
              <div className="settings-widget profile-details">
                <div className="settings-menu p-0">
                  <div className="profile-heading">
                    <h3>{id ? t("updateVideo") : t("addVideo")}</h3>
                  </div>
                  <div className="checkout-form personal-address add-course-info">
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>{t("form.title")}</label>
                            <input
                              className="form-control"
                              {...register("title")}
                            />
                            {errors.title && (
                              <p className="error">{t(errors.title.message)}</p>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>{t("form.duration")}</label>
                            <input
                              className="form-control"
                              {...register("duration")}
                            />
                            {errors.duration && (
                              <p className="error">
                                {t(errors.duration.message)}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>{t("form.videoLink")}</label>
                            <input
                              className="form-control"
                              {...register("videoLink")}
                            />
                            {errors.videoLink && (
                              <p className="error">
                                {t(errors.videoLink.message)}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>
                              {t("form.image")}
                              <p className="invalid-feedback d-block">
                                {t(error)}
                              </p>
                            </label>
                            {!imgFile && (
                              <input
                                type="file"
                                className="form-control"
                                {...register("fileAttachment")}
                                onChange={handleFileUpload}
                              />
                            )}{" "}
                            <small className="form-text text-muted">
                              {t("form.recommendedDimensions", {
                                dimensions: "500 * 278",
                              })}
                            </small>
                            {errors.fileAttachment && (
                              <span className="text-danger">
                                {t(errors.fileAttachment.message)}
                              </span>
                            )}
                            {imgFile && (
                              <div
                                style={{
                                  position: "relative",
                                  width: "100px",
                                }}>
                                <img src={imgFile} style={{ width: "100%" }} />
                                {imgFile && (
                                  <button
                                    style={{
                                      position: "absolute",
                                      top: "0",
                                      right: "0",
                                      background: "none",
                                      border: "none",
                                      color: "#fff",
                                      fontSize: "20px",
                                      cursor: "pointer",
                                      padding: "5px",
                                      zIndex: 1,
                                    }}
                                    type="button"
                                    onClick={() => setImgFile(null)}>
                                    &times;
                                  </button>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group">
                            <div className="form-check">
                              <input
                                className="form-check-input big"
                                type="checkbox"
                                name="isSubscribed"
                                {...register("isPinned")}
                              />
                              <label className="form-check-label">
                                {t("form.isPinned")}
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="update-profile un-subscribe">
                          <button
                            type="submit"
                            className="btn btn-primary"
                            disabled={loadingBtn}>
                            {loadingBtn && (
                              <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"></span>
                            )}
                            {id ? t("updateVideo") : t("addVideo")}
                          </button>

                          <button
                            type="button"
                            onClick={goBack}
                            className="btn btn-danger">
                            {t("cancel")}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

              {/* Profile Details */}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default AddVideo;
