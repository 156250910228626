export { default as headlogo } from "../assets/img/logo.png";
export { default as logo } from "../assets/img/logo.svg";
export { default as logoPng } from "../assets/img/logo/logo.png";
export { default as logoSvg } from "../assets/img/logo/logo.svg";
export { default as logoFivePng } from "../assets/img/logo/logo-five.png";
export { default as logoFiveSvg } from "../assets/img/logo/logo-five.svg";
export { default as Share } from "../assets/img/share.png";
export { default as PencilIcon } from "../assets/img/pencil-icon.svg";
export { default as CourseIcon } from "../assets/img/cources-icon.svg";
export { default as CertificateIcon } from "../assets/img/certificate-icon.svg";
export { default as GratuateIcon } from "../assets/img/gratuate-icon.svg";

export { default as Lead1 } from "../assets/img/lead-01.png";
export { default as Lead2 } from "../assets/img/lead-02.png";
export { default as Lead3 } from "../assets/img/lead-03.png";
export { default as Lead4 } from "../assets/img/lead-04.png";
export { default as Lead5 } from "../assets/img/lead-05.png";
export { default as Lead6 } from "../assets/img/lead-06.png";

// Testimonials

export { default as Qute } from "../assets/img/qute.png";
export { default as Qute01 } from "../assets/img/qute-01.png";

export { default as Testimonial01 } from "../assets/img/testimonial/testimonial-01.png";
export { default as Testimonial02 } from "../assets/img/testimonial/testimonial-02.png";
export { default as Testimonial03 } from "../assets/img/testimonial/testimonial-03.png";
// home
export { default as bannerimg } from "../assets/img/male-student-img.png";
export { default as bannerimg1 } from "../assets/img/BG-Lifeproject2.png";
export { default as CategoryIcon } from "../assets/img/categories-icon.png";
export { default as CategoryIcon1 } from "../assets/img/categories-icon-01.png";
export { default as CategoryIcon2 } from "../assets/img/categories-icon-02.png";
export { default as CategoryIcon3 } from "../assets/img/categories-icon-03.png";
export { default as CategoryIcon4 } from "../assets/img/categories-icon-04.png";
export { default as CategoryIcon5 } from "../assets/img/categories-icon-05.png";

export { default as HomeMain } from "../assets/img/bg/home-main.png";

export { default as CourseArrowBg } from "../assets/img/course-bg.png";

export { default as CountOne } from "../assets/img/icon/count-one.svg";
export { default as CountTwo } from "../assets/img/icon/count-two.svg";
export { default as CountThree } from "../assets/img/icon/count-three.svg";
export { default as CountFour } from "../assets/img/icon/count-four.svg";
export { default as BuildingSvg } from "../assets/img/icon-three/building.svg";

export { default as MasterVectorLeft } from "../assets/img/bg/master-vector-left.svg";

// Home 2
export { default as shape1 } from "../assets/img/bg/home-right.png";
export { default as shape2 } from "../assets/img/bg/home-right-bottom.png";
export { default as shape3 } from "../assets/img/bg/home-middle.png";
export { default as shape4 } from "../assets/img/bg/home-left.png";

export { default as Category1 } from "../assets/img/category/category-1.svg";
export { default as Category2 } from "../assets/img/category/category-2.svg";
export { default as Category3 } from "../assets/img/category/category-3.svg";
export { default as Category4 } from "../assets/img/category/category-4.svg";
export { default as Category5 } from "../assets/img/category/category-5.svg";
export { default as Category6 } from "../assets/img/category/category-6.svg";
export { default as Category7 } from "../assets/img/category/category-7.svg";
export { default as Category8 } from "../assets/img/category/category-8.svg";

export { default as Pattern1 } from "../assets/img/bg/patter-one.png";

export { default as Courses1 } from "../assets/img/course/courses-01.jpg";
export { default as Courses2 } from "../assets/img/course/courses-02.jpg";
export { default as Courses3 } from "../assets/img/course/courses-03.jpg";
export { default as Courses4 } from "../assets/img/course/courses-04.jpg";
export { default as Courses5 } from "../assets/img/course/courses-05.jpg";
export { default as Courses6 } from "../assets/img/course/courses-06.jpg";

export { default as Avatar1 } from "../assets/img/profiles/avatar-01.jpg";
export { default as Avatar2 } from "../assets/img/profiles/avatar-02.jpg";
export { default as Avatar3 } from "../assets/img/profiles/avatar-03.jpg";
export { default as Avatar4 } from "../assets/img/profiles/avatar-04.jpg";
export { default as Avatar5 } from "../assets/img/profiles/avatar-05.jpg";
export { default as Avatar6 } from "../assets/img/profiles/avatar-06.jpg";
export { default as Avatar7 } from "../assets/img/profiles/avatar-07.jpg";
export { default as Avatar8 } from "../assets/img/profiles/avatar-08.jpg";
export { default as Avatar9 } from "../assets/img/profiles/avatar-09.jpg";
export { default as Avatar10 } from "../assets/img/profiles/avatar-10.jpg";

export { default as HomeRightBottom } from "../assets/img/bg/home-right-bottom.png";

export { default as Skill1 } from "../assets/img/skil-01.png";

// Home 3

export { default as HomeThreeBanner } from "../assets/img/slider/home-three-banner.jpg";

export { default as HomeSlider } from "../assets/img/slider/home-slider.png";

export { default as Slider01 } from "../assets/img/slider/slider-01.png";
export { default as Slider02 } from "../assets/img/slider/slider-02.png";

export { default as BannerVector } from "../assets/img/bg/banner-vector.svg";

export { default as Course01Icon } from "../assets/img/icon-three/course-01.svg";
export { default as Course02Icon } from "../assets/img/icon-three/course-02.svg";
export { default as Course03Icon } from "../assets/img/icon-three/course-03.svg";
export { default as Course04Icon } from "../assets/img/icon-three/course-04.svg";

export { default as Pattern01 } from "../assets/img/bg/pattern-01.png";
export { default as Pattern02 } from "../assets/img/bg/pattern-02.png";
export { default as Pattern03 } from "../assets/img/bg/pattern-03.png";
export { default as Pattern04 } from "../assets/img/bg/pattern-04.png";

export { default as PatternSvg5 } from "../assets/img/bg/pattern-05.svg";
export { default as PatternSvg6 } from "../assets/img/bg/pattern-06.svg";

export { default as Career } from "../assets/img/students/career.png";
export { default as Career01 } from "../assets/img/icon-three/career-01.svg";
export { default as Career02 } from "../assets/img/icon-three/career-02.svg";
export { default as Career03 } from "../assets/img/icon-three/career-03.svg";
export { default as Career04 } from "../assets/img/icon-three/career-04.svg";

export { default as Ellipse1 } from "../assets/img/icon-three/ellipse-1.svg";
export { default as Ellipse2 } from "../assets/img/icon-three/ellipse-2.svg";
export { default as Ellipse3 } from "../assets/img/icon-three/ellipse-3.svg";

export { default as Shape01 } from "../assets/img/bg/shape-1.png";
export { default as Shape02 } from "../assets/img/bg/shape-2.png";

export { default as Award01 } from "../assets/img/students/award-01.png";
export { default as AwardSvg } from "../assets/img/icon-three/award.svg";
export { default as AwardNew } from "../assets/img/icon/award-new.svg";
export { default as CheckRound1 } from "../assets/img/icon-three/check-round-1.svg";
export { default as CheckRound2 } from "../assets/img/icon-three/check-round-2.svg";
export { default as CheckRound3 } from "../assets/img/icon-three/check-round-3.svg";

export { default as Gallery1 } from "../assets/img/gallery/gallery-1.jpg";
export { default as Gallery2 } from "../assets/img/gallery/gallery-2.jpg";
export { default as Gallery3 } from "../assets/img/gallery/gallery-3.jpg";
export { default as Gallery4 } from "../assets/img/gallery/gallery-4.jpg";
export { default as Gallery5 } from "../assets/img/gallery/gallery-5.jpg";
export { default as Gallery6 } from "../assets/img/gallery/gallery-6.jpg";
export { default as Gallery7 } from "../assets/img/gallery/gallery-7.jpg";
export { default as Gallery8 } from "../assets/img/gallery/gallery-8.jpg";

export { default as Quote } from "../assets/img/bg/quote.svg";

export { default as Time } from "../assets/img/icon-three/time.svg";

export { default as LearnAnything } from "../assets/img/students/learn-anything.png";

export { default as Certification } from "../assets/img/students/certification.png";

export { default as Client01 } from "../assets/img/client/client-01.svg";
export { default as Client02 } from "../assets/img/client/client-02.svg";
export { default as Client03 } from "../assets/img/client/client-03.svg";
export { default as Client04 } from "../assets/img/client/client-04.svg";
export { default as Client05 } from "../assets/img/client/client-05.svg";

export { default as FeaturedCourse1 } from "../assets/img/course/featured-course-01.jpg";
export { default as FeaturedCourse2 } from "../assets/img/course/featured-course-02.jpg";
export { default as FeaturedCourse3 } from "../assets/img/course/featured-course-03.jpg";
export { default as FeaturedCourse4 } from "../assets/img/course/featured-course-04.jpg";
export { default as FeaturedCourse5 } from "../assets/img/course/featured-course-05.jpg";
export { default as FeaturedCourse6 } from "../assets/img/course/featured-course-06.jpg";

export { default as Profile01 } from "../assets/img/profiles/profile-01.png";
export { default as Profile02 } from "../assets/img/profiles/profile-02.png";
export { default as Profile03 } from "../assets/img/profiles/profile-03.png";
export { default as Profile04 } from "../assets/img/profiles/profile-04.png";
export { default as Profile05 } from "../assets/img/profiles/profile-05.png";
export { default as Profile06 } from "../assets/img/profiles/profile-06.png";
export { default as Profile07 } from "../assets/img/profiles/profile-07.png";
export { default as Profile08 } from "../assets/img/profiles/profile-08.png";
export { default as Profile09 } from "../assets/img/profiles/profile-09.png";
export { default as Profile10 } from "../assets/img/profiles/profile-10.png";
export { default as Profile11 } from "../assets/img/profiles/profile-11.png";
export { default as Profile12 } from "../assets/img/profiles/profile-12.png";
export { default as Profile13 } from "../assets/img/profiles/profile-13.png";
export { default as Profile14 } from "../assets/img/profiles/profile-14.png";
export { default as Profile15 } from "../assets/img/profiles/profile-15.png";
export { default as Profile16 } from "../assets/img/profiles/profile-16.png";

export { default as Education } from "../assets/img/education.png";

export { default as EmailSvg } from "../assets/img/icon-three/email.svg";

// Home 3 Student
export { default as StudentIcon } from "../assets/img/icon-three/student.svg";
export { default as StudentIconThree } from "../assets/img/icon-three/student-icon.svg";

export { default as Winning } from "../assets/img/icon-three/winning.svg";

// footer

export { default as FooterLeft } from "../assets/img/bg/footer-left.svg";
export { default as FooterRight } from "../assets/img/bg/footer-right.svg";

// Instructor
export { default as Messages } from "../assets/img/icon/messages.svg";
export { default as MessagesText } from "../assets/img/icon/message-text.svg";
export { default as Cart } from "../assets/img/icon/cart.svg";
export { default as Wish } from "../assets/img/icon/wish.svg";
export { default as Notification } from "../assets/img/icon/notification.svg";
export { default as ProfileAvatar } from "../assets/img/instructor/profile-avatar.jpg";
export { default as defaultImg } from "../assets/img/default-img.png";

export { default as InstructorProfileBg } from "../assets/img/instructor-profile-bg.jpg";

export { default as UserIconSvg } from "../assets/img/icon/user-icon.svg";
export { default as lang } from "../assets/img/icon/language.png";

export { default as TimerStart } from "../assets/img/icon/timer-start.svg";
export { default as InstructorBgBanner } from "../assets/img/instructor/instructor-bg-banner.png";

export { default as TicketClose } from "../assets/img/students/ticket-close.svg";
export { default as TicketOpen } from "../assets/img/students/ticket-open.svg";
export { default as TicketTotal } from "../assets/img/students/ticket-total.svg";

export { default as PhoneIcon } from "../assets/img/instructor/phone-icon.png";
export { default as AddressIcon } from "../assets/img/instructor/address-icon.png";
export { default as EmailIcon } from "../assets/img/instructor/email-icon.png";
export { default as ReviewIcon } from "../assets/img/instructor/review-icon.png";
export { default as TtlStudIcon } from "../assets/img/instructor/ttl-stud-icon.png";
export { default as CoursesIcon } from "../assets/img/instructor/courses-icon.png";

export { default as NetIcon01 } from "../assets/img/net-icon-01.png";
export { default as NetIcon02 } from "../assets/img/net-icon-02.png";
export { default as NetIcon03 } from "../assets/img/net-icon-03.png";
export { default as NetIcon04 } from "../assets/img/net-icon-04.png";

export { default as ReferImage1 } from "../assets/img/students/refer-img1.png";
export { default as ReferImage2 } from "../assets/img/students/refer-img2.png";
export { default as ReferImage3 } from "../assets/img/students/refer-img3.png";
export { default as ReferImage4 } from "../assets/img/students/refer-img4.png";
export { default as ReferImage5 } from "../assets/img/students/refer-img5.png";
export { default as ReferImage6 } from "../assets/img/students/refer-img6.png";
export { default as ReferImage7 } from "../assets/img/students/refer-img7.png";
export { default as ReferImage8 } from "../assets/img/students/refer-img8.png";
export { default as ReferImage9 } from "../assets/img/students/refer-img9.png";
export { default as ReferImage10 } from "../assets/img/students/refer-img10.png";

export { default as ProfileUser } from "../assets/img/students/profile-user.svg";

export { default as BookSvg } from "../assets/img/students/book.svg";
export { default as ReceiptText } from "../assets/img/students/receipt-text.svg";
export { default as EmptyWalletTick } from "../assets/img/students/empty-wallet-tick.svg";
export { default as EmptyWalletChange } from "../assets/img/students/empty-wallet-change.svg";

// Student
export { default as ProfileBg } from "../assets/img/profile-bg.jpg";

export { default as BankImage } from "../assets/img/bank-image.png";

export { default as StudentProfileAvatar } from "../assets/img/students/profile-avatar.png";

export { default as LocIcon } from "../assets/img/students/loc-icon.svg";

export { default as StudentBgBanner } from "../assets/img/students/student-bg-banner.png";

export { default as Student01 } from "../assets/img/students/student-01.jpg";
export { default as Student02 } from "../assets/img/students/student-02.jpg";
export { default as Student03 } from "../assets/img/students/student-03.jpg";
export { default as Student04 } from "../assets/img/students/student-04.jpg";
export { default as Student05 } from "../assets/img/students/student-05.jpg";
export { default as Student06 } from "../assets/img/students/student-06.jpg";
export { default as Student07 } from "../assets/img/students/student-07.jpg";
export { default as Student08 } from "../assets/img/students/student-08.jpg";
export { default as Student09 } from "../assets/img/students/student-09.jpg";
export { default as Student10 } from "../assets/img/students/student-10.jpg";
export { default as Student11 } from "../assets/img/students/student-11.jpg";
export { default as Student12 } from "../assets/img/students/student-12.jpg";

// Course
export { default as Course1 } from "../assets/img/course/course-01.jpg";
export { default as Course2 } from "../assets/img/course/course-02.jpg";
export { default as Course3 } from "../assets/img/course/course-03.jpg";
export { default as Course4 } from "../assets/img/course/course-04.jpg";
export { default as Course5 } from "../assets/img/course/course-05.jpg";
export { default as Course6 } from "../assets/img/course/course-06.jpg";
export { default as Course7 } from "../assets/img/course/course-07.jpg";
export { default as Course8 } from "../assets/img/course/course-08.jpg";
export { default as Course9 } from "../assets/img/course/course-09.jpg";
export { default as Course10 } from "../assets/img/course/course-10.jpg";
export { default as Course11 } from "../assets/img/course/course-11.jpg";
export { default as Course12 } from "../assets/img/course/course-12.jpg";
export { default as Course13 } from "../assets/img/course/course-13.jpg";
export { default as Course14 } from "../assets/img/course/course-14.jpg";
export { default as Course15 } from "../assets/img/course/course-15.jpg";
export { default as Course16 } from "../assets/img/course/course-16.jpg";
export { default as Course17 } from "../assets/img/course/course-17.jpg";

export { default as Course20 } from "../assets/img/course/course-20.jpg";
export { default as Course21 } from "../assets/img/course/course-21.jpg";
export { default as Course22 } from "../assets/img/course/course-22.jpg";
export { default as Course23 } from "../assets/img/course/course-23.jpg";
export { default as Course24 } from "../assets/img/course/course-24.jpg";
export { default as Course25 } from "../assets/img/course/course-25.jpg";
export { default as Course26 } from "../assets/img/course/course-26.jpg";
export { default as Course27 } from "../assets/img/course/course-27.jpg";

export { default as CourseSvg1 } from "../assets/img/course/course-01.svg";
export { default as CourseSvg2 } from "../assets/img/course/course-02.svg";
export { default as CourseSvg3 } from "../assets/img/course/course-03.svg";
export { default as CourseSvg4 } from "../assets/img/course/course-04.svg";

export { default as Skills01 } from "../assets/img/skills/skills-01.jpg";
export { default as SkillsIcon01 } from "../assets/img/bg/skill-icon-01.png";
export { default as Skills02 } from "../assets/img/skills/skills-02.jpg";
export { default as SkillsIcon02 } from "../assets/img/bg/skill-icon-02.png";
export { default as Skills03 } from "../assets/img/skills/skills-03.jpg";
export { default as SkillsIcon03 } from "../assets/img/bg/skill-icon-03.png";

export { default as SkillsSvg1 } from "../assets/img/skills/skills-01.svg";
export { default as SkillsSvg2 } from "../assets/img/skills/skills-02.svg";
export { default as SkillsSvg3 } from "../assets/img/skills/skills-03.svg";
export { default as SkillsSvg4 } from "../assets/img/skills/skills-04.svg";

export { default as MapUser } from "../assets/img/bg/map-user.png";

export { default as Skill01 } from "../assets/img/skills/skill-01.jpg";
export { default as Skill02 } from "../assets/img/skills/skill-02.jpg";
export { default as Skill03 } from "../assets/img/skills/skill-03.jpg";
export { default as Skill04 } from "../assets/img/skills/skill-04.jpg";

export { default as Joing01 } from "../assets/img/icon/joing-01.svg";
export { default as Joing02 } from "../assets/img/icon/joing-02.svg";
export { default as Joing03 } from "../assets/img/icon/joing-03.svg";
export { default as Joing04 } from "../assets/img/icon/joing-04.svg";

export { default as Joingus } from "../assets/img/joing-us.png";
export { default as JoingusSkill } from "../assets/img/joing-us-skill.png";

export { default as Instructor01 } from "../assets/img/instructor/instructor-01.jpg";
export { default as Instructor02 } from "../assets/img/instructor/instructor-02.jpg";
export { default as Instructor03 } from "../assets/img/instructor/instructor-03.jpg";
export { default as Instructor04 } from "../assets/img/instructor/instructor-04.jpg";

export { default as InstructorVectorLeft } from "../assets/img/bg/instructor-vector-left.svg";
export { default as InstructorVectorRight } from "../assets/img/bg/instructor-vector-right.svg";

export { default as ReviewsImgTwo } from "../assets/img/reviews-img-two.png";

// User
export { default as User } from "../assets/img/user/user.jpg";
export { default as User1 } from "../assets/img/user/user1.jpg";
export { default as User2 } from "../assets/img/user/user2.jpg";
export { default as User3 } from "../assets/img/user/user3.jpg";
export { default as User4 } from "../assets/img/user/user4.jpg";
export { default as User5 } from "../assets/img/user/user5.jpg";
export { default as User6 } from "../assets/img/user/user6.jpg";
export { default as User7 } from "../assets/img/user/user7.jpg";
export { default as User8 } from "../assets/img/user/user8.jpg";
export { default as User9 } from "../assets/img/user/user9.jpg";
export { default as User10 } from "../assets/img/user/user10.jpg";
export { default as User11 } from "../assets/img/user/user11.jpg";
export { default as User12 } from "../assets/img/user/user12.jpg";
export { default as User13 } from "../assets/img/user/user13.jpg";
export { default as User14 } from "../assets/img/user/user14.jpg";
export { default as User15 } from "../assets/img/user/user15.jpg";

//icon
export { default as Icon1 } from "../assets/img/icon/icon-01.svg";
export { default as Icon2 } from "../assets/img/icon/icon-02.svg";
export { default as Icon3 } from "../assets/img/icon/icon-03.svg";
export { default as Icon4 } from "../assets/img/icon/icon-04.svg";
export { default as Icon7 } from "../assets/img/icon/icon-07.svg";
export { default as Icon8 } from "../assets/img/icon/icon-08.svg";
export { default as Icon9 } from "../assets/img/icon/icon-09.svg";
export { default as Icon10 } from "../assets/img/icon/icon-10.svg";
export { default as Icon11 } from "../assets/img/icon/icon-11.svg";
export { default as Icon12 } from "../assets/img/icon/icon-12.svg";
export { default as Icon13 } from "../assets/img/icon/icon-13.svg";
export { default as Icon14 } from "../assets/img/icon/icon-14.svg";
export { default as Icon15 } from "../assets/img/icon/icon-15.svg";
export { default as Icon16 } from "../assets/img/icon/icon-16.svg";
export { default as Icon17 } from "../assets/img/icon/icon-17.svg";
export { default as Icon18 } from "../assets/img/icon/icon-18.svg";
export { default as Icon19 } from "../assets/img/icon/icon-19.svg";
export { default as Icon20 } from "../assets/img/icon/icon-20.svg";
export { default as Icon21 } from "../assets/img/icon/icon-21.svg";
export { default as Icon25 } from "../assets/img/icon/icon-25.svg";
export { default as Icon26 } from "../assets/img/icon/icon-26.svg";
export { default as Icon27 } from "../assets/img/icon/icon-27.svg";

export { default as Icon01 } from "../assets/img/icon/icon-1.svg";
export { default as Icon02 } from "../assets/img/icon/icon-2.svg";
export { default as Icon03 } from "../assets/img/icon/icon-3.svg";
export { default as Icon04 } from "../assets/img/icon/icon-4.svg";

export { default as SendIcon } from "../assets/img/send-icon.svg";

//Join
export { default as Join } from "../assets/img/Group 1813.png";

//Blog
export { default as Blog1 } from "../assets/img/blog/blog-01.jpg";
export { default as Blog2 } from "../assets/img/blog/blog-02.jpg";
export { default as Blog3 } from "../assets/img/blog/blog-03.jpg";
export { default as Blog4 } from "../assets/img/blog/blog-04.jpg";
export { default as Blog5 } from "../assets/img/blog/blog-05.jpg";
export { default as Blog6 } from "../assets/img/blog/blog-06.jpg";
export { default as Blog7 } from "../assets/img/blog/blog-07.jpg";
export { default as Blog8 } from "../assets/img/blog/blog-08.jpg";
export { default as Blog9 } from "../assets/img/blog/blog-09.jpg";
export { default as Blog10 } from "../assets/img/blog/blog-10.jpg";
export { default as Blog20 } from "../assets/img/blog/blog-20.jpg";
export { default as Blog21 } from "../assets/img/blog/blog-21.jpg";
export { default as Blog22 } from "../assets/img/blog/blog-22.jpg";
export { default as Blog23 } from "../assets/img/blog/blog-23.jpg";
export { default as Blog24 } from "../assets/img/blog/blog-24.jpg";

export { default as BlogImg1 } from "../assets/img/blog/blog-img-01.jpg";
export { default as BlogImg2 } from "../assets/img/blog/blog-img-02.jpg";
export { default as BlogImg3 } from "../assets/img/blog/blog-img-03.jpg";

export { default as Become1 } from "../assets/img/become-01.png";
export { default as Become2 } from "../assets/img/become-02.png";

export { default as Spotify } from "../assets/img/spotify.png";
export { default as SoundCloud } from "../assets/img/soundcloud.png";
export { default as Podcast } from "../assets/img/podcast.png";

export { default as Plan } from "../assets/img/plan.png"
export { default as Send } from "../assets/img/send-icon.svg"
export { default as Video } from "../assets/img/video.jpg"
export { default as Video1 } from "../assets/img/video-img-01.jpg"
export { default as ComeSoon } from "../assets/img/come-soon.png"
export { default as Error1 } from "../assets/img/error-01.png"
export { default as Error3 } from "../assets/img/error-02.png"
export { default as Error2 } from "../assets/img/error.png"
export { default as VideoBg } from "../assets/img/video-bg.jpg"
export { default as LoginImg } from "../assets/img/login-img.png"
export { default as RegisterImg } from "../assets/img/register-img.png"
export { default as NetIcon1 } from "../assets/img/net-icon-01.png"
export { default as NetIcon2 } from "../assets/img/net-icon-02.png"
export { default as NetIcon3 } from "../assets/img/net-icon-03.png"
export { default as NetIcon4 } from "../assets/img/net-icon-04.png"


export { default as Category01 } from "../assets/img/category/category-01.jpg";
export { default as Category02 } from "../assets/img/category/category-02.jpg";
export { default as Category03 } from "../assets/img/category/category-03.jpg";
export { default as Category04 } from "../assets/img/category/category-04.jpg";
export { default as Category05 } from "../assets/img/category/category-05.jpg";
export { default as Category06 } from "../assets/img/category/category-06.jpg";
export { default as Category07 } from "../assets/img/category/category-07.jpg";
export { default as Category08 } from "../assets/img/category/category-08.jpg";
export { default as Category09 } from "../assets/img/category/category-09.jpg";
export { default as Category10 } from "../assets/img/category/category-10.jpg";
export { default as Category11 } from "../assets/img/category/category-11.jpg";
export { default as Category12 } from "../assets/img/category/category-12.jpg";
export { default as Category13 } from "../assets/img/category/category-13.jpg";
export { default as Category14 } from "../assets/img/category/category-14.jpg";
export { default as Category15 } from "../assets/img/category/category-15.jpg";
export { default as Category16 } from "../assets/img/category/category-16.jpg";
export { default as Category17 } from "../assets/img/category/category-17.jpg";



export { default as Icon22 } from "../assets/img/icon/icon-22.svg";
export { default as Icon23 } from "../assets/img/icon/icon-23.svg";
export { default as Icon24 } from "../assets/img/icon/icon-24.svg";

export { default as Timer } from "../assets/img/icon/timer-icon.svg";
export { default as Play } from "../assets/img/icon/play.svg";
export { default as People } from "../assets/img/icon/people.svg";
export { default as Lock } from "../assets/img/icon/lock.svg";
export { default as Import } from "../assets/img/icon/import.svg";
export { default as Key } from "../assets/img/icon/key.svg";
export { default as Mobile } from "../assets/img/icon/mobile.svg";
export { default as Cloud } from "../assets/img/icon/cloud.svg";
export { default as Teacher } from "../assets/img/icon/teacher.svg";
export { default as Users } from "../assets/img/icon/users.svg";
export { default as Timer2 } from "../assets/img/icon/timer.svg";
export { default as Chapter } from "../assets/img/icon/chapter.svg";
export { default as Chart } from "../assets/img/icon/chart.svg";
export { default as Video2 } from "../assets/img/icon/video.svg";


export { default as CheckCircle } from "../assets/img/icon/check-circle.svg";
export { default as CloseCircle } from "../assets/img/icon/close-circle.svg";


export { default as Blog11 } from "../assets/img/blog/blog-11.jpg";
export { default as Blog12 } from "../assets/img/blog/blog-12.jpg";
export { default as Blog13 } from "../assets/img/blog/blog-13.jpg";
export { default as Blog14 } from "../assets/img/blog/blog-14.jpg";
export { default as Blog15 } from "../assets/img/blog/blog-15.jpg";
export { default as Blog16 } from "../assets/img/blog/blog-16.jpg";
export { default as Blog17 } from "../assets/img/blog/blog-17.jpg";
export { default as Blog18 } from "../assets/img/blog/blog-18.jpg";
export { default as Blog19 } from "../assets/img/blog/blog-19.jpg";

export { default as BlogBanner } from "../assets/img/blog-banner.jpg"
export { default as reportImg } from "../assets/img/report-illustration.png"

export { default as LoginCover } from "../assets/img/login-cover.png";
export { default as RegisterCover } from "../assets/img/register-cover.png";
export { default as ForgotPasswordCover } from "../assets/img/forgot-password-cover.png";
export { default as CountriesIcon } from "../assets/img/countries-icon.svg";
export { default as MainCover } from "../assets/img/main-cover.png";
export { default as Goals } from "../assets/img/goals.png";

export { default as BackgroundCover } from "../assets/img/cover-background.png";

export { default as UnitedKingdomLogo } from "../assets/img/united-kingdom.png";
export { default as SaudiArabiaLogo } from "../assets/img/saudi-arabia.png";