import * as Yup from "yup";

const SUPPORTED_IMAGE_FORMATS = [
    "image/jpg",
    "image/jpeg",
    "image/gif",
    "image/png",
  ];
  
  const FILE_SIZE = 2 * 1024 * 1024; // 2MB

export const AdminProfileValidator = Yup.object().shape({
    firstName: Yup.string("form.validation.string")
    .required("form.validation.required"),

 avatar: Yup
    .mixed()
    .test('fileSize', 'File size too large', (value) => {
        console.log(value)
      return value && value[0].size <= 2000000; // 2 MB
    }),
    lastName: Yup.string("form.validation.string")
    .required("form.validation.required"),

    email: Yup.string("form.validation.string")
        .email("form.validation.email").required("form.validation.required"),

    password: Yup.string("form.validation.string").required("form.validation.required"),
    

    confirmPassword: Yup.string("form.validation.string")
    .oneOf([Yup.ref("password"), null], "form.validation.passwordMatch")
    .required("form.validation.required"),});
