import * as Yup from "yup";

export const LoginFormValidator = Yup.object().shape({
  email: Yup.string("form.validation.string")
    .email("form.validation.email")
    .required("form.validation.required"),

  password: Yup.string("form.validation.string")
    .min(8, "form.validation.passwordLength")
    .required("form.validation.required"),
});
