import { initializeApp } from "@firebase/app";
import { getMessaging, getToken, onMessage } from "@firebase/messaging";
import { overmind } from "../index";

const firebaseConfig = {
  apiKey: process.env.API_KEY,
  authDomain: process.env.AUTH_DOMAIN,
  projectId: process.env.PROJECT_ID,
  storageBucket: process.env.STORAGE_BUCKET,
  messagingSenderId: process.env.MESSAGING_SENDER_ID,
  appId: process.env.APPID,
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);
export const requestNotificationPermission = async () => {
  try {
    const permission = await Notification.requestPermission();
    const registration = await navigator.serviceWorker.register(
      "./firebase-messaging-sw.js"
    );
    if (permission === "granted") {
      const token = await getToken(messaging, {
        vapidKey:
          "BCwc5Zi1fWOD4UnLGfWnoa8-MItKmQZIsl2S7hDO4rYYGh5MbTB1bTRVFos9lYzc7FzlcxgKL--zX5mPpKrlgFY",
        serviceWorkerRegistration: registration,
      });
      localStorage.setItem("deviceToken", token);
      return token;
    } else {
      console.log("Notification permission denied");
      return null;
    }
  } catch (error) {
    console.log("Error requesting notification permission:", error);
    return null;
  }
};
export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      console.log("nptoficTION payload", payload);
      overmind.actions.pushNotifications({
        ...payload.notification,
        createdAt: new Date().toUTCString(),
        status: "Active",
        id: payload.data.id,
      });

      resolve(payload);
    });
  });
