import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactApexChart from "react-apexcharts";
import { useAppState } from "../../../../presenter";

function Chart() {
  const { t } = useTranslation();
  const { topResults, result } = useAppState();

  const [chartState, setChartState] = useState({
    series: [
      {
        name: "Percentage",
        data: [],
      },
    ],
    options: {
      chart: {
        height: 500,
        width: "100%",
        type: "radar",
      },
      xaxis: {
        categories: [],
        labels: {
          style: {
            fontSize: "15px",
            fontWeight: "bold",
            margin: "10px",
            offsetX: 0,
            color: "#000000",
            offsetY: 10,
          },
        },
      },
    },
  });

  useEffect(() => {
    const fieldNames = Object.keys(result);
    const data = Object.values(result);

    setChartState({
      series: [
        {
          name: "Percentage",
          data,
        },
      ],
      options: {
        chart: {
          height: "100%",
          width: "100%",
          type: "radar",
          toolbar: {
            show: false, // Disable the default ApexCharts toolbar
          },
          offsetX: 0, // Horizontal offset
          offsetY: 0, // Vertical offset
        },
        yaxis: {
          labels: {
            show: false,
          },
        },
        xaxis: {
          categories: fieldNames,
        },
        stroke: {
          colors: ["#5561A5"],
        },
        fill: {
          type: "gradient",
          gradient: {
            shade: "dark",
            type: "radial",
            gradientToColors: ["rgba(43, 58, 142, 0)"],
            stops: [0, 100],
            colorStops: [
              {
                offset: 0,
                color: "rgba(43, 58, 142, 0.3344)",
              },
            ],
          },
        },
        markers: {
          colors: ["#5561A5"],
        },
        tooltip: {
          y: {
            formatter: function (value) {
              return ""; // Set the tooltip labels to an empty string
            },
          },
        },
        legend: {
          position: "top",
          horizontalAlign: "right",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                height: "400px", // Adjust the height for smaller mobile view
              },
            },
          },
          {
            breakpoint: 576,
            options: {
              chart: {
                height: "400px", // Adjust the height for medium mobile view
              },
            },
          },
          {
            breakpoint: 768,
            options: {
              chart: {
                height: "400px", // Adjust the height for larger mobile view
              },
            },
          },
          {
            breakpoint: 992,
            options: {
              chart: {
                height: "500px", // Adjust the height for tablet view
              },
            },
          },
        ],
      },
    });
  }, [result]);
  return (
    <section>
      <div id="chart" className=" d-flex justify-content-center row">
        <div className="col-md-6 mb-3 mb-md-0 chart-container-print">
          <div style={{ height: "10%" }}></div>

          <ReactApexChart
            options={chartState.options}
            series={chartState.series}
            type="radar"
          />
        </div>
        <div
          className="col-md-6 "
          style={{
            display: "flex",
            alignItems: "center",
            marginRight: "-150px",
          }}>
          <div className="mt-md-5 px-4" style={{ width: "100%" }}>
            <h5 className="mb-4 font-weight-bold">
              {" "}
              أعلى&nbsp;المجالات&nbsp;لديك&nbsp;هي:
            </h5>
            {topResults.map((result, i) => (
              <div key={i} className="mb-4">
                <div className="row align-items-center">
                  <div className="col-4 col-md-3">
                    <p>{result?.fieldName}</p>
                  </div>
                  <div className="col-8 col-md-9">
                    <div
                      className={`progress-result progress-result-${i} mb-3`}
                      style={{ width: `${result?.percentage}%` }}>
                      <p>{result?.percentage}%</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Chart;
