import "regenerator-runtime/runtime";

import axios from "axios";
import {
  BadRequestError,
  ForbiddenError,
  InternalServerError,
  UnauthenticatedError,
  UnexpectedError,
} from "../errors";
import { apiClient } from "./constants";

const BASE_ROUTE = "/blogs";

export const listBlogs = async (currentPage, limit, q , language) => {
  try {
    let queryParams = currentPage ? `?page=${currentPage}&limit=${limit}` : "";
    if (q || language) queryParams += `&q=${q}&language=${language}`;
    const result = await apiClient.get(
      `${BASE_ROUTE}/listBlogs${queryParams}`);
    return result.data;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      const responseData = err.response.data;

      if (err.response?.status == 400)
        throw new BadRequestError(responseData.message);
      if (err.response?.status == 401)
        throw new UnauthenticatedError(responseData.message);
      if (err.response?.status == 403)
        throw new ForbiddenError(responseData.message);
      if (err.response?.status == 404)
        throw new UnexpectedError(responseData.message);
      if (err.response?.status == 500)
        throw new InternalServerError(responseData.message);
    }
    throw new UnexpectedError();
  }
};

export const getBlog = async (data) => {
  try {
    const result = await apiClient.get(`${BASE_ROUTE}/${data.id}?language=${data.language}`);
    return result.data;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      const responseData = err.response.data;

      if (err.response?.status == 400)
        throw new BadRequestError(responseData.message);
      if (err.response?.status == 401)
        throw new UnauthenticatedError(responseData.message);
      if (err.response?.status == 403)
        throw new ForbiddenError(responseData.message);
      if (err.response?.status == 404)
        throw new UnexpectedError(responseData.message);
      if (err.response?.status == 500)
        throw new InternalServerError(responseData.message);
    }
    throw new UnexpectedError();
  }
};

export const createBlog = async (data) => {
  try {
    console.log(data)
    const result = await apiClient.post(`${BASE_ROUTE}/create`, data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return result.data;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      const responseData = err.response.data;

      if (err.response?.status == 400)
        throw new BadRequestError(responseData.message);
      if (err.response?.status == 401)
        throw new UnauthenticatedError(responseData.message);
      if (err.response?.status == 403)
        throw new ForbiddenError(responseData.message);
      if (err.response?.status == 404)
        throw new UnexpectedError(responseData.message);
      if (err.response?.status == 500)
        throw new InternalServerError(responseData.message);
    }
    throw new UnexpectedError();
  }
};

export const addBlog = async (data) => {
  try {
    console.log(data.data)

    const result = await apiClient.post(`${BASE_ROUTE}/create/${data.id}/language/${data.language}`, data.data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return result.data;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      const responseData = err.response.data;

      if (err.response?.status == 400)
        throw new BadRequestError(responseData.message);
      if (err.response?.status == 401)
        throw new UnauthenticatedError(responseData.message);
      if (err.response?.status == 403)
        throw new ForbiddenError(responseData.message);
      if (err.response?.status == 404)
        throw new UnexpectedError(responseData.message);
      if (err.response?.status == 500)
        throw new InternalServerError(responseData.message);
    }
    throw new UnexpectedError();
  }
};

export const updateBlog = async (data) => {
  try {
    console.log(data.data)
    const result = await apiClient.put(`${BASE_ROUTE}/${data.id}`, data.data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return result.data;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      const responseData = err.response.data;

      if (err.response?.status == 400)
        throw new BadRequestError(responseData.message);
      if (err.response?.status == 401)
        throw new UnauthenticatedError(responseData.message);
      if (err.response?.status == 403)
        throw new ForbiddenError(responseData.message);
      if (err.response?.status == 404)
        throw new UnexpectedError(responseData.message);
      if (err.response?.status == 500)
        throw new InternalServerError(responseData.message);
    }
    throw new UnexpectedError();
  }
};

export const deleteBlog = async (data) => {
  try {
    const result = await apiClient.delete(`${BASE_ROUTE}/${data.id}?language=${data.language}`);
    return result.data;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      const responseData = err.response.data;

      if (err.response?.status == 400)
        throw new BadRequestError(responseData.message);
      if (err.response?.status == 401)
        throw new UnauthenticatedError(responseData.message);
      if (err.response?.status == 403)
        throw new ForbiddenError(responseData.message);
      if (err.response?.status == 404)
        throw new UnexpectedError(responseData.message);
      if (err.response?.status == 500)
        throw new InternalServerError(responseData.message);
    }
    throw new UnexpectedError();
  }
};
