import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useActions, useAppState } from "../../../../presenter";
import { useTranslation } from "react-i18next";

const Basic = ({ nextTab, data, updateFormData }) => {
  const { t } = useTranslation();
  const { user } = useAppState();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const lang = queryParams.get("lang");
  const id = queryParams.get("id");
  const [isFormValid, setIsFormValid] = useState(false);
  const { getCourse } = useActions();
  const [formData, setFormData] = useState({
    title: '',
    subtitle: '',
    language: '',
    description: ''
  });

  useEffect(() => {
    if (data.title) setFormData(data);
  }, [data]);

  useEffect(() => {
    checkFormValidity();
  }, [formData]);

  useEffect(() => {
    if (id && !data.title) {
      getCourse({ id, language: lang })
        .then(data => {
          // setFormData({
          //   title: data.course.title,
          //   subtitle: data.course.subTitle,
          //   language: data.course.language,
          //   description: data.course.description
          // })
          setFormData((prevFormData) => ({
            ...prevFormData,

            title: data.course.title,
            subtitle: data.course.subTitle,
            language: data.course.language,
            description: data.course.description

          }));
        });
    }
  }, [id]);

  const goBack = () => navigate(-1);

  const handleChange = (event) => {
    let { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));

    checkFormValidity();
  };

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        description: data
      };
    });

    checkFormValidity();
  };

  const checkFormValidity = () => {
    const isValid = formData.title && formData.subtitle && (formData.language || lang) && formData.description;
    setIsFormValid(isValid);
  };

  const handleNextTab = () => {
    if (isFormValid) {
      updateFormData(formData);
      nextTab();
    }
  };

  return (
    <>
      <fieldset id="first">
        <div className="add-course-info">
          <div className="add-course-inner-header">
            <h4>{t('Basic Information')}</h4>
          </div>
          <div className="add-course-form">
            <form action="#">
              <div className="form-group">
                <label className="add-course-label">{t('Course Title')}</label>
                <input
                  name="title"
                  type="text"
                  className="form-control"
                  placeholder={t('Course Title')}
                  value={formData.title}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label className="add-course-label">{t('Course Subtitle')}</label>
                <input
                  name="subtitle"
                  type="text"
                  className="form-control"
                  placeholder={t('Course Subtitle')}
                  value={formData.subtitle}
                  onChange={handleChange}
                />
              </div>
              {!lang && (
                <div className="form-group">
                  <label className="add-course-label">{t('Course Language')}</label>
                  <select
                    className="form-select select"
                    id="language"
                    name="language"
                    value={formData.language}
                    onChange={handleChange}
                  >
                    <option value="">{t('Select Language')}</option>
                    <option value="AR">{t('AR')}</option>
                    <option value="EN">{t('EN')}</option>
                  </select>
                </div>
              )}
              <div className="form-group mb-0">
                <label className="add-course-label">{t('Course Description')}</label>
                <div id="editor">
                  <CKEditor
                    editor={ClassicEditor}
                    data={formData.description}
                    onChange={handleEditorChange}
                    config={{
                      language: user.language.toLowerCase(),
                      contentsLangDirection: user.language == "AR" ? "rtl" : "ltr",
                    }}
                  />
                </div>
              </div>
            </form>
          </div>
          <div className="widget-btn">
            <button type="button" onClick={goBack} className="btn btn-black">
              {t('Back')}
            </button>
            <button
              type="button"
              className={`btn btn-info-light next_btn ${!isFormValid ? 'disabled' : ''}`}
              onClick={handleNextTab}
            >
              {t('Continue')}
            </button>
          </div>
        </div>
      </fieldset>
    </>
  );
};

export default Basic;
