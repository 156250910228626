import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Home, LogOut, Moon, Star } from "react-feather";
import { useActions, useAppState } from "../../presenter";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import {
  logo,
  SaudiArabiaLogo,
  UnitedKingdomLogo
} from "../imagepath";
import { useLocation } from "react-router-dom";
import { NotificationHeader } from "../../components/headerNotification";

const Header = ({ activeMenu }) => {
  const { t } = useTranslation();
  const [showProfile, setShowProfile] = useState(false);
  const { i18n } = useTranslation();
  const { logout, updateGeneralSettings } = useActions();

  const { user } = useAppState();
  const routeArr = [
    "/admin/dashboard",
    "/admin/courses",
    "/admin/blogs",
    "/admin/videos",
    "/admin/podcasts",
  ];
  const location = useLocation();
  const currentRoute = location.pathname;

  useEffect(() => {
    document.body.classList.remove("menu-opened");
    return () => {
      document.body.className = "";
    };
  }, []);

  const profile = useRef();
  useOnClickOutside(profile, () => setShowProfile(false));

  const profileClick = (e) => {
    e.preventDefault();
    setShowProfile(!showProfile);
  };

  const handleLanguageChange = () => {
    const newLanguage = i18n.language === "ar" ? "en" : "ar";
    i18n.changeLanguage(newLanguage);
    if (user) {
      updateGeneralSettings({
        language: newLanguage.toUpperCase(),
        firstName: user.firstName,
        lastName: user.lastName,
        birthDate: new Date(user.birthDate).toISOString().substr(0, 10),
        country: user.country || "",
        description: user.description || "",
        job: user.job || "",
      });
    }
    const isRtl = i18n.language === "ar";
    const elem = document.getElementById("root");
    if (isRtl) {
      elem.classList.add("rtl");
      elem.classList.remove("ltr");
    } else {
      elem.classList.add("ltr");
      elem.classList.remove("rtl");
    }
  };

  // change header background on scroll
  const [navbar, setNavbar] = useState(false);
  // Mobile Menu toggle
  const [mobileMenu, setMobileMenu] = useState(false);
  const [mobileSubMenu, setMobileSubMenu] = useState(false);
  const [mobileSubMenu2, setMobileSubMenu2] = useState(false);
  const [mobileSubMenu22, setMobileSubMenu22] = useState(false);
  const [mobileSubMenu3, setMobileSubMenu3] = useState(false);
  const [mobileSubMenu32, setMobileSubMenu32] = useState(false);
  const [mobileSubMenu4, setMobileSubMenu4] = useState(false);
  const [mobileSubMenu42, setMobileSubMenu42] = useState(false);
  const [mobileSubMenu43, setMobileSubMenu43] = useState(false);
  const [mobileSubMenu5, setMobileSubMenu5] = useState(false);
  const queryParams = new URLSearchParams(location.search);
  const admin = queryParams.get("admin");

  const openMobileMenu = (event) => {
    document.body.classList.add("menu-opened");
    setMobileMenu(true);
  };
  const hideMobileMenu = (event) => {
    document.body.classList.remove("menu-opened");
    setMobileMenu(false);
  };

  const openMobileSubMenu = (e) => {
    e.preventDefault();
    setMobileSubMenu(!mobileSubMenu);
  };
  const openMobileSubMenu2 = (e) => {
    e.preventDefault();
    setMobileSubMenu2(!mobileSubMenu2);
  };
  const openMobileSubMenu22 = (e) => {
    e.preventDefault();
    setMobileSubMenu22(!mobileSubMenu22);
  };
  const openMobileSubMenu3 = (e) => {
    e.preventDefault();
    setMobileSubMenu3(!mobileSubMenu3);
  };
  const openMobileSubMenu32 = (e) => {
    e.preventDefault();
    setMobileSubMenu32(!mobileSubMenu32);
  };
  const openMobileSubMenu4 = (e) => {
    e.preventDefault();
    setMobileSubMenu4(!mobileSubMenu4);
  };
  const openMobileSubMenu42 = (e) => {
    e.preventDefault();
    setMobileSubMenu42(!mobileSubMenu42);
  };
  const openMobileSubMenu43 = (e) => {
    e.preventDefault();
    setMobileSubMenu43(!mobileSubMenu43);
  };
  const openMobileSubMenu5 = (e) => {
    e.preventDefault();
    setMobileSubMenu5(!mobileSubMenu5);
  };

  const changeHeaderBackground = () => {
    if (window.scrollY >= 90) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  const hideButton = () => {
    return routeArr.includes(location.pathname);
  };

  window.addEventListener("scroll", changeHeaderBackground);
  return (
    <header className="header">
      <div className="header-fixed">
        <nav
          className={
            navbar
              ? "navbar navbar-expand-lg header-nav scroll-sticky add-header-bg"
              : "navbar navbar-expand-lg header-nav scroll-sticky"
          }>
          <div className="container">
            <div className="navbar-header">
              <Link id="mobile_btn" to="/" onClick={openMobileMenu}>
                <span className="bar-icon">
                  <span />
                  <span />
                  <span />
                </span>
              </Link>
              <Link to="/" className="navbar-brand logo">
                <img src={logo} className="img-fluid" alt="Logo" />
              </Link>
            </div>
            <div className="main-menu-wrapper">
              <div className="menu-header">
                <Link to="/" className="menu-logo">
                  <img src={logo} className="img-fluid" alt="Logo" />
                </Link>
                <Link
                  id="menu_close"
                  className="menu-close"
                  to="/"
                  onClick={hideMobileMenu}>
                  <i className="fas fa-times" />
                </Link>
              </div>
              <ul className="main-nav">
                <li
                  className={`has-submenu ${
                    activeMenu === "Home" ? "active" : ""
                  }`}>
                  <Link className={mobileSubMenu ? "submenu" : ""} to="/">
                    {t("header.home")}
                  </Link>
                </li>
                {user &&
                  (user.role === "Admin" || user.role === "Super_Admin") && (
                    <li className="has-submenu">
                      <Link to="/admin/profile">{t("header.profile")}</Link>
                    </li>
                  )}
                {user && user.role === "Student" && (
                  <li className="has-submenu">
                    <Link to="/setting-edit-profile">
                      {t("header.profile")}
                    </Link>
                  </li>
                )}
                <li
                  className={`has-submenu d-none ${
                    activeMenu === "List" || activeMenu === "Details"
                      ? "active"
                      : ""
                  }`}>
                  <Link to="/blog-list">{t("header.blog")}</Link>
                </li>

                <li
                  className={`has-submenu ${
                    activeMenu === "CourseGrid" ? "active" : ""
                  }`}>
                  <Link to="/course-grid">{t("header.coursesHeader")}</Link>
                </li>
                <li
                  className={`has-submenu ${
                    activeMenu === "SurveyGrid" ? "active" : ""
                  }`}>
                  <Link to="/survey-grid">{t("header.surveyHeader")}</Link>
                </li>

                <li className="login-link">
                  <Link to="/login">{t("header.loginSignup")}</Link>
                </li>
              </ul>
            </div>
            <ul className="nav header-navbar-rht">
              {user && user.role === "Student" && <NotificationHeader />}
              {user && user.role === "Student" && (
                <li className="nav-item" style={{ marginRight: "20px" }}>
                  <Link
                    to="/setting-student-security"
                    className="nav-link header-login">
                    {t("header.accountSettings")}
                  </Link>
                </li>
              )}
              <li className="nav-item">
                {!user && (
                  <div style={{
                    display:"flex",
                    gap: "8px"
                    
                  }}>
                  
                  <Link className="nav-link header-login" to="/login">
                    {t("header.signIn")}
                  </Link>
                  <Link className="nav-link header-login" to="/register">
                    {t("header.signUp")}
                  </Link>
                    </div>
                )}
                {user && (
                  <Link
                    className="nav-link header-login custom-header-login"
                    to="/"
                    onClick={() => {
                      logout();
                    }}>
                    {t("header.signOut")}
                  </Link>
                )}
              </li>
              {!hideButton(currentRoute) && !user && (
                <li className="nav-item" style={{ marginRight: "20px" }}>
                  <img
                    className="language-switcher"
                    onClick={() => {
                      handleLanguageChange();
                    }} 
                    src={i18n.language === "ar"
                      ? UnitedKingdomLogo
                      : SaudiArabiaLogo}
                  ></img>
                </li>
              )}
            </ul>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Header;
