import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../../footer";
import {
  Course13,
  Course14,
  Course15,
  Icon1,
  Icon2,
  User1,
  User11,
} from "../../imagepath";
import CourseMenu from "../courseMenu";
import Header from "../../header";
import Select from "react-select";
import { Search } from "react-feather";
import DepositMenu from "../depositMenu";
import { useActions, useAppState } from "../../../presenter";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import PageChange from "../../page-change";
import defaultCourseImg from "../../../assets/img/defaultCourseImg.png";

export default function CourseWishlist() {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const [favorites, setFavorites] = useState([]);
  const { loadingFavorites } = useAppState();
  const { listFavorites, deleteFavorite } = useActions();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(100);
  const [pagesNum, setPagesNum] = useState([]);

  useEffect(() => {
    getFavorites();
  }, [currentPage, itemsPerPage]);

  const getFavorites = () => {
    listFavorites({ currentPage, limit: itemsPerPage }).then((data) => {
      setFavorites(data.favorites);

      const arr = [];
      for (let i = 1; i <= data.meta.totalPages; i++) {
        arr.push(i);
      }
      setPagesNum(arr);
    });
  };
  console.log(favorites);
  const handleDeleteFavorite = async (id, language) => {
    await deleteFavorite({ id, language });
    getFavorites();
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const computeTotals = (sections) => {
    let totalLessons = 0;
    let totalDuration = 0;

    sections.forEach((section) => {
      if (section.lessons && Array.isArray(section.lessons)) {
        section.lessons.forEach((lesson) => {
          if (lesson.duration) {
            totalLessons++;
            totalDuration += +lesson.duration;
          }
        });
      }
    });

    const hours = Math.floor(totalDuration / 60);
    const minutes = totalDuration % 60;

    const formattedDuration =
      (hours > 0 ? hours + "h " : "") + (minutes > 0 ? minutes + "min" : "");

    return { totalLessons, totalDuration: formattedDuration };
  };

  if (loadingFavorites) return <PageChange />;
  return (
    <div className="main-wrapper">
      <Header />
      <div className="course-student-header" style={{ marginTop: "110px" }}>
        <div className="container">
          <DepositMenu activeMenu={"Wishlists"} />
        </div>
      </div>

      <section className="course-content">
        <div className="container">
          <div className="student-widget">
            <div className="student-widget-group">
              <div className="row">
                <div className="col-lg-12">
                  <div className="showing-list">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="show-filter choose-search-blk">
                          <form action="#">
                            <div className="mycourse-student align-items-center">
                              <div className="student-search">
                                <div className=" search-group">
                                  <Search className="searchFeather" size={16} />
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Search wishlist"
                                  />
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    {favorites &&
                      favorites.map((course) => (
                        <div
                          key={course.id + course.language}
                          className="col-lg-4 col-md-6 d-flex">
                          <div className="course-box course-design d-flex ">
                            <div className="product">
                              <div className="product-img">
                                <Link
                                  to={`/course-details/${course?.language}/${course.id}`}>
                                  <img
                                    className="img-fluid"
                                    alt=""
                                    style={{
                                      width: "100%",
                                      height: "200px",
                                      objectFit: "cover",
                                    }}
                                    src={course.image?.url ?? defaultCourseImg}
                                  />
                                </Link>
                                <div className="price">
                                  <h3 className="price-h3">
                                    {course.price} {t("currency")}
                                    {/* <span>$99.00</span> */}
                                  </h3>
                                </div>
                              </div>
                              <div className="product-content">
                                <div className="course-group d-flex">
                                  <h3 className="title">
                                    <Link
                                      to={`/course-details/${course?.language}/${course.id}`}>
                                      {course.title}
                                    </Link>
                                  </h3>
                                  <div className="course-share d-flex align-items-center justify-content-center">
                                    <Link
                                      onClick={() => {
                                        Swal.fire({
                                          title: t("deleteConfirmation"),
                                          text: t("deleteWarning"),
                                          icon: "warning",
                                          showCancelButton: true,
                                          confirmButtonColor: "#3085d6",
                                          cancelButtonColor: "#d33",
                                          cancelButtonText: t("cancel"),
                                          confirmButtonText: t("delete"),
                                        }).then((result) => {
                                          if (result.isConfirmed) {
                                            handleDeleteFavorite(
                                              course.id,
                                              course.language
                                            );
                                          }
                                        });
                                      }}>
                                      <i className="fa fa-heart"></i>
                                    </Link>
                                  </div>
                                </div>
                                <div
                                  className="course-info d-flex align-items-center"
                                  style={{ border: "none", padding: "unset" }}>
                                  <div className="rating-img d-flex align-items-center">
                                    <img src={Icon1} alt="" />
                                    <p>
                                      {t(
                                        computeTotals(course.sections)
                                          .totalLessons > 1
                                          ? "courseInfo.totalLessons.plural"
                                          : "courseInfo.totalLessons.singular",
                                        {
                                          count: computeTotals(course.sections)
                                            .totalLessons,
                                        }
                                      )}
                                    </p>
                                  </div>
                                  <div className="course-view d-flex align-items-center">
                                    <img src={Icon2} alt="" />
                                    <p>
                                      {
                                        computeTotals(course.sections)
                                          .totalDuration
                                      }
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    {favorites && favorites.length == 0 && (
                      <h1 style={{ textAlign: "center" }}>{t("noResults")}</h1>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}
