import "regenerator-runtime/runtime";

import { handleError } from "../errors";
import { toast } from "react-toastify";
import i18n from "../../locales/i18n";
import { changePositionByLanguage } from "../../utils/toastify";

export const listCourses = async (context, { currentPage, limit, q }) => {
  try {
    context.state.loading = true;
    const result = await context.effects.listCourses(currentPage, limit, q);
    context.state.loading = false;
    return result;
  } catch (err) {
    context.state.loading = false;
    handleError(err);
  }
};

export const listEnrolledCourses = async (
  context,
  { currentPage, limit, q }
) => {
  try {
    context.state.loading = true;
    const result = await context.effects.listEnrolledCourses(
      currentPage,
      limit,
      q
    );
    context.state.loading = false;
    return result;
  } catch (err) {
    context.state.loading = false;
    handleError(err);
  }
};

export const getCourse = async (context, data) => {
  try {
    context.state.loading = true;
    const result = await context.effects.getCourse(data);
    context.state.loading = false;
    return result;
  } catch (err) {
    context.state.loading = false;
    handleError(err);
  }
};

export const isEnrolled = async (context, data) => {
  try {
    console.log(data);
    context.state.loading = true;
    const result = await context.effects.isEnrolled(data);
    context.state.loading = false;
    return result;
  } catch (err) {
    context.state.loading = false;
    handleError(err);
  }
};

export const getEnrolledCourseData = async (context, data) => {
  try {
    context.state.loading = true;
    const result = await context.effects.getEnrolledCourseData(data);
    context.state.loading = false;
    return result;
  } catch (err) {
    context.state.loading = false;
    handleError(err);
  }
};

export const getEnrolledLessonData = async (context, data) => {
  try {
    context.state.loading = true;
    const result = await context.effects.getEnrolledLessonData(data);
    context.state.loading = false;
    return result;
  } catch (err) {
    context.state.loading = false;
    handleError(err);
  }
};

export const enrollCourse = async (context, data) => {
  try {
    context.state.loading = true;
    const result = await context.effects.enrollCourse(data);
    toast.success(i18n.t("form.success.enrolled"), {
      position: changePositionByLanguage(i18n.language),
    });
    context.state.loading = false;
    return result;
  } catch (err) {
    context.state.loading = false;
    handleError(err);
  }
};

export const createCourse = async (context, data) => {
  try {
    context.state.loadingBtn = true;
    const result = await context.effects.createCourse(data);
    context.state.loadingBtn = false;
    return result;
  } catch (err) {
    context.state.loadingBtn = false;
    handleError(err);
  }
};

export const addCourse = async (context, data) => {
  try {
    context.state.loadingBtn = true;
    const result = await context.effects.addCourse(data);
    context.state.loadingBtn = false;
    return result;
  } catch (err) {
    context.state.loadingBtn = false;
    handleError(err);
  }
};

export const addMaterial = async (context, data) => {
  try {
    context.state.loadingBtn = true;
    const result = await context.effects.addMaterial(data);
    context.state.loadingBtn = false;
    toast.success(i18n.t("form.success.created"), {
      position: changePositionByLanguage(i18n.language),
    });
    return result;
  } catch (err) {
    context.state.loadingBtn = false;
    handleError(err);
  }
};

export const updateMaterial = async (context, data) => {
  try {
    context.state.loadingBtn = true;
    const result = await context.effects.updateMaterial(data);
    context.state.loadingBtn = false;
    toast.success(i18n.t("form.success.updated"), {
      position: changePositionByLanguage(i18n.language),
    });
    return result;
  } catch (err) {
    context.state.loadingBtn = false;
    handleError(err);
  }
};

export const deleteMaterial = async (context, data) => {
  try {
    context.state.loadingBtn = true;
    const result = await context.effects.deleteMaterial(data);
    context.state.loadingBtn = false;
    toast.success(i18n.t("form.success.deleted"), {
      position: changePositionByLanguage(i18n.language),
    });
    return result;
  } catch (err) {
    context.state.loadingBtn = false;
    handleError(err);
  }
};

export const updateCourse = async (context, data) => {
  try {
    context.state.loadingBtn = true;
    const result = await context.effects.updateCourse(data);
    context.state.loadingBtn = false;
    return result;
  } catch (err) {
    context.state.loadingBtn = false;
    handleError(err);
  }
};

export const updateProgress = async (context, data) => {
  try {
    context.state.loadingBtn = true;
    const result = await context.effects.updateProgress(data);
    context.state.loadingBtn = false;
    return result;
  } catch (err) {
    context.state.loadingBtn = false;
    handleError(err);
  }
};

export const deleteCourse = async (context, data) => {
  try {
    context.state.loadingBtn = true;
    const result = await context.effects.deleteCourse(data);
    context.state.loadingBtn = false;
    return result;
  } catch (err) {
    context.state.loadingBtn = false;
    handleError(err);
  }
};

export const listTags = async (context, data) => {
  try {
    const result = await context.effects.listTags();
    return result;
  } catch (err) {
    context.state.loadingBtn = false;
    handleError(err);
  }
};

export const listFavorites = async (context, { currentPage, limit }) => {
  try {
    context.state.loadingFavorites = true;
    const result = await context.effects.listFavorites(currentPage, limit);
    context.state.loadingFavorites = false;
    return result;
  } catch (err) {
    context.state.loadingFavorites = false;
    // handleError(err);
  }
};

export const addFavorite = async (context, data) => {
  try {
    // context.state.loadingBtn = true;
    const result = await context.effects.addFavorite(data);
    // context.state.loadingBtn = false;
    return result;
  } catch (err) {
    // context.state.loadingBtn = false;
    handleError(err);
  }
};

export const deleteFavorite = async (context, data) => {
  try {
    // context.state.loadingBtn = true;
    const result = await context.effects.deleteFavorite(data);
    // context.state.loadingBtn = false;
    return result;
  } catch (err) {
    // context.state.loadingBtn = false;
    handleError(err);
  }
};

export const listFields = async (context, { currentPage, limit, q }) => {
  try {
    // context.state.loading = true;
    const result = await context.effects.listFields(currentPage, limit, q);
    // context.state.loading = false;
    return result;
  } catch (err) {
    // context.state.loading = false;
    handleError(err);
  }
};

export const getField = async (context, id) => {
  try {
    context.state.loading = true;
    const result = await context.effects.getField(id);
    context.state.loading = false;
    return result;
  } catch (err) {
    context.state.loading = false;
    handleError(err);
  }
};

export const addField = async (context, data) => {
  try {
    // context.state.loadingBtn = true;
    const result = await context.effects.addField(data);
    // context.state.loadingBtn = false;
    return result;
  } catch (err) {
    // context.state.loadingBtn = false;
    handleError(err);
  }
};

export const updateField = async (context, data) => {
  try {
    context.state.loadingBtn = true;
    const result = await context.effects.updateField(data);
    context.state.loadingBtn = false;
    toast.success(i18n.t("form.success.updated"), {
      position: changePositionByLanguage(i18n.language),
    });
    return result;
  } catch (err) {
    context.state.loadingBtn = false;
    handleError(err);
  }
};

export const deleteField = async (context, id) => {
  try {
    // context.state.loadingBtn = true;
    const result = await context.effects.deleteField(id);
    // context.state.loadingBtn = false;
    return result;
  } catch (err) {
    // context.state.loadingBtn = false;
    handleError(err);
  }
};

export const addSubfield = async (context, data) => {
  try {
    // context.state.loadingBtn = true;
    const result = await context.effects.addSubfield(data);
    // context.state.loadingBtn = false;
    return result;
  } catch (err) {
    // context.state.loadingBtn = false;
    handleError(err);
  }
};

export const updateSubfield = async (context, data) => {
  try {
    context.state.loadingBtn = true;
    const result = await context.effects.updateSubfield(data);
    context.state.loadingBtn = false;
    return result;
  } catch (err) {
    context.state.loadingBtn = false;
    handleError(err);
  }
};

export const deleteSubfield = async (context, id) => {
  try {
    // context.state.loadingBtn = true;
    const result = await context.effects.deleteSubfield(id);
    // context.state.loadingBtn = false;
    return result;
  } catch (err) {
    // context.state.loadingBtn = false;
    handleError(err);
  }
};

export const addQuestion = async (context, data) => {
  try {
    // context.state.loadingBtn = true;
    const result = await context.effects.addQuestion(data);
    // context.state.loadingBtn = false;
    return result;
  } catch (err) {
    // context.state.loadingBtn = false;
    handleError(err);
  }
};

export const updateQuestion = async (context, data) => {
  try {
    context.state.loadingBtn = true;
    const result = await context.effects.updateQuestion(data);
    context.state.loadingBtn = false;
    return result;
  } catch (err) {
    context.state.loadingBtn = false;
    handleError(err);
  }
};

export const deleteQuestion = async (context, id) => {
  try {
    // context.state.loadingBtn = true;
    const result = await context.effects.deleteQuestion(id);
    // context.state.loadingBtn = false;
    return result;
  } catch (err) {
    // context.state.loadingBtn = false;
    handleError(err);
  }
};

export const listBusinessModelQuestions = async (context, data) => {
  try {
    // context.state.loading = true;
    const result = await context.effects.listBusinessModelQuestions(data);
    // context.state.loading = false;
    return result;
  } catch (err) {
    // context.state.loading = false;
    handleError(err);
  }
};

export const getBusinessModelQuestion = async (context, id) => {
  try {
    context.state.loading = true;
    const result = await context.effects.getBusinessModelQuestion(id);
    context.state.loading = false;
    return result;
  } catch (err) {
    context.state.loading = false;
    handleError(err);
  }
};

export const addBusinessModelQuestion = async (context, data) => {
  try {
    context.state.loadingBtn = true;
    const result = await context.effects.addBusinessModelQuestion(data);
    context.state.loadingBtn = false;
    return result;
  } catch (err) {
    context.state.loadingBtn = false;
    handleError(err);
  }
};

export const updateBusinessModelQuestion = async (context, data) => {
  try {
    // context.state.loadingBtn = true;
    const result = await context.effects.updateBusinessModelQuestion(data);
    // context.state.loadingBtn = false;
    toast.success(i18n.t("form.success.updated"), {
      position: changePositionByLanguage(i18n.language),
    });
    return result;
  } catch (err) {
    context.state.loadingBtn = false;
    handleError(err);
  }
};

export const deleteBusinessModelQuestion = async (context, id) => {
  try {
    context.state.loadingBtn = true;
    const result = await context.effects.deleteBusinessModelQuestion(id);
    context.state.loadingBtn = false;
    return result;
  } catch (err) {
    context.state.loadingBtn = false;
    handleError(err);
  }
};

export const getCertificateDate = async (context, data) => {
  try {
    const result = await context.effects.certificate(data);
    return result;
  } catch (err) {
    handleError(err);
  }
};
