import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Course10,
  Course11,
  Course12,
  Course13,
  Course14,
  Course15,
  Course16,
  Course17,
  Icon1,
  Icon2,
  User,
  User1,
  User2,
  User3,
  User4,
  User5,
  User6,
  lang,
} from "../../../imagepath";
import { useActions } from "../../../../presenter";
import defaultCourseImg from "../../../../assets/img/defaultCourseImg.png";
import { useTranslation } from "react-i18next";

const GridInnerPage = ({ courses }) => {
  const { t } = useTranslation();

  const computeTotals = (sections) => {
    let totalLessons = 0;
    let totalDuration = 0;

    sections.forEach((section) => {
      if (section.lessons && Array.isArray(section.lessons)) {
        section.lessons.forEach((lesson) => {
          if (lesson.duration) {
            totalLessons++;
            totalDuration += +lesson.duration;
          }
        });
      }
    });

    const hours = Math.floor(totalDuration / 60);
    const minutes = totalDuration % 60;

    const formattedDuration =
      (hours > 0 ? hours + "h " : "") + (minutes > 0 ? minutes + "min" : "");

    return { totalLessons, totalDuration: formattedDuration };
  };

  return (
    <>
      <div className="row">
        {courses &&
          courses.map((course) => (
            <div key={course.id} className="col-lg-4 col-md-6 d-flex">
              <div className="course-box course-design d-flex ">
                <div className="product">
                  <div className="product-img">
                    <Link
                      to={`/course-details/${course.language}/${course.id}`}>
                      <img
                        className="img-fluid"
                        alt=""
                        style={{
                          width: "100%",
                          height: "200px",
                          objectFit: "cover",
                        }}
                        src={course.image?.url ?? defaultCourseImg}
                      />
                    </Link>
                    <div className="price">
                      <h3 className="price-h3">
                        {course.price} {t("currency")}
                        {/* <span>$99.00</span> */}
                      </h3>
                    </div>
                  </div>
                  <div className="product-content">
                    <div className="course-group d-flex">
                      <div className="course-share d-flex align-items-center justify-content-center">
                        {/* <Link
                          onClick={() => {
                            favorites.find((item) => item.id == course.id)
                              ? removeFromFavorite(course.id, course.language)
                              : addToFavorite(course.id, course.language);
                          }}>
                          <i
                            className={`fa fa-heart${
                              !favorites.find((item) => item.id == course.id)
                                ? "-o"
                                : ""
                            }`}
                          />
                        </Link> */}
                      </div>
                    </div>
                    <h3 className="title">
                      <Link
                        to={`/course-details/${course.language}/${course.id}`}>
                        {course.title}
                      </Link>
                    </h3>
                    <div className="course-info d-flex align-items-center">
                      <div className="rating-img d-flex align-items-center">
                        <img src={Icon1} alt="" />
                        <p>
                          {t(
                            computeTotals(course.sections).totalLessons > 1
                              ? "courseInfo.totalLessons.plural"
                              : "courseInfo.totalLessons.singular",
                            {
                              count: computeTotals(course.sections)
                                .totalLessons,
                            }
                          )}
                        </p>
                      </div>
                      <div className="course-view d-flex align-items-center">
                        <img src={Icon2} alt="" />
                        <p>{computeTotals(course.sections).totalDuration}</p>
                      </div>
                      <div className="course-view d-flex align-items-center">
                        <img className="img-fluid" src={lang} alt="" />
                        {course.isMultiLanguage
                          ? "English-عربي"
                          : course.language == "AR"
                          ? "عربي"
                          : "English"}
                      </div>
                    </div>
                    {/* <div className="rating">
                  <i className="fas fa-star filled me-1" />
                  <i className="fas fa-star filled me-1" />
                  <i className="fas fa-star filled me-1" />
                  <i className="fas fa-star filled me-1" />
                  <i className="fas fa-star me-1" />
                  <span className="d-inline-block average-rating">
                    <span>4.0</span> (15)
                  </span>
                </div> */}
                    <div className="all-btn all-category d-flex align-items-center">
                      <Link
                        style={{ width: "100%" }}
                        to={`/course-details/${course.language}/${course?.id}`}
                        className="btn btn-primary">
                        {t("courseDetails.moveCourse")}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}

        {courses && courses.length === 0 && (
          <h1 style={{ textAlign: "center" }}>{t("noResults")}</h1>
        )}
      </div>
    </>
  );
};

export default GridInnerPage;
