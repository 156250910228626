import i18n from "i18next";
import arTranslations from "./ar.json";
import enTranslations from "./en.json";

import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  lng: "ar",
  fallbackLng: "ar",
  resources: {
    ar: { translation: arTranslations },
    en: { translation: enTranslations },
  },
  interpolation: { escapeValue: false },
});

export default i18n;
