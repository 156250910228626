import "regenerator-runtime/runtime";
import axios from "axios";
import {
  BadRequestError,
  ForbiddenError,
  InternalServerError,
  UnauthenticatedError,
  UnexpectedError,
} from "../errors";
import { apiClient } from "./constants";

const BASE_ROUTE = "/payment";

export const payment = async (data) => {
  try {
 
    const result = await apiClient.post(`${BASE_ROUTE}/charge/${data.chargeId}/productType/${data.productType}/product/${data.productId}`,data.data);
    return result.data;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      const responseData = err.response.data;

      if (err.response?.status == 400)
        throw new BadRequestError(responseData.message);
      if (err.response?.status == 401)
        throw new UnauthenticatedError(responseData.message);
      if (err.response?.status == 403)
        throw new ForbiddenError(responseData.message);
      if (err.response?.status == 404)
        throw new UnexpectedError(responseData.message);
      if (err.response?.status == 500)
        throw new InternalServerError(responseData.message);
    }
    throw new UnexpectedError();
  }
};

