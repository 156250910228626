import React from "react";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import OwlCarousel from "react-owl-carousel";
import { ForgotPasswordCover, logo } from "../../imagepath";
import { Link } from "react-router-dom";
import { ForgotPasswordFormValidator } from "../../../validators/forgot-password.validator"
import { useActions, useAppState } from "../../../presenter";
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useTranslation } from "react-i18next";

const ForgotPassword = () => {
  const { t: translate } = useTranslation();
  const { t } = useTranslation();
  const { loadingBtn } = useAppState();
  const { forgetPassword } = useActions();
  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(ForgotPasswordFormValidator),
  });

  var settings = {
    //autoWidth: true,
    items: 2,
    margin: 25,
    dots: true,
    nav: true,
    navText: [
      '<i className="fas fa-arrow-left"></i>',
      '<i className="fas fa-arrow-right"></i>',
    ],

    loop: false,
    responsiveClass: true,
    responsive: {
      0: {
        items: 1,
      },
      768: {
        items: 1,
      },
      1170: {
        items: 1,
      },
    },
  };

  const onSubmit = async (data) => {
    await forgetPassword(data);
  }

  return (
    <>
      <div className="main-wrapper">
        <div className="row">
          {/* Login Banner */}
          <div className="col-md-6 login-bg">

            <OwlCarousel
              {...settings}
              className="owl-carousel login-slide owl-theme">
              <div className="welcome-login">
                <div className="login-banner">
                  <img
                    src={ForgotPasswordCover}
                    className="img-fluid"
                    alt="Logo"
                  />
                </div>
                <div className="mentor-course text-center">
                  <h2>
                    {t("form.resetPasswordTitle")}
                  </h2>
                  <p>
                    {t("form.resetPasswordSubtitle")}
                  </p>
                </div>
              </div>
            </OwlCarousel>

          </div>
          {/* /Login Banner */}
          <div className="col-md-6 login-wrap-bg">
            {/* Login */}
            <div className="login-wrapper">
              <div className="loginbox">
                <div className="img-logo">
                  <img
                    src={logo}
                    className="img-fluid"
                    alt="Logo"
                  />
                  <div className="back-home">
                    <Link to="/">{t("form.backHome")}</Link>
                  </div>
                </div>
                <h1>{t("form.forgotPassword")}</h1>
                <div className="reset-password">
                  <p>{t("form.EnterEmailResetPassword")}</p>
                </div>
                <form onSubmit={handleSubmit(async (data) => await onSubmit(data))}>
                  <div className="form-group">
                    <label className="form-control-label">{t("form.email")}</label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder={t("form.enterEmail")}
                      {...register("email")}
                    />
                  </div>
                  <p className="invalid-feedback d-block">
                    {translate(errors.email?.message)}
                  </p>

                  <div className="d-grid">
                    <button className="btn btn-start" type="submit" disabled={loadingBtn}>
                      {loadingBtn && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>}
                      {t("form.submit")}
                    </button>
                  </div>
                </form>
              </div>
            </div>
            {/* /Login */}
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
