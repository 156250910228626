import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../../footer";
import Header from "../../header";
import DepositMenu from "../depositMenu";
import { Search } from "react-feather";
import { useActions } from "../../../presenter";
import { useTranslation } from "react-i18next";
import PageChange from "../../page-change";
import defaultCourseImg from "../../../assets/img/defaultCourseImg.png";
export default function CourseStudent() {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const [courses, setCourses] = useState(null);
  const { listEnrolledCourses } = useActions();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [pagesNum, setPagesNum] = useState([]);

  useEffect(() => {
    getCourses();
  }, [currentPage, itemsPerPage]);

  const getCourses = () => {
    listEnrolledCourses({ currentPage, limit: itemsPerPage, q: null }).then(
      (data) => {
        setCourses(data.enrolledCourses);

        const arr = [];
        for (let i = 1; i <= data.meta.totalPages; i++) {
          arr.push(i);
        }
        setPagesNum(arr);
      }
    );
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  if (!courses) return <PageChange />;

  return (
    <div className="main-wrapper">
      <Header />
      <div className="course-student-header" style={{ marginTop: "110px" }}>
        <div className="container">
          <DepositMenu activeMenu={"Courses"} />
        </div>
      </div>

      <section className="course-content">
        <div className="container">
          <div className="student-widget">
            <div className="student-widget-group">
              <div className="row">
                <div className="col-lg-12">
                  <div className="showing-list">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="show-filter choose-search-blk">
                          <form action="#">
                            <div className="mycourse-student align-items-center">
                              <div className="student-search">
                                <div className=" search-group">
                                  <Search className="searchFeather" size={16} />
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder={t("searchCourses")}
                                  />
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    {courses &&
                      courses?.length > 0 &&
                      courses?.map((course) => (
                        <div
                          key={course.id + course.Language}
                          className="col-xl-3 col-lg-4 col-md-6 d-flex">
                          <div className="course-box course-design d-flex ">
                            <div className="product">
                              <div className="product-img">
                                <Link
                                  to={`/course-details/${course.Language}/${course.id}`}>
                                  <img
                                    className="img-fluid"
                                    alt=""
                                    src={course.image?.url ?? defaultCourseImg}
                                    style={{ height: "150px" }}
                                  />
                                </Link>
                              </div>
                              <div className="product-content">
                                <h3 className="title">
                                  <Link
                                    to={`/course-details/${course.Language}/${course.id}`}>
                                    {course.title}
                                  </Link>
                                </h3>
                                <div className="progress-stip">
                                  <div
                                    className="progress-bar bg-success progress-bar-striped"
                                    style={{
                                      width: `${
                                        course.progress > 100
                                          ? 100
                                          : course.progress ?? 0
                                      }%`,
                                    }}></div>
                                </div>
                                <div className="student-percent">
                                  <p>
                                    {t("completed", {
                                      percent:
                                        course.progress?.toFixed(0) > 100
                                          ? 100
                                          : course.progress?.toFixed(0) ?? 0,
                                    })}
                                  </p>
                                </div>
                                <div className="start-leason hoverBlue d-flex align-items-center">
                                  <Link
                                    to={`/course-lesson/${course.id}?language=${course.Language}`}
                                    className="btn btn-primary">
                                    {t("startLesson")}
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}

                    {courses && courses.length == 0 && (
                      <h1 style={{ textAlign: "center" }}>{t("noResults")}</h1>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}
