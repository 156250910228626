import React, { useState, useEffect } from "react";
import Header from "../../../header";
import DetailsContent from "./detailsContent";
import { Icon1, People, Timer, User1 } from "../../../imagepath";
import Footer from "../../../footer";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useActions, useAppState } from "../../../../presenter";
import { translateTime } from "../../../../utils/time";
import PageChange from "../../../page-change";
const SurveyDetails = () => {
  const queryParams = new URLSearchParams(location.search);
  const { t } = useTranslation();
  const { id } = useParams();
  const lang = queryParams.get("language");
  const navigate = useNavigate();
  const [survey, setSurvey] = useState(null);
  const [isUserEnrolled, setIsUserEnrolled] = useState();
  const { getSurveyDetails } = useActions();
  const { user } = useAppState();

  useEffect(() => {
    getSurveyDetails({ id }).then((data) => {
      setSurvey(data);
    });
  }, [id]);

  if (!survey) return <PageChange />;

  return (
    <>
      <div className="main-wrapper">
        <Header activeMenu={"CourseDetails"} />

        <div className="breadcrumb-bar">
          <div className="container" style={{ marginTop: "70px" }}>
            <div className="row">
              <div className="col-md-12 col-12">
                <div className="breadcrumb-list">
                  <nav aria-label="breadcrumb" className="page-breadcrumb">
                    <ol className="breadcrumb">
                      <li
                        className="breadcrumb-item"
                        style={{ paddingLeft: "8px" }}>
                        <Link to="/">{t("breadcrumb.home")}</Link>
                      </li>
                      <li className="breadcrumb-item" aria-current="page">
                        {t("surveys")}
                      </li>
                      <li className="breadcrumb-item" aria-current="page">
                        {t("allSurveys")}
                      </li>
                      <li className="breadcrumb-item" aria-current="page">
                        {survey.title}
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="inner-banner"
          style={{ backgroundImage: `url(${survey?.image?.url ?? ""})` }}>
          <div className="container">
            <div className="row">
              <div className="col-lg-8">
                <h2>{survey.title}</h2>
                <p>{survey.subTitle}</p>
                <div className="course-info d-flex align-items-center border-bottom-0 m-0 p-0"></div>
              </div>
            </div>
          </div>
        </div>

        <DetailsContent survey={survey} />

        <Footer />
      </div>
    </>
  );
};

export default SurveyDetails;
