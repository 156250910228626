import React, { useEffect, useRef } from "react";
import { useState } from "react";
import Collapse from "react-bootstrap/Collapse";
import { Link, useNavigate, useParams } from "react-router-dom";
import Footer from "../../../footer";
import { Lock, Play, CheckCircle } from "../../../imagepath";
import Header from "../../../header";
import { useActions, useAppState } from "../../../../presenter";
import PageChange from "../../../page-change";
import { useTranslation } from "react-i18next";
import qs from "qs";

const CourseLesson = () => {
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const { t } = useTranslation();
  const { id } = useParams();
  const { user } = useAppState();
  const language = queryParams.get("language");
  let lessonIdQuery = queryParams.get("lessonId");
  let sectionIdQuery = queryParams.get("sectionId");
  const [selectedSection, setSelectedSection] = useState(null);
  const [tab, setTab] = useState(1);
  const [course, setCourse] = useState(null);
  const [lesson, setLesson] = useState(false);
  const [sectionId, setSectionId] = useState(null);
  const [lessonId, setLessonId] = useState(null);
  const { loading, loadingBtn } = useAppState();
  const [isCourseCompleted, setIsCourseCompleted] = useState(false);
  const [courseCompleted, setCourseCompleted] = useState(false);
  const [activeLesson, setActiveLesson] = useState("");
  const [isVideoEnded, setIsVideoEnded] = useState(false);
  const [lifeProjectSurveyId, setLifeProjectSurveyId] = useState("");
  const [isLifeProjectSurveyCompleted, setIsLifeProjectSurveyCompleted] =
    useState(false);
  const { getEnrolledCourseData, getEnrolledLessonData, updateProgress } =
    useActions();
  const tabToggle = (id) => {
    setTab(id);
  };
  useEffect(() => {
    if (course?.isMultiLanguage && user.language !== language) {
      navigate(`/course-lesson/${id}?language=${user.language}`);
    }
  }, [user.language, course?.isMultiLanguage]);

  useEffect(() => {
    if (lessonIdQuery && sectionIdQuery) {
      setLessonId(lessonIdQuery);
      setSectionId(sectionIdQuery);
    }
  }, [lessonIdQuery, sectionIdQuery, isCourseCompleted]);

  useEffect(() => {
    fetchData();
  }, [id, language, isCourseCompleted, isLifeProjectSurveyCompleted]);

  useEffect(() => {
    setCourseCompleted(false);
    if (lessonId && sectionId) {
      setActiveLesson(lessonId);
      getEnrolledLessonData({ id, language, sectionId, lessonId }).then(
        (data) => {
          setLesson(data);
        }
      );
    }
  }, [sectionId, lessonId]);

  const calculateSectionProgress = (section) => {
    const totalLessons = section.lessons.length;
    const watchedLessons = section.lessons.filter((lesson) =>
      lesson.LessonProgress.find((progress) => progress.isWatched)
    ).length;

    if (totalLessons === 0) {
      return 0; // If the section has no lessons, the progress is 0.
    }

    const progress = (watchedLessons / totalLessons) * 100;
    return Math.round(progress);
  };

  const calculateDuration = (section) => {
    let totalDuration = 0;

    if (section.lessons && Array.isArray(section.lessons)) {
      section.lessons.forEach((lesson) => {
        if (lesson.duration) {
          totalDuration += +lesson.duration;
        }
      });
    }

    const hours = Math.floor(totalDuration / 60);
    const minutes = totalDuration % 60;

    const formattedDuration =
      (hours > 0 ? hours + "h " : "") + (minutes > 0 ? minutes + "min" : "");

    return formattedDuration;
  };

  const fetchData = () => {
    setCourseCompleted(false);
    getEnrolledCourseData({ id, language })
      .then((data) => {
        setCourse(data);
        setIsLifeProjectSurveyCompleted(data.isLifeProjectSurveyCompleted);
        setLifeProjectSurveyId(data.lifeProjectId);

        let lessonFound = false;
        data.sections.map((section, index) => {
          section.lessons.map((lesson) => {
            if (!lesson.LessonProgress[0].isWatched) {
              setIsCourseCompleted(false);
            } else {
              setIsCourseCompleted(true);
            }
            if (
              lesson.LessonProgress.find(
                (item) => item.isLocked == false && !item.isWatched
              )
            ) {
              setLessonId(lessonIdQuery ?? lesson.id);
              setSectionId(sectionIdQuery ?? section.id);
              setSelectedSection(index);
              lessonFound = true;
              return;
            }
          });
        });

        if (!lessonIdQuery && !sectionIdQuery && !lessonFound) {
          setLessonId(data.sections[0].lessons[0].id);
          setSectionId(data.sections[0].id);
          setSelectedSection(0);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  const continueLearning = () => {
    let nextLessonId;
    let nextSectionId;
    setIsVideoEnded(false);

    updateProgress({ id, language, sectionId, lessonId }).then((data) => {
      if (data == "This is the last lesson in the course.") {
        setIsCourseCompleted(true);
        //do nothing
        // if (course.hasSurvey) {
        //   navigate(`/survey?id=${id}&language=${language}`);
        //   window.scrollTo(0, 0);
        // }
      } else {
        getEnrolledCourseData({ id, language }).then((data) => {
          setCourse(data);

          const sectionIndex = course.sections.findIndex(
            (section) => section.id == sectionId
          );
          const lessonIndex = course.sections[sectionIndex].lessons.findIndex(
            (lesson) => lesson.id == lessonId
          );
          if (course.sections[sectionIndex].lessons[lessonIndex + 1]) {
            nextLessonId =
              course.sections[sectionIndex].lessons[lessonIndex + 1].id;
            nextSectionId = course.sections[sectionIndex].id;
            setSelectedSection(sectionIndex);
          } else if (
            course.sections[sectionIndex + 1] &&
            course.sections[sectionIndex + 1].lessons[0]
          ) {
            nextLessonId = course.sections[sectionIndex + 1].lessons[0].id;
            nextSectionId = course.sections[sectionIndex + 1].id;
            setSelectedSection(sectionIndex + 1);
          } else if (course.hasSurvey) {
            //last video watched
            // navigate(`/survey?id=${id}&language=${language}`);
            window.scrollTo(0, 0);
            return;
          } else {
            setCourseCompleted(true);
          }
          if (nextSectionId && nextLessonId) {
            setSectionId(nextSectionId);
            setLessonId(nextLessonId);
          }
        });
        navigate(`/course-lesson/${id}?language=${language}`);
        window.scrollTo(0, 0);
        lessonIdQuery = null;
        sectionIdQuery = null;
      }
      // fetchData();
    });
  };
  const iframeRef = useRef(null);

  useEffect(() => {
    const iframe = iframeRef.current;

    const handleIframeLoad = () => {
      if (!iframe) {
        return;
      }

      const player = new window.YT.Player(iframe, {
        events: {
          onReady: () => {
            console.log("The YouTube video player is ready.");
          },
          onStateChange: (event) => {
            console.log(event.data);
            if (event.data === window.YT.PlayerState.ENDED) {
              console.log("The YouTube video has ended.");
              setIsVideoEnded(true);
            }
          },
        },
      });
    };

    if (iframe) {
      iframe.addEventListener("load", handleIframeLoad);
    }

    // Clean up the event listener
    return () => {
      if (iframe) {
        iframe.removeEventListener("load", handleIframeLoad);
      }
    };
  }, [lesson]);

  if (!course && lesson) return <PageChange />;

  return (
    <>
      <div className="main-wrapper">
        <Header />

        <section className="page-content course-sec course-lesson">
          <div className="container" style={{ marginTop: "70px" }}>
            <div className="row">
              <div className="col-lg-4">
                {/* Course Lesson */}
                <div className="lesson-group">
                  {course &&
                    course.sections.map((section, index) => (
                      <div className="course-card" key={section.id}>
                        <h6 className="cou-title">
                          <Link
                            className={`collapsed ${
                              selectedSection === index ? "active" : ""
                            }`}
                            data-bs-toggle="collapse"
                            to={`#collapse${section.order}`}
                            onClick={() => setSelectedSection(index)}
                            aria-controls={`collapse${section.order}`}
                            aria-expanded={selectedSection === index}>
                            {section.title}{" "}
                            <span>
                              {section.lessons.length} {t("lessons")}
                            </span>{" "}
                          </Link>
                        </h6>
                        <Collapse in={selectedSection === index}>
                          <div
                            id={`collapse${section.order}`}
                            className={`card-collapse collapse ${
                              selectedSection === index ? "show" : ""
                            }`}
                            style={{}}>
                            <div className="progress-stip">
                              <div
                                className="progress-bar bg-success progress-bar-striped"
                                style={{
                                  width: `${calculateSectionProgress(
                                    section
                                  )}%`,
                                }}
                              />
                            </div>
                            <div className="student-percent lesson-percent">
                              <p>
                                {calculateDuration(section)}
                                <span>
                                  {calculateSectionProgress(section)}%
                                </span>
                              </p>
                            </div>
                            <ul>
                              {section.lessons.map((lesson, j) => (
                                <div key={lesson.id}>
                                  <li>
                                    {!lesson.LessonProgress[0].isLocked ? (
                                      <Link
                                        to={`/course-lesson/${id}?language=${language}&sectionId=${section.id}&lessonId=${lesson.id}`}>
                                        <p
                                          className={
                                            lesson.id == activeLesson
                                              ? "play-intro"
                                              : "lesson-title"
                                          }>
                                          {lesson.title}
                                        </p>
                                      </Link>
                                    ) : (
                                      <p
                                        className={
                                          lesson.id == activeLesson
                                            ? "play-intro"
                                            : "lesson-title"
                                        }>
                                        {lesson.title}
                                      </p>
                                    )}
                                    <div>
                                      <img
                                        src={
                                          lesson.LessonProgress.find(
                                            (item) => item.isLocked == false
                                          )
                                            ? lesson.LessonProgress.find(
                                                (item) => item.isWatched == true
                                              )
                                              ? CheckCircle
                                              : Play
                                            : Lock
                                        }
                                        alt=""
                                      />
                                    </div>
                                  </li>
                                </div>
                              ))}
                              {course.sections.length - 1 === index && (
                                <>
                                  {course?.isLifeProject && (
                                    <li>
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                          width: "100%",
                                          cursor: isCourseCompleted
                                            ? "pointer"
                                            : "not-allowed",
                                        }}
                                        onClick={() => {
                                          if (isCourseCompleted)
                                            navigate(
                                              `/survey/${lifeProjectSurveyId}?courseId=${id}&language=${language}`
                                            );
                                        }}>
                                        {t("survey")}

                                        {
                                          <img
                                            src={
                                              isLifeProjectSurveyCompleted
                                                ? CheckCircle
                                                : !isCourseCompleted
                                                ? Lock
                                                : null
                                            }
                                            alt=""
                                          />
                                        }
                                      </div>
                                    </li>
                                  )}
                                  <li>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        width: "100%",
                                        cursor: isLifeProjectSurveyCompleted
                                          ? "pointer"
                                          : "not-allowed",
                                      }}
                                      onClick={() => {
                                        const url = `/certificate?courseId=${id}&language=${language}`;
                                        if (isCourseCompleted)
                                          // Open the URL in a new tab
                                          window.open(url, "_blank");
                                      }}>
                                      {t("certificate")}
                                      {!isLifeProjectSurveyCompleted && (
                                        <img src={Lock} alt="" />
                                      )}
                                    </div>
                                  </li>
                                </>
                              )}
                            </ul>
                          </div>
                        </Collapse>
                      </div>
                    ))}
                </div>
                {/* /Course Lesson */}
              </div>
              <div className="col-lg-8">
                {/* Introduction */}
                {lesson && (
                  <div className="student-widget lesson-introduction">
                    <div className="lesson-widget-group">
                      <h4 className="tittle">{lesson.title}</h4>
                      <div className="introduct-video">
                        <iframe
                          ref={iframeRef}
                          className="video-thumbnail"
                          src={`${lesson.videoUrl}?enablejsapi=1`}
                          title="External Link Preview"
                          width="100%"
                          height="500px"
                          allow="fullscreen"
                        />
                      </div>
                    </div>

                    <div className="category-tab">
                      <ul className="nav nav-justified">
                        <li
                          className="nav-item"
                          style={{ marginInlineStart: "20px" }}>
                          <Link
                            to="#"
                            className={
                              tab === 1 ? "nav-link active" : "nav-link"
                            }
                            data-bs-toggle="tab"
                            onClick={(e) => {
                              e.preventDefault();
                              tabToggle(1);
                            }}>
                            {t("transcript")}
                          </Link>
                        </li>
                        {lesson.materials && lesson.materials.length > 0 && (
                          <li className="nav-item">
                            <Link
                              to="#"
                              className={
                                tab === 2 ? "nav-link active" : "nav-link"
                              }
                              data-bs-toggle="tab"
                              onClick={(e) => {
                                e.preventDefault();
                                tabToggle(2);
                              }}>
                              {t("material")}
                            </Link>
                          </li>
                        )}
                      </ul>
                    </div>

                    <div className="tab-content">
                      <div
                        className={
                          tab === 1
                            ? "tab-pane fade show active"
                            : "tab-pane fade"
                        }
                        id="graphics">
                        <div className="row">
                          <div className="col-lg-12 col-md-6">
                            <p className="lesson-description">
                              {lesson.description}
                            </p>
                          </div>
                        </div>
                      </div>

                      <div
                        className={
                          tab === 2
                            ? "tab-pane fade show active"
                            : "tab-pane fade"
                        }
                        id="programming">
                        <div className="row">
                          <div className="col-lg-12 col-md-6">
                            {lesson.materials.map((material) => (
                              <div className="category-box">
                                <div className="category-title">
                                  <h5>{material.name}</h5>
                                </div>
                                <div className="cat-count">
                                  <Link to={material.url} target="_blank">
                                    {t("link")}
                                  </Link>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>

                    {!courseCompleted && (
                      <div
                        className="update-profile"
                        style={{ marginInlineStart: "35%" }}>
                        <button
                          type="button"
                          className="btn btn-primary"
                          disabled={
                            loadingBtn || !(isVideoEnded || lesson.isWatched)
                          }
                          onClick={continueLearning}>
                          {loadingBtn && (
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"></span>
                          )}
                          {t("continueLearning")}
                        </button>
                      </div>
                    )}
                  </div>
                )}

                {/* /Introduction */}
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </>
  );
};

export default CourseLesson;
