import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useForm, FormProvider, useFormContext } from "react-hook-form";
import { useActions, useAppState } from "../../../presenter";
import PageChange from "../../page-change";
import jsPDF from "jspdf";
import { captureScreenshot } from "../../../utils/screenshot";
import { reportImg } from "../../imagepath";
import { useTranslation } from "react-i18next";

const Report = ({ onClick: setPageChange }) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { screenshots, loadingBtn } = useAppState();
  const { setScreenshots, submitReport, resetSurvey } = useActions();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const courseId = queryParams.get("courseId");
  const language = queryParams.get("language");
  console.log(courseId);
  console.log(language);
  useEffect(() => {
    localStorage.setItem("screenshots", JSON.stringify(screenshots));
  }, [screenshots]);

  const getScreenShots = async () => {
    const arr = [
      "chart",
      "suggestedFields",
      "selectedSuggestions",
      "projectPosition",
      "businessModel",
    ];
    for (const i in arr) {
      const screenshot = await captureScreenshot(
        document.getElementById(arr[0])
      );

      if (screenshot) {
        console.log("screenshot", screenshot);
        await setScreenshots({ key: arr[i], screenshot });
      }
    }
    downloadPDFReport();
  };

  const downloadPDFReport = () => {
    const doc = new jsPDF();

    const allScreenshots = Object.values(screenshots);

    // Calculate the center position of the page
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();
    const centerX = pageWidth / 2;
    const centerY = pageHeight / 2;

    // Define the dimensions and positioning of the screenshots
    const screenshotWidth = 200;
    const screenshotHeight = screenshotWidth * (pageHeight / pageWidth / 3); // Maintain aspect ratio
    const screenshotX = centerX - screenshotWidth / 2;
    const screenshotY = centerY - screenshotHeight / 2;

    // Loop through the screenshots and add them to the PDF
    allScreenshots.forEach((screenshot, index) => {
      let description;
      if (index == 0) description = "Chart Result";
      else if (index == 1) description = "Field Suggestions";
      else if (index == 2) description = "Selected Suggestions";
      else if (index == 3) description = "Project Position";
      else if (index == 4) description = "Business Model";
      if (index !== 0) {
        doc.addPage();
      }

      // Add screenshot image to the center of the page
      doc.addImage(
        screenshot,
        "PNG",
        screenshotX,
        screenshotY,
        screenshotWidth,
        screenshotHeight
      );

      // Add page number and description
      doc.setFontSize(12);
      doc.text(`Page ${index + 1}`, centerX, 10, { align: "center" });
      doc.setFontSize(14);
      doc.text(description, centerX, pageHeight - 10, { align: "center" });
    });

    // Save the PDF
    doc.save("report.pdf");
  };

  const onResetSurvey = async () => {
    await resetSurvey();
    setPageChange("questions");
  };

  return (
    <>
      <div
        className="survey-header bg-custom py-3 "
        style={{ background: "#1F8D85" }}>
        <div className="container">
          <div className="row ">
            <div className="col-12 col-md-8">
              <div className="survey-header-text ">
                <h6>{t("Congratulations")}</h6>
                <p className="d-md-block">{t("doYouWantToDownload")}</p>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div
                className="progress-circle mx-auto mx-md-0"
                style={{
                  border: "4px solid #fff",
                  width: "100px",
                  height: "100px",
                }}>
                <span
                  className="progress-text d-flex justify-content-center align-items-center"
                  style={{ height: "100%" }}>
                  <svg
                    width="26"
                    height="20"
                    viewBox="0 0 26 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M8.91703 19.292C8.69481 19.292 8.48648 19.257 8.29203 19.187C8.09759 19.1181 7.91703 19.0003 7.75037 18.8337L0.583701 11.667C0.278145 11.3614 0.132034 10.9653 0.145367 10.4787C0.159812 9.9931 0.319811 9.59755 0.625367 9.29199C0.930922 8.98643 1.31981 8.83366 1.79203 8.83366C2.26426 8.83366 2.65314 8.98643 2.9587 9.29199L8.91703 15.2503L23.042 1.12533C23.3476 0.81977 23.7437 0.666992 24.2304 0.666992C24.7159 0.666992 25.1115 0.81977 25.417 1.12533C25.7226 1.43088 25.8754 1.82644 25.8754 2.31199C25.8754 2.79866 25.7226 3.19477 25.417 3.50033L10.0837 18.8337C9.91703 19.0003 9.73648 19.1181 9.54203 19.187C9.34759 19.257 9.13926 19.292 8.91703 19.292Z"
                      fill="#EAF6F5"
                    />
                  </svg>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section>
        <div className="container my-5">
          <div className="row align-items-center justify-content-center">
            <div className="col-12 col-md-6 order-md-2 mb-4 mb-md-0">
              <div className="report-img">
                <img src={reportImg} alt="" />
              </div>
            </div>
            <div className="col-12 col-md-6 order-md-1">
              <div className="report-text text-break">
                {t("downloadSurvey")}
                <p className="sub-text"> {t("doYouWantToDownload")}</p>
              </div>
              {/* <div className="sub-text text-wrap text-center">
              </div> */}
              <div className="action-btns">
                <button
                  className="download-btn btn btn-success mb-2"
                  onClick={() => navigate("/PDFDocument")}>
                  {t("download")}
                </button>
                <button
                  className="retest-btn btn mb-2"
                  type="button"
                  onClick={onResetSurvey}>
                  {t("reset")}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="widget-btn">
          <button
            type="button"
            className="btn btn-black prev_btn"
            onClick={() => setPageChange("businessModel")}>
            {t("back")}
          </button>
          <button
            type="button"
            onClick={() => {
              navigate(
                `${
                  courseId
                    ? `/course-details/${language}/${courseId}`
                    : `/survey-details/${id}`
                }`
              );
            }}
            className="btn btn-info-light next_btn"
            disabled={loadingBtn}>
            {courseId ? t("form.backToCourse") : t("form.backToSurvey")}
            {loadingBtn && (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"></span>
            )}
          </button>
        </div>
      </section>
    </>
  );
};

export default Report;
