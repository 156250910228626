import "regenerator-runtime/runtime";
import axios from "axios";
import {
    BadRequestError,
    ForbiddenError,
    InternalServerError,
    UnauthenticatedError,
    UnexpectedError,
} from "../errors";
import { apiClient } from "./constants";

export const loginWithGoogle = async (token) => {
    try {
        return await apiClient.post("auth/google/login", {
            token,
        });
    } catch (err) {
        if (axios.isAxiosError(err)) {
            const responseData = err.response.data;

            if (err.response?.status == 400)
                throw new BadRequestError(responseData.message);
            if (err.response?.status == 401)
                throw new UnauthenticatedError(responseData.message);
            if (err.response?.status == 403)
                throw new ForbiddenError(responseData.message);
            if (err.response?.status == 404)
                throw new UnexpectedError(responseData.message);
            if (err.response?.status == 500)
                throw new InternalServerError(responseData.message);
        }
        throw new UnexpectedError();
    }
};

export const loginWithFacebook = async (data) => {
    try {
        return await apiClient.post("auth/facebook/login", {
            data
        });
    } catch (err) {
        if (axios.isAxiosError(err)) {
            const responseData = err.response.data;

            if (err.response?.status == 400)
                throw new BadRequestError(responseData.message);
            if (err.response?.status == 401)
                throw new UnauthenticatedError(responseData.message);
            if (err.response?.status == 403)
                throw new ForbiddenError(responseData.message);
            if (err.response?.status == 404)
                throw new UnexpectedError(responseData.message);
            if (err.response?.status == 500)
                throw new InternalServerError(responseData.message);
        }
        throw new UnexpectedError();
    }
};

export const login = async (data) => {
    try {
        const result = await apiClient.post('auth/login', data);
        return result.data;
    } catch (err) {
        if (axios.isAxiosError(err)) {
            const responseData = err.response.data;

            if (err.response?.status == 400)
                throw new BadRequestError(responseData.message);
            if (err.response?.status == 401)
                throw new UnauthenticatedError(responseData.message);
            if (err.response?.status == 403)
                throw new ForbiddenError(responseData.message);
            if (err.response?.status == 404)
                throw new UnexpectedError(responseData.message);
            if (err.response?.status == 500)
                throw new InternalServerError(responseData.message);
        }
        throw new UnexpectedError();
    }
};

export const forgetPassword = async (email) => {
    try {
        await apiClient.post("auth/forget-password", {
            email,
        });
    } catch (err) {
        if (axios.isAxiosError(err)) {
            const responseData = err.response.data;

            if (err.response?.status == 400)
                throw new BadRequestError(responseData.message);
            if (err.response?.status == 401)
                throw new UnauthenticatedError(responseData.message);
            if (err.response?.status == 403)
                throw new ForbiddenError(responseData.message);
            if (err.response?.status == 404)
                throw new UnexpectedError(responseData.message);
            if (err.response?.status == 500)
                throw new InternalServerError(responseData.message);
        }
        throw new UnexpectedError();
    }
};

export const resetPassword = async (data) => {
    try {
        await apiClient.put(`auth/reset-password?token=${data.token}`, data);
    } catch (err) {
        if (axios.isAxiosError(err)) {
            const responseData = err.response.data;

            if (err.response?.status == 400)
                throw new BadRequestError(responseData.message);
            if (err.response?.status == 401)
                throw new UnauthenticatedError(responseData.message);
            if (err.response?.status == 403)
                throw new ForbiddenError(responseData.message);
            if (err.response?.status == 404)
                throw new UnexpectedError(responseData.message);
            if (err.response?.status == 500)
                throw new InternalServerError(responseData.message);
        }
        throw new UnexpectedError();
    }
};
