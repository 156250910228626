import axios from "axios";
import config from "../../../config";
import { overmind } from "../../../src/index"

export const apiClient = axios.create({
    baseURL: config.appUrl,
    headers: { 'Content-Type': 'application/json' }
});

// Add a request interceptor to add the authorization header
apiClient.interceptors.request.use((config) => {
    const token = overmind.state.accessToken;
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});