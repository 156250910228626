import React, { useState, useEffect, useRef } from "react";
import Header from "../header";
import blogDefaultImage from "../../assets/img/blogDefaultImage.png";
import videosDefaultImage from "../../assets/img/videosDefaultImage.png";

import {
  bannerimg,
  GratuateIcon,
  Icon01,
  Icon02,
  Icon03,
  Icon04,
  Join,
  PencilIcon,
  CountriesIcon,
  MainCover,
  Goals,
} from "../imagepath";
import TopCategory from "./slider/topCategory";
import Loginbg from "../../assets/img/BG-Lifeproject2.png";
import UserLove from "../../assets/img/user-love.jpg";
import Footer from "../footer";
import Testimonial from "./slider/testimonial";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";
import { useSpring, animated } from "react-spring";
import { useTranslation } from "react-i18next";
import { useActions, useAppState } from "../../presenter";
import { formatDate } from "../../utils/date";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { onMessageListener } from "../../firebase/firebase";

export const Home = () => {
  var settings = {
    //autoWidth: true,
    items: 2,
    margin: 25,
    dots: true,
    nav: true,
    navText: [
      '<i className="fas fa-arrow-left"></i>',
      '<i className="fas fa-arrow-right"></i>',
    ],

    loop: false,
    rewind: true,
    responsiveClass: true,
    responsive: {
      0: {
        items: 1,
      },
      768: {
        items: 3,
      },
      1170: {
        items: 4,
      },
    },
  };
  const [blogs, setBlogs] = useState([]);
  const [podcasts, setPodcasts] = useState([]);
  const [podcastLink, setPodcastLink] = useState(null);
  const [videos, setVideos] = useState([]);
  const [videoLink, setVideoLink] = useState(null);
  const { user } = useAppState();
  const { getHomepageData } = useActions();
  const [totalCourses, setTotalCourses] = useState();
  const [totalStudents, setTotalStudents] = useState();
  const [totalCountries, setTotalCountries] = useState();
  const { t, i18n } = useTranslation();

  const handleCarouselInit = (carousel) => {
    const $carousel = carousel.$element;
    const maxHeight = Math.max(
      ...$carousel.find(".owl-item").map((i, el) => $(el).height())
    );
    $carousel.find(".owl-item").height(maxHeight);
  };

  useEffect(() => {
    AOS.init({ duration: 1000, once: true });
  }, []);

  // console.log(user.language);
  useEffect(() => {
    getHomepageData({
      language: user?.language ?? i18n.language.toUpperCase(),
    }).then((data) => {
      setTotalCourses(data.totalCourses);
      setTotalStudents(data.totalStudents);
      setTotalCountries(data.totalCountries);
      setBlogs(data.highlightedBlogs);
      setPodcasts(data.podcasts.podcasts);
      setPodcastLink(data.podcasts.podcastLink.value);
      setVideos(data.videos.videos);
      setVideoLink(data.videos.youtubeLink.value);
    });
  }, []);
  function Number({ n }) {
    const { number } = useSpring({
      from: { number: 0 },
      number: n,
      delay: 200,
      config: { mass: 1, tension: 20, friction: 10 },
    });
    return <animated.div>{number.to((n) => n.toFixed(0))}</animated.div>;
  }

  onMessageListener();

  return (
    <>
      <div className="main-wrapper">
        <Header activeMenu={"Home"} />
        {/* banner */}

        <section
          className="home-slide d-flex align-items-center"
          style={{ backgroundImage: "url(" + Loginbg + ")" }}>
          <div className="container" style={{ marginTop: "3%" }}>
            <div className="row ">
              <div className="col-md-7 my-auto">
                <div className="home-slide-face aos" data-aos="fade-up">
                  <div className="home-slide-text ">
                    <h1>{t("home.cover-title")}</h1>
                    <p>{t("home.cover-second-title")}</p>
                  </div>
                  <div className="banner-content">
                    <ul className="nav  dis-banner-content header-navbar-rht">
                      <li className="nav-item">
                        <Link className="nav-link header-sign" to="/login">
                          {t("home.btn1")}
                        </Link>
                      </li>
                      <li className="nav-item mr-5">
                        <Link
                          className="nav-link header-login"
                          to="https://calendly.com">
                          {t("home.btn2")}
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-5 d-flex align-items-center">
                <div className="girl-slide-img aos" data-aos="fade-up">
                  <img src={MainCover} alt="" />
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* /banner */}

        {/* Home banner bottom */}
        <section className="section student-course">
          <div className="container">
            <div className="course-widget">
              <div className="row justify-content-md-center">
                <div className="col-lg-3 col-md-6">
                  <div className="course-full-width">
                    <div
                      className="blur-border course-radius align-items-center aos"
                      data-aos="fade-up">
                      <div className="online-course d-flex align-items-center">
                        <div className="about-count">
                          <div className="course-img">
                            <img src={PencilIcon} alt="" />
                          </div>
                        </div>
                        <div className="course-inner-content">
                          <h4>
                            <span className="d-flex">
                              <Number n={totalCourses ?? 0} />
                            </span>
                          </h4>
                          <p>دورات</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 d-flex">
                  <div className="course-full-width">
                    <div
                      className="blur-border course-radius aos"
                      data-aos="fade-up">
                      <div className="online-course d-flex align-items-center">
                        <div className="about-count">
                          <div className="course-img">
                            <img src={GratuateIcon} alt="" />
                          </div>
                        </div>
                        <div className="course-inner-content">
                          <h4>
                            <span className="d-flex">
                              <Number n={2000} />+
                            </span>
                          </h4>
                          <p>مشارك</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 d-flex">
                  <div className="course-full-width">
                    <div
                      className="blur-border course-radius aos"
                      data-aos="fade-up">
                      <div className="online-course d-flex align-items-center">
                        <div className="about-count">
                          <div className="course-img">
                            <img src={CountriesIcon} alt="" />
                          </div>
                        </div>
                        <div className="course-inner-content">
                          <h4>
                            <span className="d-flex">
                              <Number n={totalCountries ?? 0} />
                            </span>
                          </h4>
                          <p>دولة</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Home banner bottom */}

        {/* Podcasts */}
        <TopCategory podcasts={podcasts} podcastLink={podcastLink} />
        {/* Podcasts */}

        {/* Blogs */}
        <section className="section new-course d-none">
          <div className="container">
            {/* Feature Instructors */}
            <div className="feature-instructors">
              <div className="section-header aos" data-aos="fade-up">
                <div className="section-sub-head">
                  <h2>{t("home.latestBlogs")}</h2>
                </div>
                <div className="all-btn all-category d-flex align-items-center">
                  <Link to="/blog-list" className="btn btn-primary">
                    {t("home.allBlogs")}
                  </Link>
                </div>
              </div>
              <div>
                {blogs && blogs.length > 0 && (
                  <OwlCarousel
                    {...settings}
                    className="owl-carousel blogs-slide owl-theme aos"
                    data-aos="fade-up">
                    {blogs.map((blog) => (
                      <div
                        key={blog.id + blog.language}
                        className="card text-center instructors-widget">
                        <div className="instructors-img card-img-top">
                          <Link
                            to={`/blog-details/${blog.id}?language=${blog.language}`}>
                            <img
                              className="w-100"
                              alt=""
                              src={blog.image?.url ?? blogDefaultImage}
                              style={{ height: "140px" }}
                            />
                          </Link>
                        </div>
                        <div className="card-body px-0">
                          <h5
                            className="card-title"
                            style={{ fontSize: "1rem" }}>
                            <Link
                              to={`/blog-details/${blog.id}?language=${blog.language}`}>
                              {blog.title.slice(0, 50).trim()}
                              {blog.title.length > 50 ? "..." : ""}
                            </Link>
                          </h5>
                          <p
                            className={
                              blog.language === "AR"
                                ? "card-text rtl"
                                : "card-text ltr"
                            }
                            style={{ fontSize: "0.8rem" }}>
                            {blog.description.slice(0, 25).trim()}
                            {blog.description.length > 25 ? "..." : ""}
                          </p>
                        </div>
                      </div>
                    ))}
                  </OwlCarousel>
                )}
              </div>
            </div>
          </div>
        </section>
        {/* Blogs */}

        {/* Master Skills */}
        <section className="section master-skill">
          <div className="container">
            <div className="row">
              <div className="col-lg-7 col-md-12">
                <div className="section-header aos" data-aos="fade-up">
                  <div className="section-sub-head">
                    <span>{t("home.benefits.title1")}</span>
                    <h2>{t("home.benefits.title2")}</h2>
                  </div>
                </div>
                <div className="section-text aos" data-aos="fade-up">
                  <p>{t("home.benefits.title3")}</p>
                </div>
                <div className="career-group aos" data-aos="fade-up">
                  <div className="row">
                    <div className="col-lg-6 col-md-6 d-flex">
                      <div className="certified-group blur-border d-flex">
                        <div className="get-certified d-flex align-items-center">
                          <div className="blur-box">
                            <div className="certified-img ">
                              <img src={Icon01} alt="" className="img-fluid" />
                            </div>
                          </div>
                          <p>{t("home.benefits.one")}</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 d-flex">
                      <div className="certified-group blur-border d-flex">
                        <div className="get-certified d-flex align-items-center">
                          <div className="blur-box">
                            <div className="certified-img ">
                              <img src={Icon02} alt="" className="img-fluid" />
                            </div>
                          </div>
                          <p>{t("home.benefits.two")}</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 d-flex">
                      <div className="certified-group blur-border d-flex">
                        <div className="get-certified d-flex align-items-center">
                          <div className="blur-box">
                            <div className="certified-img ">
                              <img src={Icon03} alt="" className="img-fluid" />
                            </div>
                          </div>
                          <p>{t("home.benefits.three")}</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 d-flex">
                      <div className="certified-group blur-border d-flex">
                        <div className="get-certified d-flex align-items-center">
                          <div className="blur-box">
                            <div className="certified-img ">
                              <img src={Icon04} alt="" className="img-fluid" />
                            </div>
                          </div>
                          <p>{t("home.benefits.four")}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-5 col-md-12 d-flex">
                <div className="career-img aos" data-aos="fade-up">
                  <img
                    src={Goals}
                    alt=""
                    className="img-fluid"
                    style={{ maxWidth: "100%" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* /Master Skills */}

        {/* Videos */}
        <section className="section new-course videos-section d-none">
          <div className="container">
            <div className="section-header aos" data-aos="fade-up">
              <div className="section-sub-head">
                <h2>{t("home.videos")}</h2>
              </div>
              {videoLink && (
                <div className="all-btn all-category d-flex align-items-center">
                  <Link
                    to={videoLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn btn-primary">
                    {t("home.allVideos")}
                  </Link>
                </div>
              )}
            </div>
            <div className="section-text aos" data-aos="fade-up">
              <p className="mb-0">{t("home.videosDescription")}</p>
            </div>
            <div className="course-feature">
              <div className="row">
                {videos.map((video) => (
                  <div key={video.id} className="col-lg-6 col-md-6 col-sm-12">
                    <div className="course-box aos" data-aos="fade-up">
                      <div className="row align-items-center">
                        <div className="col-6 position-relative">
                          <Link
                            to={video.videoLink}
                            target="_blank"
                            rel="noopener noreferrer">
                            <img
                              className="img-fluid position-block w-100"
                              alt=""
                              src={video.image?.url ?? videosDefaultImage}
                              style={{ height: "170px", borderRadius: "12px" }}
                            />
                            <div
                              className="price position-absolute bottom-3 end-3"
                              style={{
                                minWidth: "40px",
                                display: "flex",
                                background: "#333333",
                                cursor: "pointer",
                              }}>
                              <svg
                                style={{ marginTop: "1px" }}
                                width="8"
                                height="10"
                                viewBox="0 0 8 10"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                  d="M1.34967 9.01656C1.12745 9.161 0.902341 9.16922 0.674341 9.04122C0.446786 8.91367 0.333008 8.71656 0.333008 8.44989V1.54989C0.333008 1.28322 0.446786 1.08589 0.674341 0.957891C0.902341 0.830335 1.12745 0.83878 1.34967 0.983224L6.78301 4.43322C6.98301 4.56656 7.08301 4.75545 7.08301 4.99989C7.08301 5.24434 6.98301 5.43322 6.78301 5.56656L1.34967 9.01656Z"
                                  fill="white"
                                />
                              </svg>
                              <h3
                                style={{
                                  color: "#FFFFFF",
                                  marginInlineStart: "4px",
                                }}>
                                {video.duration}
                              </h3>
                            </div>
                          </Link>
                        </div>
                        <div className="col-6">
                          <h3
                            className="title fs-4 fw-normal"
                            style={{ padding: "0px 20px" }}>
                            <Link
                              to={video.videoLink}
                              target="_blank"
                              rel="noopener noreferrer">
                              {video.title}
                            </Link>
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
        {/* Videos */}

        {/* Reviews */}
        {/* <section
          className="section user-love"
          style={{ backgroundImage: "url(" + UserLove + ")" }}
        >
          <div className="container">
            <div className="section-header white-header aos" data-aos="fade-up">
              <div className="section-sub-head feature-head text-center">
                <span>{t('home.reviewsTitle')}</span>
                <h2>Users-love-us Don't take it from us.</h2>
              </div>
            </div>
          </div>
        </section> */}
        {/* <Testimonial experiences={experiences} /> */}
        {/* <section className="section" style={{ margin: "100px" }}>
          <div className="exp-container d-flex">
            {experiences && experiences.length > 0 && (
              <OwlCarousel
                {...settings}
                className="owl-carousel exp-slide owl-theme aos"
                data-aos="fade-up">
                {experiences.map((experience) => (
                  <div
                    key={experience.id}
                    className="card flex-grow-1 d-flex flex-column">
                    <div className="card-body flex-grow-1">
                      <h4 className="card-title">
                        <img src="https://img.icons8.com/ultraviolet/40/000000/quote-left.png" />
                      </h4>
                      <div className="template-demo">
                        <p>{experience.body}</p>
                      </div>
                    </div>
                    <div className="card-footer mt-auto">
                      <div className="row">
                        <div className="col-sm-2">
                          <img
                            className="profile-pic"
                            src={
                              experience.image
                                ? experience.image?.url
                                : defaultImg
                            }
                          />
                        </div>
                        <div className="col-sm-10">
                          <div className="profile">
                            <h4 className="cust-name">{experience.name}</h4>
                            <p className="cust-profession">
                              {experience.title}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </OwlCarousel>
            )}
          </div>
        </section> */}
        {/* Reviews */}

        {/* Footer */}
        <Footer />
        {/* /Footer */}
      </div>
    </>
  );
};

export default Home;
