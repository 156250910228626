import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Footer from "../../../footer";
import Header from "../../../header";
import { useForm } from 'react-hook-form';
import { useActions, useAppState } from "../../../../presenter";
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { BusinessModelValidator } from "../../../../validators/business-model.validator";

const AddBusinessModel = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get("id");
    const { loadingBtn } = useAppState();
    const navigate = useNavigate();

    const { getBusinessModelQuestion, addBusinessModelQuestion, updateBusinessModelQuestion } = useActions();

    const { register, handleSubmit, setValue, formState: { errors } } = useForm({
        resolver: yupResolver(BusinessModelValidator),
        defaultValues: {
            question: "",
        }
    });

    const goBack = () => navigate("/admin/business-model");

    useEffect(() => {
        if (id) {
            getBusinessModelQuestion(id)
                .then(data => {
                    console.log('getQuestion', data);
                    setValue("question", data.question);
                });
        }
    }, [id, setValue]);

    const onSubmit = async (data) => {
        const result = id ? await updateBusinessModelQuestion({ id, data }) : await addBusinessModelQuestion(data);
        if (result) goBack();
    };


    return (
        <>
            <div className="main-wrapper">
                <Header />
                <div className="page-content">
                    <div className="container" style={{ marginTop: "70px" }}>
                        <div className="row">
                            {/* Profile Details */}
                            <div className="settings-widget profile-details">
                                <div className="settings-menu p-0">
                                    <div className="profile-heading">
                                        <h3>{id ? t('updateQuestion') : t('addQuestion')}</h3>
                                    </div>
                                    <div className="checkout-form personal-address add-course-info">

                                        <form onSubmit={handleSubmit(onSubmit)}>
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="form-group">
                                                        <label>{t('form.question')}</label>
                                                        <input className="form-control" {...register("question")} />
                                                        {errors.question && <p className="error">{t(errors.question.message)}</p>}
                                                    </div>
                                                </div>
                                                <div className="update-profile un-subscribe">
                                                    <button type="submit" className="btn btn-primary" disabled={loadingBtn}>
                                                        {loadingBtn && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>}
                                                        {id ? t('updateQuestion') : t('addQuestion')}
                                                    </button>

                                                    <button type="button" onClick={goBack} className="btn btn-danger">
                                                        {t('cancel')}
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>

                            {/* Profile Details */}
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
};

export default AddBusinessModel;