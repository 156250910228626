import "regenerator-runtime/runtime";

import { handleError } from "../errors";
import { toast } from "react-toastify";
import { changePositionByLanguage } from "../../utils/toastify";
import i18n from "../../locales/i18n";



export const listNotifications = async (context, { currentPage, limit , q}) => {
    context.state.loading = true;
    const {notifications , meta} = await context.effects.listNotifications(currentPage, limit , q);
    if(q && currentPage === 1) context.state.notifications = []
    if(currentPage > 1) context.state.notifications.push(...notifications)    
    else context.state.notifications = notifications
    
      
    context.state.loading = false;
    return meta.totalPages
};

export const pushNotifications = async (context, data) => {
    context.state.loading = true;
    if(data)  toast.success(i18n.t(`${data.title}`), {
      position: changePositionByLanguage(i18n.language),
  });
    context.state.notifications.unshift( data) 
    context.state.loading = false;
 
};

export const markAllNotificationsAsRead = async(context)=>{
   const isNotificationsUpdated = await context.effects.markAllNotificationsAsRead()
   if(isNotificationsUpdated.count > 0){
    context.state.notifications.map((notifi)=> notifi.status = 'InActive')
   }
  

}

export const markNotificationAsRead = async(context , id)=>{
  const isNotificationUpdated =  await context.effects.markNotificationAsRead(id)
  if(isNotificationUpdated) {
       const index =  context.state.notifications.findIndex((notifi) => notifi.id === id);
       context.state.notifications.splice(index,1 ,isNotificationUpdated)
  

  }
  

}