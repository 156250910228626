import * as Yup from "yup";

export const PodcastValidator = Yup.object().shape({
    title: Yup.string("form.validation.string").required("form.validation.required"),
    transcript: Yup.string("form.validation.string").required("form.validation.required"),
    duration: Yup.number("form.validation.number").required("form.validation.required").positive("form.validation.durationPositive"),
    episodeNumber: Yup.number("form.validation.number").required("form.validation.required").positive("form.validation.episodeNumberPositive"),
    soundCloudLink: Yup.string("form.validation.string").url("form.validation.soundCloudLinkURL"),
    applePodcastLink: Yup.string("form.validation.string").url("form.validation.applePodcastLinkURL"),
    googlePodcastLink: Yup.string("form.validation.string").url("form.validation.googlePodcastLinkURL"),
    fileAttachment: Yup.mixed().notRequired(),
});