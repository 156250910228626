import * as Yup from "yup";

export const AccountSettingValidator = Yup.object().shape({
    email: Yup.string("form.validation.string")
        .email("form.validation.email"),

    phoneNumber: Yup.string("form.validation.string"),

    currentPassword: Yup.string("form.validation.string"),

    newPassword: Yup.string("form.validation.string"),

    confirmPassword: Yup.string("form.validation.string"),
});
