import React, { useEffect, useState } from "react";
import Header from "../../header";
import Footer from "../../footer";
import StudentSideBar from "../sidebar";
import PageChange from "../../page-change";
import { Link } from "react-router-dom";
import Select from "react-select";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useActions, useAppState } from "../../../presenter";
import { CardValidator } from "../../../validators/card.validator";
import { useTranslation } from "react-i18next";
import { deleteCard } from "../../../presenter/actions";
import Swal from "sweetalert2";

export default function StudentPayment() {
  const [cards, setCards] = useState([]);
  const { t: translate } = useTranslation();
  const { t } = useTranslation();
  const { getCountries, getCards, addCard, deleteCard } = useActions();
  const { loadingBtn, loading } = useAppState();
  const [countries, setCountries] = useState([]);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(CardValidator),
  });

  useEffect(() => {
    fetchCards();
    getCountries().then((result) => {
      setCountries(result);
    });
  }, []);

  const fetchCards = () => {
    getCards().then((data) => {
      setCards(data);
    });
  };

  const handleDeleteCard = async (id) => {
    await deleteCard(id);
    fetchCards();
  };

  const onSubmit = async (data) => {
    const { cardNumber, ...result } = data;
    const newCardNumber = cardNumber.replace(/\s/g, ""); // remove spaces
    await addCard({ ...result, cardNumber: newCardNumber });
    fetchCards();
  };

  // if (loading) return <PageChange />
  return (
    <div className="main-wrapper">
      <Header activeMenu={"Payment"} />
      {/* Student Dashboard */}
      <div className="page-content">
        <div className="container" style={{ marginTop: "70px" }}>
          <div className="row">
            {/* sidebar */}
            <StudentSideBar activeMenu={"Payment"} />
            {/* sidebar */}

            {/* Profile Details */}
            <div className="col-xl-9 col-md-8">
              {cards && cards.length > 0 && (
                <div className="settings-widget profile-details">
                  <div className="settings-inner-blk p-0">
                    <div className="profile-heading">
                      <h3>{t("Saved Cards")}</h3>
                    </div>
                    <div className="comman-space">
                      <div className="payment-method-blk">
                        {cards &&
                          cards.map((card) => (
                            <div
                              key={card.id}
                              className="payment-list d-flex align-items-center">
                              <div className="custom-me-auto">
                                <h5>
                                  {t("Visa ending in")}{" "}
                                  {card.cardNumber.substr(-4)}
                                </h5>
                                <p>
                                  {t("Expires in")} {card.expirationMonth}/
                                  {card.expirationYear}
                                </p>
                              </div>
                              <div>
                                <ul className="grp-action-style inline-inline">
                                  <li className="list-inline-item ">
                                    <button
                                      data-bs-toggle="tooltip"
                                      data-bs-placement="top"
                                      title={t("Remove")}
                                      className="btn btn-action-style btn-action-delete"
                                      onClick={() =>
                                        Swal.fire({
                                          title: t("deleteConfirmation"),
                                          text: t("deleteWarning"),
                                          icon: "warning",
                                          showCancelButton: true,
                                          confirmButtonColor: "#3085d6",
                                          cancelButtonColor: "#d33",
                                          cancelButtonText: t("cancel"),
                                          confirmButtonText: t("delete"),
                                        }).then((result) => {
                                          if (result.isConfirmed) {
                                            handleDeleteCard(card.id);
                                          }
                                        })
                                      }>
                                      <i className="fa-regular fa-trash-can"></i>
                                    </button>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div className="student-widget pay-method">
                <div className="student-widget-group add-course-info">
                  <div className="cart-head">
                    <h3 style={{ fontWeight: "600", fontSize: "24px" }}>
                      {t("Add a new card")}
                    </h3>
                  </div>
                  <div className="checkout-form">
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="form-group">
                            <label className="form-control-label">
                              {t("Card Number")}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              onInput={(e) => {
                                const input = e.target;
                                const value = input.value.replace(/\s/g, "");
                                let newValue = "";
                                for (let i = 0; i < value.length; i++) {
                                  if (i > 0 && i % 4 === 0) {
                                    newValue += " ";
                                  }
                                  newValue += value[i];
                                }
                                input.value = newValue;
                              }}
                              placeholder="XXXX XXXX XXXX XXXX"
                              {...register("cardNumber")}
                            />
                          </div>
                          <p className="invalid-feedback d-block">
                            {translate(errors.cardNumber?.message)}
                          </p>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <label className="form-label">{t("Month")}</label>
                            <input
                              className="form-control"
                              type="number"
                              placeholder="MM"
                              {...register("expirationMonth")}
                            />
                          </div>
                          <p className="invalid-feedback d-block">
                            {translate(errors.expirationMonth?.message)}
                          </p>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <label className="form-label">{t("Year")}</label>
                            <input
                              className="form-control"
                              type="number"
                              min={new Date().getFullYear()}
                              max={new Date().getFullYear() + 10}
                              placeholder="YYYY"
                              {...register("expirationYear")}
                            />
                          </div>
                          <p className="invalid-feedback d-block">
                            {translate(errors.expirationYear?.message)}
                          </p>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <label htmlFor="sel1" className="form-label">
                              {t("form.country")}
                            </label>
                            <select
                              {...register("country")}
                              className="form-select"
                              id="sel1"
                              name="country">
                              <option value="">
                                {t("form.selectCountry")}
                              </option>
                              {countries &&
                                countries.map((country) => (
                                  <option value={country.name}>
                                    {country.name}
                                  </option>
                                ))}
                            </select>
                          </div>
                          <p className="invalid-feedback d-block">
                            {translate(errors.country?.message)}
                          </p>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group">
                            <label className="form-control-label">
                              {t("Name on Card")}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="KHALID MOHAMMED"
                              {...register("holderName")}
                            />
                          </div>
                          <p className="invalid-feedback d-block">
                            {translate(errors.holderName?.message)}
                          </p>
                        </div>
                        <div className="payment-btn">
                          <button
                            className="btn btn-primary"
                            type="submit"
                            disabled={loadingBtn}>
                            {loadingBtn && (
                              <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"></span>
                            )}
                            {t("Add Card")}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            {/* Profile Details */}
          </div>
        </div>
      </div>
      {/* Student Dashboard */}
      <Footer />
    </div>
  );
}
