import React from "react";
import {
  Bell,
  Calendar,
  Clipboard,
  CreditCard,
  Lock,
  Power,
  RefreshCw,
  Settings,
  Trash2,
  User,
  UserPlus,
  Globe,
} from "react-feather";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useActions, useAppState } from "../../../presenter";
import { ProfileBg, User11, defaultImg } from "../../imagepath";

export default function StudentSideBar({ activeMenu }) {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const { updateGeneralSettings } = useActions();
  const { user } = useAppState();

  const handleLanguageChange = () => {
    const newLanguage = i18n.language === "ar" ? "en" : "ar";
    i18n.changeLanguage(newLanguage);
    updateGeneralSettings({
      language: newLanguage.toUpperCase(),
      firstName: user.firstName,
      lastName: user.lastName,
      birthDate: new Date(user.birthDate).toISOString().substr(0, 10),
      country: user.country || "",
      description: user.description || "",
      job: user.job || "",
    });

    const isRtl = i18n.language === "ar";
    const elem = document.getElementById("root");
    if (isRtl) {
      elem.classList.add("rtl");
      elem.classList.remove("ltr");
    } else {
      elem.classList.add("ltr");
      elem.classList.remove("rtl");
    }
  };
  return (
    <div className="col-xl-3 col-md-4 theiaStickySidebar">
      <div className="settings-widget dash-profile mb-3">
        <div className="settings-menu p-0">
          <div className="profile-bg">
            {/* <h5>Beginner</h5> */}
            {/* <img src={ProfileBg} alt="" /> */}
            <div style={{ height: "100px" }}></div>
            <div className="profile-img">
              <img src={user.image?.url ?? user.image ?? defaultImg} alt="" />
            </div>
          </div>
          <div className="profile-group">
            <div className="profile-name text-center">
              <h4>
                {user.firstName} {user.lastName}
              </h4>
              <p>{t("footer.student")}</p>
            </div>
            <div className="go-dashboard text-center">
              <Link to="/student-dashboard" className="btn btn-primary">
                {t("goToDashboard")}
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="settings-widget account-settings">
        <div className="settings-menu">
          <h3>{t("settings.title")}</h3>
          <ul>
            {/* <li
              className={
                activeMenu === "MyCourses" ? "nav-item active" : "nav-item"
              }
            >
              <Link to="/course-student" className="nav-link">
                <Settings size={20} /> My Courses
              </Link>
            </li> */}
            <li
              className={
                activeMenu === "EditProfile" ? "nav-item active" : "nav-item"
              }>
              <Link to="/setting-edit-profile" className="nav-link">
                <Settings size={20} /> {t("settings.general")}
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/setting-student-security"
                className={
                  activeMenu === "Security" ? "nav-item active" : "nav-item"
                }>
                <User size={20} /> {t("settings.account")}
              </Link>
            </li>
            {/* <li className="nav-item">
              <Link
                to="/setting-student-social-profile"
                className={
                  activeMenu === "SocialProfile"
                    ? "nav-item active"
                    : "nav-item"
                }
              >
                <RefreshCw size={20} /> Social Profiles
              </Link>
            </li> */}
            <li className="nav-item">
              <Link
                to="/setting-student-notification"
                className={
                  activeMenu === "Notification" ? "nav-item active" : "nav-item"
                }>
                <Bell size={20} /> {t("settings.notifications.notifications")}
              </Link>
            </li>
            {/* 
            <li className="nav-item">
              <Link
                to="/setting-student-payment"
                className={
                  activeMenu === "Payment" ? "nav-item active" : "nav-item"
                }>
                <CreditCard size={20} /> {t("settings.payment")}
              </Link>
            </li> */}

            <li className="nav-item">
              <Link className="nav-link" onClick={handleLanguageChange}>
                <Globe size={20} />{" "}
                {i18n.language === "ar" ? "English" : "العربية"}
              </Link>
            </li>

            {/* <li className="nav-item">
              <Link
                to="/setting-student-privacy"
                className={
                  activeMenu === "Privacy" ? "nav-item active" : "nav-item"
                }
              >
                <Lock size={20} /> Profile Privacy
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/setting-student-delete-profile"
                className={
                  activeMenu === "DeleteProfile"
                    ? "nav-item active"
                    : "nav-item"
                }
              >
                <Trash2 size={20} /> Delete Profile
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/setting-student-accounts"
                className={
                  activeMenu === "Accounts" ? "nav-item active" : "nav-item"
                }
              >
                <User size={20} /> Linked Accounts
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/setting-student-referral"
                className={
                  activeMenu === "Referral" ? "nav-item active" : "nav-item"
                }
              >
                <UserPlus size={20} /> Referrals
              </Link>
            </li>
            <li className="nav-item">
              <Link to="login" className="nav-link">
                <Power size={20} /> Sign Out
              </Link>
            </li>
            {activeMenu === "Ticket" ? (
              <li className="nav-item active">
                <Link to="login" className="nav-link">
                  <Clipboard size={20} /> Support Tickets
                </Link>
              </li>
            ) : null} */}
          </ul>
          {/* <h3>SUBSCRIPTION</h3>
          <ul>
            <li className="nav-item">
              <Link
                to="/setting-student-subscription"
                className={
                  activeMenu === "Subscription" ? "nav-item active" : "nav-item"
                }
              >
                <Calendar size={20} /> My Subscriptions
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/setting-student-billing"
                className={
                  activeMenu === "Billing" ? "nav-item active" : "nav-item"
                }
              >
                <CreditCard size={20} /> Billing Info
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/setting-student-payment"
                className={
                  activeMenu === "Payment" ? "nav-item active" : "nav-item"
                }
              >
                <CreditCard size={20} /> Payment
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/setting-student-invoice"
                className={
                  activeMenu === "Invoice" ? "nav-item active" : "nav-item"
                }
              >
                <Clipboard size={20} /> Invoice
              </Link>
            </li>
          </ul>  */}
        </div>
      </div>
    </div>
  );
}
