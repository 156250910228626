import React, { useEffect, useState } from "react";
import { useActions, useAppState } from "../../../presenter";
import { useTranslation } from "react-i18next";
import PageChange from "../../page-change";
import Chart from "./surveyProps/chart";
import ProjectPositionSection from "./surveyProps/projectPositionSection";
import BussinessModelSection from "./surveyProps/bussinessModelSection";
import SuggestedFieldSection from "./surveyProps/suggestedFieldSection";
import SelectedSuggestionSection from "./surveyProps/selectedSuggestionSection";
const PDFDocument = () => {
  const { t } = useTranslation();
  const { screenshots } = useAppState();

  useEffect(() => {
    // console.log('screenshots', screenshots);
    if (screenshots) {
      setTimeout(() => {
        window.print();
      }, 1000);
    }
  }, [screenshots]);

  if (!screenshots) return <PageChange />;
  return (
    <>
      <div className="container-fluid" style={{ marginTop: "50px" }}>
        {screenshots &&
          Object.values(screenshots).map((image, i) => (
            <div>
              {i == 0 && (
                <>
                  <h1 className="result-header">نتيجة إتمام الرحلة</h1>
                  <Chart />
                </>
              )}
              {i == 1 && (
                <div className="mb-10">
                  <h1 className="suggestion-header ">المقترحات</h1>
                  <div className="d-flex justify-content-center no-break">
                    <SuggestedFieldSection />
                  </div>
                </div>
              )}
              {i == 2 && (
                <div className="no-break">
                  <h1 className="selected-suggestion-header">
                    المقترحات المختارة
                  </h1>
                  <SelectedSuggestionSection />
                </div>
              )}
              {i == 3 && (
                <div className="no-break">
                  <h1 className="selection-header">اختياراتك</h1>
                  <div className="row justify-content-center">
                    <div className="col-md-8 col-lg-6 col-print-6">
                      <ProjectPositionSection />
                    </div>
                    <div className="col-md-8 col-lg-6 col-print-6">
                      <BussinessModelSection />
                    </div>
                  </div>
                </div>
              )}

              {i < 3 && <div className="separator"></div>}
            </div>
          ))}
      </div>
    </>
  );
};

export default PDFDocument;
