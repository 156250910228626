import "regenerator-runtime/runtime";

import i18n from "../../locales/i18n";
import { toast } from "react-toastify";
import { handleError } from "../errors";
import { changePositionByLanguage } from "../../utils/toastify";

export const listAdmins = async (context , { currentPage, limit, q }) => {
    try {
        context.state.loading = true;
        const result = await context.effects.listAdmins(currentPage, limit, q);
        context.state.loading = false;
        return result;
    } catch (err) {
        context.state.loading = false;
        handleError(err);
    }
};

export const getAdmin = async (context, id) => {
    try {
        context.state.loading = true;
        const result = await context.effects.getAdmin(id);
        context.state.loading = false;
        return result;
    } catch (err) {
        context.state.loading = false;
        handleError(err);
    }
};

export const createAdmin= async (context, data) => {
    try {
      context.state.loadingBtn = true;
      const result = await context.effects.createAdmin(data);
      context.state.loadingBtn = false;
      toast.success(i18n.t("form.success.created"), {
        position: changePositionByLanguage(i18n.language),
    });
      return result;
    } catch (err) {
      context.state.loadingBtn = false;
      handleError(err);
    }
  };


  export const updateAdminProfile = async (context, data) => {
    try {
        context.state.loadingBtn = true;
        const user = await context.effects.updateAdminProfile(data);
        context.state.user = user;
        localStorage.setItem('user', JSON.stringify(user));

        toast.success(i18n.t("form.success.settingsUpdate"), {
            position: changePositionByLanguage(i18n.language),
        });
        context.state.loadingBtn = false;
        return user;
    } catch (err) {
        handleError(err);
        context.state.loadingBtn = false;
    }
};
export const updateAdmin = async (context, data) => {
    try {
        context.state.loadingBtn = true;
        const result = await context.effects.updateAdmin(data);
        context.state.loadingBtn = false;

        toast.success(i18n.t("form.success.updated"), {
            position: changePositionByLanguage(i18n.language),
        });
        return result;
    } catch (err) {
        context.state.loadingBtn = false;
        handleError(err);
    }
};

export const deleteAdmin = async (context, id) => {
    try {
        context.state.loadingBtn = true;
        const result = await context.effects.deleteAdmin(id);
        context.state.loadingBtn = false;

        toast.success(i18n.t("form.success.deleted"), {
            position: changePositionByLanguage(i18n.language),
        });
        return result;
    } catch (err) {
        context.state.loadingBtn = false;
        handleError(err);
    }
};

export const adminDashboardStats = async (context) => {
    try {
     
      const result = await context.effects.adminDashboardStats();
     
      return result;
    } catch (err) {
      handleError(err);
    }
  };
  
  