import React, { useEffect, useState } from "react";

import { useActions, useAppState } from "../../../../presenter";
import { useTranslation } from "react-i18next";

function SuggestedFieldSection() {
  const [fields, setFields] = useState([]);
  const { topResults, suggestedFields } = useAppState();
  const { getField } = useActions();
  const { t } = useTranslation();

  useEffect(() => {
    if (suggestedFields) {
      const arr = JSON.parse(JSON.stringify(suggestedFields));
      setFields(arr);
      return;
    }
    const fetchTopResults = async () => {
      // Fetch subfields for each field
      const fieldPromises = topResults.map((result, i) =>
        fetchFieldData(result.fieldId, i)
      );

      // Wait for all subfield fetches to complete
      const fetchedFields = await Promise.all(fieldPromises);
      console.log("fetchhh", fetchedFields);

      // Update fields state with fetched fields
      setFields(fetchedFields);
    };
    fetchTopResults();
  }, [suggestedFields, topResults]);

  const isSubfieldSelected = (field, subfield) => {
    return field.selectedSubfields?.some(
      (selectedSubfield) => selectedSubfield.subfieldId === subfield.id
    );
  };
  // Fetch subfields for a specific fieldId
  const fetchFieldData = async (fieldId, i) => {
    try {
      // Make API call to fetch field data using fieldId
      const data = await getField(fieldId);
      const res = data;
      return res; // Return the fetched field data
    } catch (error) {
      console.error(`Error fetching field data for ${fieldId}:`, error);
      return null;
    }
  };
  return (
    <section>
      <div className="d-flex flex-wrap justify-content-center">
        {fields?.map((field) => (
          <div
            key={field.id}
            className="suggested-field-card col col-print-4 mb-4">
            <h3 style={{ textAlign: "center" }}>{field.name}</h3>
            <div className="field-desc-title">{t("fieldDescription")}</div>
            <div className="field-desc">{field.description}</div>
            <div className="field-desc-title">{t("suggestedSubfields")}</div>
            <div className="subfield-checkboxes">
              {field?.subfields?.map((subfield) => (
                <div className="subfield-checkbox mb-3" key={subfield.id}>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id={subfield.id}
                      name={subfield.name}
                      checked={isSubfieldSelected(field, subfield)}
                      disabled={true}
                    />
                    <label className="form-check-label" htmlFor={subfield.id}>
                      {subfield.name}
                    </label>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}

export default SuggestedFieldSection;
