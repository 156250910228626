import moment from "moment";
export const formatTime = (timestamp, language) => {
  const date = moment(timestamp);
  const timeAgoAr = date.locale(language).fromNow(); //
  return timeAgoAr;
};

export const formatTimeMonth = (timestamp, language) => {
  const date = moment(timestamp);
  var month = date.format("MMMM");

  // const timeAgoAr = month.locale(language) //
  return month;
};

export const formatTimeDate = (timestamp, language) => {
  const date = moment(timestamp);

  const formattedDateStr = date
    .locale(language)
    .format("Do MMMM YYYY، hh:mm:ss a");
  return formattedDateStr;
};



export const translateTime = (minutes, language) => {
  const duration = moment(minutes ,'minutes');

  const formatStrings = {
    en: 'h [hours and] m [minutes]',
    ar: 'h [ساعة و] m [دقيقة]',
  };

  const formatString = formatStrings[language] || formatStrings.en;

  moment.locale(language);

  const formattedDuration = duration.format(formatString);

  return formattedDuration;
};