import React from "react";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import OwlCarousel from "react-owl-carousel";
import { ForgotPasswordCover, logo } from "../../imagepath";
import { useState } from "react";
import { Link } from "react-router-dom";
import { ResetPasswordFormValidator } from "../../../validators/reset-password.validator"
import { useActions, useAppState } from "../../../presenter";
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

const NewPassword = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState(null);

  const [eye, seteye] = useState(true)
  const [eye2, seteye2] = useState(true)
  const { t: translate } = useTranslation();
  const { t } = useTranslation();
  const { loadingBtn } = useAppState();
  const { resetPassword } = useActions();
  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(ResetPasswordFormValidator),
  });

  const onEyeClick = () => {
    seteye(!eye)
  }

  const onEyeClick2 = () => {
    seteye2(!eye2)
  }

  var settings = {
    //autoWidth: true,
    items: 2,
    margin: 25,
    dots: true,
    nav: true,
    navText: [
      '<i className="fas fa-arrow-left"></i>',
      '<i className="fas fa-arrow-right"></i>',
    ],

    loop: false,
    responsiveClass: true,
    responsive: {
      0: {
        items: 1,
      },
      768: {
        items: 1,
      },
      1170: {
        items: 1,
      },
    },
  };

  const onSubmit = async (data) => {
    if (password !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }
    const token = new URLSearchParams(location.search).get("token");
    await resetPassword({ newPassword: data.newPassword, token });
    navigate("/login");
  }
  
  return (
    <>
      <div className="main-wrapper">
        <div className="row">
          {/* Login Banner */}
          <div className="col-md-6 login-bg">

            <OwlCarousel
              {...settings}
              className="owl-carousel login-slide owl-theme">
              <div className="welcome-login">
                <div className="login-banner">
                  <img
                    src={ForgotPasswordCover}
                    className="img-fluid"
                    alt="Logo"
                  />
                </div>
                <div className="mentor-course text-center">
                  <h2>
                    {t("form.resetPasswordTitle")}
                  </h2>
                  <p>
                    {t("form.resetPasswordSubtitle")}
                  </p>
                </div>
              </div>
            </OwlCarousel>

          </div>
          {/* /Login Banner */}
          <div className="col-md-6 login-wrap-bg">
            {/* Login */}
            <div className="login-wrapper">
              <div className="loginbox">
                <div className="img-logo">
                  <img
                    src={logo}
                    className="img-fluid"
                    alt="Logo"
                  />
                  <div className="back-home">
                    <Link to="/">{t("form.backHome")}</Link>
                  </div>
                </div>
                <h1>{t('form.setupPassword')}</h1>
                <form onSubmit={handleSubmit(async (data) => await onSubmit(data))}>
                  <div className="form-group">
                    <label className="form-control-label">{t("form.password")}</label>
                    <div className="pass-group" id="passwordInput">
                      <input
                        {...register("newPassword")}
                        onChange={(e) => setPassword(e.target.value)}
                        className="form-control pass-input"
                        placeholder={t("form.enterPassword")}
                        type={eye ? "password" : "text"} />
                      <span onClick={onEyeClick} className={`fa toggle-password feather-eye" ${eye ? "fa-eye" : "fa-eye-slash"}`} />
                      <span className="pass-checked">
                        <i className="feather-check" />
                      </span>
                    </div>
                    <p className="invalid-feedback d-block">
                      {translate(errors.newPassword?.message)}
                    </p>
                    {/* <div className="password-strength" id="passwordStrength">
                      <span id="poor" />
                      <span id="weak" />
                      <span id="strong" />
                      <span id="heavy" />
                    </div>
                    <div id="passwordInfo" /> */}
                  </div>
                  <div className="form-group">
                    <label className="form-control-label">
                      {t('form.confirmNewPassword')}
                    </label>
                    <div className="pass-group" id="passwordInputs">
                      <input
                        {...register("confirmPassword")}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        className="form-control pass-input"
                        placeholder={t("form.enterPassword")}
                        type={eye2 ? "password" : "text"} />
                      <span onClick={onEyeClick2} className={`fa toggle-password feather-eye" ${eye2 ? "fa-eye" : "fa-eye-slash"}`} />
                      <span className="pass-checked">
                        <i className="feather-check" />
                      </span>
                    </div>
                    <p className="invalid-feedback d-block">
                      {translate(errors.confirmPassword?.message)}
                    </p>
                    {error && <p className="invalid-feedback d-block">
                      {error}
                    </p>}
                    {/* <div className="password-strength" id="passwordStrengths">
                      <span id="poors" />
                      <span id="weaks" />
                      <span id="strongs" />
                      <span id="heavys" />
                    </div> */}
                    {/* <div id="passwordInfos" /> */}
                  </div>
                  {/* <div className="form-check remember-me">
                    <label className="form-check-label mb-0">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="remember"
                      />{" "}
                      I agree to the <Link to="#">Terms of Service</Link> and{" "}
                      <Link to="#">Privacy Policy.</Link>
                    </label>
                  </div> */}
                  <div className="d-grid">
                    <button className="btn btn-start" type="submit" disabled={loadingBtn}>
                      {loadingBtn && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>}
                      {t('form.resetPassword')}
                    </button>
                  </div>
                  <div className="reset-account">
                    <p className="mb-0">
                      {t('form.haveAcc')} <Link to="/login">{t('form.signIn')}</Link>
                    </p>
                  </div>
                </form>
              </div>
            </div>
            {/* /Login */}
          </div>
        </div>
      </div>
    </>
  );
};

export default NewPassword;
