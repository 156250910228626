import * as Yup from "yup";



export const AddAdminValidator = Yup.object().shape({
    firstName: Yup.string("form.validation.string")
    .required("form.validation.required"),

    lastName: Yup.string("form.validation.string")
    .required("form.validation.required"),

    email: Yup.string("form.validation.string")
        .email("form.validation.email").required("form.validation.required"),

        birthDate: Yup.date().required("form.validation.required"),


    password: Yup.string("form.validation.string").required("form.validation.required"),
    

});
