import React from "react";
import config from "../../../config";

import { GoSell } from "@tap-payments/gosell";
import { useAppState } from "../../presenter";
import { logo } from "../imagepath";

export default function PaymentComponent(props) {
  const { user } = useAppState();

  return (
    <GoSell
      gateway={{
        publicKey: config.paymentPublicKey,
        language: "en",
        contactInfo: true,
        supportedCurrencies: ["SAR"],
        supportedPaymentMethods: "all",
        saveCardOption: true,
        customerCards: true,
        notifications: "standard",
        backgroundImg: {
          url: logo,
          opacity: "0.5",
        },
        style: {
          base: {
            color: "#535353",
            lineHeight: "18px",
            fontFamily: "sans-serif",
            fontSmoothing: "antialiased",
            fontSize: "16px",
            "::placeholder": {
              color: "rgba(0, 0, 0, 0.26)",
              fontSize: "15px",
            },
          },
          invalid: {
            color: "red",
            iconColor: "#fa755a ",
          },
        },
      }}
      customer={{
        first_name: user.firstName,
        last_name: user.lastName,
        email: user.email
      }}
      order={{
        amount: props.order.amount,
        currency: "SAR",
        items: [
          {
            id: props.order.itemId,
            name: props.order.name,
            description: props.order.description,
            quantity: props.order.quantity,
            amount_per_unit: props.order.amount,
            total_amount: props.order.amount,
          }
        ],
        shipping: null,
        taxes: null
      }}
      transaction={{
        mode: "charge",
        charge: {
          saveCard: true,
          threeDSecure: true,
          description: "",
          statement_descriptor: "Sample",
          metadata: {},
          receipt: {
            email: true,
            sms: true,
          },
          redirect: props.redirectUrl,
          post: null,
        },
      }}
    />
  );
}
