import React from "react";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Error, Error2, logo } from "../../../imagepath";

const Unathorized = () => {
    const navigate = useNavigate();

    const goBack = () => navigate(-1);

    useEffect(() => {
        document.body.classList.add("error-page");

        return () => document.body.classList.remove("error-page");
    }, []);

    return (
        <>
            <div className="main-wrapper">
                <div className="error-box">
                    <div className="error-logo">
                        <Link to="/">
                            <img src={logo} className="img-fluid" alt="Logo" />
                        </Link>
                    </div>
                    <div className="error-box-img">
                        <img src={Error2} alt="" className="img-fluid" />
                    </div>
                    <h3 className="h2 mb-3"> Unauthorized</h3>
                    <p className="h4 font-weight-normal">
                        You do not have access to the requested page.
                    </p>
                    <button onClick={goBack} className="btn btn-primary">
                        Go Back
                    </button>
                </div>
            </div>
        </>
    );
};

export default Unathorized;
