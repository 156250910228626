import React, { useState, useEffect } from "react";
import StudentHeader from "../header";
import Header from "../../header";
import Footer from "../../footer";
import { User11, defaultImg } from "../../imagepath";
import { Link } from "react-router-dom";
import StudentSideBar from "../sidebar";
import Select from "react-select";
import { useActions, useAppState } from "../../../presenter";
import { AdminProfileValidator } from "../../../validators/admin-profile.validator";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Password from "antd/es/input/Password";
import { avatarValidator } from "./../../../utils/avatar-validation";

export default function InstructorEditProfile() {
  const { user, loadingBtn } = useAppState();
  const [avatar, setAvatar] = useState(user.image?.url || defaultImg);
  const [userData, setUserData] = useState({
    firstName: user.firstName || "",
    lastName: user.lastName || "",
    // phone: user.phone || '',
    email: user.email || "",
    birthDate: new Date(user.birthDate).toISOString().substr(0, 10) || "",
    country: user.country || "",
    description: user.description || "",
    job: user.job || "",
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(AdminProfileValidator),
  });
  const { t: translate } = useTranslation();
  const { t } = useTranslation();
  const { updateAvatar, updateAdminProfile } = useActions();

  const style = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      backgroundColor: "white",
      width: "100%",
      height: "40px",
      color: "black",
      minHeight: "40px",
      border: "1px solid #e9ecef",
      paddingLeft: "5px",
      // This line disable the blue border
      boxShadow: state.isFocused ? 0 : 0,
      borderRadius: state.isSelected ? "0" : "10px",
      fontSize: "14px",
      "&:hover": {
        cursor: "pointer",
      },
      outline: "none",
    }),
    menu: (base) => ({ ...base, marginTop: "0px" }),
    menuList: (base) => ({ ...base, padding: "0" }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#76ACD6" : "white",
      color: "black",
      fontSize: "14px",
      "&:hover": {
        backgroundColor: "#76ACD6",
        // #dddddd
      },
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (base, state) => ({
      ...base,
      color: "black",
      transform: state.selectProps.menuIsOpen ? "rotate(-180deg)" : "rotate(0)",
      transition: "250ms",
    }),
  };
  const [error, setError] = useState(null);
  const [eye2, seteye2] = useState(true);
  const [eye3, seteye3] = useState(true);

  const onEyeClick2 = () => {
    seteye2(!eye2);
  };

  const onEyeClick3 = () => {
    seteye3(!eye3);
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    const errorMessege = avatarValidator(file);
    if (errorMessege) {
      setError(errorMessege);
      return;
    }
    setError("");
    reader.onloadend = async () => {
      const formData = new FormData();
      formData.append("file", file);
      const result = await updateAvatar(formData);
      if (result) setAvatar(reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleChange = (event) => {
    let { name, value } = event.target;

    setUserData((prevUserData) => ({
      ...prevUserData,
      [name]: value,
    }));
  };

  const onSubmit = async (data) => {
    const { confirmPassword, ...updatedData } = data;

    await updateAdminProfile(updatedData).then(() => setError(""));
  };

  return (
    <div className="main-wrapper">
      <Header activeMenu={"Profile"} />
      {/* Student Dashboard */}
      <div className="page-content">
        <div className="container" style={{ marginTop: "70px" }}>
          <div className="row">
            {/* Sidebar */}
            <StudentSideBar activeMenu="EditProfile" />
            {/* Sidebar */}

            {/* Profile Details */}
            <div className="col-xl-9 col-md-8">
              <div className="settings-widget profile-details">
                <div className="settings-menu p-0">
                  <div className="profile-heading">
                    <h3>{t("settings.general")}</h3>
                    {/* <p>
                      You have full control to manage your own account setting.
                    </p> */}
                  </div>
                  <div className="course-group mb-0 d-flex">
                    <div className="course-group-img d-flex align-items-center">
                      <img
                        src={avatar}
                        alt=""
                        className="img-fluid"
                        style={{ width: "800px" }}
                      />
                      <div className="course-name">
                        <h4>{t("settings.avatar")}</h4>
                        <p>{t("settings.avatarDesc")}</p>
                        <p className="invalid-feedback d-block">{t(error)}</p>
                      </div>
                    </div>
                    <div className="profile-share d-flex align-items-center justify-content-center">
                      <label
                        htmlFor="avatar-upload"
                        className={
                          "btn btn-success" +
                          (loadingBtn ? " disable-label" : "")
                        }>
                        {loadingBtn && (
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"></span>
                        )}
                        {t("settings.upload")}
                      </label>
                      <input
                        type="file"
                        accept="image/*"
                        id="avatar-upload"
                        className="d-none"
                        onChange={handleFileUpload}
                      />
                    </div>
                  </div>
                  <div className="checkout-form personal-address add-course-info ">
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label className="form-control-label">
                              {t("form.firstName")}
                            </label>
                            <input
                              {...register("firstName")}
                              type="text"
                              className="form-control"
                              value={userData.firstName}
                              onChange={handleChange}
                              placeholder={t("form.enterFirstName")}
                            />
                          </div>
                          <p className="invalid-feedback d-block">
                            {translate(errors.firstName?.message)}
                          </p>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label className="form-control-label">
                              {t("form.lastName")}
                            </label>
                            <input
                              {...register("lastName")}
                              type="text"
                              className="form-control"
                              value={userData.lastName}
                              onChange={handleChange}
                              placeholder={t("form.enterLastName")}
                            />
                          </div>
                          <p className="invalid-feedback d-block">
                            {translate(errors.lastName?.message)}
                          </p>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label className="form-control-label">
                              {t("form.email")}
                            </label>
                            <input
                              {...register("email")}
                              type="text"
                              className="form-control"
                              value={userData.email}
                              onChange={handleChange}
                              placeholder={t("form.email")}
                            />
                          </div>
                          <p className="invalid-feedback d-block">
                            {translate(errors.email?.message)}
                          </p>
                        </div>
                        <div className="checkout-form personal-address">
                          <div className="personal-info-head">
                            <h4>{translate("form.changePassword")}</h4>
                          </div>
                          <div className="row">
                            <div className="col-lg-6">
                              <form action="#">
                                <div className="form-group">
                                  <label className="form-control-label">
                                    {translate("form.password")}
                                  </label>
                                  <div
                                    className="pass-group"
                                    id="passwordInput">
                                    <input
                                      {...register("password")}
                                      type={eye2 ? "password" : "text"}
                                      className="form-control pass-input"
                                      placeholder={t("form.enterNewPassword")}
                                    />
                                    <span
                                      onClick={onEyeClick2}
                                      className={`fa toggle-password feather-eye" ${
                                        eye2 ? "fa-eye" : "fa-eye-slash"
                                      }`}
                                    />
                                    <span className="pass-checked">
                                      <i className="feather-check" />
                                    </span>
                                    <p className="invalid-feedback d-block">
                                      {translate(errors.password?.message)}
                                    </p>
                                  </div>

                                  <div id="passwordInfo"></div>
                                </div>
                                <div className="form-group">
                                  <label className="form-control-label">
                                    {t("form.confirmNewPassword")}
                                  </label>
                                  <div
                                    className="pass-group"
                                    id="passwordInputs">
                                    <input
                                      {...register("confirmPassword")}
                                      type={eye3 ? "password" : "text"}
                                      className="form-control"
                                      placeholder={t("form.confirmNewPassword")}
                                    />
                                    <span
                                      onClick={onEyeClick3}
                                      className={`fa toggle-password feather-eye" ${
                                        eye3 ? "fa-eye" : "fa-eye-slash"
                                      }`}
                                    />
                                    <span className="pass-checked">
                                      <i className="feather-check" />
                                    </span>
                                    <p className="invalid-feedback d-block">
                                      {translate(
                                        errors.confirmPassword?.message
                                      )}
                                    </p>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>

                        {/* <div className="col-lg-6">
                          <div className="form-group">
                            <label className="form-control-label">{t('form.job')}</label>
                            <input
                              {...register("job")}
                              type="text"
                              className="form-control"
                              value={userData.job}
                              onChange={handleChange}
                              placeholder={t('form.enterJob')}
                            />
                          </div>
                          <p className="invalid-feedback d-block">
                            {translate(errors.job?.message)}
                          </p>
                        </div>
                         <div className="col-lg-6">
                          <div className="form-group">
                            <label className="form-control-label">
                              Address Line 2 (Optional)
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Address"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label className="form-control-label">City</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter your City"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label className="form-control-label">
                              ZipCode
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter your Zipcode"
                            />
                          </div>
                        </div> */}
                        <div className="update-profile">
                          <button
                            type="submit"
                            className="btn btn-primary"
                            disabled={loadingBtn}>
                            {loadingBtn && (
                              <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"></span>
                            )}
                            {t("settings.save")}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {/* Profile Details */}
          </div>
        </div>
      </div>
      {/* Student Dashboard */}
      <Footer />
    </div>
  );
}
