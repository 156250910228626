import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useActions, useAppState } from "../../../../presenter";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";

const Curriculum = ({ nextTab3, prevTab2, data, updateFormData }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const lang = queryParams.get("lang");
  const id = queryParams.get("id");
  const { loadingBtn } = useAppState();
  const { getCourse, addMaterial, updateMaterial, deleteMaterial } =
    useActions();
  const [isFormValid, setIsFormValid] = useState(false);
  const [sections, setSections] = useState([
    {
      id: 1,
      title: "",
      lessons: [
        {
          id: 1,
          title: "",
          description: "",
          videoUrl: "",
          duration: "",
        },
      ],
    },
  ]);

  useEffect(() => {
    if (data.sections) setSections(data.sections);
  }, [data]);

  useEffect(() => {
    checkFormValidity();
  }, [sections]);

  useEffect(() => {
    if (id && !data.sections) {
      getCourse({ id, language: lang }).then((data) => {
        let sec = JSON.parse(JSON.stringify(data.course.sections));
        sec.map((item) => {
          item.sectionId = item.id;
          item.id = item.order;
          item.lessons.map((lesson) => {
            lesson.lessonId = lesson.id;
            lesson.id = lesson.order;
            lesson.materials.map((material) => {
              if (material.type == "File") material.material = material.url;
            });
          });
        });
        setSections(sec);
      });
    }
  }, [id]);

  const addSection = (sectionIndex) => {
    const newSection = {
      id: sections.length + 1,
      title: "",
      lessons: [
        {
          id: 1,
          title: "",
          description: "",
          videoUrl: "",
          duration: "",
        },
      ],
    };
    setSections([...sections, newSection]);
  };

  const removeSection = (sectionIndex) => {
    const updatedSections = [...sections];
    updatedSections.splice(sectionIndex, 1);
    setSections(updatedSections);
  };

  const addLesson = (sectionIndex) => {
    const newLesson = {
      id: sections[sectionIndex].lessons.length + 1,
      title: "",
      description: "",
      videoUrl: "",
      duration: "",
    };
    const updatedSections = [...sections];
    updatedSections[sectionIndex].lessons.push(newLesson);
    setSections(updatedSections);
  };

  const removeLesson = (sectionIndex, lessonIndex) => {
    const updatedSections = [...sections];
    updatedSections[sectionIndex].lessons.splice(lessonIndex, 1);
    setSections(updatedSections);
  };

  const handleNextTab = () => {
    if (isFormValid) {
      updateFormData({ sections });
      nextTab3();
    }
  };

  const checkFormValidity = () => {
    const hasEmptyField = sections.some((section) => {
      return (
        section.title === "" ||
        section.lessons.some((lesson) => {
          return (
            lesson.title === "" ||
            lesson.description === "" ||
            lesson.videoUrl === "" ||
            lesson.duration === ""
          );
        })
      );
    });

    const isVideoUrlValid = sections.every((section) => {
      return section.lessons.every((lesson) => {
        return isValidUrl(lesson.videoUrl);
      });
    });

    setIsFormValid(!hasEmptyField && isVideoUrlValid);
  };

  const isValidUrl = (url) => {
    const urlPattern = /^(ftp|http|https):\/\/[^ "]+$/;
    return urlPattern.test(url);
  };

  const handleAddMaterial = async (
    section,
    lesson,
    material,
    materialIndex
  ) => {
    const courseFormData = new FormData();
    courseFormData.append("name", material.name);
    courseFormData.append("type", material.type);
    if (material.type == "File")
      courseFormData.append("material", material.material);
    else courseFormData.append("url", material.url);
    const result = await addMaterial({
      courseId: section.courseId,
      language: section.language,
      sectionId: section.sectionId,
      lessonId: lesson.lessonId,
      data: courseFormData,
    });
    const updatedSections = [...sections];
    updatedSections[section.order - 1].lessons[lesson.order - 1].materials[
      materialIndex
    ].id = result.id;
    if (material.type == "File") {
      updatedSections[section.order - 1].lessons[lesson.order - 1].materials[
        materialIndex
      ].file = result.url;
    }
    setSections(updatedSections);
  };

  const handleUpdateMaterial = async (section, lesson, material) => {
    const courseFormData = new FormData();
    courseFormData.append("name", material.name);
    courseFormData.append("type", material.type);
    if (material.type == "File" && material.file) {
      courseFormData.append("material", material.file);
    } else if (material.type == "Url") {
      courseFormData.append("url", material.url);
    }
    const result = await updateMaterial({
      courseId: section.courseId,
      language: section.language,
      sectionId: section.sectionId,
      lessonId: lesson.lessonId,
      materialId: material.id,
      data: courseFormData,
    });
  };

  const handleDeleteMaterial = (section, lesson, material, materialIndex) => {
    Swal.fire({
      title: t("deleteConfirmation"),
      text: t("deleteWarning"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: t("cancel"),
      confirmButtonText: t("delete"),
    }).then(async (result) => {
      if (result.isConfirmed) {
        await deleteMaterial({
          courseId: section.courseId,
          language: section.language,
          sectionId: section.sectionId,
          lessonId: lesson.lessonId,
          materialId: material.id,
        });
        const updatedSections = [...sections];
        updatedSections[section.order - 1].lessons[
          lesson.order - 1
        ].materials.splice(materialIndex, 1);
        setSections(updatedSections);
      }
    });
  };

  return (
    <>
      <fieldset className="field-card" style={{ display: "block" }}>
        <div className="add-course-info">
          <div className="add-course-inner-header">
            <h4>{t("Curriculum")}</h4>
          </div>
          <div className="add-course-section">
            <button onClick={addSection} className="btn">
              {t("Add Section")}
            </button>
          </div>
          <div className="add-course-form">
            {/* Section Repeater */}
            {sections.map((section, sectionIndex) => (
              <div
                className="curriculum-grid"
                key={sectionIndex}
                style={{ position: "relative" }}>
                <div style={{ display: "inline-flex" }}>
                  <div className="curriculum-head">
                    {/* <p>{section.title}</p> */}
                    <input
                      type="text"
                      className="form-control"
                      name="title"
                      value={section.title}
                      placeholder={t("sectionTitle")}
                      onChange={(e) => {
                        const updatedSections = [...sections];
                        updatedSections[sectionIndex].title = e.target.value;
                        setSections(updatedSections);
                      }}
                    />
                    <button
                      onClick={() => addLesson(sectionIndex)}
                      className="btn"
                      style={{
                        position: "absolute",
                        left: "100px",
                      }}>
                      {t("Add Lesson")}
                    </button>
                  </div>
                  <div
                    className="faq-left"
                    style={{
                      position: "absolute",
                      left: 43,
                      top: 30,
                    }}>
                    <div
                      className="me-0"
                      style={{ cursor: "pointer" }}
                      onClick={() => removeSection(sectionIndex)}>
                      <i className="far fa-trash-can" />
                    </div>
                  </div>
                </div>
                <div className="curriculum-info">
                  <div id={`accordion-${sectionIndex}`}>
                    {/* Lesson Repeater */}
                    {section.lessons.map((lesson, lessonIndex) => (
                      <div className="faq-grid" key={lessonIndex}>
                        <div className="faq-header">
                          <Link
                            className="collapsed faq-collapse"
                            data-bs-toggle="collapse"
                            data-bs-target={`#collapse-${sectionIndex}-${lessonIndex}`}
                            aria-expanded="false"
                            aria-controls={`collapse-${sectionIndex}-${lessonIndex}`}>
                            <span>
                              {lessonIndex + 1}. {lesson.title}
                            </span>
                          </Link>
                          <div className="faq-left">
                            {/* <Link to="#">
                      <i className="far fa-pen-to-square me-1" />
                    </Link> */}
                            <div
                              style={{ cursor: "pointer" }}
                              className="me-0"
                              onClick={() =>
                                removeLesson(sectionIndex, lessonIndex)
                              }>
                              <i className="far fa-trash-can" />
                            </div>
                          </div>
                        </div>
                        <div
                          id={`collapse-${sectionIndex}-${lessonIndex}`}
                          className="collapse"
                          data-bs-parent={`#accordion-${sectionIndex}`}>
                          <div className="faq-body">
                            <form>
                              <div className="form-group">
                                <label>{t("Title")}</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="title"
                                  value={lesson.title}
                                  onChange={(e) => {
                                    const updatedSections = [...sections];
                                    updatedSections[sectionIndex].lessons[
                                      lessonIndex
                                    ].title = e.target.value;
                                    setSections(updatedSections);
                                  }}
                                />
                              </div>
                              <div className="form-group">
                                <label>{t("Description")}</label>
                                <textarea
                                  className="form-control"
                                  name="description"
                                  value={lesson.description}
                                  onChange={(e) => {
                                    const updatedSections = [...sections];
                                    updatedSections[sectionIndex].lessons[
                                      lessonIndex
                                    ].description = e.target.value;
                                    setSections(updatedSections);
                                  }}
                                />
                              </div>
                              <div className="form-group">
                                <label>{t("Video URL")}</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="videoUrl"
                                  value={lesson.videoUrl}
                                  onChange={(e) => {
                                    const updatedSections = [...sections];
                                    updatedSections[sectionIndex].lessons[
                                      lessonIndex
                                    ].videoUrl = e.target.value;
                                    setSections(updatedSections);
                                  }}
                                />
                                <small className="form-text text-muted">
                                  {t("Video URL must be a valid url")}
                                </small>
                              </div>
                              <div className="form-group">
                                <label>{t("Duration")}</label>
                                <input
                                  type="number"
                                  className="form-control"
                                  name="duration"
                                  value={lesson.duration}
                                  onChange={(e) => {
                                    const updatedSections = [...sections];
                                    updatedSections[sectionIndex].lessons[
                                      lessonIndex
                                    ].duration = e.target.value;
                                    setSections(updatedSections);
                                  }}
                                />
                              </div>

                              {id && lesson.lessonId && (
                                <div className="curriculum-grid">
                                  <div className="curriculum-head">
                                    <p>{t("Materials")}</p>
                                    <button
                                      type="button"
                                      onClick={() => {
                                        const updatedSections = [...sections];
                                        updatedSections[sectionIndex].lessons[
                                          lessonIndex
                                        ].materials.push({
                                          name: "",
                                          type: "Url",
                                          url: "",
                                        });
                                        setSections(updatedSections);
                                      }}
                                      className="btn">
                                      {t("Add Material")}
                                    </button>
                                  </div>
                                  <div id={`accordion-${sectionIndex}`}>
                                    {/* Material Repeater */}
                                    {id &&
                                      lesson.materials?.map(
                                        (material, materialIndex) => (
                                          <div
                                            className="faq-grid"
                                            key={materialIndex}>
                                            <div className="faq-header">
                                              <Link
                                                className="collapsed faq-collapse"
                                                data-bs-toggle="collapse"
                                                data-bs-target={`#collapse-${sectionIndex}-${lessonIndex}-${materialIndex}`}
                                                aria-expanded="false"
                                                aria-controls={`collapse-${sectionIndex}-${lessonIndex}-${materialIndex}`}>
                                                <span>{material.name}</span>
                                              </Link>
                                            </div>
                                            <div
                                              id={`collapse-${sectionIndex}-${lessonIndex}-${materialIndex}`}
                                              className="collapse"
                                              data-bs-parent={`#accordion-${sectionIndex}-${lessonIndex}-${materialIndex}`}>
                                              <div className="faq-body">
                                                <div className="form-group">
                                                  <label>{t("Name")}</label>
                                                  <input
                                                    type="text"
                                                    className="form-control"
                                                    name="name"
                                                    value={material.name}
                                                    onChange={(e) => {
                                                      const updatedSections = [
                                                        ...sections,
                                                      ];
                                                      updatedSections[
                                                        sectionIndex
                                                      ].lessons[
                                                        lessonIndex
                                                      ].materials[
                                                        materialIndex
                                                      ].name = e.target.value;
                                                      setSections(
                                                        updatedSections
                                                      );
                                                    }}
                                                  />
                                                </div>
                                                <div className="form-group">
                                                  <label>{t("Type")}</label>
                                                  <select
                                                    className="form-control"
                                                    name="type"
                                                    value={material.type}
                                                    onChange={(e) => {
                                                      const updatedSections = [
                                                        ...sections,
                                                      ];
                                                      if (
                                                        e.target.value === "Url"
                                                      ) {
                                                        updatedSections[
                                                          sectionIndex
                                                        ].lessons[
                                                          lessonIndex
                                                        ].materials[
                                                          materialIndex
                                                        ].url = "";
                                                      }
                                                      updatedSections[
                                                        sectionIndex
                                                      ].lessons[
                                                        lessonIndex
                                                      ].materials[
                                                        materialIndex
                                                      ].type = e.target.value;
                                                      setSections(
                                                        updatedSections
                                                      );
                                                    }}>
                                                    <option value="Url">
                                                      {t("URL")}
                                                    </option>
                                                    <option value="File">
                                                      {t("File")}
                                                    </option>
                                                  </select>
                                                </div>
                                                {material.type === "File" ? (
                                                  <div className="form-group">
                                                    <label>
                                                      {t("Material")}
                                                    </label>
                                                    {material.id &&
                                                    material.material ? (
                                                      <div>
                                                        {/* <p>File Uploaded</p> */}
                                                        <button
                                                          className="btn btn-warning"
                                                          type="button"
                                                          onClick={() => {
                                                            const updatedSections =
                                                              [...sections];
                                                            updatedSections[
                                                              sectionIndex
                                                            ].lessons[
                                                              lessonIndex
                                                            ].materials[
                                                              materialIndex
                                                            ].material = "";
                                                            setSections(
                                                              updatedSections
                                                            );
                                                          }}>
                                                          {t(
                                                            "Change file uploaded"
                                                          )}
                                                        </button>
                                                      </div>
                                                    ) : (
                                                      <input
                                                        type="file"
                                                        className="form-control"
                                                        name="material"
                                                        onChange={(e) => {
                                                          const updatedSections =
                                                            [...sections];
                                                          const file =
                                                            e.target.files[0];
                                                          updatedSections[
                                                            sectionIndex
                                                          ].lessons[
                                                            lessonIndex
                                                          ].materials[
                                                            materialIndex
                                                          ].material = file;
                                                          updatedSections[
                                                            sectionIndex
                                                          ].lessons[
                                                            lessonIndex
                                                          ].materials[
                                                            materialIndex
                                                          ].file = file;
                                                          setSections(
                                                            updatedSections
                                                          );
                                                        }}
                                                      />
                                                    )}
                                                  </div>
                                                ) : (
                                                  <div className="form-group">
                                                    <label>
                                                      {t("Material")}
                                                    </label>
                                                    <input
                                                      type="text"
                                                      className="form-control"
                                                      name="url"
                                                      value={material.url}
                                                      onChange={(e) => {
                                                        const updatedSections =
                                                          [...sections];
                                                        updatedSections[
                                                          sectionIndex
                                                        ].lessons[
                                                          lessonIndex
                                                        ].materials[
                                                          materialIndex
                                                        ].url = e.target.value;
                                                        setSections(
                                                          updatedSections
                                                        );
                                                      }}
                                                    />
                                                  </div>
                                                )}
                                                {!material?.id && (
                                                  <button
                                                    type="button"
                                                    className="btn btn-primary m-1"
                                                    disabled={
                                                      loadingBtn ||
                                                      !material.name ||
                                                      !material.type ||
                                                      (material.type ===
                                                        "File" &&
                                                        !material.material) ||
                                                      (material.type ===
                                                        "Url" &&
                                                        !material.url)
                                                    }
                                                    onClick={() => {
                                                      handleAddMaterial(
                                                        section,
                                                        lesson,
                                                        material,
                                                        materialIndex
                                                      );
                                                    }}>
                                                    {loadingBtn && (
                                                      <span
                                                        className="spinner-border spinner-border-sm"
                                                        role="status"
                                                        aria-hidden="true"></span>
                                                    )}
                                                    {t("Submit Material")}
                                                  </button>
                                                )}
                                                {material?.id && (
                                                  <button
                                                    type="button"
                                                    className="btn btn-primary m-1"
                                                    disabled={
                                                      loadingBtn ||
                                                      !material.name ||
                                                      !material.type ||
                                                      (material.type ===
                                                        "File" &&
                                                        !material.material) ||
                                                      (material.type ===
                                                        "Url" &&
                                                        !material.url)
                                                    }
                                                    onClick={() => {
                                                      handleUpdateMaterial(
                                                        section,
                                                        lesson,
                                                        material
                                                      );
                                                    }}>
                                                    {loadingBtn && (
                                                      <span
                                                        className="spinner-border spinner-border-sm"
                                                        role="status"
                                                        aria-hidden="true"></span>
                                                    )}
                                                    {t("Update Material")}
                                                  </button>
                                                )}
                                                {material?.id && (
                                                  <button
                                                    type="button"
                                                    className="btn btn-danger m-1"
                                                    disabled={loadingBtn}
                                                    onClick={() => {
                                                      handleDeleteMaterial(
                                                        section,
                                                        lesson,
                                                        material,
                                                        materialIndex
                                                      );
                                                    }}>
                                                    {t("Delete Material")}
                                                  </button>
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        )
                                      )}
                                    {/* Material Repeater End */}
                                  </div>
                                </div>
                              )}
                            </form>
                          </div>
                        </div>
                      </div>
                    ))}
                    {/* Lesson Repeater End */}
                  </div>
                </div>
              </div>
            ))}
            {/* Section Repeater End */}
            <div className="widget-btn">
              <button
                type="button"
                className="btn btn-black prev_btn"
                onClick={prevTab2}>
                {t("Previous")}
              </button>
              <button
                type="button"
                className={`btn btn-info-light next_btn ${
                  !isFormValid ? "disabled" : ""
                }`}
                onClick={handleNextTab}>
                {t("Next")}
              </button>
            </div>
          </div>
        </div>
      </fieldset>
    </>
  );
};

export default Curriculum;
