import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useForm, FormProvider, useFormContext } from "react-hook-form";
import Footer from "../../footer";
import PageHeader from "../header";
import { useActions, useAppState } from "../../../presenter";
import ReactApexChart from "react-apexcharts";
import PageChange from "../../page-change";
import { captureScreenshot } from "../../../utils/screenshot";
import Header from "../../header";
import { useTranslation } from "react-i18next";

const SuggestedField = ({ onClick: setPageChange }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [isFormValid, setIsFormValid] = useState(false);
  const [fields, setFields] = useState([]);
  const { topResults, suggestedFields } = useAppState();
  const { getField, setSuggestedFields, setScreenshots } = useActions();

  useEffect(() => {
    console.log("topResults", topResults);
    console.log("suggestedFields", suggestedFields);
    if (suggestedFields) {
      const arr = JSON.parse(JSON.stringify(suggestedFields));
      setFields(arr);
      return;
    }
    const fetchTopResults = async () => {
      // Fetch subfields for each field
      const fieldPromises = topResults.map((result, i) =>
        fetchFieldData(result.fieldId, i)
      );

      // Wait for all subfield fetches to complete
      const fetchedFields = await Promise.all(fieldPromises);
      console.log("fetchhh", fetchedFields);

      // Update fields state with fetched fields
      setFields(fetchedFields);
    };
    fetchTopResults();
  }, [suggestedFields, topResults]);

  useEffect(() => {
    // console.log("fieldssss", fields, fields.length);
    checkFormValidity();
  }, [fields]);

  // Fetch subfields for a specific fieldId
  const fetchFieldData = async (fieldId, i) => {
    try {
      // Make API call to fetch field data using fieldId
      const data = await getField(fieldId);
      const res = data;
      return res; // Return the fetched field data
    } catch (error) {
      console.error(`Error fetching field data for ${fieldId}:`, error);
      return null;
    }
  };

  const handleCheckboxChange = (event, fieldId, fieldName) => {
    const { checked } = event.target;
    const updatedFields = [...fields];
    const fieldIndex = updatedFields.findIndex((field) => field.id === fieldId);

    if (checked) {
      // Add the subfield to the field's selectedSubfields array
      if (fieldIndex !== -1) {
        if (!updatedFields[fieldIndex].selectedSubfields)
          updatedFields[fieldIndex].selectedSubfields = [];
        updatedFields[fieldIndex].selectedSubfields.push({
          subfieldId: event.target.id,
          subfieldName: event.target.name,
        });
      } else {
        updatedFields.push({
          id: fieldId,
          name: fieldName,
          selectedSubfields: [
            {
              subfieldId: event.target.id,
              subfieldName: event.target.name,
            },
          ],
        });
      }
    } else {
      // Remove the subfield from the field's selectedSubfields array
      if (fieldIndex !== -1) {
        const subfieldIndex = updatedFields[
          fieldIndex
        ].selectedSubfields.findIndex(
          (subfield) => subfield.subfieldId === event.target.id
        );
        if (subfieldIndex !== -1) {
          updatedFields[fieldIndex].selectedSubfields.splice(subfieldIndex, 1);
        }
      }
    }

    setFields(updatedFields);
  };

  const isSubfieldSelected = (field, subfield) => {
    return field.selectedSubfields?.some(
      (selectedSubfield) => selectedSubfield.subfieldId === subfield.id
    );
  };

  const checkFormValidity = () => {
    for (const field of fields) {
      if (field.selectedSubfields && field.selectedSubfields.length > 0) {
        setIsFormValid(true);
        return;
      }
    }
    setIsFormValid(false);
  };

  const handleNextClick = async () => {
    await setSuggestedFields(fields);

    const screenshot = await captureScreenshot(
      document.getElementById("suggestedFields")
    );

    if (screenshot) {
      await setScreenshots({ key: "suggestedFields", screenshot });
      setPageChange("selectedSuggestions");
      ``;
    }
  };

  if (fields.length == 0 || !topResults) return <PageChange />;

  return (
    <>
      <div id="suggestedFields">
        <div className="container-fluid survey-header" id="remove">
          <div className="row justify-content-between align-items-center py-3 bg-custom">
            <div className="col">
              <h6 className="text-light">{t("step2")}</h6>
              <h3 className="text-light">{t("suggestedFields")}</h3>
              <p className="text-light">{t("chooseSuggestedField")}</p>
            </div>
            <div className="col-auto">
              <div className="progress-circle">
                <div className="half-circle"></div>
                <span className="progress-text">2/4</span>
              </div>
            </div>
          </div>
        </div>

        <div style={{ marginBottom: "10rem" }}></div>
        <section>
          <h1 className="report-header">{t("suggestedFields")}</h1>
          <div className="d-flex flex-wrap justify-content-center">
            {fields.map((field) => (
              <div
                key={field.id}
                className="suggested-field-card col-lg-4 col-md-6 mb-4">
                <h3 style={{ textAlign: "center" }}>{field.name}</h3>
                <div className="field-desc-title">{t("fieldDescription")}</div>
                <div className="field-desc">{field.description}</div>
                <div className="field-desc-title">
                  {t("suggestedSubfields")}
                </div>
                <div className="subfield-checkboxes">
                  {field.subfields.map((subfield) => (
                    <div className="subfield-checkbox mb-3" key={subfield.id}>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id={subfield.id}
                          name={subfield.name}
                          checked={isSubfieldSelected(field, subfield)}
                          onChange={(event) =>
                            handleCheckboxChange(event, field.id, field.name)
                          }
                        />
                        <label
                          className="form-check-label"
                          htmlFor={subfield.id}>
                          {subfield.name}
                        </label>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </section>
      </div>
      <div className="widget-btn">
        <button
          type="button"
          className="btn btn-black prev_btn"
          onClick={() => setPageChange("results")}>
          {t("back")}
        </button>
        <button
          type="button"
          disabled={!isFormValid}
          onClick={handleNextClick}
          className="btn btn-info-light next_btn">
          {t("next")}
        </button>
      </div>
    </>
  );
};

export default SuggestedField;
