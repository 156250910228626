import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Multiselect } from "multiselect-react-dropdown";
// import { TagsInput } from "react-tag-input-component";
import { useActions } from "../../../../presenter";
import { useTranslation } from "react-i18next";

const Settings = ({ nextTab4, prevTab3, updateFormData }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const lang = queryParams.get("lang");
  const id = queryParams.get("id");
  const [isFormValid, setIsFormValid] = useState(false);
  const [options, setOptions] = useState([]);
  const { getCourse } = useActions();
  const [hasSurvey, setHasSurvey] = useState();
  const [formData, setFormData] = useState({
    tags: [],
    price: "",
    hasSurvey: false,
  });
  const { listTags } = useActions();

  useEffect(() => {
    listTags().then((result) => {
      setOptions(result);
    });
  }, []);

  useEffect(() => {
    checkFormValidity();
  }, [formData]);

  useEffect(() => {
    if (id) {
      getCourse({ id, language: lang }).then((data) => {
        setHasSurvey(data.hasSurvey);
        setFormData((prevFormData) => ({
          ...prevFormData,
          price: +data.course.price,
          tags: data.tags,
          hasSurvey: data.course.hasSurvey,
        }));
      });
    }
  }, [id]);

  const checkFormValidity = () => {
    const isValid = formData.tags && formData.tags.length > 0 && (formData.price || formData.price === 0);
    setIsFormValid(isValid);
  };

  const handleTagsChange = (tags) => {
    setFormData((prevState) => ({ ...prevState, tags }));
  };

  const handlePriceChange = (event) => {
    const value = event.target.value;
    setFormData((prevState) => ({ ...prevState, price: value }));
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    console.log(name, checked);
    setFormData((prevState) => ({
      ...prevState,
      hasSurvey: checked,
    }));
  };

  const handleNextTab = () => {
    if (isFormValid) {
      updateFormData(formData);
      nextTab4();
    }
  };

  return (
    <>
      <fieldset className="field-card" style={{ display: "block" }}>
        <div className="add-course-info">
          <div className="add-course-inner-header">
            <h4>{t("Requirements")}</h4>
          </div>
          <div className="add-course-form">
            <form action="#">
              <div className="form-group form-group-tagsinput">
                <label className="add-course-label">{t("Tags")}</label>
                <Multiselect
                  options={options}
                  selectedValues={formData.tags}
                  displayValue="name"
                  onSelect={handleTagsChange}
                  onRemove={handleTagsChange}
                />
              </div>
              <div className="form-group mb-0">
                <label className="add-course-label">{t("Price")}</label>
                <input
                  type="number"
                  className="form-control"
                  value={formData.price}
                  onChange={handlePriceChange}
                />
              </div>

              <div className="form-group mb-0">
                <div style={{ marginTop: "30px" }}>
                  <input
                    className="form-check-input big"
                    type="checkbox"
                    style={{ marginInlineEnd: "10px" }}
                    id="hasSurvey"
                    name="hasSurvey"
                    checked={formData.hasSurvey}
                    onChange={handleCheckboxChange}
                  />
                  <label className="form-check-label" htmlFor="hasSurvey">
                    {t("lifeProjectCourse")}
                  </label>
                </div>
              </div>
            </form>
          </div>
          <div className="widget-btn">
            <button
              type="button"
              className="btn btn-black prev_btn"
              to="#"
              onClick={prevTab3}>
              {t("Previous")}
            </button>
            <button
              type="button"
              className={`btn btn-success-dark ${
                !isFormValid ? "disabled" : ""
              }`}
              to="#"
              onClick={handleNextTab}>
              {id ? t("Edit Course") : t("Add Course")}
            </button>
          </div>
        </div>
      </fieldset>
    </>
  );
};

export default Settings;
