import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

const Success = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");
  return (
    <>
      <fieldset className="field-card" style={{ display: "block" }}>
        <div className="add-course-info">
          <div className="add-course-msg">
            <i className="fas fa-circle-check"></i>

            {id
              ? <h4>{t('form.success.updated')}</h4>
              : <h4>{t('form.success.created')}</h4>
            }
          </div>
        </div>
      </fieldset>
    </>
  );
};

export default Success;
