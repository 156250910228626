// import { Question, SurveyAnswerEnum } from "../../types/question";
import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

export default function QuestionInput({
  question,
  isHidden,
  setSavedSurveyQuestions,
  survey,
  isNextDisabled,
}) {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const [surveyResult, setSurveyResult] = useState({});
  const { t } = useTranslation();
  useEffect(() => {
    if (surveyResult?.questionId) {
      setSavedSurveyQuestions((prev) => {
        return { ...prev, [surveyResult?.questionId]: surveyResult.answer };
      });
    }
  }, [surveyResult]);
  return (
    <>
      <div
        className={` question-container card   ${
          errors?.answers?.[question.fieldId]?.[question.id] ? "red-border" : ""
        } ${isHidden ? "hide-item" : ""}`}>
        <div>
          <h5 className="font-bold">{question.text}</h5>
        </div>
        <div className="row justify-content-between align-items-center">
          <div className="col-12 col-md-6 col-lg-3">
            <label className="custom_check custom_one question-label">
              <input
                id={`question-${question.id}-strongly-inclined`}
                type="radio"
                defaultChecked={survey[question?.id] === "4" && true}
                {...register(`answers.${question.fieldId}.${question.id}`, {
                  required: "true",
                  onChange: (e) => {
                    setSurveyResult({
                      questionId: question?.id,
                      answer: e.target.value,
                    });
                  },
                })}
                value="4"
              />
              <span
                htmlFor={`question-${question.id}-strongly-inclined`}
                className="checkmark"></span>{" "}
              {t("stronglyInclined")}
            </label>
          </div>
          <div className="col-12 col-md-6 col-lg-3">
            <label className="custom_check custom_one question-label">
              <input
                id={`question-${question.id}-inclined`}
                type="radio"
                defaultChecked={survey[question?.id] === "3" && true}
                {...register(`answers.${question.fieldId}.${question.id}`, {
                  required: "true",
                  onChange: (e) => {
                    setSurveyResult({
                      questionId: question?.id,
                      answer: e.target.value,
                    });
                  },
                })}
                value="3"
              />
              <span
                htmlFor={`question-${question.id}-inclined`}
                className="checkmark"></span>{" "}
              {t("inclined")}
            </label>
          </div>
          <div className="col-12 col-md-6 col-lg-3">
            <label className="custom_check custom_one question-label">
              <input
                id={`question-${question.id}-slightly-inclined`}
                type="radio"
                defaultChecked={survey[question?.id] === "2" && true}
                {...register(`answers.${question.fieldId}.${question.id}`, {
                  required: "true",
                  onChange: (e) => {
                    setSurveyResult({
                      questionId: question?.id,
                      answer: e.target.value,
                    });
                  },
                })}
                value="2"
              />
              <span
                htmlFor={`question-${question.id}-slightly-inclined`}
                className="checkmark"></span>
              {t("slightlyInclined")}
            </label>
          </div>
          <div className="col-12 col-md-6 col-lg-3">
            <label className="custom_check custom_one question-label">
              <input
                id={`question-${question.id}-not-inclined`}
                type="radio"
                defaultChecked={survey[question?.id] === "1" && true}
                {...register(`answers.${question.fieldId}.${question.id}`, {
                  required: "true",
                  onChange: (e) => {
                    setSurveyResult({
                      questionId: question?.id,
                      answer: e.target.value,
                    });
                  },
                })}
                value="1"
              />
              <span
                htmlFor={`question-${question.id}-not-inclined`}
                className="checkmark"></span>{" "}
              {t("notInclined")}
            </label>
          </div>
        </div>
      </div>
    </>
  );
}
