import React from 'react';
import { useLocation, Navigate, Outlet } from 'react-router-dom';
import { toast } from "react-toastify";
import { overmind } from '../../src/index';

const NotLoggedIn = () => {
  const location = useLocation();

  if (overmind.state.user) {
    const { state } = location;
    const from = state?.from || '/';
    toast.error("You are already logged in", {
        position: "top-left",
      });
    return <Navigate to={from} replace />;
  }

  return <Outlet />;
};

export default NotLoggedIn;