import "regenerator-runtime/runtime";
import axios from "axios";
import {
    BadRequestError,
    ForbiddenError,
    InternalServerError,
    UnauthenticatedError,
    UnexpectedError,
} from "../errors";
import { apiClient } from "./constants";

export const getCountries = async () => {
    try {
        const result = await apiClient.get("country");
        return result.data;
    } catch (err) {
        if (axios.isAxiosError(err)) {
            const responseData = err.response.data;

            if (err.response?.status == 400)
                throw new BadRequestError(responseData.message);
            if (err.response?.status == 401)
                throw new UnauthenticatedError(responseData.message);
            if (err.response?.status == 403)
                throw new ForbiddenError(responseData.message);
            if (err.response?.status == 404)
                throw new UnexpectedError(responseData.message);
            if (err.response?.status == 500)
                throw new InternalServerError(responseData.message);
        }
        throw new UnexpectedError();
    }
};

export const updateGeneralSettings = async (data) => {
    try {
        const result = await apiClient.patch("users/general", data);
        return result.data;
    } catch (err) {
        if (axios.isAxiosError(err)) {
            const responseData = err.response.data;

            if (err.response?.status == 400)
                throw new BadRequestError(responseData.message);
            if (err.response?.status == 401)
                throw new UnauthenticatedError(responseData.message);
            if (err.response?.status == 403)
                throw new ForbiddenError(responseData.message);
            if (err.response?.status == 404)
                throw new UnexpectedError(responseData.message);
            if (err.response?.status == 500)
                throw new InternalServerError(responseData.message);
        }
        throw new UnexpectedError();
    }
};

export const updateAvatar = async (data) => {
    try {
        const result = await apiClient.post("users/upload-avatar", data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return result.data;
    } catch (err) {
        if (axios.isAxiosError(err)) {
            const responseData = err.response.data;

            if (err.response?.status == 400)
                throw new BadRequestError(responseData.message);
            if (err.response?.status == 401)
                throw new UnauthenticatedError(responseData.message);
            if (err.response?.status == 403)
                throw new ForbiddenError(responseData.message);
            if (err.response?.status == 404)
                throw new UnexpectedError(responseData.message);
            if (err.response?.status == 500)
                throw new InternalServerError(responseData.message);
        }
        throw new UnexpectedError();
    }
};

export const updateAccountSettings = async (data) => {
    try {
        const result = await apiClient.patch("users/account", data);
        return result.data;
    } catch (err) {
        if (axios.isAxiosError(err)) {
            const responseData = err.response.data;

            if (err.response?.status == 400)
                throw new BadRequestError(responseData.message);
            if (err.response?.status == 401)
                throw new UnauthenticatedError(responseData.message);
            if (err.response?.status == 403)
                throw new ForbiddenError(responseData.message);
            if (err.response?.status == 404)
                throw new UnexpectedError(responseData.message);
            if (err.response?.status == 500)
                throw new InternalServerError(responseData.message);
        }
        throw new UnexpectedError();
    }
};

export const updateNotificationsSettings = async (data) => {
    try {
        const result = await apiClient.patch("users/notifications", data);
        return result.data;
    } catch (err) {
        if (axios.isAxiosError(err)) {
            const responseData = err.response.data;

            if (err.response?.status == 400)
                throw new BadRequestError(responseData.message);
            if (err.response?.status == 401)
                throw new UnauthenticatedError(responseData.message);
            if (err.response?.status == 403)
                throw new ForbiddenError(responseData.message);
            if (err.response?.status == 404)
                throw new UnexpectedError(responseData.message);
            if (err.response?.status == 500)
                throw new InternalServerError(responseData.message);
        }
        throw new UnexpectedError();
    }
};

export const getCards = async (data) => {
    try {
        const result = await apiClient.get("users/cards", data);
        return result.data;
    } catch (err) {
        if (axios.isAxiosError(err)) {
            const responseData = err.response.data;

            if (err.response?.status == 400)
                throw new BadRequestError(responseData.message);
            if (err.response?.status == 401)
                throw new UnauthenticatedError(responseData.message);
            if (err.response?.status == 403)
                throw new ForbiddenError(responseData.message);
            if (err.response?.status == 404)
                throw new UnexpectedError(responseData.message);
            if (err.response?.status == 500)
                throw new InternalServerError(responseData.message);
        }
        throw new UnexpectedError();
    }
};

export const addCard = async (data) => {
    try {
        const result = await apiClient.post("users/card", data);
        return result.data;
    } catch (err) {
        if (axios.isAxiosError(err)) {
            const responseData = err.response.data;

            if (err.response?.status == 400)
                throw new BadRequestError(responseData.message);
            if (err.response?.status == 401)
                throw new UnauthenticatedError(responseData.message);
            if (err.response?.status == 403)
                throw new ForbiddenError(responseData.message);
            if (err.response?.status == 404)
                throw new UnexpectedError(responseData.message);
            if (err.response?.status == 500)
                throw new InternalServerError(responseData.message);
        }
        throw new UnexpectedError();
    }
};

export const deleteCard = async (id) => {
    try {
        const result = await apiClient.delete(`users/card/${id}`);
        return result.data;
    } catch (err) {
        if (axios.isAxiosError(err)) {
            const responseData = err.response.data;

            if (err.response?.status == 400)
                throw new BadRequestError(responseData.message);
            if (err.response?.status == 401)
                throw new UnauthenticatedError(responseData.message);
            if (err.response?.status == 403)
                throw new ForbiddenError(responseData.message);
            if (err.response?.status == 404)
                throw new UnexpectedError(responseData.message);
            if (err.response?.status == 500)
                throw new InternalServerError(responseData.message);
        }
        throw new UnexpectedError();
    }
};
