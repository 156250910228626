import React from "react";
import { Link } from "react-router-dom";
import Footer from "../../footer";
import Header from "../../header";
import { useTranslation } from "react-i18next";

const PrivacyPolicy = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="main-wrapper">
        <Header />

        <div className="breadcrumb-bar">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-12">
                <div className="breadcrumb-list">
                  <nav aria-label="breadcrumb" className="page-breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item ml-2">
                        <Link to="/">{t("breadcrumb.home")} </Link>
                      </li>

                      <li className="breadcrumb-item" aria-current="page">
                        {t("breadcrumb.privacyPolicy")}
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="page-banner">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-12">
                <h1 className="mb-0">{t('breadcrumb.privacyPolicy')}</h1>
              </div>
            </div>
          </div>
        </div>

        <div className="page-content">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="terms-content">
                  <div className="terms-text">
                    {/* <h3>
                      Effective date: <span>23rd of March, 2022</span>
                    </h3> */}
                    <h4>{t('privacyPolicy.policy.title')}</h4>
                    <p>
                      {t('privacyPolicy.policy.body')}
                    </p>
                    <h5>{t('privacyPolicy.informationCollection.title')}</h5>
                    <p>
                      {t('privacyPolicy.informationCollection.body')}
                    </p>
                    <h5>{t('privacyPolicy.useOfInformation.title')}</h5>
                    <p>
                      {t('privacyPolicy.useOfInformation.body')}
                    </p>
                    <h5>{t('privacyPolicy.protection.title')}</h5>
                    <p>
                      {t('privacyPolicy.protection.body')}
                    </p>

                    <h5>{t('privacyPolicy.cookies.title')}</h5>
                    <p>
                      {t('privacyPolicy.cookies.body')}
                    </p>

                    <h5>{t('privacyPolicy.thirdParty.title')}</h5>
                    <p>
                      {t('privacyPolicy.thirdParty.body')}
                    </p>

                    <h5>{t('privacyPolicy.updates.title')}</h5>
                    <p>
                      {t('privacyPolicy.updates.body')}
                    </p>
                  </div>


                  <div className="terms-text">
                    <h4>{t('privacyPolicy.contactUs.title')}</h4>
                    <p>
                      {t('privacyPolicy.contactUs.body')} {" "}
                      <a href="mailto:privacy@lifeprojectacademy.com">privacy@lifeprojectacademy.com</a>.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default PrivacyPolicy;
