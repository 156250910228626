import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { Link } from "react-router-dom";
import Footer from "../../footer";
import {
  Course10,
  Course11,
  Course12,
  InstructorProfileBg,
  User15,
} from "../../imagepath";
import { useActions, useAppState } from "../../../presenter";

import { InstructorHeader } from "../../instructor/header";
import Header from "../../header";
import InstructorSidebar from "../sidebar";
import { useTranslation } from "react-i18next";

export const Dashboard = () => {
  const { adminDashboardStats } = useActions();
  const [revenuPerEachMonth, setRevenuPerEachMonth] = useState();
  const [courseRevenuThisMonth, setCourseRevenuThisMonth] = useState();
  const [surveyRevenuThisMonth, setSurveyRevenuThisMonth] = useState();
  const [totalCourseOrders, setTotalCourseOrders] = useState();
  const [totalSurveyOrders, setTotalSurveyOrders] = useState();
  const [initialState, setinitialState] = useState({
    series: [
      {
        name: "Current Month",
        data: [0, 10, 40, 43, 40, 25, 35, 25, 40, 30],
      },
    ],

    options: {
      chart: {
        height: 300,
        type: "area",
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
        width: 3,
      },
      colors: ["#2B3A8E"],
      fill: {
        gradient: {
          opacityFrom: 0.6,
          opacityTo: 0.5,
        },
      },
      axisBorder: {
        show: true,
        color: "#78909C",
        offsetX: 0,
        offsetY: 0,
      },
      xaxis: {
        categories: [
          "",
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
        ],
      },

      title: {
        text: undefined,
        offsetX: 0,
        offsetY: 0,
        style: {
          color: undefined,
          fontSize: "12px",
          fontFamily: "Helvetica, Arial, sans-serif",
          fontWeight: 600,
          cssClass: "apexcharts-xaxis-title",
        },
      },
      yaxis: {
        // seriesName: "seriesY",
        logbase: 10,
        // logarithmic: true,
        tickAmount: 5,
        axisBorder: {
          show: true,
          color: "#E5E4E2",
          offsetX: 0,
          offsetY: 0,
        },
      },
      grid: {
        show: true, // you can either change hear to disable all grids
        xaxis: {
          lines: {
            show: false, //or just here to disable only x axis grids
          },
        },
        yaxis: {
          lines: {
            show: false, //or just here to disable only y axis
          },
        },
      },
      markers: {
        size: 3,
        colors: undefined,
        strokeColors: "#fff",
        strokeWidth: 2,
        strokeOpacity: 0.9,
        strokeDashArray: 0,
        fillOpacity: 1,
        discrete: [],
        shape: "circle",
        radius: 2,
        offsetX: 0,
        offsetY: 0,
        onClick: undefined,
        onDblClick: undefined,
        showNullDataPoints: true,
        hover: {
          size: 8,
          sizeOffset: 3,
        },
      },
      tooltip: {
        x: {
          // format: "dd/MM/yy HH:mm",
        },
      },
    },
  });

  const [orderCourseChart, setCourseOrderChart] = useState({
    series: [
      {
        name: "Current Month",
        data: [76, 85, 101, 98, 87, 105, 91, 114, 94],
      },
    ],
    options: {
      chart: {
        height: 300,
        type: "bar",
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "19%",
          endingShape: "rounded",
          borderRadius: 7,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      colors: ["#1d9cfd"],
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val; // value
          },
        },
      },
      axisBorder: {
        show: true,
        color: "#78909C",
        offsetX: 0,
        offsetY: 0,
      },
      xaxis: {
        categories: [
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
        ],
      },
      yaxis: {
        // seriesName: "seriesY",
        logbase: 10,
        // logarithmic: true,
        tickAmount: 5,
        axisBorder: {
          show: false,
          color: "#E5E4E2",
          offsetX: 0,
          offsetY: 0,
        },
      },
      grid: {
        show: true, // you can either change hear to disable all grids
        xaxis: {
          lines: {
            show: false, //or just here to disable only x axis grids
          },
        },
        yaxis: {
          lines: {
            show: true, //or just here to disable only y axis
          },
        },
      },
    },
  });
  const [orderSurveyChart, setSurveyOrderChart] = useState({
    series: [
      {
        name: "Current Month",
        data: [76, 85, 101, 98, 87, 105, 91, 114, 94],
      },
    ],
    options: {
      chart: {
        height: 300,
        type: "bar",
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "19%",
          endingShape: "rounded",
          borderRadius: 7,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      colors: ["#1d9cfd"],
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val; // value
          },
        },
      },
      axisBorder: {
        show: true,
        color: "#78909C",
        offsetX: 0,
        offsetY: 0,
      },
      xaxis: {
        categories: [
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
        ],
      },
      yaxis: {
        // seriesName: "seriesY",
        logbase: 10,
        // logarithmic: true,
        tickAmount: 5,
        axisBorder: {
          show: false,
          color: "#E5E4E2",
          offsetX: 0,
          offsetY: 0,
        },
      },
      grid: {
        show: true, // you can either change hear to disable all grids
        xaxis: {
          lines: {
            show: false, //or just here to disable only x axis grids
          },
        },
        yaxis: {
          lines: {
            show: true, //or just here to disable only y axis
          },
        },
      },
    },
  });
  const [totalEnrolledStudentThisMonth, setTotalEnrolledStudentThisMonth] =
    useState();

  const { t } = useTranslation();

  const handleStats = async () => {
    const result = await adminDashboardStats();
    setRevenuPerEachMonth(result.revenuPerEachMonth);
    setTotalCourseOrders(result.totalCourseOrders);
    setTotalSurveyOrders(result.totalSurveyOrders);
    setCourseRevenuThisMonth(result.totalCourseRevenue);
    setSurveyRevenuThisMonth(result.totalSurveyRevenue);
    setTotalEnrolledStudentThisMonth(result.totalEnrolledStudentThisMonth);
  };

  useEffect(() => {
    handleStats();
  }, []);
  useEffect(() => {
    if (totalCourseOrders && totalSurveyOrders) {
      const courseData = totalCourseOrders.map((item) => item.count);
      const updatedCourseSeries = [
        { ...initialState.series[0], data: courseData },
      ];
      const surveyData = totalSurveyOrders.map((item) => item.count);
      const updatedSurveySeries = [
        { ...initialState.series[0], data: surveyData },
      ];

      setCourseOrderChart((prevState) => ({
        ...prevState,
        series: updatedCourseSeries,
      }));
      setinitialState((prevState) => ({
        ...prevState,
        series: updatedCourseSeries,
      }));
      setSurveyOrderChart((prevState) => ({
        ...prevState,
        series: updatedSurveySeries,
      }));
    }
  }, [revenuPerEachMonth]);

  return (
    <div className="main-wrapper">
      <Header activeMenu={"Dashboard"} />
      <div className="page-content instructor-page-content">
        <div className="container">
          <div className="row">
            {/* Sidebar */}
            <InstructorSidebar activeMenu={"Dashboard"} />
            {/* Sidebar */}

            {/* Instructor Dashboard */}
            <div className="col-xl-9 col-lg-8 col-md-12">
              <div className="row">
                <div className="col-md-4 d-flex">
                  <div className="card instructor-card w-100">
                    <div className="card-body">
                      <div className="instructor-inner">
                        <h6>{t("CourseRevenue")}</h6>
                        <h4 className="instructor-text-success">
                          {courseRevenuThisMonth} ر.س
                        </h4>
                        <p>{t("earning")}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 d-flex">
                  <div className="card instructor-card w-100">
                    <div className="card-body">
                      <div className="instructor-inner">
                        <h6>{t("surveyRevenu")}</h6>
                        <h4 className="instructor-text-warning">
                          {surveyRevenuThisMonth} ر.س
                        </h4>
                        <p>{t("earning")}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 d-flex">
                  <div className="card instructor-card w-100">
                    <div className="card-body">
                      <div className="instructor-inner">
                        <h6>{t("studentEnrollment")}</h6>
                        <h4 className="instructor-text-info">
                          {totalEnrolledStudentThisMonth}
                        </h4>
                        <p>{t("newThisMonth")}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="card instructor-card">
                    <div className="card-header">
                      <h4>{t("earnings")}</h4>
                    </div>
                    <div className="card-body">
                      <div id="instructor_chart">
                        <ReactApexChart
                          options={initialState.options}
                          series={initialState.series}
                          type="area"
                          height={300}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="card instructor-card">
                    <div className="card-header">
                      <h4>{t("orderCourse")}</h4>
                    </div>
                    <div className="card-body">
                      <div id="order_chart">
                        <ReactApexChart
                          options={orderCourseChart.options}
                          series={orderCourseChart.series}
                          type="bar"
                          height={300}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="card instructor-card">
                    <div className="card-header">
                      <h4>{t("orderSurvey")}</h4>
                    </div>
                    <div className="card-body">
                      <div id="order_chart">
                        <ReactApexChart
                          options={orderSurveyChart.options}
                          series={orderSurveyChart.series}
                          type="bar"
                          height={300}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Referred Users */}
              {/* 
              <div className="row">
                <div className="col-md-12">
                  <div className="settings-widget">
                    <div className="settings-inner-blk p-0">
                      <div className="sell-course-head comman-space">
                        <h3>Best Selling Courses</h3>
                      </div>
                      <div className="comman-space pb-0">
                        <div className="settings-tickets-blk course-instruct-blk table-responsive">
                          <table className="table table-nowrap mb-0">
                            <thead>
                              <tr>
                                <th>COURSES</th>
                                <th>SALES</th>
                                <th>AMOUNT</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  <div className="sell-table-group d-flex align-items-center">
                                    <div className="sell-group-img">
                                      <Link to="course-details">
                                        <img
                                          src={Course10}
                                          className="img-fluid "
                                          alt=""
                                        />
                                      </Link>
                                    </div>
                                    <div className="sell-tabel-info">
                                      <p>
                                        <Link to="course-details">
                                          Information About UI/UX Design Degree
                                        </Link>
                                      </p>
                                    </div>
                                  </div>
                                </td>
                                <td>34</td>
                                <td>$3,145.23</td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="sell-table-group d-flex align-items-center">
                                    <div className="sell-group-img">
                                      <Link to="course-details">
                                        <img
                                          src={Course11}
                                          className="img-fluid "
                                          alt=""
                                        />
                                      </Link>
                                    </div>
                                    <div className="sell-tabel-info">
                                      <p>
                                        <Link to="course-details">
                                          Wordpress for Beginners - Master
                                          Wordpress Quickly
                                        </Link>
                                      </p>
                                    </div>
                                  </div>
                                </td>
                                <td>34</td>
                                <td>$3,145.23</td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="sell-table-group d-flex align-items-center">
                                    <div className="sell-group-img">
                                      <Link to="course-details">
                                        <img
                                          src={Course12}
                                          className="img-fluid "
                                          alt=""
                                        />
                                      </Link>
                                    </div>
                                    <div className="sell-tabel-info">
                                      <p>
                                        <Link to="course-details">
                                          Sketch from A to Z (2022): Become an
                                          app designer
                                        </Link>
                                      </p>
                                    </div>
                                  </div>
                                </td>
                                <td>34</td>
                                <td>$3,145.23</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
            {/* Instructor Dashboard */}

            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
};
