import "regenerator-runtime/runtime";
import html2canvas from "html2canvas";

export const captureScreenshot = async (element) => {
    try {
      const canvas = await html2canvas(element, { scrollY: -window.scrollY });
      return canvas.toDataURL("image/png");
    } catch (error) {
      console.error("Screenshot capture failed:", error);
      return null;
    }
  };