export * from "./blogs.gateway";
export * from "./podcasts.gateway";
export * from "./videos.gateway";
export * from "./home.gateway";
export * from "./news-letters.gateway";
export * from "./registrations.gateway";
export * from "./auth.gateway";
export * from "./user-settings.gateway";
export * from "./courses.gateway";
export * from './notifications.gateway'
export * from './survey.gateway'
export * from './admins.gateway'
export * from './genenral-settings.gateway'
export * from './student.gateway'
export * from './payment.gateway'
