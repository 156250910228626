import React, { useEffect, useState } from "react";
import Footer from "../footer";
import Header from "../header";
import SideBlog from "./sideBlog";
import PageChange from "../page-change";

import { Link, useParams } from "react-router-dom";
import { Icon22 } from "../imagepath";
import { useTranslation } from "react-i18next";
import { useActions, useAppState } from "../../presenter";
import { formatDate } from "../../utils/date";
import { LazyLoadImage } from "react-lazy-load-image-component";
import blogDefaultImage from "../../assets/img/blogDefaultImage.png";

const BlogList = () => {
  const { t } = useTranslation();
  const [allBlogs, setAllBlogs] = useState([]);
  const [suggestedBlogs, setSuggestedBlogs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(6);
  const [pagesNum, setPagesNum] = useState([]);
  const [query, setQuery] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const { i18n } = useTranslation();

  const { loading, user } = useAppState();
  const { listBlogs } = useActions();
  useEffect(() => {
    listBlogs({
      currentPage,
      limit: itemsPerPage,
      q: query,
      language: user?.language ?? i18n.language?.toUpperCase() ?? "AR",
    }).then((data) => {
      setAllBlogs(data.blogs);
      setSuggestedBlogs(data.highlightedBlogs);

      const arr = [];
      for (let i = 1; i <= data.meta.totalPages; i++) {
        arr.push(i);
      }
      setPagesNum(arr);
    });
  }, [currentPage, itemsPerPage]);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const q = searchParams.get("q");
    if (q) {
      setSearchQuery(decodeURIComponent(q));
      setQuery(decodeURIComponent(q));
    }
  }, []);

  useEffect(() => {
    getBlogs();
  }, [query]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const getBlogs = () => {
    listBlogs({
      currentPage,
      limit: itemsPerPage,
      q: query,
      language: user?.language ?? i18n.language?.toUpperCase() ?? "AR",
    }).then((data) => {
      setAllBlogs(data.blogs);
      const arr = [];
      for (let i = 1; i <= data.meta.totalPages; i++) {
        arr.push(i);
      }
      setPagesNum(arr);
    });
  };

  const handleSearch = (e) => {
    e.preventDefault();
    setQuery(searchQuery);
    // getBlogs();
  };

  // if (loading) return <PageChange />
  return (
    <>
      <div className="main-wrapper">
        <Header activeMenu={"List"} />
        <div className="breadcrumb-bar">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-12">
                <div className="breadcrumb-list">
                  <nav aria-label="breadcrumb" className="page-breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/">{t("breadcrumb.home")} </Link>
                      </li>

                      <li className="breadcrumb-item" aria-current="page">
                        {t("breadcrumb.blogList")}
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section className="course-content">
          <div className="container">
            <div className="row">
              <div className="col-lg-9 col-md-12">
                {/* Blog Post */}
                {allBlogs &&
                  allBlogs.length &&
                  allBlogs.map((blog) => (
                    <div key={blog?.id + blog?.language} className="blog">
                      <div className="blog-image">
                        <Link
                          to={`/blog-details/${blog?.id}?language=${blog?.language}`}>
                          <LazyLoadImage
                            className="img-fluid"
                            src={blog.image?.url ?? blogDefaultImage}
                            alt="Blog Image"
                          />
                        </Link>
                      </div>
                      <div className="blog-info clearfix">
                        <div className="post-left">
                          <ul>
                            <li>
                              <img className="img-fluid" src={Icon22} alt="" />
                              {formatDate(blog.createdAt)}
                            </li>
                          </ul>
                        </div>
                      </div>
                      <h3 className="blog-title">
                        <Link
                          to={`/blog-details/${blog.id}?language=${blog.language}`}>
                          {blog.title}
                        </Link>
                      </h3>
                      <div className="blog-content blog-read">
                        <p>
                          {blog.description.slice(0, 90).trim()}
                          {blog.description.length > 90 ? "..." : ""}
                        </p>
                        <Link
                          to={`/blog-details/${blog.id}?language=${blog.language}`}
                          className="read-more btn btn-primary">
                          {t("readMore")}
                        </Link>
                      </div>
                    </div>
                  ))}
                {allBlogs && allBlogs.length === 0 && (
                  <h1 style={{ textAlign: "center" }}>{t("noResults")}</h1>
                )}
                {/* /Blog Post */}

                {/* Blog pagination */}
                <div className="row">
                  <div className="col-md-12">
                    <ul className="pagination lms-page">
                      <li
                        className={`"page-item prev" ${
                          currentPage == 1 ? "disable-btn" : ""
                        }`}>
                        <Link
                          className="page-link"
                          to="#"
                          tabIndex={-1}
                          onClick={() => setCurrentPage((prev) => prev - 1)}>
                          <i className="fas fa-angle-left" />
                        </Link>
                      </li>

                      {pagesNum.map((number) => (
                        <li
                          key={number}
                          className={`"page-item ${
                            currentPage == 1 ? "first-page" : ""
                          } ${currentPage == number ? "active" : ""}`}>
                          <Link
                            onClick={() => handlePageChange(number)}
                            className="page-link"
                            to="#">
                            {number}
                          </Link>
                        </li>
                      ))}

                      <li
                        className={`"page-item next" ${
                          currentPage == pagesNum[pagesNum.length - 1]
                            ? "disable-btn"
                            : ""
                        }`}>
                        <Link
                          onClick={() => setCurrentPage((prev) => prev + 1)}
                          className="page-link"
                          to="#">
                          <i className="fas fa-angle-right" />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* /Blog pagination */}
              </div>

              {/* Blog Sidebar */}
              <div className="col-lg-3 col-md-12 sidebar-right theiaStickySidebar">
                <div className="stickysidebar">
                  {/* Search */}
                  <div className="card search-widget blog-search blog-widget">
                    <div className="card-body">
                      <form className="search-form" onSubmit={handleSearch}>
                        <div className="input-group">
                          <input
                            type="text"
                            placeholder="Search..."
                            className="form-control"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                          />
                          <button type="submit" className="btn btn-primary">
                            <i className="fa fa-search" />
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                  {/* /Search */}
                  {/* Latest Posts */}
                  <SideBlog
                    blogs={suggestedBlogs}
                    title={t("sugBlogs")}
                    type={"blog"}
                  />
                  {/* /Latest Posts */}
                </div>
                {/* /Blog Sidebar */}
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </>
  );
};

export default BlogList;
