import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import OwlCarousel from "react-owl-carousel";
import { RegisterCover, logo, NetIcon2 } from "../../imagepath";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import "../../../assets/js/jquery-3.6.0.min.js";
import { GoogleLogin } from "@react-oauth/google";
import { useActions, useAppState } from "../../../presenter";
import FacebookLogin from "react-facebook-login";
import configurations from "../../../../config";
import { SignupFormValidator } from "../../../validators/registration.validator";
import { useTranslation } from "react-i18next";

const Register = (props) => {
  const [eye, seteye] = useState(true);
  const { t: translate } = useTranslation();
  const { t } = useTranslation();
  const [countries, setCountries] = useState([]);
  const { getCountries, registerUser } = useActions();
  const { loadingBtn } = useAppState();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(SignupFormValidator),
  });

  const navigate = useNavigate();

  const { loginWithGoogle, loginWithFacebook } = useActions();

  useEffect(() => {
    getCountries().then((result) => {
      setCountries(result);
    });
  }, []);

  const onEyeClick = () => {
    seteye(!eye);
  };

  const onSubmit = async (data) => {
    const result = await registerUser(data);
    if (result) navigate("/login");
  };

  var settings = {
    //autoWidth: true,
    items: 2,
    margin: 25,
    dots: true,
    nav: true,
    navText: [
      '<i className="fas fa-arrow-left"></i>',
      '<i className="fas fa-arrow-right"></i>',
    ],

    loop: false,
    responsiveClass: true,
    responsive: {
      0: {
        items: 1,
      },
      768: {
        items: 1,
      },
      1170: {
        items: 1,
      },
    },
  };

  return (
    <>
      <div className="main-wrapper log-wrap">
        <div className="row">
          {/* Login Banner */}
          <div className="col-md-6 login-bg">
            <OwlCarousel
              {...settings}
              className="owl-carousel login-slide owl-theme">
              <div className="welcome-login">
                <div className="login-banner">
                  <img src={RegisterCover} className="img-fluid" alt="Logo" />
                </div>
                <div className="mentor-course text-center">
                  <h2>{t("form.welcomeTitle")}</h2>
                  <p>{t("form.registerSubtitle")}</p>
                </div>
              </div>
            </OwlCarousel>
          </div>
          {/* /Login Banner */}
          <div className="col-md-6 login-wrap-bg">
            {/* Login */}
            <div className="login-wrapper">
              <div className="loginbox">
                <div className="img-logo">
                  <img src={logo} className="img-fluid" alt="Logo" />
                  <div className="back-home">
                    <Link to="/">{t("form.backHome")}</Link>
                  </div>
                </div>
                <h1>{t("form.signup")}</h1>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="form-group">
                    <label className="form-control-label">
                      {t("form.firstName")}
                    </label>
                    <input
                      {...register("firstName")}
                      type="text"
                      className="form-control"
                      placeholder={t("form.enterFirstName")}
                    />
                  </div>
                  <p className="invalid-feedback d-block">
                    {translate(errors.firstName?.message)}
                  </p>

                  <div className="form-group">
                    <label className="form-control-label">
                      {t("form.lastName")}
                    </label>
                    <input
                      {...register("lastName")}
                      type="text"
                      className="form-control"
                      placeholder={t("form.enterLastName")}
                    />
                  </div>
                  <p className="invalid-feedback d-block">
                    {translate(errors.lastName?.message)}
                  </p>

                  <div className="form-group">
                    <label className="form-control-label">
                      {t("form.email")}
                    </label>
                    <input
                      {...register("email")}
                      type="email"
                      className="form-control"
                      placeholder={t("form.enterEmail")}
                    />
                  </div>
                  <p className="invalid-feedback d-block">
                    {translate(errors.email?.message)}
                  </p>

                  <div className="form-group">
                    <label className="form-control-label">
                      {t("form.password")}
                    </label>
                    <div className="pass-group" id="passwordInput">
                      <input
                        {...register("password")}
                        className="form-control pass-input"
                        placeholder={t("form.enterPassword")}
                        type={eye ? "password" : "text"}
                      />
                      <span
                        onClick={onEyeClick}
                        className={`fa toggle-password feather-eye" ${
                          eye ? "fa-eye" : "fa-eye-slash"
                        }`}
                      />
                      <span className="toggle-password feather-eye"></span>
                      <span className="pass-checked">
                        <i className="feather-check"></i>
                      </span>
                    </div>
                    {/* <div className="password-strength" id="passwordStrength">
                      <span id="poor" />
                      <span id="weak" />
                      <span id="strong" />
                      <span id="heavy" />
                    </div>
                    <div id="passwordInfo" /> */}
                  </div>
                  <p className="invalid-feedback d-block">
                    {translate(errors.password?.message)}
                  </p>

                  {/* <div className="form-group">
                    <label htmlFor="sel1" className="form-label">
                      {t("form.country")}
                    </label>
                    <select
                      {...register("country")}
                      className="form-select"
                      id="sel1"
                      name="country">
                      <option value="">{t("form.selectCountry")}</option>
                      {countries &&
                        countries.map((country) => (
                          <option value={country.name}>{country.name}</option>
                        ))}
                    </select>
                  </div>
                  <p className="invalid-feedback d-block">
                    {translate(errors.country?.message)}
                  </p> */}

                  <div className="form-check remember-me">
                    <label className="form-check-label mb-0">
                      <input
                        {...register("isSubscribed")}
                        className="form-check-input"
                        type="checkbox"
                        name="isSubscribed"
                      />
                      {t("form.subscribe")}
                    </label>
                  </div>
                  {/* {errors.isSubscribed && <p className="invalid-feedback d-block">
                    {translate('form.validation.required')}
                  </p>} */}

                  <div className="d-grid">
                    <button
                      className="btn btn-primary btn-start"
                      type="submit"
                      disabled={loadingBtn}>
                      {loadingBtn && (
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"></span>
                      )}
                      {t("form.createAcc")}
                    </button>
                  </div>
                </form>
              </div>
              <div className="google-bg text-center">
                <span>
                  <Link to="#">{t("form.signInWith")}</Link>
                </span>
                <div className="sign-google">
                  <ul>
                    {/* <li>
                      <Link to="#">
                        <img src={NetIcon1} className="img-fluid" alt="Logo" />
                        Sign In using Google
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <img src={NetIcon2} className="img-fluid" alt="Logo" />
                        Sign In using Facebook
                      </Link>
                    </li> */}
                    <li>
                      {/* <img src={NetIcon1} className="img-fluid" alt="Logo" /> */}
                      <GoogleLogin
                        onSuccess={async (credentialResponse) => {
                          const result = await loginWithGoogle(
                            credentialResponse.credential
                          );
                          const data = result.data;
                          if (data) {
                            data.user.role == "Super_Admin"
                              ? navigate("/admin/dashboard")
                              : navigate("/student-dashboard");
                          }
                        }}
                        onError={() => {
                          console.log("Login Failed");
                        }}
                      />
                    </li>
                    <li>
                      <Link to="#">
                        <img src={NetIcon2} className="img-fluid" alt="Logo" />
                        <FacebookLogin
                          appId={`${configurations.facebookClientID}`}
                          fields="name,first_name,last_name,email,picture"
                          callback={async (response) => {
                            const result = await loginWithFacebook(response);
                            const data = result.data;
                            if (data) {
                              data.user.role == "Super_Admin"
                                ? navigate("/admin/dashboard")
                                : navigate("/student-dashboard");
                            }
                          }}
                          cssClass="my-facebook-button-class"
                        />
                      </Link>
                    </li>
                  </ul>
                </div>
                <p className="mb-0">
                  {t("form.haveAcc")}{" "}
                  <Link to="/login">{t("form.signIn")}</Link>
                </p>
              </div>
            </div>
            {/* /Login */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Register;

{
  /* <div className="form-group">
<label className="form-control-label">
  {t("form.description")}
</label>
<div className="form-check custom-form-check">
  <input
    {...register("description")}
    className="form-check-input"
    type="radio"
    name="description"
    id="employee"
    value="Employee"
  />
  <label
    style={{ marginInlineStart: "-5px" }}
    for="employee">
    {t("form.employee")}
  </label>
</div>
<div className="form-check custom-form-check">
  <input
    {...register("description")}
    className="form-check-input"
    type="radio"
    name="description"
    id="student"
    value="Student"
  />
  <label
    style={{ marginInlineStart: "-5px" }}
    for="student">
    {t("form.student")}
  </label>
</div>
</div>
<p className="invalid-feedback d-block">
{translate(errors.description?.message)}
</p>

<div className="form-group">
<label className="form-control-label">
  {t("form.job")}
</label>
<input
  {...register("job")}
  type="text"
  className="form-control"
  placeholder={t("form.enterJob")}
/>
</div>
<p className="invalid-feedback d-block">
{translate(errors.job?.message)}
</p>

<div className="form-group">
<label className="form-control-label">
  {t("form.birthDate")}
</label>
<input
  {...register("birthDate")}
  type="date"
  className="form-control"
  format="yyyy-mm-dd"
  placeholder={t("form.enterBirthDate")}
/>

</div>
{errors.birthDate && (
<p className="invalid-feedback d-block">
  {translate("form.validation.required")}
</p>
)} */
}
