import * as Yup from "yup";

export const GeneralSettingValidator = Yup.object().shape({
    firstName: Yup.string("form.validation.string")
        .required("form.validation.required"),

    lastName: Yup.string("form.validation.string")
        .required("form.validation.required"),

    country: Yup.string("form.validation.string")
        .required("form.validation.required"),

    description: Yup.string("form.validation.string")
        .required("form.validation.required"),

    job: Yup.string("form.validation.string")
        .required("form.validation.required"),

    birthDate: Yup.date().required("form.validation.required"),

});
