import React from "react";
import NewsLetterForm from "./news-letter-form";
import { Link } from "react-router-dom";
import { logo } from "../imagepath";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <div className="footer-top pb-5">
      <div className="container">
        <footer className="footer row row-cols-1 row-cols-sm-2 row-cols-md-5 py-5 mt-5 border-top">
          <div className="col mb-3 align-self-center">
            <div className="footer-logo">
              <img src={logo} alt="logo" />
            </div>
          </div>

          <div className="col-md-1 mb-3"></div>

          <div className="col-md-2 mb-3">
            <h6>{t('footer.privacyPolicy')}</h6>
            <ul className="nav flex-column p-0">
              <li className="nav-item mb-2">
                <a href="#" className="nav-link p-0 text-muted">
                  <Link to="/term-condition">{t('footer.terms')}</Link>
                </a>
              </li>
              <li className="nav-item mb-2">
                <a href="#" className="nav-link p-0 text-muted">
                  <Link to="/privacy-policy">{t('footer.privacy')}</Link>
                </a>
              </li>
            </ul>
          </div>

          <div className="col mb-3">
            <h6>{t('footer.addressTitle')}</h6>
            <ul className="nav flex-column p-0">
              <li className="nav-item mb-2">
                <p className="nav-link p-0 text-muted">
                  {t('footer.address')}
                </p>
              </li>
            </ul>
          </div>

          <div className="col mb-3">
            <h6>{t('footer.subscribeToNewsLetter')}</h6>
            <ul className="nav flex-column p-0">
              <li className="nav-item mb-2">
                <NewsLetterForm></NewsLetterForm>
              </li>
            </ul>
          </div>
        </footer>

        <p className="text-center text-muted">{t('footer.copyright')}</p>
      </div>
    </div>
  );
};

export default Footer;
