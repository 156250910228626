import "regenerator-runtime/runtime";

import i18n from "../../locales/i18n";
import { toast } from "react-toastify";
import { handleError } from "../errors";
import { changePositionByLanguage } from "../../utils/toastify";

export const subscribeToNewsLetter = async (context, { email }) => {
  try {
    await context.effects.subscribeToNewsLetter(email);

    toast.success(i18n.t("form.success.subscribeToNewsLetters"), {
      position: changePositionByLanguage(i18n.language),
    });
  } catch (err) {
    handleError(err);
  }
};
