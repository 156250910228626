import React, { useEffect, useState } from "react";
import Header from "../../header";
import Footer from "../../footer";
import StudentSideBar from "../sidebar";
import { useActions, useAppState } from "../../../presenter";
import { useTranslation } from "react-i18next";

export default function StudentNotification() {
  const { t } = useTranslation();
  const { user } = useAppState();
  const [userData, setUserData] = useState({
    instantNotifications: user.instantNotifications,
    emailNotifications: user.emailNotifications,
    isSubscribed: user.isSubscribed,
    discountNotifications: user.discountNotifications || false,
  });
  const { updateNotificationsSettings } = useActions();

  const handleNotificationsChange = (event) => {
    const { name, checked } = event.target;
    console.log(name, checked);
    setUserData((prevUserData) => ({
      ...prevUserData,
      [name]: checked,
    }));
  };

  useEffect(() => {
    console.log('userdata', userData);
    updateNotificationsSettings(userData);
  }, [userData]);

  return (
    <div className="main-wrapper">
      <Header activeMenu={"Notification"} />
      {/* Student Dashboard */}
      <div className="page-content">
        <div className="container" style={{ marginTop: '70px' }}>
          <div className="row">
            {/* sidebar */}
            <StudentSideBar activeMenu={"Notification"} />
            {/* sidebar */}

            {/* Notifications */}
            <div className="col-xl-9 col-md-8">
              <div className="settings-widget profile-details">
                <div className="settings-menu p-0">
                  <div className="profile-heading">
                    <h3>{t('settings.notifications.title')}</h3>
                    {/* <p>You will get only notification what have enabled.</p> */}
                  </div>
                  <div className="checkout-form personal-address secure-alert border-line">
                    <div className="personal-info-head">
                      {/* <h4>Security Alerts</h4> */}
                      <p>
                      {t('settings.notifications.pushTitle')}
                      </p>
                    </div>

                    <div className="form-check form-switch check-on pl0">
                      <label className="form-check-label">
                      {t('settings.notifications.push')}
                      </label>
                      <input className="form-check-input big"
                        type="checkbox"
                        name="instantNotifications"
                        checked={userData.instantNotifications}
                        onChange={handleNotificationsChange} />
                    </div>
                  </div>
                  <div className="checkout-form personal-address secure-alert border-line">
                    <div className="personal-info-head">
                      {/* <h4>News</h4> */}
                      <p>
                      {t('settings.notifications.emailsTitle')}
                      </p>
                    </div>
                    <div className="form-check form-switch check-on pl0">
                      <label className="form-check-label">
                      {t('settings.notifications.email')}
                      </label>
                      <input className="form-check-input big"
                        type="checkbox"
                        name="emailNotifications"
                        checked={userData.emailNotifications}
                        onChange={handleNotificationsChange} />
                    </div>
                  </div>
                  <div className="checkout-form personal-address secure-alert border-line">
                    <div className="personal-info-head">
                      {/* <h4>Courses</h4> */}
                      <p>
                      {t('settings.notifications.newsletterTitle')}
                      </p>
                      {/* <h1 className="notification-title">{t('settings.notifications.newsletterTitle2')}</h1> */}
                    </div>
                    <div className="form-check">
                      <input className="form-check-input big" type="checkbox"
                        name="isSubscribed"
                        checked={userData.isSubscribed}
                        onChange={handleNotificationsChange} />
                      <label className="form-check-label">
                      {t('settings.notifications.newsletter')}
                      </label>
                    </div>
                    {/* <div className="form-check">
                      <input className="form-check-input big" type="checkbox" 
                      name="discountNotifications"
                      checked={userData.discountNotifications}
                      onChange={handleNotificationsChange}/>
                      <label className="form-check-label">
                      {t('settings.notifications.discount')}
                      </label>
                    </div> */}
                  </div>
                  {/* <div className="un-subscribe">
                    <Link to="#" className="btn btn-danger">
                      Unsubscribe from all of the above
                    </Link>
                  </div> */}
                </div>
              </div>
            </div>
            {/* Notifications */}
          </div>
        </div>
      </div>
      {/* Student Dashboard */}
      <Footer />
    </div>
  );
}
