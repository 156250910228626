import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../../footer";
import { BookSvg, EmptyWalletTick, ReceiptText, User11 } from "../../imagepath";
import Header from "../../header";
import DepositMenu from "../depositMenu";
import vector from "../../../assets/img/VectorImg.png";
import { Icon1 } from "../../imagepath";
import { Icon2 } from "../../imagepath";
import blogDefaultImage from "../../../assets/img/blogDefaultImage.png";
import podcastDefaultImage from "../../../assets/img/podcastDefaultImage.png";
import videosDefaultImage from "../../../assets/img/videosDefaultImage.png";
import { useActions, useAppState } from "../../../presenter";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { formatDate } from "../../../utils/date";
import { useTranslation } from "react-i18next";
import { formatTimeMonth, formatTimeDate } from "../../../utils/time";
import defaultCourseImg from "../../../assets/img/defaultCourseImg.png";
export default function StudentDashboard() {
  const { t } = useTranslation();
  const { user } = useAppState();
  var settings = {
    //autoWidth: true,
    items: 2,
    margin: 25,
    dots: true,
    nav: true,
    navText: [
      '<i className="fas fa-arrow-left"></i>',
      '<i className="fas fa-arrow-right"></i>',
    ],

    loop: true,
    responsiveClass: true,
    responsive: {
      0: {
        items: 1,
      },
      768: {
        items: 3,
      },
      1170: {
        items: 4,
      },
    },
  };
  const [videos, setVideos] = useState();
  const [blogs, setBlogs] = useState();
  const { getStudentDashboard } = useActions();
  const [podcasts, setPodcasts] = useState([]);
  const [podcastLink, setPodcastLink] = useState();
  const [totalEnrolledCourse, setTotalEnrolledCourses] = useState();
  const [userCreatedAt, setUserCreatedAt] = useState();
  const [lifeProjectCourse, setLifeProjectCourse] = useState();
  const [courses, setCourse] = useState();
  const [totalFinishedCourses, setTotalFinishedCourses] = useState();
  const colors = ["#2C3B8F", "#27A07C", "#B9402F", "#3774BD"];
  const getDashboard = async () => {
    const result = await getStudentDashboard();
    if (result) {
      setUserCreatedAt(result.userCreatedAt);
      setTotalFinishedCourses(result.finishedCourses);
      setTotalEnrolledCourses(result.totalEnrolledCourses);
      setCourse(result.enrolledCourses);
    }
  };
  useEffect(() => {
    getDashboard();
  }, []);
  // console.log(videos);
  return (
    <div className="main-wrapper">
      <Header />
      {/* Student Header */}
      <div className="course-student-header" style={{ marginTop: "110px" }}>
        <div className="container">
          <DepositMenu activeMenu={"Dashboard"} />
        </div>
      </div>
      {/* Student Header */}
      {/* Dashboard Student */}
      <div className="page-content">
        <div className="container">
          <div className="row">
            <div className="col-xl-12 col-md-12">
              <div className="settings-top-widget student-deposit-blk">
                <div className="row">
                  <div className="col-lg-4 col-md-6">
                    <div className="card stat-info ttl-tickets">
                      <div className="card-body">
                        <div className="view-all-grp d-flex">
                          <div className="student-ticket-view">
                            <h3>{totalFinishedCourses ?? 0}</h3>
                            <p>{t("finishedCourses")}</p>
                          </div>
                          <div className="img-deposit-ticket">
                            <img src={BookSvg} alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <div className="card stat-info open-tickets">
                      <div className="card-body">
                        <div className="view-all-grp d-flex">
                          <div className="student-ticket-view">
                            <h3>{totalEnrolledCourse ?? 0}</h3>
                            <p>{t("totalEnrolledCourse")}</p>
                          </div>
                          <div className="img-deposit-ticket">
                            <img src={ReceiptText} alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <div className="card stat-info close-tickets">
                      <div className="card-body">
                        <div className="view-all-grp d-flex">
                          <div className="student-ticket-view">
                            <h4>{userCreatedAt ?? 0 + "\t" + t("days")}</h4>
                            <div style={{ height: "15px" }}></div>

                            <p>{t("registerDate")}</p>
                          </div>
                          <div className="img-deposit-ticket">
                            <img src={EmptyWalletTick} alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {courses &&
                courses.length >= 1 &&
                courses.map((course, i) => {
                  return (
                    <div
                      key={course.id}
                      className={"card-lifeProject"}
                      style={{ marginTop: "20px", background: colors[i % 4] }}>
                      <div className="comman-space pb-0 inside-card-life-project">
                        <div className="filter-grp ticket-grp align-items-center justify-content-between">
                          <div className="jumbotron">
                            <h1
                              className="display-4"
                              style={{ color: "white" }}>
                              {course.hasSurvey
                                ? t("lifeProjectTour")
                                : course.title}
                            </h1>
                            <p style={{ color: "white" }}>
                              {course.hasSurvey
                                ? t("lifeProjectFocus")
                                : course.subTitle}
                            </p>
                            <div className="row">
                              <div className="col-5">
                                <div
                                  className="progress-stip"
                                  style={{ marginTop: "55px" }}>
                                  <div
                                    className="progress-bar bg-success progress-bar-striped"
                                    style={{ width: `${course.progress}%` }}
                                  />
                                </div>
                              </div>
                              <div className="col">
                                <a
                                  className="btn btn-primary btn-lg"
                                  href={`/course-details/${course.Language}/${course?.id}`}
                                  role="button"
                                  style={{
                                    margin: "20px",
                                    background: " #E8F1F8",
                                    border: "none",
                                  }}>
                                  <div style={{ display: "flex" }}>
                                    <p style={{ color: "#2B3A8E" }}>
                                      {t("LearnMore")}
                                    </p>
                                  </div>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="settings-tickets-blk table-responsive"></div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
      {/* Dashboard Student */}
      <Footer />
    </div>
  );
}
