import "regenerator-runtime/runtime";

import i18n from "../../locales/i18n";
import { toast } from "react-toastify";
import { handleError } from "../errors";
import { changePositionByLanguage } from "../../utils/toastify";
import { requestNotificationPermission } from "../../firebase/firebase"; 

export const registerUser = async (context, data) => {
  try {
    context.state.loadingBtn = true;
    data.notification_token = context.state.deviceToken
    const result = await context.effects.registerUser(data);

    toast.success(i18n.t("form.success.registerUsers"), {
      position: changePositionByLanguage(i18n.language),
    });
    context.state.loadingBtn = false;

    return result;
  } catch (err) {
    handleError(err);
    context.state.loadingBtn = false;
  }
};
