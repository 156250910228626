import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Icon22 } from "../imagepath";
import { useActions, useAppState } from "../../presenter";
import { formatDate } from "../../utils/date";
import { LazyLoadImage } from "react-lazy-load-image-component";

import Footer from "../footer";
import Header from "../header";
import SideBlog from "./sideBlog";
import PageChange from "../page-change";

const BlogDetails = () => {
  const queryParams = new URLSearchParams(location.search);
  const { t } = useTranslation();
  const { id } = useParams();
  const lang = queryParams.get("language");
  const navigate = useNavigate();
  const [blog, setBlog] = useState();
  const [randomBlogs, setRandomBlogs] = useState([]);
  const [query, setQuery] = useState("");
  const { user } = useAppState();
  const { loading } = useAppState();
  const [isLoading, setIsLoading] = useState();
  const { getBlog } = useActions(true);

  useEffect(() => {
    getBlog({ id, language: lang }).then((data) => {
      setBlog(data.blog);
      setRandomBlogs(data.randomBlogs);
      setIsLoading(false);
    });
  }, [id]);

  const handleSearch = (e) => {
    e.preventDefault();
    const searchQuery = encodeURIComponent(query);
    // window.location.href = `/blog-list?q=${searchQuery}`;
    navigate(`/blog-list?q=${searchQuery}`);
  };

  const replaceStr = (str) => {
    const modifiedString = str.replace(new RegExp("oembed", "g"), "iframe");
    const body = modifiedString.replace(
      new RegExp("<iframe url", "g"),
      "<iframe src"
    );
    return body;
  };

  if (isLoading && !blog) return <PageChange />;
  return (
    <>
      <div className="main-wrapper">
        <Header activeMenu={"Details"} />
        <div className="breadcrumb-bar">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-12">
                <div className="breadcrumb-list">
                  <nav aria-label="breadcrumb" className="page-breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/">{t("breadcrumb.home")}</Link>{" "}
                      </li>
                      <li className="breadcrumb-item" aria-current="page">
                        {t("breadcrumb.blogDetails")}
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>

        <section className="course-content">
          <div className="container">
            <div className="row">
              <div className="col-lg-9 col-md-12">
                {/* Blog Post */}
                {blog && blog.image && id && (
                  <div className="blog">
                    <div className="blog-image">
                      {/* <Link  to="/blog-details"> */}
                      <LazyLoadImage
                        className="img-fluid blog-img"
                        src={blog.image.url}
                        alt="Blog Image"
                      />
                      {/* </Link>  */}
                    </div>
                    <div className="blog-info clearfix">
                      <div className="post-left">
                        <ul>
                          <li>
                            <img className="img-fluid" src={Icon22} alt="" />
                            {formatDate(blog.createdAt)}
                          </li>
                        </ul>
                      </div>
                    </div>
                    <h3 className="blog-title">
                      {/* <Link  to=" /blog-details"> */}
                      {blog.title}
                      {/* </Link> */}
                    </h3>
                    <div className="blog-content">
                      <div
                        className="card-body"
                        dangerouslySetInnerHTML={{
                          __html: replaceStr(blog?.body),
                        }}></div>
                    </div>
                  </div>
                )}
                {/* /Blog Post */}
              </div>
              {/* Blog Sidebar */}
              <div className="col-lg-3 col-md-12 sidebar-right theiaStickySidebar">
                {/* Search */}
                <div className="stickysidebar">
                  <div className="card search-widget blog-search blog-widget">
                    <div className="card-body">
                      <form className="search-form" onSubmit={handleSearch}>
                        <div className="input-group">
                          <input
                            type="text"
                            placeholder="Search..."
                            className="form-control"
                            value={query}
                            onChange={(e) => setQuery(e.target.value)}
                          />
                          <button type="submit" className="btn btn-primary">
                            <i className="fa fa-search" />
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                  {/* /Search */}
                  {/* Latest Posts */}
                  <SideBlog
                    blogs={randomBlogs}
                    title={t("sugBlogs")}
                    type="blog"
                  />
                  {/* /Latest Posts */}
                </div>
              </div>
              {/* /Blog Sidebar */}
            </div>
          </div>
        </section>

        <Footer />
      </div>
    </>
  );
};

export default BlogDetails;
