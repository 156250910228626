import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useActions, useAppState } from "../../../presenter";
import ReactApexChart from "react-apexcharts";
import PageChange from "../../page-change";
import { captureScreenshot } from "../../../utils/screenshot";
import { useTranslation } from "react-i18next";

const ResultChart = ({
  onClick: setPageChange,
  courseId,
  language,
  surveyId,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [chartState, setChartState] = useState({
    series: [
      {
        name: "Percentage",
        data: [],
      },
    ],
    options: {
      chart: {
        height: 500,
        width: "100%",
        type: "radar",
      },
      xaxis: {
        categories: [],
        labels: {
          style: {
            fontSize: "15px",
            fontWeight: "bold",
            margin: "10px",
            offsetX: 0,
            color: "#000000",
            offsetY: 10,
          },
        },
      },
    },
  });
  const { result, topResults, surveyResult } = useAppState();

  const { setScreenshots } = useActions();

  useEffect(() => {
    const fieldNames = Object.keys(result);
    const data = Object.values(result);

    setChartState({
      series: [
        {
          name: "Percentage",
          data,
        },
      ],
      options: {
        chart: {
          height: "100%",
          width: "100%",
          type: "radar",
          toolbar: {
            show: false, // Disable the default ApexCharts toolbar
          },
          offsetX: 0, // Horizontal offset
          offsetY: 0, // Vertical offset
        },
        yaxis: {
          labels: {
            show: false,
          },
        },
        xaxis: {
          categories: fieldNames,
        },
        stroke: {
          colors: ["#5561A5"],
        },
        fill: {
          type: "gradient",
          gradient: {
            shade: "dark",
            type: "radial",
            gradientToColors: ["rgba(43, 58, 142, 0)"],
            stops: [0, 100],
            colorStops: [
              {
                offset: 0,
                color: "rgba(43, 58, 142, 0.3344)",
              },
            ],
          },
        },
        markers: {
          colors: ["#5561A5"],
        },
        tooltip: {
          y: {
            formatter: function (value) {
              return ""; // Set the tooltip labels to an empty string
            },
          },
        },
        legend: {
          position: "top",
          horizontalAlign: "right",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                height: "400px", // Adjust the height for smaller mobile view
              },
            },
          },
          {
            breakpoint: 576,
            options: {
              chart: {
                height: "400px", // Adjust the height for medium mobile view
              },
            },
          },
          {
            breakpoint: 768,
            options: {
              chart: {
                height: "400px", // Adjust the height for larger mobile view
              },
            },
          },
          {
            breakpoint: 992,
            options: {
              chart: {
                height: "500px", // Adjust the height for tablet view
              },
            },
          },
        ],
      },
    });
  }, [result]);

  const handleNextClick = async () => {
    setPageChange("suggestedFields");
    const screenshot = await captureScreenshot(
      document.getElementById("chart")
    );

    if (screenshot) {
      await setScreenshots({ key: "chart", screenshot });
    }
  };

  if (!result) return <PageChange />;

  return (
    <>
      <div>
        <div className="container-fluid survey-header">
          <div className="row justify-content-between align-items-center py-3 bg-custom">
            <div className="col">
              <h6 className="text-light">{t("step1")}</h6>
              <h3 className="text-light">{t("result")}</h3>
              <p className="text-light">{t("skillMatch")}</p>
            </div>
            <div className="col-auto">
              <div className="progress-circle">
                <div className="half-circle"></div>
                <span className="progress-text">1/4</span>
              </div>
            </div>
          </div>
        </div>
        <div style={{ marginBottom: "10rem" }}></div>

        <section>
          <h1 className="report-header">{t("results")}</h1>
          <div id="chart" className=" d-flex justify-content-center row">
            <div className="col-md-6 mb-3 mb-md-0 chart-container">
              <ReactApexChart
                options={chartState.options}
                series={chartState.series}
                type="radar"
              />
            </div>
            <div className="col-md-6 ">
              <div className="mt-md-5 px-4">
                <h5 className="mb-4 font-weight-bold">
                  {" "}
                  أعلى&nbsp;المجالات&nbsp;لديك&nbsp;هي:
                </h5>
                {topResults.map((result, i) => (
                  <div key={i} className="mb-4">
                    <div className="row align-items-center">
                      <div className="col-4 col-md-3">
                        <p>{result.fieldName}</p>
                      </div>
                      <div className="col-8 col-md-9">
                        <div
                          className={`progress-result progress-result-${i} mb-3`}
                          style={{ width: `${result.percentage}%` }}>
                          <p>{result.percentage}%</p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
        <div className="widget-btn">
          {!surveyResult && (
            <button
              type="button"
              className="btn btn-black prev_btn"
              onClick={() => {
                if (surveyId) navigate(`/survey-details/${surveyId}`);
                else navigate(`/course-details/${language}/${courseId}`);
              }}>
              {t("back")}
            </button>
          )}
          <button
            type="button"
            onClick={handleNextClick}
            className="btn btn-info-light next_btn">
            {t("next")}
          </button>
        </div>
      </div>
    </>
  );
};

export default ResultChart;
