import React, { useEffect, useState } from "react";
import { Link, json, useNavigate, useParams } from "react-router-dom";
import { useForm, FormProvider, useFormContext } from "react-hook-form";
import Footer from "../../footer";
import { useActions, useAppState } from "../../../presenter";
import QuestionInput from "./questionInput";
import Pagination from "./pagination";
import ResultChart from "./resultChart";
import SuggestedField from "./suggestedField";
import SelectedSuggestion from "./selectedSuggestion";
import ProjectPosition from "./projectPosition";
import BusinessModel from "./businessModel";
import Report from "./report";
import Header from "../../header";
import { useTranslation } from "react-i18next";
import PageChange from "../../page-change";

const SurveyQuestion = () => {
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);

  const language = queryParams.get("language");
  const courseId = queryParams.get("courseId");
  const surveyId = useParams().id;
  console.log(surveyId);
  console.log(courseId);
  const [questions, setQuestions] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [minItem, setMinItem] = useState(questionsPerPage * (currentPage - 1));
  const [maxItem, setMaxItem] = useState(questionsPerPage * currentPage);
  const [pageChange, setPageChange] = useState("questions");
  const questionsPerPage = 8;
  const [totalPages, setTotalPages] = useState(null);
  const [totalQuestions, setTotalQuestions] = useState(null);
  const {
    listQuestions,
    calculateResults,
    getSurveyReport,
    deleteSavedSurveyQuestions,
    saveQuestions,
    listSavedQuestions,
  } = useActions();
  const { selectedSubfields } = useAppState();
  const { t } = useTranslation();
  const [savedSurveyQuestions, setSavedSurveyQuestions] = useState([]);
  const methods = useForm();
  const [isNextDisabled, setIsNextDisabled] = useState(true);

  useEffect(() => {
    listSavedQuestions({ page: 1, limit: 1000 }).then(async (result) => {
      if (Object.keys(result?.surveyQuestions)?.length == totalQuestions) {
        const convertedData = {
          answers: {},
        };

        result.shuffledQuestions.forEach((question) => {
          const fieldId = question.fieldId;
          const questionId = question.id;
          const answer = result.surveyQuestions[questionId];

          if (!convertedData.answers[fieldId]) {
            convertedData.answers[fieldId] = {};
          }

          convertedData.answers[fieldId][questionId] = answer;
        });

        await calculateResults(convertedData);
        setPageChange("results");
      }
    });

    listSavedQuestions({ page: 1, limit: 1000 }).then((result) => {
      localStorage.removeItem("shuffledQuestion");
      localStorage.removeItem("survey");
      localStorage.removeItem("surveyQuestionsPage");
      if (result?.shuffledQuestions) {
        setTotalQuestions(result?.shuffledQuestions?.length);
        setCurrentPage(result?.surveyQuestionsProgress);
        localStorage.setItem(
          "surveyQuestionsPage",
          JSON.stringify(result?.surveyQuestionsProgress)
        );
        setTotalPages(
          Math.ceil(result?.shuffledQuestions?.length / questionsPerPage)
        );
        localStorage.setItem(
          "shuffledQuestion",
          JSON.stringify(result?.shuffledQuestions)
        );
        setQuestions(result?.shuffledQuestions);
      }
      if (result?.surveyQuestions) {
        localStorage.setItem("survey", JSON.stringify(result?.surveyQuestions));
        setSavedSurveyQuestions(result?.surveyQuestions);
      }
      if (!result?.shuffledQuestions) {
        listQuestions({ page: currentPage, limit: 1000 }).then((result) => {
          const shuffledQuestions = result.sort(() => Math.random() - 0.5);
          setTotalQuestions(shuffledQuestions?.length);
          localStorage.setItem(
            "shuffledQuestion",
            JSON.stringify(shuffledQuestions)
          );
          setTotalPages(
            Math.ceil(shuffledQuestions?.length / questionsPerPage)
          );
          setSavedSurveyQuestions([]);
          setQuestions(shuffledQuestions);
          setTotalPages(Math.ceil(result.length / questionsPerPage));
        });
      }
    });

    getSurveyReport().then((res) => {
      if (res) setPageChange("results");
    });
  }, [totalQuestions]);

  useEffect(() => {
    setMinItem(questionsPerPage * (currentPage - 1));
    setMaxItem(questionsPerPage * currentPage);
  }, [currentPage, totalPages, questionsPerPage]);

  const onSubmit = async (data) => {
    const surveyQuestions = JSON.parse(localStorage.getItem("survey"));
    await saveQuestions({
      shuffledQuestion: JSON.parse(localStorage.getItem("shuffledQuestion")),
      surveyQuestions: surveyQuestions,
      surveyQuestionsNumber:
        JSON.parse(localStorage.getItem("surveyQuestionsPage")) ?? 1,
    });
    await calculateResults(data);

    setPageChange("results");
  };
  useEffect(() => {
    const localSurvey = JSON.parse(localStorage.getItem("survey"));
    localStorage.setItem(
      "survey",
      JSON.stringify({ ...localSurvey, ...savedSurveyQuestions })
    );
  }, [savedSurveyQuestions]);
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pageChange, currentPage]);

  const renderQuestions = () => {
    const startIndex = (currentPage - 1) * questionsPerPage;
    const endIndex = startIndex + questionsPerPage;

    return (
      <>
        {questions.slice(startIndex, endIndex).map((question, index) => (
          <QuestionInput
            isHidden={!(index >= minItem && index < maxItem)}
            question={question}
            key={question.id}></QuestionInput>
        ))}
      </>
    );
  };

  return (
    <>
      <div className="main-wrapper">
        <Header activeMenu={"SurveyQuestion"} />

        <div className="container-fluid" style={{ marginTop: "270px" }}>
          {pageChange == "questions" && (
            <div>
              <div class="container-fluid mb-5">
                <div class="row justify-content-between align-items-center py-3 survey-header">
                  <div class="col">
                    <h6 class="text-light">{t("step1")}</h6>
                    <h3 class="text-light">{t("survey")}</h3>
                    <p class="text-light">{t("chooseSurveyQuestions")}</p>
                  </div>
                  <div class="col-auto ">
                    <div class="progress-circle">
                      <div class="half-circle"></div>
                      <span class="progress-text">1/4</span>
                    </div>
                  </div>
                </div>
              </div>

              <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                  <div className="container">
                    <div className="row justify-content-center">
                      <div className="col-12 col-md-8">
                        <div className="mb-5">
                          <div
                            className="progress-stip mb-2"
                            style={{ height: "10px" }}>
                            <div
                              className="progress-bar bg-primary progress-bar-striped"
                              style={{
                                width: `${
                                  (currentPage /
                                    Math.ceil(questions?.length / 8)) *
                                  100
                                }%`,
                              }}></div>
                          </div>
                          <p
                            className="text-center text-muted"
                            style={{ color: "black", fontSize: "15px" }}>
                            {currentPage}/{Math.ceil(questions?.length / 8)}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12">
                        <h2 className="survey-title">{t("questions")}</h2>
                        <div className="survey-container">
                          {!questions && <PageChange />}
                          {questions &&
                            questions.map((question, index) => (
                              <QuestionInput
                                isHidden={
                                  !(index >= minItem && index < maxItem)
                                }
                                question={question}
                                survey={savedSurveyQuestions}
                                isNextDisabled={isNextDisabled}
                                setSavedSurveyQuestions={
                                  setSavedSurveyQuestions
                                }
                                key={question.id}></QuestionInput>
                            ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </form>

                <Pagination
                  onSubmit={onSubmit}
                  pagesCount={totalPages}
                  selectedPage={currentPage}
                  isNextDisabled={isNextDisabled}
                  setIsNextDisabled={setIsNextDisabled}
                  survey={savedSurveyQuestions}
                  onClick={setCurrentPage}
                />
              </FormProvider>
            </div>
          )}

          {pageChange == "results" && (
            <ResultChart
              onClick={setPageChange}
              courseId={courseId}
              surveyId={surveyId}
              language={language}
            />
          )}

          {pageChange == "suggestedFields" && (
            <SuggestedField onClick={setPageChange} />
          )}

          {pageChange == "selectedSuggestions" && (
            <SelectedSuggestion onClick={setPageChange} />
          )}

          {pageChange == "projectPosition" && (
            <ProjectPosition onClick={setPageChange} />
          )}

          {pageChange == "businessModel" && (
            <BusinessModel onClick={setPageChange} />
          )}

          {pageChange == "report" && (
            <Report onClick={setPageChange} id={courseId} language={language} />
          )}
        </div>

        <Footer />
      </div>
    </>
  );
};

export default SurveyQuestion;
