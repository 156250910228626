import "regenerator-runtime/runtime"; 

import { handleError } from "../errors";
import { toast } from "react-toastify";
import i18n from "../../locales/i18n";

export const payment = async (context ,data) => {
  try {
   
    const result = await context.effects.payment(data);
    if(result){
      toast.success(i18n.t("form.success.enrolled"), {
        position: changePositionByLanguage(i18n.language),
      });
    }
    return result;
  } catch (err) {
    handleError(err);
  }
};

