import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useActions, useAppState } from "../../../../presenter";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import Footer from "../../../footer";
import Header from "../../../header";
import { TagsInput } from "react-tag-input-component";
import i18n from "../../../../locales/i18n";
import { toast } from "react-toastify";
import { changePositionByLanguage } from "../../../../utils/toastify";

const AddField = ({ nextTab3, prevTab2, data, updateFormData }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const lang = queryParams.get("lang");
  const id = queryParams.get("id");
  //   const { isLoading } = useAppState();
  const {
    getField,
    addField,
    addSubfield,
    addQuestion,
    updateField,
    updateQuestion,
    updateSubfield,
    deleteQuestion,
    deleteSubfield,
  } = useActions();
  const [isFormValid, setIsFormValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [updatedSubfieldIndex, setUpdatedSubfieldIndex] = useState("");
  const [updatedQuestionIndex, setUpdatedQuestionIndex] = useState(false);
  const [actionType, setActionType] = useState("");
  const [subfields, setSubfields] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [field, setField] = useState({
    text: "",
  });

  useEffect(() => {
    checkFormValidity();
  }, [field]);

  useEffect(() => {
    if (id) {
      getField(id).then((data) => {
        setField({
          id: data.id,
          name: data.name,
          description: data.description,
        });
        setSubfields(data.subfields);
        setQuestions(data.questions);
      });
    }
  }, [id]);

  const handleAddSubfield = () => {
    const newSubfield = {
      name: "",
    };
    const updatedSubfields = [...subfields];
    updatedSubfields.push(newSubfield);
    setSubfields(updatedSubfields);
  };

  const handleAddQuestion = () => {
    const newQuestion = {
      name: "",
    };
    const updatedQuestions = [...questions];
    updatedQuestions.push(newQuestion);
    setQuestions(updatedQuestions);
  };

  const handleSubmitSubfield = async (subfield, i) => {
    const result = await addSubfield({ id, data: { name: subfield.name } });
    setIsLoading(true);
    if (result) {
      toast.success(i18n.t("form.success.updated"), {
        position: changePositionByLanguage(i18n.language),
      });
      setIsLoading(false);
    }
    const updatedSubfields = [...subfields];
    updatedSubfields[i].id = result.id;
    setSubfields(updatedSubfields);
  };

  const handleSubmitQuestion = async (question, i) => {
    setIsLoading(true);

    setUpdatedQuestionIndex(i);

    setActionType("addQuestion");

    const result = await addQuestion({ id, data: { text: question.text } });

    if (result) {
      toast.success(i18n.t("form.success.updated"), {
        position: changePositionByLanguage(i18n.language),
      });
      setIsLoading(true);
    }
    const updatedQuestions = [...questions];
    updatedQuestions[i].id = result.id;
    setQuestions(updatedQuestions);
  };

  const handleSave = async () => {
    console.log("field", field, field.name, subfields);
    if (isFormValid) {
      setIsLoading(true);
      const newField = await addField({
        name: field.name,
        description: field.description,
      });
      console.log("addfield", newField);
      for (const subfield of subfields) {
        await addSubfield({ id: newField.id, data: { name: subfield.name } });
      }
      for (const question of questions) {
        await addQuestion({ id: newField.id, data: { text: question.text } });
      }
      setIsLoading(false);
    }
  };

  const checkFormValidity = () => {
    if (!field.name || !field.description) {
      setIsFormValid(false);
      return;
    }

    for (const subfield of subfields) {
      if (!subfield.name) {
        setIsFormValid(false);
        return;
      }
    }

    for (const question of questions) {
      if (!question.text) {
        setIsFormValid(false);
        return;
      }
    }

    setIsFormValid(true); // All names are non-empty
  };

  const handleUpdateField = async () => {
    Swal.fire({
      title: t("form.success.updateConfirmation"),
      text: t("form.success.updateWarning"),
      icon: "success",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: t("cancel"),
      confirmButtonText: t("form.submit"),
    }).then(async (answer) => {
      if (answer.isConfirmed) {
        setIsLoading(true);
        setActionType("editField");

        await updateField({
          id: field.id,
          data: { name: field.name, description: field.description },
        });
        setIsLoading(false);
      }
    });
  };

  const handleUpdateSubfield = async (subfield, subfieldIndex) => {
    Swal.fire({
      title: t("form.success.updateConfirmation"),
      text: t("form.success.updateWarning"),
      icon: "success",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: t("cancel"),
      confirmButtonText: t("form.submit"),
    }).then(async (answer) => {
      if (answer.isConfirmed) {
        setIsLoading(true);
        setUpdatedSubfieldIndex(subfieldIndex);
        setActionType("edit");

        const result = await updateSubfield({
          id: subfield.id,
          data: { name: subfield.name },
        });
        if (result)
          toast.success(i18n.t("form.success.updated"), {
            position: changePositionByLanguage(i18n.language),
          });
        setIsLoading(false);

        const updatedSubfields = [...subfield];
        updatedSubfields[subfieldIndex].id = result.id;
        setQuestions(updatedSubfields);
        console.log("updateSubfield", result);
      }
    });
  };

  const handleDeleteSubfield = (id, subfieldIndex) => {
    Swal.fire({
      title: t("deleteConfirmation"),
      text: t("deleteWarning"),
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: t("cancel"),
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: t("delete"),
    }).then(async (result) => {
      if (result.isConfirmed) {
        setIsLoading(true);

        await deleteSubfield(id);
        toast.success(i18n.t("form.success.deleted"), {
          position: changePositionByLanguage(i18n.language),
        });
        setIsLoading(false);

        const updatedSubfields = [...subfields];
        updatedSubfields.splice(subfieldIndex, 1);
        setSubfields(updatedSubfields);
      }
    });
  };

  const handleUpdateQuestion = async (question, questionIndex) => {
    console.log(question);
    Swal.fire({
      title: t("form.success.updateConfirmation"),
      text: t("form.success.updateWarning"),
      icon: "success",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: t("cancel"),
      confirmButtonText: t("form.submit"),
    }).then(async (answer) => {
      if (answer.isConfirmed) {
        setIsLoading(true);
        setUpdatedQuestionIndex(questionIndex);
        setActionType("editQuestion");

        const result = await updateQuestion({
          id: question.id,
          data: { text: question.text },
        });
        if (result) {
          toast.success(i18n.t("form.success.updated"), {
            position: changePositionByLanguage(i18n.language),
          });
          setIsLoading(false);

          const updatedQuestions = [...questions];
          updatedQuestions[questionIndex].id = result.id;
          setQuestions(updatedQuestions);
        }
      }
    });
  };

  const handleDeleteQuestion = (id, questionIndex) => {
    console.log("handleDeleteMaterial");
    Swal.fire({
      title: t("deleteConfirmation"),
      text: t("deleteWarning"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: t("cancel"),
      confirmButtonText: t("delete"),
    }).then(async (result) => {
      if (result.isConfirmed) {
        setIsLoading(true);

        await deleteQuestion(id);
        toast.success(i18n.t("form.success.deleted"), {
          position: changePositionByLanguage(i18n.language),
        });
        setIsLoading(false);

        const updatedQuestions = [...questions];
        updatedQuestions.splice(questionIndex, 1);
        setQuestions(updatedQuestions);
      }
    });
  };

  return (
    <>
      <div className="main-wrapper">
        <Header activeMenu={"AddField"} />
        <section className="page-content course-sec">
          <div className="container" style={{ marginTop: "70px" }}>
            <div className="row align-items-center">
              <div className="col-md-12">
                <div className="add-course-header">
                  {id && <h2> {t("Edit Field")}</h2>}
                  {!id && <h2> {t("Add New Field")}</h2>}
                  <div className="add-course-btns">
                    <ul className="nav">
                      <li>
                        <Link to="/admin/fields" className="btn btn-black">
                          {t("Back to Fields")}
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <fieldset className="field-card" style={{ display: "block" }}>
                    <div className="add-course-info">
                      <div className="add-course-inner-header"></div>
                      <div className="add-course-form">
                        <div className="form-group">
                          <div className="curriculum-grid">
                            <div className="curriculum-head">
                              <p>{t("Field")}</p>
                            </div>
                            <div className="faq-grid">
                              <div
                                className="faq-header"
                                style={{ display: "block" }}>
                                <input
                                  style={{ marginBottom: "20px" }}
                                  type="text"
                                  className="form-control"
                                  name="title"
                                  value={field.name}
                                  placeholder={t("Enter field name")}
                                  onChange={(e) => {
                                    const updatedField = { ...field };
                                    updatedField.name = e.target.value;
                                    setField(updatedField);
                                  }}
                                />

                                <input
                                  style={{ marginBottom: "20px" }}
                                  type="text"
                                  className="form-control"
                                  name="description"
                                  value={field.description}
                                  placeholder={t("Enter field description")}
                                  onChange={(e) => {
                                    const updatedField = { ...field };
                                    updatedField.description = e.target.value;
                                    setField(updatedField);
                                  }}
                                />
                              </div>
                              {id && (
                                <button
                                  type="button"
                                  className="btn btn-primary m-1"
                                  disabled={
                                    (isLoading && actionType == "editField") ||
                                    !field.name
                                  }
                                  onClick={() => {
                                    handleUpdateField();
                                  }}>
                                  {isLoading && actionType == "editField" && (
                                    <span
                                      className="spinner-border spinner-border-sm"
                                      role="status"
                                      aria-hidden="true"></span>
                                  )}
                                  {t("Update Field")}
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="curriculum-grid">
                          <div className="curriculum-head">
                            <p>{t("Subfields")}</p>
                            <button
                              type="button"
                              className="btn"
                              onClick={() => handleAddSubfield()}>
                              {t("Add Subfield")}
                            </button>
                          </div>
                          {subfields.map((subfield, subfieldIndex) => (
                            <div className="faq-grid" key={subfieldIndex}>
                              <div className="faq-header">
                                <input
                                  type="text"
                                  className="form-control"
                                  name="name"
                                  placeholder={t("Enter subfield name")}
                                  value={subfield.name}
                                  onChange={(e) => {
                                    const updatedSubfields = [...subfields];
                                    updatedSubfields[subfieldIndex].name =
                                      e.target.value;
                                    setSubfields(updatedSubfields);
                                  }}
                                />
                              </div>
                              {id && !subfield.id && (
                                <button
                                  type="button"
                                  className="btn btn-primary m-1"
                                  disabled={isLoading || !subfield.name}
                                  onClick={() => {
                                    handleSubmitSubfield(
                                      subfield,
                                      subfieldIndex
                                    );
                                  }}>
                                  {isLoading && (
                                    <span
                                      className="spinner-border spinner-border-sm"
                                      role="status"
                                      aria-hidden="true"></span>
                                  )}
                                  {t("Submit Subfield")}
                                </button>
                              )}
                              {subfield.id && (
                                <button
                                  type="button"
                                  className="btn btn-primary m-1"
                                  disabled={
                                    (isLoading &&
                                      actionType == "edit" &&
                                      updatedSubfieldIndex == subfieldIndex) ||
                                    !subfield.name
                                  }
                                  onClick={() => {
                                    handleUpdateSubfield(
                                      subfield,
                                      subfieldIndex
                                    );
                                  }}>
                                  {isLoading &&
                                    actionType == "edit" &&
                                    updatedSubfieldIndex == subfieldIndex && (
                                      <span
                                        className="spinner-border spinner-border-sm"
                                        role="status"
                                        aria-hidden="true"></span>
                                    )}
                                  {t("Update Subfield")}
                                </button>
                              )}
                              {subfield.id && (
                                <button
                                  type="button"
                                  className="btn btn-danger m-1"
                                  onClick={() => {
                                    handleDeleteSubfield(
                                      subfield.id,
                                      subfieldIndex
                                    );
                                  }}>
                                  {t("Delete Subfield")}
                                </button>
                              )}
                            </div>
                          ))}
                        </div>
                        <div className="curriculum-grid">
                          <div className="curriculum-head">
                            <p>{t("Questions")}</p>
                            <button
                              type="button"
                              className="btn"
                              onClick={() => handleAddQuestion()}>
                              {t("Add Question")}
                            </button>
                          </div>
                          {questions.map((question, questionIndex) => (
                            <div className="faq-grid" key={questionIndex}>
                              <div className="faq-header">
                                <input
                                  type="text"
                                  className="form-control"
                                  name="name"
                                  value={question.text}
                                  placeholder={t("Enter question text")}
                                  onChange={(e) => {
                                    const updatedQuestions = [...questions];
                                    updatedQuestions[questionIndex].text =
                                      e.target.value;
                                    setQuestions(updatedQuestions);
                                  }}
                                />
                              </div>
                              {id && !question.id && (
                                <button
                                  type="button"
                                  className="btn btn-primary m-1"
                                  disabled={
                                    (isLoading &&
                                      actionType == "addQuestion" &&
                                      updatedQuestionIndex == questionIndex) ||
                                    !question.text
                                  }
                                  onClick={() => {
                                    handleSubmitQuestion(
                                      question,
                                      questionIndex
                                    );
                                  }}>
                                  {isLoading &&
                                    actionType == "addQuestion" &&
                                    updatedQuestionIndex == questionIndex && (
                                      <span
                                        className="spinner-border spinner-border-sm"
                                        role="status"
                                        aria-hidden="true"></span>
                                    )}
                                  {t("Submit Question")}
                                </button>
                              )}
                              {question.id && (
                                <button
                                  type="button"
                                  className="btn btn-primary m-1"
                                  disabled={
                                    (isLoading &&
                                      actionType == "editQuestion" &&
                                      updatedQuestionIndex == questionIndex) ||
                                    !question.text
                                  }
                                  onClick={() => {
                                    handleUpdateQuestion(
                                      question,
                                      questionIndex
                                    );
                                  }}>
                                  {isLoading &&
                                    actionType == "editQuestion" &&
                                    updatedQuestionIndex == questionIndex && (
                                      <span
                                        className="spinner-border spinner-border-sm"
                                        role="status"
                                        aria-hidden="true"></span>
                                    )}
                                  {t("Update Question")}
                                </button>
                              )}
                              {question.id && (
                                <button
                                  type="button"
                                  className="btn btn-danger m-1"
                                  onClick={() => {
                                    handleDeleteQuestion(
                                      question.id,
                                      questionIndex
                                    );
                                  }}>
                                  {t("Delete Question")}
                                </button>
                              )}
                            </div>
                          ))}
                        </div>
                      </div>
                      {!id && (
                        <div className="widget-btn">
                          <button
                            type="button"
                            className={`btn btn-success-dark ${
                              !isFormValid ? "disabled" : ""
                            }`}
                            onClick={handleSave}
                            disabled={isLoading}>
                            {isLoading && (
                              <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"></span>
                            )}
                            {t("Save")}
                          </button>
                        </div>
                      )}
                    </div>
                  </fieldset>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </div>
    </>
  );
};

export default AddField;
