import React from "react";
import { Link } from "react-router-dom";
import {
  Course13,
  Course14,
  Course15,
  Icon1,
  Icon2,
  User1,
  User11,
  defaultImg,
} from "../../imagepath";
import { useAppState } from "../../../presenter";
import { useTranslation } from "react-i18next";

export default function DepositMenu({ activeMenu }) {
  const { user } = useAppState();
  const { t } = useTranslation();
  return (
    <div>
      <div className="student-group">
        <div className="course-group ">
          <div className="course-group-img d-flex">
            <div>
              <img
                src={user.image ? user.image.url : defaultImg}
                alt=""
                className="img-fluid"
              />
            </div>
            <div className="d-flex align-items-center">
              <div className="course-name">
                <p>{t("studentMenu")}</p>
                <h4 style={{ color: "#fff" }}>
                  {user.firstName} {user.lastName}
                  {/* <span>Beginner</span> */}
                </h4>
                <p>
                  {user.role == "Student" ? t("footer.student") : user.role}
                </p>
              </div>
            </div>
          </div>
          {/* <div className="course-share ">
            <Link to="/setting-student-security" className="btn btn-primary">
              Account Settings
            </Link>
          </div> */}
        </div>
      </div>

      <div className="my-student-list">
        <ul>
          <li>
            <Link
              className={activeMenu === "Dashboard" ? "active" : ""}
              to="/student-dashboard">
              {t("settings.dashboard")}
            </Link>
          </li>
          <li>
            <Link
              className={activeMenu === "Courses" ? "active" : ""}
              to="/course-student">
              {t("enrolledCourses")}
            </Link>
          </li>
          <li>
            <Link
              className={activeMenu === "Wishlists" ? "active" : ""}
              to="/course-wishlist">
              {t("wishlists")}
            </Link>
          </li>
          {/* <li>
          <Link to="/course-message">Messages</Link>
        </li>
        <li>
          <Link to="/purchase-history">Purchase history</Link>
        </li>
        <li>
          <Link
            className={activeMenu === "Deposit" ? "active" : ""}
            to="/deposit-student"
          >
            Deposit
          </Link>
        </li>
        <li className="mb-0">
          <Link
            className={activeMenu === "Transactions" ? "active" : ""}
            to="/transactions-student"
          >
            Transactions
          </Link>
        </li> */}
        </ul>
      </div>
    </div>
  );
}
