import React, { useState, useEffect } from "react";
import Header from "../../../header";
import DetailsContent from "./detailsContent";
import { Icon1, People, Timer, User1 } from "../../../imagepath";
import Footer from "../../../footer";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useActions, useAppState } from "../../../../presenter";
import { translateTime } from "../../../../utils/time";
import PageChange from "../../../page-change";

const CourseDetails = () => {
  const { t } = useTranslation();
  const { id, language: lang } = useParams();
  const [course, setCourse] = useState(null);
  const [totalEnrolledStudent, setTotalEnrolledStudent] = useState(null);
  const [tags, setTags] = useState([]);
  const { getCourse } = useActions();
  const { user } = useAppState();

  useEffect(() => {
    getCourse({ id, language: lang }).then((data) => {
      setCourse(data.course);
      setTotalEnrolledStudent(data.totalEnrolledStudent);
      setTags(data.tags);
    });
  }, [id]);
  const computeTotals = () => {
    let totalLessons = 0;
    let totalDuration = 0;

    course.sections.forEach((section) => {
      if (section.lessons && Array.isArray(section.lessons)) {
        section.lessons.forEach((lesson) => {
          if (lesson.duration) {
            totalLessons++;
            totalDuration += +lesson.duration;
          }
        });
      }
    });

    const hours = Math.floor(totalDuration / 60);
    const minutes = totalDuration % 60;

    const formattedDuration =
      (hours > 0 ? hours + "h " : "") + (minutes > 0 ? minutes + "min" : "");

    return { totalLessons, totalDuration: formattedDuration };
  };

  if (!course) return <PageChange />;

  return (
    <>
      <div className="main-wrapper">
        <Header activeMenu={"CourseDetails"} />

        <div className="breadcrumb-bar">
          <div className="container" style={{ marginTop: "70px" }}>
            <div className="row">
              <div className="col-md-12 col-12">
                <div className="breadcrumb-list">
                  <nav aria-label="breadcrumb" className="page-breadcrumb">
                    <ol className="breadcrumb">
                      <li
                        className="breadcrumb-item"
                        style={{ paddingLeft: "8px" }}>
                        <Link to="/">{t("breadcrumb.home")}</Link>
                      </li>
                      <li className="breadcrumb-item" aria-current="page">
                        {t("courses")}
                      </li>
                      <li className="breadcrumb-item" aria-current="page">
                        {t("allCourses")}
                      </li>
                      <li className="breadcrumb-item" aria-current="page">
                        {course.title}
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="inner-banner"
          style={{ backgroundImage: `url(${course.image?.url ?? ""})` }}>
          <div className="container">
            <div className="row">
              <div className="col-lg-8">
                <div className="instructor-wrap border-bottom-0 m-0">
                  {tags &&
                    tags.map((tag) => (
                      <span className="web-badge mb-3">{tag.name}</span>
                    ))}
                </div>
                <h2>{course.title}</h2>
                <p>{course.subTitle}</p>
                <div className="course-info d-flex align-items-center border-bottom-0 m-0 p-0">
                  <div className="cou-info">
                    <img src={Icon1} alt="" />
                    <p>
                      {t(
                        computeTotals().totalLessons > 1
                          ? "courseInfo.totalLessons.plural"
                          : "courseInfo.totalLessons.singular",
                        {
                          count: computeTotals().totalLessons,
                        }
                      )}
                    </p>
                  </div>{" "}
                  <div className="cou-info">
                    <img src={Timer} alt="" />
                    <p>
                      {" "}
                      {translateTime(
                        computeTotals().totalDuration.toString(),
                        user?.language?.toLowerCase() ?? "ar"
                      )}
                    </p>
                  </div>
                  <div className="cou-info">
                    <img src={People} alt="" />
                    <p>
                      {t("courseInfo.enrolledStudents", {
                        count: totalEnrolledStudent,
                      })}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <DetailsContent
          course={course}
          totalEnrolledStudent={totalEnrolledStudent}
        />

        <Footer />
      </div>
    </>
  );
};

export default CourseDetails;
