import React, { useEffect, useRef, useState } from "react";
import { LogOut, Moon, Star, User } from "react-feather";
import { Link, useNavigate } from "react-router-dom";

import useOnClickOutside from "../../hooks/useOnClickOutside";
import { Notification } from "../imagepath";
import { useActions, useAppState } from "../../presenter";
import { formatTime } from "../../utils/time";
import { useTranslation } from "react-i18next";
import { notificationNavigation } from "../../utils/notification-navigation";

export const NotificationHeader = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [showNotification, setShowNotification] = useState(false);
  const {
    listNotifications,
    markAllNotificationsAsRead,
    markNotificationAsRead,
  } = useActions();
  const { notifications, user } = useAppState();
  const isRtl = user ? user.language == "AR" : i18n.language === "ar";
  const [page, setPage] = useState(1);
  const [isStatusChanged, setIsStatusChanged] = useState(false);
  const [isNewNotification, setIsNewNotification] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [query, setQuery] = useState("");

  // change header background on scroll
  function handleQueryChange(event) {
    setQuery(event.target.value);
  }
  const unreadNotifications = notifications.filter(
    (notification) => notification.status === "Active"
  );
  useEffect(() => {
    if (unreadNotifications.length > 0) setIsNewNotification(true);
    else setIsNewNotification(false);
  }, [unreadNotifications, isStatusChanged]);
  useEffect(() => {
    getNotifications();
  }, [query, page, isStatusChanged]);

  useEffect(() => {
    setPage(1);
  }, [query]);
  const getNotifications = () => {
    listNotifications({ currentPage: page, limit: 5, q: query }).then(
      (pages) => {
        setTotalPages(pages);
      }
    );
  };
  const markAllAsRead = () => {
    setPage(1);
    markAllNotificationsAsRead().then(getNotifications());
  };

  const notificationNav = (courseId, sectionId, language, lessonId, action) => {
    const result = notificationNavigation(
      courseId,
      language,
      sectionId,
      lessonId,
      action
    );
    navigate(result);
  };

  const notification = useRef();
  useOnClickOutside(notification, () => setShowNotification(false));

  const profile = useRef();
  useOnClickOutside(profile, () => setShowProfile(false));

  const notificationClick = (e) => {
    e.preventDefault();
    setShowNotification(!showNotification);
  };

  return (
    <li className="nav-item noti-nav " style={{ cursor: "pointer" }}>
      <Link
        to="#"
        className={
          showNotification ? "dropdown-toggle show" : "dropdown-toggle"
        }
        data-bs-toggle="dropdown">
        {isNewNotification && (
          <span
            className={`badge rounded-pill badge-notification bg-primary  ${
              isRtl ? "right dot" : "right dot_l"
            }`}>
            &nbsp;
          </span>
        )}

        <img onClick={notificationClick} src={Notification} alt="img" />
      </Link>
      <div
        ref={notification}
        className={
          showNotification
            ? `notifications dropdown-menu dropdown-menu-${
                isRtl ? "left" : "right"
              } show `
            : `notifications dropdown-menu dropdown-menu-${
                isRtl ? "left" : "right"
              }`
        }>
        <div className="topnav-dropdown-header notify">
          <span className="notification-title">
            {t("Notifications")}{" "}
            <select value={query} onChange={handleQueryChange}>
              <option value="">{t("all")}</option>
              <option value="Active">{t("unRead")}</option>
            </select>
          </span>
          <Link
            to="#"
            className="clear-noti"
            onClick={() => {
              markAllAsRead();
            }}>
            {t("MarkAllAsRead")}{" "}
            <span className="fa-solid fa-circle-check"></span>
          </Link>
        </div>
        <div className={`noti-content `}>
          <ul className={`notification-list ${isRtl ? "total-item" : ""}`}>
            {notifications.map((notification) => (
              <li
                key={notification.id}
                className="notification-message"
                onClick={() => {
                  markNotificationAsRead(notification.id);
                  setIsStatusChanged((prev) => !prev);
                  notificationNav({
                    courseId: notification?.metaData?.courseId,
                    language: notification?.metaData?.language,
                    action: notification?.metaData?.action,
                  });
                }}>
                <div
                  className="media d-block"
                  style={{
                    backgroundColor: `${
                      notification.status === "Active" ? "#ccc" : "#fff"
                    }`,
                  }}>
                  <div className="media-body">
                    <h6>
                      <Link to="/page-notification">{notification.title}</Link>
                    </h6>

                    <p>{notification.body}</p>
                    <p>{formatTime(notification.createdAt, user.language)}</p>
                  </div>
                </div>
              </li>
            ))}

            {totalPages > page && (
              <p
                style={{
                  textAlign: "center",
                  marginTop: "20px",
                  fontSize: "18px",
                }}
                onClick={(e) => {
                  setPage((prev) => prev + 1);
                  e.stopPropagation();
                }}>
                {t("showMore")}
              </p>
            )}
          </ul>
        </div>
      </div>
    </li>
  );
};
