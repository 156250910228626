import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Footer from "../../../footer";
import Header from "../../../header";
import { InstructorProfileBg } from "../../../imagepath";
import { BlogValidator } from "../../../../validators/blog.validator";
import { useForm } from "react-hook-form";
import { useActions, useAppState } from "../../../../presenter";
import { yupResolver } from "@hookform/resolvers/yup";
import i18n from "../../../../locales/i18n";
import { useTranslation } from "react-i18next";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import { avatarValidator } from "./../../../../utils/avatar-validation";

const AddBlog = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");
  const lang = queryParams.get("lang");
  const blogId = queryParams.get("blogId");
  const { user, loadingBtn } = useAppState();
  const [imgFile, setImgFile] = useState(null);
  const [body, setBody] = useState();
  const [file, setFile] = useState(null);
  const navigate = useNavigate();
  const [error, setError] = useState(null);

  const { getBlog, createBlog, addBlog, updateBlog } = useActions();

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setValue("body", data);
  };
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(BlogValidator),
    defaultValues: {
      title: "",
      description: "",
      body: "",
      isHighlighted: false,
      language: "",
      fileAttachment: null,
    },
  });

  const goBack = () => navigate("/admin/blogs");

  useEffect(() => {
    if (id) {
      getBlog({ id, language: lang }).then((data) => {
        setValue("title", data.blog.title);
        setValue("body", data.blog.body);
        setValue("description", data.blog.description);
        setValue("isHighlighted", data.blog.isHighlighted);
        setValue("language", lang ? lang : data.blog.language);
        setImgFile(data.blog.image.url);
      });
    }
  }, [id, setValue]);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    setFile(file);
    const reader = new FileReader();

    const errorMessege = avatarValidator(file);
    if (errorMessege) {
      setError(errorMessege);
      return;
    }
    setError("");

    reader.onloadend = async () => {
      setImgFile(reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const onSubmit = async (data) => {
    console.log(data, imgFile);
    if (!imgFile) {
      return;
    }
    const formData = new FormData();

    formData.append("title", data.title);
    formData.append("body", data.body);
    formData.append("description", data.description);
    formData.append("isHighlighted", data.isHighlighted);
    formData.append("language", lang ? lang : data.language);
    if (data.fileAttachment) formData.append("file", file);
    console.log(formData);
    let result;
    if (id) result = await updateBlog({ id, data: formData });
    else if (lang) {
      result = await addBlog({ id: blogId, language: lang, data: formData });
    } else result = await createBlog(formData);
    if (result) goBack();
  };

  return (
    <>
      <div className="main-wrapper">
        <Header />
        <div className="page-content">
          <div className="container" style={{ marginTop: "70px" }}>
            <div className="row">
              {/* Profile Details */}
              <div className="settings-widget profile-details">
                <div className="settings-menu p-0">
                  <div className="profile-heading">
                    <h3>{id ? t("updateBlog") : t("addBlog")}</h3>
                  </div>
                  <div className="checkout-form personal-address add-course-info">
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="row">
                        {!lang && (
                          <div className="col-lg-6">
                            <div className="form-group select-form mb-0">
                              <label>{t("form.language")}</label>
                              <select
                                {...register("language")}
                                className="form-select select"
                                id="language"
                                name="language">
                                <option value="AR">AR</option>
                                <option value="EN">EN</option>
                              </select>
                            </div>
                          </div>
                        )}
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>{t("form.title")}</label>
                            <input
                              className="form-control"
                              {...register("title")}
                            />
                            {errors.title && (
                              <p className="error">{t(errors.title.message)}</p>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>{t("form.description")}</label>
                            <input
                              className="form-control"
                              {...register("description")}
                            />
                            {errors.description && (
                              <p className="error">
                                {t(errors.description.message)}
                              </p>
                            )}
                          </div>
                        </div>

                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>
                              {t("form.image")}
                              <p className="invalid-feedback d-block">
                                {t(error)}
                              </p>
                            </label>
                            {!imgFile && (
                              <input
                                type="file"
                                className="form-control"
                                {...register("fileAttachment")}
                                onChange={handleFileUpload}
                              />
                            )}
                            <small className="form-text text-muted">
                              {t("form.recommendedDimensions", {
                                dimensions: "2500 * 834",
                              })}
                            </small>
                            {errors.fileAttachment && (
                              <span className="text-danger">
                                {errors.fileAttachment.message}
                              </span>
                            )}

                            {imgFile && (
                              <div
                                style={{
                                  position: "relative",
                                  width: "100px",
                                }}>
                                <img src={imgFile} style={{ width: "100%" }} />
                                {imgFile && (
                                  <button
                                    style={{
                                      position: "absolute",
                                      top: "0",
                                      right: "0",
                                      background: "none",
                                      border: "none",
                                      color: "#fff",
                                      fontSize: "20px",
                                      cursor: "pointer",
                                      padding: "5px",
                                      zIndex: 1,
                                    }}
                                    type="button"
                                    onClick={() => setImgFile(null)}>
                                    &times;
                                  </button>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group">
                            <label>{t("form.body")}</label>

                            <div></div>
                            {ClassicEditor && (
                              <div id="editor">
                                <CKEditor
                                  editor={ClassicEditor}
                                  data={getValues("body")}
                                  onChange={handleEditorChange}
                                  config={{
                                    language: user.language.toLowerCase(),
                                    contentsLangDirection:
                                      user.language == "AR" ? "rtl" : "ltr",
                                  }}
                                />
                              </div>
                            )}
                            {errors.body && (
                              <p className="error">{t(errors.body.message)}</p>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group">
                            <div className="form-check">
                              <input
                                className="form-check-input big"
                                type="checkbox"
                                name="isSubscribed"
                                {...register("isHighlighted")}
                              />
                              <label className="form-check-label">
                                {t("form.isHighlighted")}
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="update-profile un-subscribe">
                          <button
                            onClick={() => onSubmit()}
                            className="btn btn-primary"
                            disabled={loadingBtn}>
                            {loadingBtn && (
                              <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"></span>
                            )}
                            {id ? t("updateBlog") : t("addBlog")}
                          </button>

                          <button
                            type="button"
                            onClick={goBack}
                            className="btn btn-danger">
                            {t("cancel")}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

              {/* Profile Details */}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default AddBlog;
