import React, { useEffect, useState } from "react";
import { Search } from "react-feather";
import { Link } from "react-router-dom";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useActions, useAppState } from "../../../../presenter";
import PageChange from "../../../page-change";
import Header from "../../../header";
import Footer from "../../../footer";
import InstructorSidebar from "../../../instructor/sidebar";
import { Icon22 } from "../../../imagepath";
import { formatDate } from "../../../../utils/date";

export default function InstructorBusinessModel() {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const [questions, setBusinessModelQuestions] = useState(null);
  const { listBusinessModelQuestions, deleteBusinessModelQuestion } =
    useActions();

  useEffect(() => {
    getBusinessModelQuestions();
  }, []);

  const getBusinessModelQuestions = () => {
    listBusinessModelQuestions().then((data) => {
      setBusinessModelQuestions(data);
    });
  };

  const handleDeleteQuestion = async (id) => {
    await deleteBusinessModelQuestion(id);
    getBusinessModelQuestions();
  };

  if (!questions) return <PageChange />;
  return (
    <div className="main-wrapper">
      <Header activeMenu={"BusinessModel"} />
      {/* Page Wrapper */}
      <div className="page-content">
        <div className="container" style={{ marginTop: "70px" }}>
          <div className="row">
            {/* Sidebar */}
            <InstructorSidebar activeMenu="BusinessModel" />
            {/* Sidebar */}

            {/* Instructor Dashboard */}
            <div className="col-xl-9 col-lg-8 col-md-12">
              <div className="row">
                <div className="col-md-12">
                  <div className="settings-widget">
                    <div className="settings-inner-blk p-0">
                      <div className="sell-course-head comman-space">
                        <h3>{t("businessModelQuestions")}</h3>
                      </div>
                      <div className="comman-space pb-0">
                        <div className="instruct-search-blk">
                          <div className="show-filter choose-search-blk">
                            <form action="#">
                              <div className="row gx-2 align-items-center">
                                <div className="col-md-6 col-item">
                                  <div className=" search-group">
                                    {/* <Search
                                      size={16}
                                      style={{
                                        position: "absolute",
                                        marginInlineStart: "7px",
                                        color: "#1B75BB",
                                      }}
                                    />
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder={t("searchQuestions")}
                                    /> */}
                                  </div>
                                </div>
                                <div className="col-md-6 col-lg-6 col-item">
                                  <div className="form-group select-form mb-0">
                                    <Link
                                      to="/admin/add-business-model"
                                      className="btn btn-primary"
                                      style={{
                                        float:
                                          i18n.language == "en"
                                            ? "right"
                                            : "left",
                                        width: "150px",
                                      }}>
                                      {t("addQuestion")}
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                        <div className="settings-tickets-blk course-instruct-blk table-responsive">
                          {/* Referred Users */}
                          <table className="table table-nowrap mb-2">
                            <thead>
                              <tr>
                                <th>{t("questions").toUpperCase()}</th>
                                {/* <th>STUDENTS</th> */}
                                <th style={{ textAlign: "center" }}>
                                  {t("actions")}
                                </th>
                              </tr>
                            </thead>
                            {questions.length > 0 ? (
                              <tbody>
                                {questions &&
                                  questions.map((question) => (
                                    <tr key={question.id}>
                                      <td>
                                        <div className="sell-table-group d-flex align-items-center">
                                          <div className="sell-tabel-info">
                                            <p>{question.question}</p>
                                            {/* <div className="course-info d-flex align-items-center border-bottom-0 pb-0">
                                                                                        <div className="rating-img d-flex align-items-center">
                                                                                            <img
                                                                                                className="img-fluid"
                                                                                                src={Icon22}
                                                                                                alt=""
                                                                                            />
                                                                                            {formatDate(question.createdAt)}
                                                                                        </div>
                                                                                    </div> */}
                                          </div>
                                        </div>
                                      </td>
                                      <td>
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                          }}>
                                          <Link
                                            to={`/admin/add-business-model?id=${question.id}`}
                                            className="badge info-inter"
                                            style={{
                                              marginInlineEnd: "10px",
                                              color: "white",
                                            }}>
                                            {t("edit")}
                                          </Link>
                                          <Link
                                            className="badge info-high"
                                            style={{ color: "white" }}
                                            onClick={() => {
                                              Swal.fire({
                                                title: t("deleteConfirmation"),
                                                text: t("deleteWarning"),
                                                icon: "warning",
                                                showCancelButton: true,
                                                confirmButtonColor: "#3085d6",
                                                cancelButtonColor: "#d33",
                                                cancelButtonText: t("cancel"),
                                                confirmButtonText: t("delete"),
                                              }).then((result) => {
                                                if (result.isConfirmed) {
                                                  handleDeleteQuestion(
                                                    question.id
                                                  );
                                                }
                                              });
                                            }}>
                                            {/* {loadingBtn && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>} */}
                                            {t("delete")}
                                          </Link>
                                        </div>
                                      </td>
                                    </tr>
                                  ))}
                              </tbody>
                            ) : (
                              <tbody>
                                <tr>
                                  <td
                                    style={{ textAlign: "center" }}
                                    colSpan="2">
                                    {t("noQuestionsYet")}
                                  </td>
                                </tr>
                              </tbody>
                            )}
                          </table>
                          {/* Referred Users */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Instructor Dashboard */}
          </div>
        </div>
      </div>
      {/* Page Wrapper */}
      <Footer />
    </div>
  );
}
