import "regenerator-runtime/runtime"; 

import { handleError } from "../errors";
import { changePositionByLanguage } from "../../utils/toastify";
import { toast } from "react-toastify";
import i18n from "../../locales/i18n";

export const listGeneralSettings = async (context) => {
  try {
    const result = await context.effects.listGeneralSettings();
    
    return result;
  } catch (err) {
    handleError(err);
  }
};


export const updateAdminGeneralSettings = async (context, data) => {
  try {
    const result = await context.effects.updateAdminGeneralSettings(data);
    toast.success(i18n.t("form.success.updated"), {
      position: changePositionByLanguage(i18n.language),
    });
    return result;
  } catch (err) {
    handleError(err);
  }
};


