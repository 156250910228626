import React, { useEffect, useState } from "react";
import Header from "../../header";
import Footer from "../../footer";
import {
  Course10,
  Course11,
  Course12,
  Course13,
  Course14,
  Icon1,
  InstructorProfileBg,
  TimerStart,
  User15,
  Icon22,
  lang,
} from "../../imagepath";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Search } from "react-feather";
import { Link } from "react-router-dom";
import InstructorSidebar from "../sidebar";
import Select from "react-select";
import PageChange from "../../page-change";
import { useTranslation } from "react-i18next";
import { useActions, useAppState } from "../../../presenter";
import { formatDate } from "../../../utils/date";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { toast } from "react-toastify";
import { changePositionByLanguage } from "../../../utils/toastify";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useForm } from "react-hook-form";
import { removeHtmlTags } from "../../../utils/parsing-data";
import { avatarValidator } from "./../../../utils/avatar-validation";

export default function InstructorField() {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const [fields, setFields] = useState(null);
  const [isFormValid, setIsFormValid] = useState(false);
  const [imgFile, setImgFile] = useState(null);
  const [error, setError] = useState(null);
  const [file, setFile] = useState(null);

  const { loading, loadingBtn, user } = useAppState();
  const {
    listFields,
    deleteField,
    lifeProjectSurveyDetails,
    updateLifeProjectSurveyDetails,
  } = useActions();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(100);
  const [pagesNum, setPagesNum] = useState([]);

  useEffect(() => {
    getFields();
  }, [currentPage, itemsPerPage]);

  useEffect(() => {
    getLifeProjectDetails();
  }, []);
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      title: "",
      subTitle: "",
      price: "",
      description: "",
      fileAttachment: null,
    },
  });

  const getLifeProjectDetails = () => {
    lifeProjectSurveyDetails().then((data) => {
      setValue("title", data.title);
      setValue("price", data.price);
      setValue("description", data.description);
      setValue("subTitle", data.subTitle);
      setImgFile(data.image.url);
    });
  };
  const getFields = () => {
    listFields({ currentPage, limit: itemsPerPage, q: null }).then((data) => {
      setFields(data);
    });
  };
  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    const description = removeHtmlTags(data);
    setValue("description", description);
  };

  const onSubmit = (data) => {
    const formData = new FormData();
    formData.append("title", data.title);
    formData.append("price", data.price);
    formData.append("description", data.description);
    formData.append("subTitle", data.subTitle);
    if (data.fileAttachment) formData.append("file", file);
    updateLifeProjectSurveyDetails(formData);
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    setFile(file);
    const reader = new FileReader();
    console.log(file);
    const errorMessege = avatarValidator(file);
    if (errorMessege) {
      setError(errorMessege);
      return;
    }
    setError("");

    reader.onloadend = async () => {
      setImgFile(reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleDeleteField = async (id) => {
    Swal.fire({
      title: t("deleteConfirmation"),
      text: t("deleteWarning"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: t("cancel"),
      confirmButtonText: t("delete"),
    }).then(async (answer) => {
      if (answer.isConfirmed) {
        console.log(id);
        await deleteField(id);
        getFields();
        toast.success(i18n.t("form.success.deleted"), {
          position: changePositionByLanguage(i18n.language),
        });
      }
    });
  };

  if (!fields) return <PageChange />;
  return (
    <div className="main-wrapper">
      <Header activeMenu={"Fields"} />
      {/* Page Wrapper */}
      <div className="page-content">
        <div className="container" style={{ marginTop: "70px" }}>
          <div className="row">
            {/* Sidebar */}
            <InstructorSidebar activeMenu="Fields" />
            {/* Sidebar */}

            {/* Instructor Dashboard */}
            <div className="col-xl-9 col-lg-8 col-md-12">
              <div className="row">
                <div className="col-md-12">
                  <div className="settings-widget">
                    <div className="settings-inner-blk p-0">
                      <div className="sell-course-head comman-space">
                        <h3>{t("settings.fields").toUpperCase()}</h3>
                      </div>
                      <div className="container" style={{ marginTop: "70px" }}>
                        <div className="row w-100">
                          <div className="col-md-12">
                            <fieldset
                              className="field-card"
                              style={{ display: "block" }}>
                              <div className="add-course-info">
                                <div className="">
                                  <div className="form-group">
                                    <div
                                      className="faq-header"
                                      style={{
                                        display: "block",
                                      }}>
                                      <label>
                                        {t("form.image")}
                                        <p className="invalid-feedback d-block">
                                          {t(error)}
                                        </p>
                                      </label>{" "}
                                      {!imgFile && (
                                        <input
                                          type="file"
                                          className="form-control"
                                          {...register("fileAttachment")}
                                          onChange={handleFileUpload}
                                        />
                                      )}
                                      <small className="form-text text-muted">
                                        {t("form.recommendedDimensions", {
                                          dimensions: "1088 * 612",
                                        })}{" "}
                                      </small>
                                      {errors.fileAttachment && (
                                        <span className="text-danger">
                                          {errors.fileAttachment.message}
                                        </span>
                                      )}
                                      {imgFile && (
                                        <div
                                          style={{
                                            position: "relative",
                                            width: "200px",
                                          }}>
                                          <img
                                            src={imgFile}
                                            style={{ width: "100%" }}
                                          />
                                          {imgFile && (
                                            <button
                                              style={{
                                                position: "absolute",
                                                top: "0",
                                                right: "0",
                                                background: "none",
                                                border: "none",
                                                color: "#fff",
                                                fontSize: "20px",
                                                cursor: "pointer",
                                                padding: "5px",
                                                zIndex: 1,
                                              }}
                                              type="button"
                                              onClick={() => setImgFile(null)}>
                                              &times;
                                            </button>
                                          )}
                                        </div>
                                      )}{" "}
                                      <br></br>
                                      <label>{t("form.title")}</label>
                                      <input
                                        style={{
                                          marginBottom: "20px",
                                        }}
                                        type="text"
                                        className="form-control"
                                        name="title"
                                        {...register("title")}
                                        placeholder={t(
                                          "form.placeholders.title"
                                        )}
                                      />
                                      <label>{t("form.subtitle")}</label>
                                      <input
                                        style={{
                                          marginBottom: "20px",
                                        }}
                                        type="text"
                                        className="form-control"
                                        name="subTitle"
                                        {...register("subTitle")}
                                        placeholder={t(
                                          "form.placeholders.subtitle"
                                        )}
                                      />
                                      <label>{t("Price")}</label>
                                      <input
                                        type="number"
                                        className="form-control"
                                        {...register("price")}
                                        placeholder={t(
                                          "form.placeholders.price"
                                        )}
                                      />
                                      <div
                                        style={{
                                          marginBottom: "20px",
                                        }}></div>
                                      <div id="editor">
                                        <label>{t("form.description")}</label>

                                        <CKEditor
                                          editor={ClassicEditor}
                                          data={getValues("description")}
                                          onChange={handleEditorChange}
                                          config={{
                                            language:
                                              user.language.toLowerCase(),
                                            contentsLangDirection:
                                              user.language == "AR"
                                                ? "rtl"
                                                : "ltr",
                                          }}
                                        />
                                      </div>
                                    </div>

                                    {
                                      <button
                                        type="button"
                                        className="btn btn-primary m-1"
                                        onClick={handleSubmit(onSubmit)}>
                                        {t("update").toUpperCase()}
                                      </button>
                                    }
                                  </div>
                                </div>
                              </div>
                            </fieldset>
                          </div>
                        </div>
                      </div>
                      <div className="comman-space pb-0">
                        <div className="instruct-search-blk">
                          <div className="show-filter choose-search-blk">
                            {/* <form action="#"> */}
                            <div className="row gx-2 align-items-center">
                              <div className="col-md-6 col-item">
                                {/* <div className=" search-group">
                                    <Search
                                      size={16}
                                      style={{
                                        position: "absolute",
                                        marginInlineStart: "7px",
                                        color: "#1B75BB",
                                      }}
                                    />
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Search Fields"
                                    />
                                  </div> */}
                              </div>
                              <div className="col-md-6 col-lg-6 col-item">
                                <div className="form-group select-form mb-0">
                                  <Link
                                    to="/admin/add-field"
                                    className="btn btn-primary"
                                    style={{
                                      float:
                                        i18n.language == "en"
                                          ? "right"
                                          : "left",
                                      width: "150px",
                                    }}>
                                    {t("addField")}
                                  </Link>
                                </div>
                              </div>
                            </div>
                            {/* </form> */}
                          </div>
                        </div>
                        <div className="settings-tickets-blk course-instruct-blk table-responsive">
                          {/* Referred Users */}
                          <table className="table table-nowrap mb-2">
                            <thead>
                              <tr>
                                <th>{t("settings.fields").toUpperCase()}</th>
                                <th style={{ textAlign: "center" }}>
                                  {t("actions")}
                                </th>
                              </tr>
                            </thead>
                            {fields && fields.length > 0 ? (
                              <tbody>
                                {fields.map((field) => (
                                  <tr key={field.id}>
                                    <td>
                                      <div className="sell-table-group d-flex align-items-center">
                                        <div className="sell-tabel-info">
                                          <p>{field.name}</p>
                                          <div className="course-info d-flex align-items-center border-bottom-0 pb-0"></div>
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <div style={{ display: "flex" }}>
                                        <Link
                                          to={`/admin/add-field?id=${field.id}`}
                                          className="badge info-low"
                                          style={{
                                            marginInlineEnd: "10px",
                                            color: "white",
                                          }}>
                                          {t("edit")}
                                        </Link>
                                        <Link
                                          className="badge info-high"
                                          style={{ color: "white" }}
                                          onClick={() => {
                                            handleDeleteField(field.id);
                                          }}>
                                          {/* {loadingBtn && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>} */}
                                          {t("delete")}
                                        </Link>
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            ) : (
                              <tbody>
                                <tr>
                                  <td
                                    style={{ textAlign: "center" }}
                                    colSpan="2">
                                    {t("NoFieldsYet")}
                                  </td>
                                </tr>
                              </tbody>
                            )}
                          </table>
                          {/* Referred Users */}
                        </div>

                        {/* Field pagination */}
                        {/* <div className="row">
                          <div className="col-md-12">
                            <ul
                              className="pagination lms-page"
                              style={{ marginBottom: "20px" }}>
                              <li
                                className={`"page-item prev" ${
                                  currentPage == 1 ? "disable-btn" : ""
                                }`}>
                                <Link
                                  className="page-link"
                                  to="#"
                                  tabIndex={-1}
                                  onClick={() =>
                                    setCurrentPage((prev) => prev - 1)
                                  }>
                                  <i className="fas fa-angle-left" />
                                </Link>
                              </li>

                              {pagesNum.map((number) => (
                                <li
                                  key={number}
                                  className={`"page-item ${
                                    currentPage == 1 ? "first-page" : ""
                                  } ${currentPage == number ? "active" : ""}`}>
                                  <Link
                                    onClick={() => handlePageChange(number)}
                                    className="page-link"
                                    to="#">
                                    {number}
                                  </Link>
                                </li>
                              ))}

                              <li
                                className={`"page-item next" ${
                                  currentPage == pagesNum[pagesNum.length - 1]
                                    ? "disable-btn"
                                    : ""
                                }`}>
                                <Link
                                  onClick={() =>
                                    setCurrentPage((prev) => prev + 1)
                                  }
                                  className="page-link"
                                  to="#">
                                  <i className="fas fa-angle-right" />
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </div> */}
                        {/* Field pagination */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Instructor Dashboard */}
          </div>
        </div>
      </div>
      {/* Page Wrapper */}
      <Footer />
    </div>
  );
}
