
export const SUPPORTED_IMAGE_FORMATS = [
  "image/jpg",
  "image/jpeg",
  "image/gif",
  "image/png",
];
export const avatarValidator = (file)=>{
  
 if (!SUPPORTED_IMAGE_FORMATS.includes(file?.type)){
    return "UnSupportedAvatarFormat"
 }
 if(file?.size > 2 * 1024 * 1024 ){
 return "FileSizeMustBeLessThan2MB"
 }
}