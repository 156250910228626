import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useActions, useAppState } from "../../../../presenter";

function BussinessModelSection() {
  const [questions, setQuestions] = useState([]);
  const [allquestions, setAllQuestions] = useState([]);
  const { businessModelResult } = useAppState();
  const { t } = useTranslation();

  const { listBusinessModelQuestions } = useActions();

  useEffect(() => {
    listBusinessModelQuestions().then((result) => {
      setAllQuestions(result);
    });
  }, []);

  useEffect(() => {
    if (businessModelResult) {
      const updatedQuestions = [...allquestions];
      businessModelResult.map((question) => {
        const questionExist = updatedQuestions.find((q) => q.id == question.id);
        if (questionExist) {
          questionExist.answer = question.result;
        }
      });
      setQuestions(updatedQuestions);
    } else {
      setQuestions(allquestions);
    }
  }, [businessModelResult, allquestions]);
  return (
    <div style={{ width: "100%", height: "90%" }}>
      <section
        class="container-md p-5 rounded mx-auto"
        style={{
          backgroundColor: "#f4f4f4",
        }}>
        <div class="row">
          {questions?.map((question, i) => (
            <div class="col-md-6 col-sm-12 mb-4" key={question.id}>
              <div class="question-title text-wrap">
                <p className="text-break">
                  {t("form.question")}:&nbsp;
                  {question?.question?.replace(/ /g, "\u00A0")}
                </p>
              </div>
              <div>{t("answer")}:</div>
              <input
                type="text"
                value={question?.answer?.replace(/ /g, "\u00A0")}
                class="form-control"
                disabled={true}
              />
            </div>
          ))}
        </div>
      </section>
    </div>
  );
}

export default BussinessModelSection;
