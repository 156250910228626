import "regenerator-runtime/runtime";

import i18n from "../../locales/i18n";
import { toast } from "react-toastify";
import { handleError } from "../errors";
import { changePositionByLanguage } from "../../utils/toastify";

export const loginWithGoogle = async (context, token) => {
    try {
        context.state.loading = true;
        const result = await context.effects.loginWithGoogle(token);
        context.state.user = result.data.user;
        context.state.accessToken = result.data.accessToken;
        localStorage.setItem('user', JSON.stringify(result.data.user));
        localStorage.setItem('accessToken', result.data.accessToken);
        context.state.loading = false;
        return result;
    } catch (err) {
        context.state.loading = false;
    }
};

export const loginWithFacebook = async (context, data) => {
    try {
        context.state.loading = true;
        const result = await context.effects.loginWithFacebook(data);
        context.state.user = result.data.user;
        context.state.accessToken = result.data.accessToken;
        localStorage.setItem('user', JSON.stringify(result.data.user));
        localStorage.setItem('accessToken', result.data.accessToken);
        context.state.loading = false;
        return result;
    } catch (err) {
        context.state.loading = false;
    }
};

export const login = async (context, { email, password }) => {
    try {
        context.state.loadingBtn = true;
       const  notification_token =  context.state.deviceToken || localStorage.getItem('deviceToken') ;
        const result = await context.effects.login({ email, password ,notification_token});
        context.state.accessToken = result.accessToken;
        context.state.user = result.user;
        localStorage.setItem('accessToken', result.accessToken);
        localStorage.setItem('user', JSON.stringify(result.user));
        context.state.loadingBtn = false;
            return result;

    } catch (err) {
        handleError(err);
        context.state.loadingBtn = false;
    }
};

export const forgetPassword = async (context, { email }) => {
    try {
        context.state.loadingBtn = true;
        await context.effects.forgetPassword(email);

        toast.success(i18n.t("form.success.forgotPassword"), {
            position: changePositionByLanguage(i18n.language),
        });
        context.state.loadingBtn = false;
    } catch (err) {
        context.state.loadingBtn = false;
        handleError(err);
    }
};

export const resetPassword = async (context, { newPassword, token }) => {
    try {
        context.state.loadingBtn = true;
        await context.effects.resetPassword({ newPassword, token });

        toast.success(i18n.t("form.success.resetPassword"), {
            position: changePositionByLanguage(i18n.language),
        });
        context.state.loadingBtn = false;
    } catch (err) {
        context.state.loadingBtn = false;
        handleError(err);
    }
};

export const logout = async (context) => {
    try {
        context.state.accessToken = null;
        context.state.user = null;
        const deviceToken = context.state.deviceToken || localStorage.getItem('deviceToken')
        localStorage.clear();
        localStorage.setItem('deviceToken',deviceToken)
    } catch (err) {
        handleError(err);
    }
};
