import "regenerator-runtime/runtime";

import axios from "axios";
import {
  BadRequestError,
  ForbiddenError,
  InternalServerError,
  UnauthenticatedError,
  UnexpectedError,
} from "../errors";
import { apiClient } from "./constants";

const BASE_ROUTE = "/questions";

export const listQuestions = async (page, limit) => {
  try {
    let queryParams = page ? `?page=${page}&limit=${limit}` : "";
    const result = await apiClient.get(`${BASE_ROUTE}${queryParams}`);
    return result.data;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      const responseData = err.response.data;

      if (err.response?.status == 400)
        throw new BadRequestError(responseData.message);
      if (err.response?.status == 401)
        throw new UnauthenticatedError(responseData.message);
      if (err.response?.status == 403)
        throw new ForbiddenError(responseData.message);
      if (err.response?.status == 404)
        throw new UnexpectedError(responseData.message);
      if (err.response?.status == 500)
        throw new InternalServerError(responseData.message);
    }
    throw new UnexpectedError();
  }
};

export const submitReport = async (data, surveyId) => {
  try {
    const result = await apiClient.post(
      `/surveyResult/survey/${surveyId}`,
      data
    );
    return result.data;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      const responseData = err.response.data;

      if (err.response?.status == 400)
        throw new BadRequestError(responseData.message);
      if (err.response?.status == 401)
        throw new UnauthenticatedError(responseData.message);
      if (err.response?.status == 403)
        throw new ForbiddenError(responseData.message);
      if (err.response?.status == 404)
        throw new UnexpectedError(responseData.message);
      if (err.response?.status == 500)
        throw new InternalServerError(responseData.message);
    }
    throw new UnexpectedError();
  }
};

export const getSurveyReport = async () => {
  try {
    const result = await apiClient.get("/surveyResult");
    return result.data;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      const responseData = err.response.data;

      if (err.response?.status == 400)
        throw new BadRequestError(responseData.message);
      if (err.response?.status == 401)
        throw new UnauthenticatedError(responseData.message);
      if (err.response?.status == 403)
        throw new ForbiddenError(responseData.message);
      if (err.response?.status == 404)
        throw new UnexpectedError(responseData.message);
      if (err.response?.status == 500)
        throw new InternalServerError(responseData.message);
    }
    throw new UnexpectedError();
  }
};

export const deleteSavedSurveyQuestions = async () => {
  try {
    const result = await apiClient.delete(
      "/surveyResult/saveQuestionsProgress"
    );
    return result.data;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      const responseData = err.response.data;

      if (err.response?.status == 400)
        throw new BadRequestError(responseData.message);
      if (err.response?.status == 401)
        throw new UnauthenticatedError(responseData.message);
      if (err.response?.status == 403)
        throw new ForbiddenError(responseData.message);
      if (err.response?.status == 404)
        throw new UnexpectedError(responseData.message);
      if (err.response?.status == 500)
        throw new InternalServerError(responseData.message);
    }
    throw new UnexpectedError();
  }
};

export const getSavedSurveyQuestions = async (page, limit) => {
  try {
    let queryParams = page ? `?page=${page}&limit=${limit}` : "";

    const result = await apiClient.get(
      `/surveyResult/saveQuestionsProgress${queryParams}`
    );
    return result.data;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      const responseData = err.response.data;

      if (err.response?.status == 400)
        throw new BadRequestError(responseData.message);
      if (err.response?.status == 401)
        throw new UnauthenticatedError(responseData.message);
      if (err.response?.status == 403)
        throw new ForbiddenError(responseData.message);
      if (err.response?.status == 404)
        throw new UnexpectedError(responseData.message);
      if (err.response?.status == 500)
        throw new InternalServerError(responseData.message);
    }
    throw new UnexpectedError();
  }
};

export const saveSurveyQuestions = async (data) => {
  try {
    const result = await apiClient.post(
      "/surveyResult/saveQuestionsProgress",
      data
    );
    return result.data;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      const responseData = err.response.data;

      if (err.response?.status == 400)
        throw new BadRequestError(responseData.message);
      if (err.response?.status == 401)
        throw new UnauthenticatedError(responseData.message);
      if (err.response?.status == 403)
        throw new ForbiddenError(responseData.message);
      if (err.response?.status == 404)
        throw new UnexpectedError(responseData.message);
      if (err.response?.status == 500)
        throw new InternalServerError(responseData.message);
    }
    throw new UnexpectedError();
  }
};

export const listSurvey = async () => {
  try {
    const result = await apiClient.get("/survey");
    return result.data;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      const responseData = err.response.data;

      if (err.response?.status == 400)
        throw new BadRequestError(responseData.message);
      if (err.response?.status == 401)
        throw new UnauthenticatedError(responseData.message);
      if (err.response?.status == 403)
        throw new ForbiddenError(responseData.message);
      if (err.response?.status == 404)
        throw new UnexpectedError(responseData.message);
      if (err.response?.status == 500)
        throw new InternalServerError(responseData.message);
    }
    throw new UnexpectedError();
  }
};

export const getSurveyDetails = async (data) => {
  try {
    const result = await apiClient.get(`/survey/${data.id}/details`);
    return result.data;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      const responseData = err.response.data;

      if (err.response?.status == 400)
        throw new BadRequestError(responseData.message);
      if (err.response?.status == 401)
        throw new UnauthenticatedError(responseData.message);
      if (err.response?.status == 403)
        throw new ForbiddenError(responseData.message);
      if (err.response?.status == 404)
        throw new UnexpectedError(responseData.message);
      if (err.response?.status == 500)
        throw new InternalServerError(responseData.message);
    }
    throw new UnexpectedError();
  }
};

export const isUserEnrolledSurvey = async (data) => {
  try {
    let queryParams = data?.courseId ? `?courseId=${data?.courseId}` : "";
    const result = await apiClient.get(
      `/survey/${data.id}/isEnrolled${queryParams}`
    );
    return result.data;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      const responseData = err.response.data;

      if (err.response?.status == 400)
        throw new BadRequestError(responseData.message);
      if (err.response?.status == 401)
        throw new UnauthenticatedError(responseData.message);
      if (err.response?.status == 403)
        throw new ForbiddenError(responseData.message);
      if (err.response?.status == 404)
        throw new UnexpectedError(responseData.message);
      if (err.response?.status == 500)
        throw new InternalServerError(responseData.message);
    }
    throw new UnexpectedError();
  }
};

export const enrollSurvey = async (data) => {
  try {
    const result = await apiClient.post(`/survey/${data.id}/enroll`);
    return result.data;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      const responseData = err.response.data;

      if (err.response?.status == 400)
        throw new BadRequestError(responseData.message);
      if (err.response?.status == 401)
        throw new UnauthenticatedError(responseData.message);
      if (err.response?.status == 403)
        throw new ForbiddenError(responseData.message);
      if (err.response?.status == 404)
        throw new UnexpectedError(responseData.message);
      if (err.response?.status == 500)
        throw new InternalServerError(responseData.message);
    }
    throw new UnexpectedError();
  }
};

export const updateSurvey = async (data) => {
  try {
    console.log(data);
    const result = await apiClient.patch(`/survey/${data.id}`, data.survey);
    return result.data;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      const responseData = err.response.data;

      if (err.response?.status == 400)
        throw new BadRequestError(responseData.message);
      if (err.response?.status == 401)
        throw new UnauthenticatedError(responseData.message);
      if (err.response?.status == 403)
        throw new ForbiddenError(responseData.message);
      if (err.response?.status == 404)
        throw new UnexpectedError(responseData.message);
      if (err.response?.status == 500)
        throw new InternalServerError(responseData.message);
    }
    throw new UnexpectedError();
  }
};

export const updateLifeProjectSurveyDetails = async (data) => {
  try {
    const result = await apiClient.patch(
      `/survey/life-project-survey/update`,
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return result.data;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      const responseData = err.response.data;

      if (err.response?.status == 400)
        throw new BadRequestError(responseData.message);
      if (err.response?.status == 401)
        throw new UnauthenticatedError(responseData.message);
      if (err.response?.status == 403)
        throw new ForbiddenError(responseData.message);
      if (err.response?.status == 404)
        throw new UnexpectedError(responseData.message);
      if (err.response?.status == 500)
        throw new InternalServerError(responseData.message);
    }
    throw new UnexpectedError();
  }
};

export const lifeProjectSurveyDetails = async () => {
  try {
    const result = await apiClient.get("/survey/life-project");
    return result.data;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      const responseData = err.response.data;

      if (err.response?.status == 400)
        throw new BadRequestError(responseData.message);
      if (err.response?.status == 401)
        throw new UnauthenticatedError(responseData.message);
      if (err.response?.status == 403)
        throw new ForbiddenError(responseData.message);
      if (err.response?.status == 404)
        throw new UnexpectedError(responseData.message);
      if (err.response?.status == 500)
        throw new InternalServerError(responseData.message);
    }
    throw new UnexpectedError();
  }
};
