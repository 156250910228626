import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useActions, useAppState } from "../../../../presenter";

function ProjectPositionSection() {
  const { t } = useTranslation();
  const { selectedSubfields, reportData, projectPositionAnswers } =
    useAppState();
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);

  useEffect(() => {
    if (projectPositionAnswers) setSelectedCheckboxes(projectPositionAnswers);
  }, [projectPositionAnswers]);

  useEffect(() => {
    if (reportData && reportData.projectPosition) {
      setSelectedCheckboxes(reportData.projectPosition);
    }
  }, [reportData]);

  return (
    <section
      id="projectPosition"
      class="container-md mt-5 p-3 p-md-5 rounded margin"
      style={{ backgroundColor: "#f4f4f4" }}>
      <div class="d-flex  align-items-center flex-wrap mb-3">
        <h3 class="d-inline mb-0">
          {t("InTheFollowingSubfields").replace(/ /g, "\u00A0")}:
        </h3>
        <div style={{ width: "10px" }}> </div>
        <p class="d-inline mb-0  mr-5">
          {selectedSubfields
            .map((x) => x.subfieldName.replace(/ /g, "\u00A0"))
            .join(", ")}{" "}
        </p>
      </div>
      <h4 class="mb-4">
        {t("IWantto").replace(/ /g, "\u00A0")}:{"\u00A0"}
      </h4>
      <div class="row row-cols-1 row-cols-md-2 g-4">
        <div class="dummy-text col mb-3">
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              id="dummy1"
              name="dummy1"
              checked={selectedCheckboxes?.some(
                (item) => item === t("statement1")
              )}
              disabled={true}
            />
            <label class="form-check-label" htmlFor="dummy1">
              {t("statement1").replace(/ /g, "\u00A0")}
            </label>
          </div>
        </div>
        <div class="dummy-text col mb-3">
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              id="dummy2"
              name="dummy2"
              checked={selectedCheckboxes?.some(
                (item) => item === t("statement2")
              )}
              disabled={true}
            />
            <label class="form-check-label" htmlFor="dummy2">
              {t("statement2").replace(/ /g, "\u00A0")}
            </label>
          </div>
        </div>
        <div class="dummy-text col mb-3">
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              id="dummy3"
              name="dummy3"
              checked={selectedCheckboxes?.some(
                (item) => item === t("statement3")
              )}
              disabled={true}
            />
            <label class="form-check-label" htmlFor="dummy3">
              {t("statement3").replace(/ /g, "\u00A0")}
            </label>
          </div>
        </div>
        <div class="dummy-text col mb-3">
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              id="dummy4"
              name="dummy4"
              checked={selectedCheckboxes?.some(
                (item) => item === t("statement4")
              )}
              disabled={true}
            />
            <label class="form-check-label" htmlFor="dummy4">
              {t("statement4").replace(/ /g, "\u00A0")}
            </label>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ProjectPositionSection;
