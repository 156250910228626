import "regenerator-runtime/runtime";

import i18n from "../../locales/i18n";
import { toast } from "react-toastify";
import { handleError } from "../errors";
import { changePositionByLanguage } from "../../utils/toastify";

export const listPodcasts = async (context , { currentPage, limit, q }) => {
    try {
        context.state.loading = true;
        const result = await context.effects.listPodcasts(currentPage, limit, q);
        context.state.loading = false;
        return result;
    } catch (err) {
        context.state.loading = false;
        handleError(err);
    }
};

export const getPodcast = async (context, id) => {
    try {
        context.state.loading = true;
        const result = await context.effects.getPodcast(id);
        context.state.loading = false;
        return result;
    } catch (err) {
        context.state.loading = false;
        handleError(err);
    }
};

export const addPodcast = async (context, data) => {
    try {
        context.state.loadingBtn = true;
        const result = await context.effects.addPodcast(data);
        context.state.loadingBtn = false;

        toast.success(i18n.t("form.success.created"), {
            position: changePositionByLanguage(i18n.language),
        });
        return result;
    } catch (err) {
        context.state.loadingBtn = false;
        handleError(err);
    }
};

export const updatePodcast = async (context, data) => {
    try {
        context.state.loadingBtn = true;
        const result = await context.effects.updatePodcast(data);
        context.state.loadingBtn = false;

        toast.success(i18n.t("form.success.updated"), {
            position: changePositionByLanguage(i18n.language),
        });
        return result;
    } catch (err) {
        context.state.loadingBtn = false;
        handleError(err);
    }
};

export const deletePodcast = async (context, id) => {
    try {
        context.state.loadingBtn = true;
        const result = await context.effects.deletePodcast(id);
        context.state.loadingBtn = false;

        toast.success(i18n.t("form.success.deleted"), {
            position: changePositionByLanguage(i18n.language),
        });
        return result;
    } catch (err) {
        context.state.loadingBtn = false;
        handleError(err);
    }
};
