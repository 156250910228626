import React, { useEffect, useState } from "react";
import Header from "../../header";
import Footer from "../../footer";
import {
  Course10,
  Course11,
  Course12,
  Course13,
  Course14,
  Icon1,
  InstructorProfileBg,
  TimerStart,
  User15,
  Icon22,
  StudentIcon,
  UserIconSvg,
  User1,
  defaultImg,
} from "../../imagepath";
import { Search } from "react-feather";
import { Link } from "react-router-dom";
import InstructorSidebar from "../sidebar";
import Select from "react-select";
import PageChange from "../../page-change";
import { useTranslation } from "react-i18next";
import { useActions, useAppState } from "../../../presenter";
import { formatDate } from "../../../utils/date";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

export default function ManageAdmins() {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const [admins, setAdmin] = useState([]);
  const [value, setValue] = useState(null);
  const { loading, loadingBtn } = useAppState();
  const { listAdmins, deleteAdmin } = useActions();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(6);
  const [pagesNum, setPagesNum] = useState([]);
  const [search, setSearch] = useState();

  useEffect(() => {
    console.log("test");
    getAdmins();
  }, [currentPage, itemsPerPage, search]);

  const getAdmins = () => {
    listAdmins({ currentPage, limit: itemsPerPage, q: search }).then((data) => {
      setAdmin(data.users);

      const arr = [];
      for (let i = 1; i <= data.meta.totalPages; i++) {
        arr.push(i);
      }
      setPagesNum(arr);
    });
  };

  const handleDeleteAdmin = async (id) => {
    await deleteAdmin(id);
    getAdmins();
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  // if (loading) return <PageChange />
  return (
    <div className="main-wrapper">
      <Header activeMenu={"Admins"} />
      {/* Page Wrapper */}
      <div className="page-content">
        <div className="container" style={{ marginTop: "70px" }}>
          <div className="row">
            {/* Sidebar */}
            <InstructorSidebar activeMenu="Admins" />
            {/* Sidebar */}

            {/* Instructor Dashboard */}
            <div className="col-xl-9 col-lg-8 col-md-12">
              <div className="row">
                <div className="col-md-12">
                  <div className="settings-widget">
                    <div className="settings-inner-blk p-0">
                      <div className="sell-course-head comman-space">
                        <h3>{t("Admins")}</h3>
                      </div>
                      <div className="comman-space pb-0">
                        <div className="instruct-search-blk">
                          <div className="show-filter choose-search-blk">
                            <form action="#">
                              <div className="row gx-2 align-items-center">
                                <div className="col-md-6 col-item">
                                  <div className=" search-group">
                                    <Search
                                      size={16}
                                      style={{
                                        position: "absolute",
                                        marginInlineStart: "7px",
                                        color: "#1B75BB",
                                      }}
                                    />
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder={t("searchAdmin")}
                                      onChange={() =>
                                        setSearch(event.target.value)
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6 col-lg-6 col-item">
                                  <div className="form-group select-form mb-0">
                                    <Link
                                      to="/admin/add-admin"
                                      className="btn btn-primary"
                                      style={{
                                        float:
                                          i18n.language == "en"
                                            ? "right"
                                            : "left",
                                        width: "150px",
                                      }}>
                                      {t("addAdmin")}
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                        <div className="settings-tickets-blk course-instruct-blk table-responsive">
                          {/* Referred Users */}
                          <table className="table table-nowrap mb-2">
                            <thead>
                              <tr>
                                <th>{t("Admins")}</th>
                                {/* <th>STUDENTS</th> */}
                                <th style={{ textAlign: "center" }}>
                                  {t("actions")}
                                </th>
                              </tr>
                            </thead>
                            {admins.length > 0 ? (
                              <tbody>
                                {admins &&
                                  admins.map((admin) => (
                                    <tr key={admin.id}>
                                      <td>
                                        <div className="sell-table-group d-flex align-items-center">
                                          <div
                                            className="sell-group-img"
                                            style={{
                                              alignItems: "center",
                                              display: "flex",
                                            }}>
                                            <img
                                              src={admin.image ?? defaultImg}
                                              className="img-fluid "
                                              alt=""
                                              style={{
                                                width: "100px",
                                                height: "70%",
                                              }}
                                            />
                                          </div>
                                          <div className="sell-tabel-info">
                                            <p>
                                              {admin.firstName +
                                                " " +
                                                admin.lastName}
                                            </p>
                                            <div className="course-info d-flex align-items-center border-bottom-0 pb-0">
                                              <div className="rating-img d-flex align-items-center">
                                                <img
                                                  className="img-fluid"
                                                  src={Icon22}
                                                  alt=""
                                                />
                                                {formatDate(admin.createdAt)}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </td>
                                      <td>
                                        <div style={{ display: "flex" }}>
                                          <Link
                                            to={`/admin/add-admin?id=${admin.id}`}
                                            className="badge info-inter"
                                            style={{
                                              marginInlineEnd: "10px",
                                              color: "white",
                                            }}>
                                            {t("edit")}
                                          </Link>
                                          <Link
                                            className="badge info-high"
                                            style={{ color: "white" }}
                                            onClick={() => {
                                              Swal.fire({
                                                title: t("deleteConfirmation"),
                                                text: t("deleteWarning"),
                                                icon: "warning",
                                                showCancelButton: true,
                                                confirmButtonColor: "#3085d6",
                                                cancelButtonColor: "#d33",
                                                cancelButtonText: t("cancel"),
                                                confirmButtonText: t("delete"),
                                              }).then((result) => {
                                                if (result.isConfirmed) {
                                                  handleDeleteAdmin(admin.id);
                                                }
                                              });
                                            }}>
                                            {t("delete")}
                                          </Link>
                                        </div>
                                      </td>
                                    </tr>
                                  ))}
                              </tbody>
                            ) : (
                              <tbody>
                                <tr>
                                  <td
                                    style={{ textAlign: "center" }}
                                    colSpan="2">
                                    {t("NoAdminsYet")}
                                  </td>
                                </tr>
                              </tbody>
                            )}
                          </table>
                          {/* Referred Users */}
                        </div>

                        {/* Admin pagination */}
                        <div className="row">
                          <div className="col-md-12">
                            <ul
                              className="pagination lms-page"
                              style={{ marginBottom: "20px" }}>
                              <li
                                className={`"page-item prev" ${
                                  currentPage == 1 ? "disable-btn" : ""
                                }`}>
                                <Link
                                  className="page-link"
                                  to="#"
                                  tabIndex={-1}
                                  onClick={() =>
                                    setCurrentPage((prev) => prev - 1)
                                  }>
                                  <i className="fas fa-angle-left" />
                                </Link>
                              </li>

                              {pagesNum.map((number) => (
                                <li
                                  key={number}
                                  className={`"page-item ${
                                    currentPage == 1 ? "first-page" : ""
                                  } ${currentPage == number ? "active" : ""}`}>
                                  <Link
                                    onClick={() => handlePageChange(number)}
                                    className="page-link"
                                    to="#">
                                    {number}
                                  </Link>
                                </li>
                              ))}

                              <li
                                className={`"page-item next" ${
                                  currentPage == pagesNum[pagesNum.length - 1]
                                    ? "disable-btn"
                                    : ""
                                }`}>
                                <Link
                                  onClick={() =>
                                    setCurrentPage((prev) => prev + 1)
                                  }
                                  className="page-link"
                                  to="#">
                                  <i className="fas fa-angle-right" />
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                        {/* /admin pagination */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Instructor Dashboard */}
          </div>
        </div>
      </div>
      {/* Page Wrapper */}
      <Footer />
    </div>
  );
}
