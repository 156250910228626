import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useActions } from "../../../../presenter";
import { useTranslation } from "react-i18next";
import { CheckCircle } from "../../../imagepath";
import { avatarValidator } from "./../../../../utils/avatar-validation";

const CourseMedia = ({ prevTab1, nextTab2, data, updateFormData }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const lang = queryParams.get("lang");
  const id = queryParams.get("id");
  const [isFormValid, setIsFormValid] = useState(false);
  const { getCourse } = useActions();
  const [formData, setFormData] = useState({
    coverImg: null,
    manual: null,
    videoUrl: "",
  });
  const [coverImg, setCoverImg] = useState(null);
  const [manual, setManual] = useState(null);
  const [manualType, setManualType] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    setFormData(data);
    if (data.coverImg) {
      const reader = new FileReader();
      const file = data.coverImg;

      reader.onloadend = async () => {
        setCoverImg(reader.result);
      };
      if (file) {
        reader.readAsDataURL(file);
      }
    }
    if (data.manual) {
      const reader = new FileReader();
      const file = data.manual;
      if (data.manual.name) {
        if (isImageUrl(data.manual.name)) {
          setManualType("img");
        } else {
          setManualType("file");
        }
      }

      reader.onloadend = async () => {
        setManual(reader.result);
      };
      if (file) {
        reader.readAsDataURL(file);
      }
    }
  }, [data]);

  useEffect(() => {
    checkFormValidity();
  }, [formData]);

  useEffect(() => {
    if (id) {
      getCourse({ id, language: lang }).then((result) => {
        if (!data.coverImg) setCoverImg(result.course.image.url);
        if (!data.manual) {
          setManual(result.course.manual.url);
          setManualType(isImageUrl(result.course.manual.url) ? "img" : "file");
        }
        if (!data.videoUrl)
          setFormData((prevFormData) => ({
            ...prevFormData,
            videoUrl: result.course.videoUrl,
          }));
      });
    }
  }, [id]);

  const isImageUrl = (url) => {
    // Regular expression to match common image file extensions
    const imageExtensions = /\.(jpeg|jpg|gif|png|svg|webp)$/i;

    return imageExtensions.test(url);
  };

  const isValidVideoUrl = (url) => {
    const urlPattern = /^(ftp|http|https):\/\/[^ "]+$/;
    return urlPattern.test(url);
  };

  const checkFormValidity = () => {
    const isValid =
      (formData.coverImg &&
        formData.manual &&
        formData.videoUrl &&
        isValidVideoUrl(formData.videoUrl)) ||
      (id && formData.videoUrl && isValidVideoUrl(formData.videoUrl));
    setIsFormValid(isValid);
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    const errorMessege = avatarValidator(file);
    if (errorMessege) {
      setError(errorMessege);
      return;
    }
    setError("");

    reader.onloadend = async () => {
      setFormData((prevFormData) => ({
        ...prevFormData,
        coverImg: file,
      }));
      setCoverImg(reader.result);
      checkFormValidity();
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleManualUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    if (file.type == "image/png" || file.type == "image/jpeg") {
      setManualType("img");
    } else {
      setManualType("file");
    }

    reader.onloadend = async () => {
      setFormData((prevFormData) => ({
        ...prevFormData,
        manual: file,
      }));
      setManual(reader.result);
      checkFormValidity();
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleChange = (event) => {
    let { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));

    checkFormValidity();
  };

  const handleNextTab = () => {
    if (isFormValid) {
      updateFormData(formData);
      nextTab2();
    }
  };

  return (
    <>
      <fieldset className="field-card" style={{ display: "block" }}>
        <div className="add-course-info">
          <div className="add-course-inner-header">
            <h4>{t("Courses Media")}</h4>
          </div>
          <div className="add-course-form">
            <form action="#">
              <div className="form-group">
                <label>{t("Video URL")}</label>
                <input
                  type="text"
                  className="form-control"
                  name="videoUrl"
                  value={formData.videoUrl}
                  onChange={handleChange}
                />
                <small className="form-text text-muted">
                  {t("Video URL must be a valid url")}
                </small>
              </div>

              <div className="form-group">
                <label className="add-course-label">
                  {t("Course cover image")}
                  <p className="invalid-feedback d-block">{t(error)}</p>
                </label>
                <div className="relative-form">
                  <span>
                    {formData.coverImg
                      ? formData.coverImg.name
                      : t("No File Selected")}
                  </span>
                  <label className="relative-file-upload">
                    {t("Upload File")}{" "}
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleFileUpload}
                    />
                  </label>
                </div>
                <small className="form-text text-muted">
                  {t("form.recommendedDimensions", {
                    dimensions: "1088 * 612",
                  })}
                </small>
              </div>
              {coverImg && (
                <div className="form-group">
                  <div className="add-image-box">
                    <img src={coverImg} alt="" style={{ width: "100%" }} />
                  </div>
                </div>
              )}

              <div className="form-group">
                <label className="add-course-label">{t("Course manual")}</label>
                <div className="relative-form">
                  <span>
                    {formData.manual
                      ? formData.manual.name
                      : t("No File Selected")}
                  </span>
                  <label className="relative-file-upload">
                    {t("Upload File")}{" "}
                    <input type="file" onChange={handleManualUpload} />
                  </label>
                  {manualType == "file" && (
                    <span className="manual-span">
                      <img src={CheckCircle} />
                    </span>
                  )}
                </div>
              </div>
              {manual && manualType && manualType == "img" && (
                <div className="form-group">
                  <div className="add-image-box">
                    <img src={manual} alt="" style={{ width: "100%" }} />
                  </div>
                </div>
              )}

              {/* Remaining code */}
            </form>
          </div>
          <div className="widget-btn">
            <button
              type="button"
              className="btn btn-black prev_btn"
              onClick={prevTab1}>
              {t("Previous")}
            </button>
            <button
              type="button"
              className={`btn btn-info-light next_btn ${
                !isFormValid ? "disabled" : ""
              }`}
              onClick={handleNextTab}>
              {t("Continue")}
            </button>
          </div>
        </div>
      </fieldset>
    </>
  );
};

export default CourseMedia;
