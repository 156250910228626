import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Footer from "../../../footer";
import Header from "../../../header";
import { InstructorProfileBg } from "../../../imagepath";
import { useForm } from "react-hook-form";
import { useActions, useAppState } from "../../../../presenter";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { AddAdminValidator } from "../../../../validators/add-admin.validator";

const AddAdmin = () => {
  const [eye, seteye] = useState(true);

  const { t } = useTranslation();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");
  const { loadingBtn } = useAppState();
  const navigate = useNavigate();

  const { getAdmin, createAdmin, updateAdmin, listAdmins } = useActions();

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(AddAdminValidator),
  });

  const goBack = () => navigate("/admin/manage-admins");

  useEffect(() => {
    if (id) {
      getAdmin(id).then((data) => {
        setValue("firstName", data.firstName);
        setValue("lastName", data.lastName);
        setValue("email", data.email);
        setValue(
          "birthDate",
          new Date(data.birthDate).toISOString().substr(0, 10) || ""
        );
      });
    }
  }, [id, setValue]);

  const onSubmit = async (data) => {
    if (id) {
      await updateAdmin({ id, ...data });
      navigate("/admin/manage-admins");
      return;
    }
    await createAdmin(data);
    navigate("/admin/manage-admins");
  };

  const onEyeClick = () => {
    seteye(!eye);
  };
  return (
    <>
      <div className="main-wrapper">
        <Header />
        <div className="page-content">
          <div className="container" style={{ marginTop: "70px" }}>
            <div className="row">
              {/* Profile Details */}
              <div className="settings-widget profile-details">
                <div className="settings-menu p-0">
                  <div className="profile-heading">
                    <h3>{id ? t("updateAdmin") : t("addAdmin")}</h3>
                  </div>
                  <div className="checkout-form personal-address add-course-info">
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>{t("form.firstName")}</label>
                            <input
                              className="form-control"
                              {...register("firstName")}
                            />
                            {errors.firstName && (
                              <p className="error">
                                {errors.firstName.message}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>{t("form.lastName")}</label>
                            <input
                              className="form-control"
                              {...register("lastName")}
                            />
                            {errors.lastName && (
                              <p className="error">{errors.lastName.message}</p>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>{t("form.email")}</label>
                            <input
                              className="form-control"
                              {...register("email")}
                            />
                            {errors.email && (
                              <p className="error">{t(errors.email.message)}</p>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label className="form-control-label">
                              {t("form.password")}
                            </label>
                            <div className="pass-group" id="passwordInput">
                              <input
                                {...register("password")}
                                className="form-control pass-input"
                                placeholder={t("form.enterPassword")}
                                type={eye ? "password" : "text"}
                              />
                              {errors.password && (
                                <p className="error">
                                  {t(errors.password?.message)}{" "}
                                </p>
                              )}
                              <span
                                onClick={onEyeClick}
                                className={`fa toggle-password feather-eye" ${
                                  eye ? "fa-eye" : "fa-eye-slash"
                                }`}
                              />
                              <span className="toggle-password feather-eye"></span>
                              <span className="pass-checked">
                                <i className="feather-check"></i>
                              </span>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>{t("form.birthDate")}</label>
                            <input
                              className="form-control"
                              {...register("birthDate")}
                              format="yyyy-mm-dd"
                              type="date"
                              placeholder={t("form.enterBirthDate")}
                            />
                            {/* {errors.videoLink && (
                              <p className="error">
                                {errors.videoLink.message}
                              </p>
                            )} */}
                          </div>
                        </div>
                        <div className="col-lg-6"></div>

                        <div className="update-profile un-subscribe">
                          <button
                            type="submit"
                            className="btn btn-primary"
                            disabled={loadingBtn}>
                            {loadingBtn && (
                              <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"></span>
                            )}
                            {id ? t("updateAdmin") : t("addAdmin")}
                          </button>

                          <button
                            type="button"
                            onClick={goBack}
                            className="btn btn-danger">
                            {t("cancel")}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

              {/* Profile Details */}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default AddAdmin;
