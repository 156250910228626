import React from "react";
import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Footer from "../../../footer";
import Header from "../../../header";
import Basic from "./basic";
import CourseMedia from "./courseMedia";
import Curriculum from "./curriculum";
import Settings from "./settings";
import Success from "./success";
import { useActions, useAppState } from "../../../../presenter";
import PageChange from "../../../page-change";
import { useTranslation } from "react-i18next";

const AddCourse = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");
  const lang = queryParams.get("lang");
  const courseId = queryParams.get("courseId");
  const [TabChange, setTabChange] = useState(false);
  const [TabChange1, setTabChange1] = useState(false);
  const [TabChange2, setTabChange2] = useState(false);
  const [TabChange3, setTabChange3] = useState(false);
  const [pageChange, setPageChange] = useState("basic");
  const [formData, setFormData] = useState({});
  const { createCourse, addCourse, updateCourse } = useActions();

  useEffect(() => {
    if (pageChange == "success") {
      const courseFormData = new FormData();

      courseFormData.append("title", formData.title);
      courseFormData.append("subTitle", formData.subtitle);
      courseFormData.append("language", lang ? lang : formData.language);
      courseFormData.append("description", formData.description);
      courseFormData.append("videoUrl", formData.videoUrl);
      if (!id) {
        courseFormData.append("image", formData.coverImg);
        courseFormData.append("manual", formData.manual);
      } else {
        if (formData.coverImg)
          courseFormData.append("image", formData.coverImg);
        if (formData.manual) courseFormData.append("manual", formData.manual);
      }
      courseFormData.append("price", formData.price);
      courseFormData.append("isLifeProject", formData.hasSurvey);

      for (let i = 0; i < formData.sections.length; i++) {
        const section = formData.sections[i];
        if (id) {
          if (section.sectionId)
            courseFormData.append(`sections[${i}][id]`, section.sectionId);
        }
        courseFormData.append(`sections[${i}][order]`, i + 1);
        courseFormData.append(`sections[${i}][title]`, section.title);

        for (let j = 0; j < section.lessons.length; j++) {
          const lesson = section.lessons[j];
          if (id) {
            if (lesson.lessonId)
              courseFormData.append(
                `sections[${i}][lessons][${j}][id]`,
                lesson.lessonId
              );
          }
          courseFormData.append(`sections[${i}][lessons][${j}][order]`, j + 1);
          courseFormData.append(
            `sections[${i}][lessons][${j}][title]`,
            lesson.title
          );
          courseFormData.append(
            `sections[${i}][lessons][${j}][description]`,
            lesson.description
          );
          courseFormData.append(
            `sections[${i}][lessons][${j}][videoUrl]`,
            lesson.videoUrl
          );
          courseFormData.append(
            `sections[${i}][lessons][${j}][duration]`,
            lesson.duration
          );
        }
      }

      // Add tags
      for (let i = 0; i < formData.tags.length; i++) {
        courseFormData.append(`tags[${i}]`, formData.tags[i].id);
      }
      if (courseId) {
        addCourse({ id: courseId, language: lang, data: courseFormData }).then(
          () => {
            setPageChange("done");
          }
        );
      } else if (id) {
        updateCourse({ id, language: lang, data: courseFormData }).then(() => {
          setPageChange("done");
        });
      } else {
        createCourse(courseFormData).then(() => {
          setPageChange("done");
        });
      }
    }
  }, [formData]);

  function updateFormData(newData) {
    setFormData((prevData) => ({ ...prevData, ...newData }));
  }

  const nextTab = () => {
    setTabChange(true);
    setPageChange("courseMedia");
  };

  const prevTab1 = () => {
    setTabChange(false);
    setPageChange("basic");
  };

  const nextTab2 = () => {
    setTabChange1(true);
    setTabChange(true);
    setPageChange("curriculum");
  };

  const prevTab2 = () => {
    setTabChange1(false);
    setPageChange("courseMedia");
  };

  const nextTab3 = () => {
    setTabChange2(true);
    setTabChange(true);
    setPageChange("settings");
  };

  const prevTab3 = () => {
    setTabChange2(false);
    setPageChange("curriculum");
  };

  const nextTab4 = () => {
    setTabChange3(true);
    setTabChange(true);
    setPageChange("success");
  };

  return (
    <>
      <div className="main-wrapper">
        <Header activeMenu={"AddCourse"} />

        <section className="page-content course-sec">
          <div className="container" style={{ marginTop: "70px" }}>
            <div className="row align-items-center">
              <div className="col-md-12">
                <div className="add-course-header">
                  {id && <h2> {t("Add New Course")}</h2>}
                  {!id && <h2> {t("Edit Course")}</h2>}
                  <div className="add-course-btns">
                    <ul className="nav">
                      <li>
                        <Link to="/admin/courses" className="btn btn-black">
                          {t("Back to Courses")}
                        </Link>
                      </li>
                      {/* <li>
          <Link to="#" className="btn btn-success-dark" onClick={handleSubmit}>
            {t('Save')}
          </Link>
        </li> */}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="widget-set">
                    <div className="widget-setcount">
                      <ul id="progressbar">
                        <li
                          className={
                            TabChange ? "progress-activated" : "progress-active"
                          }>
                          <p>
                            <span></span> {t("Basic Information")}
                          </p>
                        </li>
                        <li
                          className={
                            TabChange1
                              ? "progress-activated"
                              : "" || TabChange
                              ? "progress-active"
                              : ""
                          }>
                          <p>
                            <span></span> {t("Courses Media")}
                          </p>
                        </li>
                        <li
                          className={
                            TabChange2
                              ? "progress-activated"
                              : "" || TabChange1
                              ? "progress-active"
                              : ""
                          }>
                          <p>
                            <span></span> {t("Curriculum")}
                          </p>
                        </li>
                        <li
                          className={
                            TabChange3
                              ? "progress-activated"
                              : "" || TabChange2
                              ? "progress-active"
                              : ""
                          }>
                          <p>
                            <span /> {t("Settings")}
                          </p>
                        </li>
                      </ul>
                    </div>

                    <div className="widget-content multistep-form">
                      {pageChange === "basic" ? (
                        <Basic
                          nextTab={nextTab}
                          updateFormData={updateFormData}
                          data={formData}
                        />
                      ) : (
                        ""
                      )}
                      {pageChange === "courseMedia" ? (
                        <CourseMedia
                          nextTab2={nextTab2}
                          prevTab1={prevTab1}
                          updateFormData={updateFormData}
                          data={formData}
                        />
                      ) : (
                        ""
                      )}
                      {pageChange === "curriculum" ? (
                        <Curriculum
                          nextTab3={nextTab3}
                          prevTab2={prevTab2}
                          updateFormData={updateFormData}
                          data={formData}
                        />
                      ) : (
                        ""
                      )}
                      {pageChange === "settings" ? (
                        <Settings
                          nextTab4={nextTab4}
                          prevTab3={prevTab3}
                          updateFormData={updateFormData}
                          data={formData}
                        />
                      ) : (
                        ""
                      )}
                      {pageChange === "success" ? <PageChange /> : ""}
                      {pageChange === "done" ? <Success /> : ""}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </div>
    </>
  );
};

export default AddCourse;
