import React, { useEffect, useState } from "react";
import { button, useNavigate, useParams } from "react-router-dom";
import { useForm, FormProvider, useFormContext } from "react-hook-form";
import Footer from "../../footer";
import PageHeader from "../header";
import { useActions, useAppState } from "../../../presenter";
import PageChange from "../../page-change";
import { captureScreenshot } from "../../../utils/screenshot";
import Header from "../../header";
import { useTranslation } from "react-i18next";
import { async } from "regenerator-runtime";

const BusinessModel = ({ onClick: setPageChange }) => {
  const { t } = useTranslation();
  const [questions, setQuestions] = useState([]);
  const { id } = useParams();
  const [allquestions, setAllQuestions] = useState([]);
  const { submitReport } = useActions();
  const {
    listBusinessModelQuestions,
    setScreenshots,
    setBusinessModelQuestions,
  } = useActions();
  const [isDisabled, setIsDisabled] = useState();
  const { businessModelResult, loadingBtn } = useAppState();
  const onSubmitReport = async () => {
    const screenshot = await captureScreenshot(
      document.getElementById("businessModel")
    );

    if (screenshot) {
      await setScreenshots({ key: "businessModel", screenshot });
    }
    await setBusinessModelQuestions(questions);
    const result = await submitReport(id);
    if (result) setPageChange("report");
  };
  useEffect(() => {
    listBusinessModelQuestions().then((result) => {
      setAllQuestions(result);
    });
  }, []);

  useEffect(() => {
    if (businessModelResult) {
      const updatedQuestions = [...allquestions];
      businessModelResult.map((question) => {
        const questionExist = updatedQuestions.find((q) => q.id == question.id);
        if (questionExist) {
          questionExist.answer = question.result;
        }
        console.log();
      });
      setQuestions(updatedQuestions);
    } else {
      setQuestions(allquestions);
    }
  }, [businessModelResult, allquestions]);

  const handleAnswerChange = (e, i) => {
    const answer = e.target.value;
    const updatedQuestions = [...questions];
    updatedQuestions[i].answer = answer;
    setQuestions(updatedQuestions);
  };

  useEffect(() => {
    const filteredData = allquestions.filter((item) =>
      item.hasOwnProperty("answer")
    );
    const answers = filteredData.map((item) => item.answer);
    const newAnswersLength = answers.filter((answer) => answer).length;
    if (newAnswersLength === allquestions.length) setIsDisabled(false);
    else setIsDisabled(true);
  }, [questions.map((obj) => obj.answer)]);

  return (
    <>
      <div id="businessModel">
        <div>
          <div
            className="survey-header container-fluid py-3 bg-custom"
            id="remove">
            <div className="row align-items-center justify-content-between">
              <div className="col-md-8">
                <h6 className="text-light">{t("step4")}</h6>
                <h3 className="text-light">{t("bussinessModel")}</h3>
                <p
                  className="text-light d-none d-md-block"
                  style={{ maxWidth: "590px" }}>
                  {t("bussinessModelKey")}
                </p>
              </div>
              <div className="col-auto">
                <div className="progress-circle mx-auto">
                  <div className="half-circle"></div>
                  <span className="progress-text">3/4</span>
                </div>
              </div>
            </div>
            <p
              className="text-light d-md-none text-center mt-3 mb-0"
              style={{ maxWidth: "590px" }}>
              {t("projectImage")} ?
            </p>
          </div>
          <div>
            <div style={{ marginBottom: "10rem" }}></div>
            <section
              class="container-md p-5 rounded mx-auto"
              style={{
                backgroundColor: "#f4f4f4",
              }}>
              <div class="row">
                {questions.map((question, i) => (
                  <div class="col-md-6 col-sm-12 mb-4" key={question.id}>
                    <div class="question-title text-wrap">
                      <p className="text-break">
                        {t("form.question")}:&nbsp;
                        {question.question.replace(/ /g, "\u00A0")}
                      </p>
                    </div>
                    <div>{t("answer")}:</div>
                    <input
                      type="text"
                      value={question.answer?.replace(/ /g, "\u00A0")}
                      class="form-control"
                      onChange={(e) => handleAnswerChange(e, i)}
                    />
                  </div>
                ))}
              </div>
            </section>
          </div>
        </div>
      </div>
      <div className="widget-btn">
        <button
          type="button"
          className="btn btn-black prev_btn"
          onClick={() => setPageChange("projectPosition")}>
          {t("back")}
        </button>
        <button
          type="button"
          onClick={() => {
            onSubmitReport();
          }}
          className="btn btn-info-light next_btn"
          disabled={loadingBtn || isDisabled}>
          {t("form.submit")}
          {loadingBtn && (
            <span
              className="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"></span>
          )}
        </button>
      </div>
    </>
  );
};

export default BusinessModel;
