import React, { useState } from "react";
import ReactDOM from "react-dom/client";

import { createOvermind } from "overmind";
import { Provider } from "overmind-react";
import { config } from "./presenter";
import { GoogleOAuthProvider } from "@react-oauth/google";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import AppRouter from "./approuter";
import {
  requestNotificationPermission,
  onMessageListener,
} from "./firebase/firebase";

// CSS & Bootstrap
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/bootstrap.min.css";
import "./assets/css/style.css";
import "./assets/js/bootstrap.min.js";

// import "./assets/css/select2.min.css";

// Font Awesome
import "./assets/plugins/fontawesome/css/fontawesome.min.css";
import "./assets/plugins/fontawesome/css/all.min.css";
import i18n from "./locales/i18n";
import configurations from "../config";
import ComingSoon from "./components/pages/error/comingSoon";

const user = JSON.parse(localStorage.getItem("user")) || null;

const isRtl = user ? user.language == "AR" : i18n.language === "ar";
if (user) i18n.changeLanguage(user.language.toLowerCase());

const elem = document.getElementById("root");
if (isRtl) elem.classList.add("rtl");
else elem.classList.add("ltr");

const root = ReactDOM.createRoot(elem);
export const overmind = createOvermind(config, {
  devtools: false,
  onInitialize: ({ state }) => {
    // set initial state from localStorage on app mount
    state.user = JSON.parse(localStorage.getItem("user")) || null;
    state.accessToken = localStorage.getItem("accessToken") || null;
    state.screenshots = JSON.parse(localStorage.getItem("screenshots")) || null;
  },
});

requestNotificationPermission();

if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register("./firebase-messaging-sw.js")
    .then((registration) => {
      console.log("Service worker registered:", registration);
    })
    .catch((error) => {
      console.log("Failed to register service worker:", error);
    });
}

onMessageListener().then((payload) => console.log(payload));

root.render(
  <Provider value={overmind}>
    <GoogleOAuthProvider clientId={`${configurations.googleClientID}`}>
      <ToastContainer
        position="top-center"
        hideProgressBar={true}
        rtl={isRtl}
      />

      <AppRouter />
    </GoogleOAuthProvider>
  </Provider>
);
