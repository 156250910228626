import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useForm, FormProvider, useFormContext } from "react-hook-form";
import Footer from "../../footer";
import PageHeader from "../header";
import { useActions, useAppState } from "../../../presenter";
import PageChange from "../../page-change";
import { captureScreenshot } from "../../../utils/screenshot";
import Header from "../../header";
import { useTranslation } from "react-i18next";

const ProjectPosition = ({ onClick: setPageChange }) => {
  const { t, i18n } = useTranslation();
  const { selectedSubfields, projectPositionAnswers, reportData } =
    useAppState();
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const { setProjectPositionAnswers, setScreenshots } = useActions();

  useEffect(() => {
    if (reportData && reportData.projectPosition) {
      setSelectedCheckboxes(reportData.projectPosition);
    }
  }, [reportData]);

  useEffect(() => {
    if (projectPositionAnswers) setSelectedCheckboxes(projectPositionAnswers);
  }, [projectPositionAnswers]);

  const handleCheckboxChange = (event, text) => {
    // Add or remove the selected text based on the checkbox state
    if (event.target.checked) {
      setSelectedCheckboxes((prevSelected) => [...prevSelected, text]);
    } else {
      setSelectedCheckboxes((prevSelected) =>
        prevSelected.filter((item) => item !== text)
      );
    }
  };

  const handleNextClick = async () => {
    if (
      JSON.stringify(selectedCheckboxes) !=
      JSON.stringify(projectPositionAnswers)
    )
      await setProjectPositionAnswers(selectedCheckboxes);
    const screenshot = await captureScreenshot(
      document.getElementById("projectPosition")
    );

    if (screenshot) {
      await setScreenshots({ key: "projectPosition", screenshot });
    }
    setPageChange("businessModel");
  };

  if (!selectedSubfields) return <PageChange />;
  return (
    <div>
      <div className="survey-header container-fluid py-3 bg-custom">
        <div className="row align-items-center justify-content-between">
          <div className="col-md-8">
            <h6 className="text-light">{t("step3")}</h6>
            <h3 className="text-light">{t("projectPosition")}</h3>
            <p className="text-light d-none d-md-block">
              {t("projectImage")} ?
            </p>
          </div>
          <div className="col-auto">
            <div className="progress-circle mx-auto">
              <div className="half-circle"></div>
              <span className="progress-text">3/4</span>
            </div>
          </div>
        </div>
        <p className="text-light d-md-none text-center mt-3 mb-0">
          {t("projectImage")} ?
        </p>
      </div>
      <div
        style={{
          height: "50px",
        }}></div>
      <section
        id="projectPosition"
        class="container-md mt-5 p-3 p-md-5 rounded"
        style={{ backgroundColor: "#f4f4f4" }}>
        <div class="d-flex  align-items-center flex-wrap mb-3">
          <h3 class="d-inline mb-0">
            {t("InTheFollowingSubfields").replace(/ /g, "\u00A0")}:
          </h3>
          <div style={{ width: "10px" }}> </div>
          <p class="d-inline mb-0  mr-5">
            {selectedSubfields
              .map((x) => x.subfieldName.replace(/ /g, "\u00A0"))
              .join(", ")}{" "}
          </p>
        </div>
        <h4 class="mb-4">
          {t("IWantto").replace(/ /g, "\u00A0")}:{"\u00A0"}
        </h4>
        <div class="row row-cols-1 row-cols-md-2 g-4">
          <div class="dummy-text col mb-3">
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                id="dummy1"
                name="dummy1"
                checked={selectedCheckboxes.some(
                  (item) => item === t("statement1")
                )}
                onChange={(event) =>
                  handleCheckboxChange(event, t("statement1"))
                }
              />
              <label class="form-check-label" htmlFor="dummy1">
                {t("statement1").replace(/ /g, "\u00A0")}
              </label>
            </div>
          </div>
          <div class="dummy-text col mb-3">
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                id="dummy2"
                name="dummy2"
                checked={selectedCheckboxes.some(
                  (item) => item === t("statement2")
                )}
                onChange={(event) =>
                  handleCheckboxChange(event, t("statement2"))
                }
              />
              <label class="form-check-label" htmlFor="dummy2">
                {t("statement2").replace(/ /g, "\u00A0")}
              </label>
            </div>
          </div>
          <div class="dummy-text col mb-3">
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                id="dummy3"
                name="dummy3"
                checked={selectedCheckboxes.some(
                  (item) => item === t("statement3")
                )}
                onChange={(event) =>
                  handleCheckboxChange(event, t("statement3"))
                }
              />
              <label class="form-check-label" htmlFor="dummy3">
                {t("statement3").replace(/ /g, "\u00A0")}
              </label>
            </div>
          </div>
          <div class="dummy-text col mb-3">
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                id="dummy4"
                name="dummy4"
                checked={selectedCheckboxes.some(
                  (item) => item === t("statement4")
                )}
                onChange={(event) =>
                  handleCheckboxChange(event, t("statement4"))
                }
              />
              <label class="form-check-label" htmlFor="dummy4">
                {t("statement4").replace(/ /g, "\u00A0")}
              </label>
            </div>
          </div>
        </div>
      </section>
      <div className="widget-btn">
        <button
          type="button"
          className="btn btn-black prev_btn"
          onClick={() => setPageChange("selectedSuggestions")}>
          {t("back")}
        </button>
        <button
          type="button"
          onClick={handleNextClick}
          disabled={selectedCheckboxes.length > 0 ? false : true}
          className="btn btn-info-light next_btn">
          {t("next")}
        </button>
      </div>
    </div>
  );
};

export default ProjectPosition;
