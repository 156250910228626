import * as Yup from "yup";

export const SignupFormValidator = Yup.object().shape({
  firstName: Yup.string("form.validation.string").required(
    "form.validation.required"
  ),

  lastName: Yup.string("form.validation.string").required(
    "form.validation.required"
  ),

  email: Yup.string("form.validation.string")
    .email("form.validation.email")
    .required("form.validation.required"),

  password: Yup.string("form.validation.string")
    .min(8, "form.validation.passwordLength")
    .required("form.validation.required"),

  isSubscribed: Yup.boolean(),
  // termsAndPolicy: Yup.boolean().oneOf([true]),
});
