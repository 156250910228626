import "regenerator-runtime/runtime";

import axios from "axios";
import {
    BadRequestError,
    ForbiddenError,
    InternalServerError,
    UnauthenticatedError,
    UnexpectedError,
} from "../errors";
import { apiClient } from "./constants";

const BASE_ROUTE = "/notification";

export const listNotifications = async (currentPage, limit, q) => {
    try {
        let queryParams = currentPage ? `?page=${currentPage}&limit=${limit}` : "";
        if (q) queryParams += `&q=${q}`;
        const result = await apiClient.get(
            `${BASE_ROUTE}${queryParams}`);
        return result.data;
    } catch (err) {
        console.log('err', err);
        if (axios.isAxiosError(err)) {
            const responseData = err.response.data;

            if (err.response?.status == 400)
                throw new BadRequestError(responseData.message);
            if (err.response?.status == 401)
                throw new UnauthenticatedError(responseData.message);
            if (err.response?.status == 403)
                throw new ForbiddenError(responseData.message);
            if (err.response?.status == 404)
                throw new UnexpectedError(responseData.message);
            if (err.response?.status == 500)
                throw new InternalServerError(responseData.message);
        }
        throw new UnexpectedError();
    }
};


export const markAllNotificationsAsRead = async () => {
    try {
        const result = await apiClient.patch(
            `${BASE_ROUTE}/readAll`);
        return result.data;
    } catch (err) {
        console.log('err', err);
        if (axios.isAxiosError(err)) {
            const responseData = err.response.data;

            if (err.response?.status == 400)
                throw new BadRequestError(responseData.message);
            if (err.response?.status == 401)
                throw new UnauthenticatedError(responseData.message);
            if (err.response?.status == 403)
                throw new ForbiddenError(responseData.message);
            if (err.response?.status == 404)
                throw new UnexpectedError(responseData.message);
            if (err.response?.status == 500)
                throw new InternalServerError(responseData.message);
        }
        throw new UnexpectedError();
    }
};


export const markNotificationAsRead = async (notificationId) => {
    try {
        const result = await apiClient.patch(
            `${BASE_ROUTE}/${notificationId}/read`);
        return result.data;
    } catch (err) {
        console.log('err', err);
        if (axios.isAxiosError(err)) {
            const responseData = err.response.data;

            if (err.response?.status == 400)
                throw new BadRequestError(responseData.message);
            if (err.response?.status == 401)
                throw new UnauthenticatedError(responseData.message);
            if (err.response?.status == 403)
                throw new ForbiddenError(responseData.message);
            if (err.response?.status == 404)
                throw new UnexpectedError(responseData.message);
            if (err.response?.status == 500)
                throw new InternalServerError(responseData.message);
        }
        throw new UnexpectedError();
    }
};