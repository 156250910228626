import React from "react";
import { useEffect } from "react";
import { ComeSoon, logo } from "../../../imagepath";

const ComingSoon = () => {
  useEffect(() => {
    document.body.classList.add("error-page");
    return () => document.body.classList.remove("error-page");
  }, []);

  return (
    <>
      <div
        className="main-wrapper"
        style={{ alignItems: "center", display: "flex", height: "100vh" }}
      >
        <div className="error-box">
          <div className="error-logo">
            <a to="/">
              <img src={logo} className="img-fluid w-75" alt="Logo" />
            </a>
          </div>
          <h4>قريباً - WE ARE COMING SOON</h4>
          <h6 className="font-weight-normal">
            ترقبوا شيئاً مدهشاً - Stay tuned for something amazing
          </h6>
        </div>
      </div>
    </>
  );
};

export default ComingSoon;
