import React from "react";
import "regenerator-runtime/runtime";

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { SubscribeToNewsLetterFormValidator } from "../../validators/newsletter.validator";
import { useActions } from "../../presenter";

export default function NewsLetterForm() {
  const { t: translate } = useTranslation();
  const { subscribeToNewsLetter } = useActions();
  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(SubscribeToNewsLetterFormValidator),
  });

  const onSubmit = async (data) => {
    await subscribeToNewsLetter(data);
  }

  const handleKeypress = async (event) => {
    //it triggers by pressing the enter key
    if (event.keyCode === 13) {
      formRef.current.dispatchEvent(new Event('submit', { cancelable: true }))
    }
  };

  return (
    <div className="news-letter">
      <form onSubmit={handleSubmit(async (data) => await onSubmit(data))}>
        <input
          type="text"
          className="form-control"
          placeholder={translate('form.placeholders.newsLetterEmail')}
          onKeyPress={handleKeypress}
          {...register("email")}
        />
      </form>
      <p className="invalid-feedback d-block">
        {translate(errors.email?.message)}
      </p>
    </div>
  );
}