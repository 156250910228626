import "regenerator-runtime/runtime"; 

import { handleError } from "../errors";

export const getHomepageData = async (context , {language}) => {
  try {
    const result = await context.effects.getHomepageData(language);
    return result;
  } catch (err) {
    handleError(err);
  }
};
