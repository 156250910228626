import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import podcastDefaultImage from "../../../assets/img/podcastDefaultImage.png";

const TopCategory = ({ podcasts, podcastLink }) => {
  const { t } = useTranslation();
  var settings = {
    //autoWidth: true,
    items: 2,
    margin: 20,
    gap: 10,
    dots: true,
    nav: true,
    loop: false,
    rewind: true,
    navText: [
      '<i className="fas fa-arrow-left"></i>',
      '<i className="fas fa-arrow-right"></i>',
    ],
    rtl: true,
    responsiveClass: true,
    responsive: {
      0: {
        items: 1,
        margin: 20,
      },
      768: {
        items: 3,
        margin: 20,
      },
      1170: {
        items: 4,
        margin: 20,
      },
    },
  };

  return (
    <section className="section how-it-works d-none">
      <div className="container">
        <div className="section-header aos" data-aos="fade-up">
          <div className="section-sub-head">
            {/* <span>Favourite Course</span> */}
            <h2>{t("home.podcasts")}</h2>
          </div>
          <div className="all-btn all-category d-flex align-items-center">
            {podcastLink && (
              <Link
                to={podcastLink}
                target="_blank"
                className="btn btn-primary">
                {t("home.allPodcasts")}
              </Link>
            )}
          </div>
        </div>
        <div className="section-text aos" data-aos="fade-up">
          <p>
            {t('home.podcastDescription')}          
          </p>
        </div>
        {podcasts.length > 0 && (
          <OwlCarousel
            {...settings}
            style={{ direction: "rtl" }}
            className="owl-carousel mentoring-course owl-theme aos"
            data-aos="fade-up"
            nav>
            {podcasts.map((podcast) => (
              <div key={podcast.id} className="d-flex justify-content-center">
                {podcastLink && (
                  <Link to={`/podcast-details/${podcast.id}`}>
                    <img
                      src={podcast.image?.url ?? podcastDefaultImage}
                      alt=""
                      className="podcast-img-home w-100"
                    />
                  </Link>
                )}
              </div>
            ))}
          </OwlCarousel>
        )}
      </div>
    </section>
  );
};

export default TopCategory;
