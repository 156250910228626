import React from 'react';
import ReactLoading from 'react-loading';

export default function PageChange() {
  return (
    <div className='d-flex justify-content-md-center align-items-center vh-100'>
      <ReactLoading type='bubbles' color='#1875BA' height={'10%'} width={'10%'} />
    </div>
  );
}
