import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../../footer";
import { InstructorProfileBg, User15 } from "../../imagepath";
import { InstructorHeader } from "../header";
import InstructorSidebar from "../sidebar";
import Header from "../../header";
import { useActions, useAppState } from "../../../presenter";
import { set, useForm } from "react-hook-form";

import { useTranslation } from "react-i18next";
export default function InstructorSocialProfiles() {
  const { t } = useTranslation();
  const { listGeneralSettings, updateAdminGeneralSettings } = useActions();
  const [generalSettings, setGeneralSettings] = useState([]);
  const [error, setError] = useState(null);

  const getGeneralSettings = async () => {
    const result = await listGeneralSettings();
    setGeneralSettings(result);
  };
  useEffect(() => {
    getGeneralSettings();
  }, []);

  const { register, handleSubmit, getValues } = useForm({
    defaultValues: generalSettings.reduce(
      (acc, curr) => ({
        ...acc,
        [curr.id]: curr.value,
      }),
      {}
    ),
  });
  //Todo
  const validationForm = () => {
    let isValid = true;
    Object.entries(getValues()).map(([id, value], index) => {
      const field = {
        id,
        key: generalSettings[index].key,
        value,
      };
      if (field.key && field.value === "") {
        setError(`${field.key} must not be empty`);
        isValid = false;
      } else if (
        field.key.toLowerCase().includes("Link") &&
        !/^(ftp|http|https):\/\/[^ "]+$/.test(field.value)
      ) {
        setError([`${field.key} must be a valid URL`]);
        isValid = false;
      } else if (
        field.key.toLowerCase().includes("email") &&
        !/\S+@\S+\.\S+/.test(field.value)
      ) {
        setError(`${field.key} must be a valid email address`);
        isValid = false;
      }
    });
    return isValid;
  };
  const onSubmit = async (data) => {
    const validate = validationForm();
    if (!validate) {
      return;
    }
    const result = {
      generalSettings: Object.entries(data).map(([id, value], index) => ({
        id,
        key: generalSettings[index].key.toLowerCase(),

        value,
      })),
    };
    const updatedData = await updateAdminGeneralSettings(result);
    if (updatedData) setGeneralSettings(...result);
  };

  return (
    <div className="main-wrapper">
      <Header />

      {/* <InstructorHeader activeMenu={"SocialProfile"} /> */}
      {/* Page Wrapper */}
      <div className="page-content">
        <div className="container" style={{ marginTop: "70px" }}>
          <div className="row">
            {/* Sidebar */}
            <InstructorSidebar activeMenu={"GeneralSettings"} />
            {/* Sidebar */}

            {/* Profile Details */}
            <div className="col-xl-9 col-md-8">
              <div className="settings-widget profile-details">
                <div className="settings-menu p-0">
                  <div className="profile-heading">
                    <h3>{t("generalSettings")}</h3>
                  </div>
                  <div className="checkout-form personal-address">
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="row">
                        {generalSettings.map((settings) => (
                          <div className="col-lg-12" key={settings.id}>
                            <div className="form-group">
                              <label className="form-control-label">
                                {settings.displayName}
                              </label>
                              <input
                                className="form-control"
                                {...register(settings.id)}
                                defaultValue={settings.value}
                                key={settings.key}
                              />
                            </div>
                          </div>
                        ))}
                        {error && (
                          <p className="invalid-feedback d-block">{error}</p>
                        )}
                        <div className="update-profile un-subscribe">
                          <button type="submit" className="btn btn-primary">
                            {t("updateGeneralSettings")}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {/* Profile Details */}
          </div>
        </div>
      </div>
      {/* Page Wrapper */}
      <Footer />
    </div>
  );
}
