import React from "react";
import {
  Bell,
  Book,
  Video,
  File,
  Globe,
  Headphones,
  DollarSign,
  Home,
  Lock,
  PieChart,
  Power,
  RefreshCw,
  Server,
  Settings,
  ShoppingBag,
  Star,
  Trash2,
  User,
  Users,
  Briefcase,
  HelpCircle
} from "react-feather";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { InstructorProfileBg, User15, defaultImg } from "../../imagepath";
import { useActions, useAppState } from "../../../presenter";
import { FileText } from "feather-icons-react/build/IconComponents";

export default function InstructorSidebar({ activeMenu }) {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const { updateGeneralSettings } = useActions();
  const { user } = useAppState();

  const handleLanguageChange = () => {
    const newLanguage = i18n.language === "ar" ? "en" : "ar";
    i18n.changeLanguage(newLanguage);
    updateGeneralSettings({
      language: newLanguage.toUpperCase(),
      firstName: user.firstName,
      lastName: user.lastName,
      birthDate: new Date(user.birthDate).toISOString().substr(0, 10),
      country: user.country || "",
      description: user.description || "",
      job: user.job || "",
    });

    const isRtl = i18n.language === "ar";
    const elem = document.getElementById("root");
    if (isRtl) {
      elem.classList.add("rtl");
      elem.classList.remove("ltr");
    } else {
      elem.classList.add("ltr");
      elem.classList.remove("rtl");
    }
  };

  return (
    <div className="col-xl-3 col-lg-4 col-md-12 theiaStickySidebar">
      <div className="sideStickyBar">
        <div className="settings-widget dash-profile">
          <div className="settings-menu p-0">
            <div className="profile-bg">
              <div style={{ height: "100px" }}></div>
              <div className="profile-img">
                <img src={user.image?.url || defaultImg} alt="" />
              </div>
            </div>
            <div className="profile-group">
              <div className="profile-name text-center">
                <h4>
                  {user.firstName} {user.lastName}
                </h4>
                <p>Admin</p>
              </div>
              <div className="go-dashboard text-center">
                <Link to="/admin/add-course" className="btn btn-primary">
                {t("settings.createNewCourse")}
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="settings-widget account-settings feather-icon">
          <div className="settings-menu">
            <h3>{t("settings.dashboard").toUpperCase()}</h3>
            <ul>
              <li className="nav-item">
                <Link
                  to="/admin/dashboard"
                  className={
                    activeMenu === "Dashboard" ? "nav-item active" : "nav-item"
                  }>
                  <Home size={20} /> {t("settings.myDashboard")}
                </Link>
              </li>
              <li
                className={
                  activeMenu === "Courses" ? "nav-item active" : "nav-item"
                }>
                <Link to="/admin/courses" className="nav-link">
                  <Book size={20} /> {t("home.courses")}
                </Link>
              </li>
              <li
                className={
                  activeMenu === "Podcasts" ? "nav-item active" : "nav-item"
                }>
                <Link to="/admin/podcasts" className="nav-link">
                  <Headphones size={20} /> {t("home.podcasts")}
                </Link>
              </li>
              <li
                className={
                  activeMenu === "Videos" ? "nav-item active" : "nav-item"
                }>
                <Link to="/admin/videos" className="nav-link">
                  <Video size={20} /> {t("home.videos")}
                </Link>
              </li>
              <li
                className={
                  activeMenu === "Blogs" ? "nav-item active" : "nav-item"
                }>
                <Link to="/admin/blogs" className="nav-link">
                  <FileText size={20} /> {t("home.blogs")}
                </Link>
              </li>
              <li
                className={
                  activeMenu === "Fields" ? "nav-item active" : "nav-item"
                }
              >
                <Link to="/admin/fields" className="nav-link">
                  <Briefcase size={20} /> {t("settings.fields")}
                </Link>
              </li>
              <li
                className={
                  activeMenu === "BusinessModel" ? "nav-item active" : "nav-item"
                }
              >
                <Link to="/admin/business-model" className="nav-link">
                  <HelpCircle size={20} /> {t("settings.businessModel")}
                </Link>
              </li>

              <li
                className={
                  activeMenu === "Admins" ? "nav-item active" : "nav-item"
                }>
                <Link className="nav-link" to="/admin/manage-admins">
                  <User size={20} /> {t("home.admins")}
                </Link>
              </li>

              <li className="nav-item">
                <Link className="nav-link" onClick={handleLanguageChange}>
                  <Globe size={20} />{" "}
                  {user.language === "AR" ? "English" : "العربية"}
                </Link>
              </li>

              {/* <li className="nav-item">
                <Link
                  to="/instructor-reviews"
                  className={
                    activeMenu === "Reviews" ? "nav-item active" : "nav-item"
                  }
                >
                  <Star size={20} /> Reviews
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/instructor-earnings"
                  className={
                    activeMenu === "Earnings" ? "nav-item active" : "nav-item"
                  }
                >
                  <PieChart size={20} /> Earnings
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/instructor-orders"
                  className={
                    activeMenu === "Orders" ? "nav-item active" : "nav-item"
                  }
                >
                  <ShoppingBag size={20} /> Orders
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/instructor-student-grid"
                  className={
                    activeMenu === ("StudentGrid" || "StudentList")
                      ? "nav-item active"
                      : "nav-item"
                  }
                >
                  <Users size={20} /> Students
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/instructor-payouts"
                  className={
                    activeMenu === "Payouts" ? "nav-item active" : "nav-item"
                  }
                >
                  <DollarSign size={20} /> Payouts
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/instructor-tickets"
                  className={
                    activeMenu === "Tickets" ? "nav-item active" : "nav-item"
                  }
                >
                  <Server size={20} /> Support Tickets
                </Link>
              </li> */}
            </ul>
            <div className="instructor-title">
              <h3>{t("settings.account").toUpperCase()}</h3>
            </div>
            <ul>
              <li className="nav-item">
                <Link
                  to="/admin/profile"
                  className={
                    activeMenu === "EditProfile"
                      ? "nav-item active"
                      : "nav-item"
                  }>
                  <Settings size={20} /> {t("editProfile")}
                </Link>
              </li>
              {/* <li className="nav-item">
                <Link
                  to="/instructor-security"
                  className={
                    activeMenu === "Security" ? "nav-item active" : "nav-item"
                  }>
                  <User size={20} /> Security
                </Link>
              </li> */}
              <li className="nav-item">
                <Link
                  to="/admin/general-settings"
                  className={
                    activeMenu === "GeneralSettings"
                      ? "nav-item active"
                      : "nav-item"
                  }>
                  <RefreshCw size={20} /> {t("generalSettings")}
                </Link>
              </li>
              {/* <li className="nav-item">
                <Link
                  to="/instructor-notifications"
                  className={
                    activeMenu === "Notification"
                      ? "nav-item active"
                      : "nav-item"
                  }>
                  <Bell size={20} /> Notifications
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/instructor-profile-privacy"
                  className={
                    activeMenu === "ProfilePrivacy"
                      ? "nav-item active"
                      : "nav-item"
                  }>
                  <Lock size={20} /> Profile Privacy
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/instructor-delete-profile"
                  className={
                    activeMenu === "DeleteProfile"
                      ? "nav-item active"
                      : "nav-item"
                  }>
                  <Trash2 size={20} /> Delete Profile
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/instructor-linked-account"
                  className={
                    activeMenu === "LinkedAccount"
                      ? "nav-item active"
                      : "nav-item"
                  }>
                  <User size={20} /> Linked Accounts
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/" className="nav-link">
                  <Power size={20} /> Sign Out
                </Link>
              </li> */}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
